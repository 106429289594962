import { content } from './content';

export const transformBigNumber = (value: number): string => {
  return value.toLocaleString('fullwide', {
    useGrouping: true,
  });
};

const uomSummarySymbols = {
  [content.GPM_SYMBOL]: content.SUMMARY_GPM_SYMBOL,
  [content.LPM_SYMBOL]: content.SUMMARY_LPM_SYMBOL,
  [content.M3PH_SYMBOL]: content.SUMMARY_M3PH_SYMBOL,
};

type TUomSummarySymbolsType = keyof typeof uomSummarySymbols;

export const getCorrectUnit = (unit: string): string => {
  return uomSummarySymbols[unit as TUomSummarySymbolsType];
};
