import { MarlinTheme } from '@marlin/shared/theme';
import { IAnnotation } from '@marlin/shared/ui/annotation-tooltip';
import { IApexAxisChartThresholdSeries } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure, getFormattedValue } from '@marlin/shared/utils-format-reading';

export const getAnnotationPoints = ({
  thresholdsData,
  theme,
  onAnnotationLabelEnter,
  onAnnotationLabelLeave,
}: {
  thresholdsData: IApexAxisChartThresholdSeries[];
  theme: MarlinTheme;
  onAnnotationLabelEnter: (annotation: IAnnotation) => void;
  onAnnotationLabelLeave: () => void;
}) => {
  function createAnnotation(
    x: number,
    y: number | null,
    text: string,
    color: string,
    uom: TUnitOfMeasure | null,
    fullText?: string
  ): PointAnnotations {
    return {
      x,
      y,
      label: {
        mouseEnter: function (_: YAxisAnnotations, event: MouseEvent) {
          if (event.target instanceof Element) {
            onAnnotationLabelEnter?.({
              currentAnnotation: event.target,
              formattedValue: getFormattedValue(y?.toString() ?? '', uom),
              name: fullText ?? text,
            });
          }
        },
        mouseLeave: onAnnotationLabelLeave,
        borderColor: color,
        text,
        borderRadius: 20,
        offsetY: 13,
        style: {
          background: color,
          color: '#fff',
          fontWeight: 700,
          padding: {
            top: 2,
            bottom: 4,
          },
        },
      },
      marker: {
        size: 0,
      },
    };
  }

  return thresholdsData
    .filter((threshold) => threshold.type === 'line')
    .reduce<IApexAxisChartThresholdSeries[]>((acc, threshold) => {
      const filteredThresholds = threshold.data.filter((item) => item.y !== null).sort((a, b) => a.x - b.x);

      if (filteredThresholds.length && filteredThresholds.length % 2 === 0) {
        const thresholdsOdd = filteredThresholds
          .filter((_, index) => index % 2 !== 0)
          .map((thresholdData) => {
            return {
              ...threshold,
              data: [thresholdData],
            } satisfies IApexAxisChartThresholdSeries;
          });

        return [...acc, ...thresholdsOdd];
      }

      return [...acc, threshold];
    }, [])
    .map((threshold) => {
      return createAnnotation(
        Math.max(...threshold.data.map((item) => item.x)),
        typeof threshold.data[0].y === 'number' ? threshold.data[0].y : threshold.data[0].y?.[1] ?? null,
        threshold.alias,
        threshold.color ?? theme.palette.thresholds.persianRed,
        threshold.uom,
        threshold.name
      );
    });
};
