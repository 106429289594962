import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useIsFetching } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useLayoutLoader = () => {
  const loadingQueries = useIsFetching({ fetchStatus: 'fetching' });
  // Note: think how to improve - maybe hook checking if currentUser is fetching?
  const query = useCurrentUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (query.isFetching && loadingQueries) {
      setIsLoading(true);
    }
    if (!loadingQueries) {
      setIsLoading(false);
    }
  }, [query.isFetching, loadingQueries]);

  return isLoading;
};
