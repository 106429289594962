import { TSublocationAssetSchema } from '@marlin/asset/data-access/location';
import {
  DeleteDeviceModalBody,
  DeleteDeviceModalFooter,
  DeleteDeviceModalTitle,
} from '@marlin/asset/ui/delete-device-modal';
import {
  DeleteEquipmentModalBody,
  DeleteEquipmentModalFooter,
  DeleteEquipmentModalTitle,
} from '@marlin/asset/ui/delete-equipment-modal';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext } from 'react';

export interface IUseDeleteAssetModalProps {
  handleAfterDelete: () => void;
  refetch: () => void;
}

export const useDeleteAssetModal = ({
  handleAfterDelete,
  refetch,
}: IUseDeleteAssetModalProps): ((item: TSublocationAssetSchema) => void) => {
  const { modalDispatch } = useContext(ModalContext);

  const handleDelete = useCallback(
    (item: TSublocationAssetSchema) => {
      if (item.type === 'EQUIPMENT') {
        modalDispatch({
          type: MODAL_ACTION_TYPE.SHOW,
          payload: {
            title: <DeleteEquipmentModalTitle deviceCount={item.deviceCount} />,
            body: (
              <DeleteEquipmentModalBody deleteAssetName={item.name} deviceCount={item.deviceCount} assetId={item.id} />
            ),
            footer: (
              <DeleteEquipmentModalFooter
                deviceCount={item.deviceCount}
                assetId={item.id}
                handleAfterDelete={handleAfterDelete}
              />
            ),
          },
        });
      } else if (item.type === 'DEVICE') {
        modalDispatch({
          type: MODAL_ACTION_TYPE.SHOW,
          payload: {
            title: <DeleteDeviceModalTitle />,
            body: <DeleteDeviceModalBody deviceName={item.name} deviceId={item.id} />,
            footer: <DeleteDeviceModalFooter deviceId={item.id} refetch={refetch} />,
          },
        });
      }
    },
    [handleAfterDelete, modalDispatch, refetch]
  );

  return handleDelete;
};
