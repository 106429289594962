import BatteryFullRoundedIcon from '@mui/icons-material/BatteryFullRounded';

import { content } from '../content';
import { useStyles } from './device-state.component.styles';

interface ILowBatteryProps {
  batteryLevel: number | null;
}

const LowBattery = ({ batteryLevel }: ILowBatteryProps) => {
  const { classes } = useStyles();

  return (
    <>
      <BatteryFullRoundedIcon className={classes.orangeBatteryIcon} />
      <span className={classes.activeText} data-testid="status-icon-container-text">
        {content.LOW_BATTERY} ({batteryLevel}%)
      </span>
    </>
  );
};

export { LowBattery };
