import { Edge, Node } from 'reactflow';

import { TGraphAsset, TGraphEdge } from './schema/cytoscape.schema';
import { compareGraphNodes } from './utils/compare-graph-nodes';
import { cytoToReactFlowEdgeAdapter, reactFlowToCytoEdgeAdapter } from './utils/edge-adapters';
import { cytoToReactFlowNodeAdapterFactory, reactFlowToCytoNodeAdapter } from './utils/node-adapters';
const cytoToReactFlowNodesAdapter = (nodes: TGraphAsset[] = []): Node[] => {
  return nodes.sort(compareGraphNodes).map(cytoToReactFlowNodeAdapterFactory(nodes)).reverse();
};

const reactFlowToCytoNodesAdapter = (nodes: Node[]): TGraphAsset[] => {
  return nodes.map(reactFlowToCytoNodeAdapter);
};

const cytoToReactFlowEdgesAdapter = (edges: TGraphEdge[] = []): Edge[] => {
  return edges.map(cytoToReactFlowEdgeAdapter);
};

const reactFlowToCytoEdgesAdapter = (edges: Edge[]): TGraphEdge[] => {
  return edges.map(reactFlowToCytoEdgeAdapter);
};

export const reactFlowAdapter = {
  nodesFromCyto: cytoToReactFlowNodesAdapter,
  edgesFromCyto: cytoToReactFlowEdgesAdapter,
  nodesToCyto: reactFlowToCytoNodesAdapter,
  edgesToCyto: reactFlowToCytoEdgesAdapter,
};
