import {
  emailZodSchema,
  firstNameZodSchema,
  jobTitleZodSchema,
  lastNameZodSchema,
  phoneZodSchema,
  roleZodSchema,
} from '@marlin/shared/ui-form';
import * as z from 'zod';

export const UpsertProfileFormValidationSchema = z.object({
  firstName: firstNameZodSchema,
  lastName: lastNameZodSchema,
  phone: phoneZodSchema,
  email: emailZodSchema,
  title: jobTitleZodSchema.nullish(),
  role: roleZodSchema,
});
