// TODO: move useAssets to assets domain
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { ASSET_TYPE, useAssets } from '@marlin/system-map/data-access/system-map';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { content } from '../content';

export const useLocationList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onError = useCallback(() => {
    enqueueSnackbar(content.LOCATIONS_FETCHING_ERROR, {
      variant: 'error',
    });
  }, [enqueueSnackbar]);
  const locationsQuery = useAssets(
    {
      filter: ASSET_TYPE.LOCATION,
    },
    true,
    onError
  );
  const locationsList = locationsQuery?.data;

  return { locationsList };
};
