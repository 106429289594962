import { createDateString } from '@marlin/shared/utils-common-date';
import { z } from 'zod';

import { CRITICALITY } from '../automation.model';
import { getCreateChannels, mapCriticality } from '../infrastructure/dtos/utils';
import {
  automationBaseSchema,
  automationRuleTypeSchema,
  deviceSchema,
  equipmentSchema,
  locationSchema,
} from './automation.schema';
import { channelsSchema } from './channel.schema';
import { conditionSchema, mapConditions } from './condition.schema';
import { deviceTypeSchema } from './device-type.schema';
import { durationSchema, mapInactivityDurationTimeUnitResponse } from './duration.schema';

export const automationUpdateSchema = automationBaseSchema
  .extend({
    device: deviceSchema.nullable(),
    location: locationSchema.nullable(),
    equipment: equipmentSchema.nullable(),
  })
  .transform((data) => {
    const { thresholdMin, thresholdMax, deadbandMin, deadbandMax, ...rest } = data;

    return {
      ...rest,
      snoozeUntil: createDateString(data.snoozeUntil ? data.snoozeUntil : undefined),
      conditions: mapConditions({ thresholdMin, thresholdMax, deadbandMin, deadbandMax }),
    };
  });

const automationDeviceSchema = z.object({
  id: z.string(),
  manufacturerId: z.string().nullish(),
  name: z.string().optional(),
  deviceType: deviceTypeSchema.optional(),
  locationId: z.string().optional(),
  equipmentId: z.string().optional(),
});

export const createAutomationSchema = z
  .object({
    name: z.string(),
    ruleType: automationRuleTypeSchema.optional(),
    device: automationDeviceSchema.nullable(),
    manufacturerId: z.string().nullable(),
    channels: channelsSchema,
    criticality: z.nativeEnum(CRITICALITY),
    conditions: conditionSchema,
    snoozeIntervalInMinutes: z.number(),
    deviceType: deviceTypeSchema.optional(),
    isSystemRule: z.boolean().optional(),
    duration: durationSchema.nullable(),
  })
  .transform((data) => {
    return {
      name: data.name,
      deviceId: data?.device?.id,
      manufacturerId: data.manufacturerId,
      channels: getCreateChannels(data.channels),
      thresholdMax: data.conditions.thresholdMax?.value,
      thresholdMin: data.conditions.thresholdMin?.value,
      deadbandMax: data.conditions.deadbandMax?.value,
      deadbandMin: data.conditions.deadbandMin?.value,
      snoozeIntervalInMinutes: data.snoozeIntervalInMinutes,
      criticality: mapCriticality(data.criticality),
      inactivityDuration: data.duration?.value || null,
      inactivityDurationTimeUnit: data.duration?.unit
        ? mapInactivityDurationTimeUnitResponse(data.duration?.unit)
        : null,
    };
  });

export type TAutomationDevice = z.infer<typeof automationDeviceSchema>;
export type TCreateAutomation = typeof createAutomationSchema._input;

export const updateAutomationSchema = createAutomationSchema
  .innerType()
  .extend({
    id: z.string(),
    isEnabled: z.boolean(),
  })
  .transform((data) => {
    const conditions = data.duration?.value
      ? {
          thresholdMax: null,
          thresholdMin: null,
          deadbandMax: null,
          deadbandMin: null,
        }
      : {
          thresholdMax: data.conditions.thresholdMax?.value || null,
          thresholdMin: data.conditions.thresholdMin?.value || null,
          deadbandMax: data.conditions.deadbandMax?.value || null,
          deadbandMin: data.conditions.deadbandMin?.value || null,
        };

    return {
      id: data.id,
      isEnabled: data.isEnabled,
      isSystemRule: data.isSystemRule,
      name: data.name,
      deviceId: data?.device?.id || null,
      manufacturerId: data.manufacturerId,
      channels: getCreateChannels(data.channels),
      ...conditions,
      snoozeIntervalInMinutes: data.snoozeIntervalInMinutes,
      criticality: mapCriticality(data.criticality),
      inactivityDuration: data.duration?.value || null,
      inactivityDurationTimeUnit: data.duration?.unit
        ? mapInactivityDurationTimeUnitResponse(data.duration?.unit)
        : null,
    };
  });

export type TAutomationCreateResponse = z.infer<typeof automationUpdateSchema>;
export type TUpdateAutomation = typeof updateAutomationSchema._input;
