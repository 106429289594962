import { PERMISSIONS, TPermission } from '@marlin/shared/utils-permission';

import { adminPermissions } from './admin.permission';

export const supportPermissions: TPermission[] = [
  ...adminPermissions,
  PERMISSIONS.CREATE_ORGANIZATION,
  PERMISSIONS.SHOW_SUPPORT_PAGE,
  PERMISSIONS.SHOW_MONNIT_API_KEY_SETTINGS_PAGE,
];
