import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { useSnackbar } from 'notistack';
import { Navigate } from 'react-router-dom';

import { content } from '../content';
import { EquipmentDetailsConfigContextProvider } from '../context/equipment-details-config-context';
import { useEquipmentConfig } from '../hooks/use-equipment-config.hook';
import { useEquipment } from '../hooks/use-equipment.hook';
import { useNavigation } from '../hooks/use-navigation.hook';
import { DetailsBuilder } from './builder/builder.component';

export const EquipmentDetails = () => {
  const { equipment, refetchEquipment, isLoading, isError } = useEquipment();
  const navigation = useNavigation();
  const { enqueueSnackbar } = useSnackbar();

  const config = useEquipmentConfig({ equipmentId: equipment?.id, equipmentModel: equipment?.model });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!equipment) {
    enqueueSnackbar(content.ERROR, { variant: 'error', preventDuplicate: true });
    return <Navigate to={navigation.getEquipmentLink()} />;
  }

  if (!config) return <Navigate to={navigation.getEquipmentDetailsLink(equipment.id)} replace />;

  return (
    <EquipmentDetailsConfigContextProvider config={config}>
      <DetailsBuilder equipment={equipment} isEquipmentError={isError} refetchEquipment={refetchEquipment} />
    </EquipmentDetailsConfigContextProvider>
  );
};
