import { zodResolver } from '@hookform/resolvers/zod';
import { TExportFeedbackPayload, useExportFeedback } from '@marlin/account-data-access-user';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';

import { content } from '../../content';
import { HelpFormDataSchema, TCategory } from './help-form-data-schema';

export interface IHelp {
  name: string;
  category: TCategory | '';
  description: string;
}

export interface IUpsertDevice {
  form: UseFormReturn<TExportFeedbackPayload>;
  onSubmit: SubmitHandler<TExportFeedbackPayload>;
  onCancel: () => void;
  isLoading: boolean;
}

export const useHelpForm = (onSuccess: (value: boolean) => void, defaultValues?: IHelp): IUpsertDevice => {
  const form = useForm<TExportFeedbackPayload>({
    mode: 'onTouched',
    resolver: zodResolver(HelpFormDataSchema),
    defaultValues,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useExportFeedback();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCancel = () => {
    form.resetField('category');
    form.resetField('description');
  };

  const onSubmit = (data: TExportFeedbackPayload) => {
    setIsLoading(true);
    return mutateAsync({
      category: data.category,
      description: data.description,
    })
      .then(() => {
        setIsLoading(false);
        onSuccess(true);
        enqueueSnackbar(content.MESSAGE_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
      })
      .catch(() => {
        setIsLoading(false);
        enqueueSnackbar(content.MESSAGE_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      });
  };

  return {
    form,
    onSubmit,
    onCancel,
    isLoading,
  };
};
