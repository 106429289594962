import { useGetOrganizationDetails, useUpdateOrganizationDetails } from '@marlin/account-data-access-organization';
import { DataLoader } from '@marlin/shared/ui-loader';
import { Paper } from '@marlin/shared/ui-page';
import { formatTimezone } from '@marlin/shared/utils-common-date';
import { getAddressValidationService } from '@marlin/shared/utils/maps-api';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { content } from './content';
import { TAddressForm } from './form/address-form-data-schema';
import { UpsertAddress } from './form/upsert-address.component';
import { useStyles } from './organization-address.styles';
import { ReadOnlyAddress } from './read-only-address.component';
import { mapAddressToMapApi } from './utils/map-address-to-map-api';

interface ICoordinates {
  latitude?: number | null;
  longitude?: number | null;
}

export function OrganizationAddress() {
  const { classes } = useStyles();

  const { data, isFetched } = useGetOrganizationDetails();
  const { mutateAsync: updateOrganizationDetails, isLoading: isOrganizationDetailsUpdating } =
    useUpdateOrganizationDetails();
  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const getAddressCoordinates = useCallback(async (address: TAddressForm) => {
    let coordinates: ICoordinates = {
      latitude: null,
      longitude: null,
    };

    try {
      const validatedAddress = await getAddressValidationService()?.validateAddress({
        address: {
          addressLines: mapAddressToMapApi(address),
        },
      });
      const location = validatedAddress.result.geocode?.location;
      const verdict = validatedAddress.result.verdict;

      const isValidAddress = [
        verdict?.addressComplete,
        verdict?.validationGranularity !== 'OTHER',
        !verdict?.hasUnconfirmedComponents,
      ].every(Boolean);

      if (isValidAddress) {
        coordinates = {
          latitude: location?.latitude || coordinates.latitude,
          longitude: location?.longitude || coordinates.longitude,
        };
      }
    } catch (e) {
      coordinates = {
        latitude: null,
        longitude: null,
      };
    }

    return coordinates;
  }, []);

  const submit = useCallback(
    async (address: TAddressForm) => {
      const coordinates = await getAddressCoordinates(address);

      return updateOrganizationDetails({
        name: data?.name || '',
        address: { ...address, ...coordinates },
        timeZone: address.timeZone?.id ?? '',
      })
        .then(() => {
          enqueueSnackbar(content.UPDATE_SUCCESS, { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar(content.UPDATE_ERROR, { variant: 'error' });
        });
    },
    [data?.name, enqueueSnackbar, updateOrganizationDetails, getAddressCoordinates]
  );

  const defaultValues = data?.address ? { ...data.address, timeZone: formatTimezone(data.timeZone || '') } : undefined;

  if (isFetched && !editMode) {
    return <ReadOnlyAddress defaultValues={defaultValues} toggleEditMode={toggleEditMode} />;
  }

  return (
    <Paper data-testid="card">
      <Grid container columnSpacing={2} className={classes.section}>
        <Grid item className={classes.sectionTitle} data-testid="card-title">
          {content.ORGANIZATION_ADDRESS}
        </Grid>
      </Grid>
      <Grid container className={classes.section}>
        {!isFetched ? (
          <DataLoader />
        ) : (
          <UpsertAddress
            onSubmit={submit}
            disableSubmit={isOrganizationDetailsUpdating}
            defaultValues={defaultValues}
            editMode={editMode}
            toggleEditMode={toggleEditMode}
          />
        )}
      </Grid>
    </Paper>
  );
}
