import { DateSchema } from '@marlin/shared/utils-common-date';
import { z } from 'zod';

export const gatewayFiltersSchema = z.object({
  pageSize: z.number(),
  page: z.number(),
});

export const paginationSchema = z.object({
  pageSize: z.number(),
  page: z.number(),
  totalItems: z.number(),
});

export const powerSourceResponseSchema = z.union([z.literal('Line'), z.literal('Battery')]);
export const powerSourceSchema = z.union([z.literal('LINE'), z.literal('BATTERY')]);
export type TPowerSource = z.infer<typeof powerSourceSchema>;

export const powerSourceSchemaParser = powerSourceResponseSchema.transform((powerSource): TPowerSource => {
  switch (powerSource) {
    case 'Line': {
      return 'LINE';
    }
    case 'Battery': {
      return 'BATTERY';
    }
  }
});

const GatewayMode = z.union([z.literal('Standby'), z.literal('Active')]);
export type TGatewayMode = z.infer<typeof GatewayMode>;

export const gatewayResponseStatusSchema = z.union([z.literal('Active'), z.literal('Inactive')]);
export const gatewayStatusSchema = z.union([z.literal('ACTIVE'), z.literal('INACTIVE')]);
export type TGatewayStatus = z.infer<typeof gatewayStatusSchema>;

export const gatewayStatusSchemaParser = gatewayResponseStatusSchema.transform((gatewayStatus): TGatewayStatus => {
  switch (gatewayStatus) {
    case 'Active': {
      return 'ACTIVE';
    }
    case 'Inactive': {
      return 'INACTIVE';
    }
  }
});

const modelsWithGatewayIdAsName = ['NexaGateway'];

export const gatewaySchema = z
  .object({
    id: z.string(),
    gatewayName: z.string().nullish(),
    gatewayId: z.string(),
    powerSource: powerSourceSchemaParser,
    signalStrength: z.number(),
    batteryLevel: z.number(),
    activeDevices: z.number(),
    lastDataReceived: DateSchema,
    status: gatewayStatusSchemaParser,
    brand: z.string().optional(),
    model: z.string().optional(),
    action: z.string().optional(),
    mode: GatewayMode.nullish(),
    assignedDevices: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
        })
      )
      .optional(),
  })
  .transform((data) => {
    return {
      ...data,
      gatewayName: modelsWithGatewayIdAsName.includes(data.model || '') ? data.gatewayId : data.gatewayName,
    };
  });

export const gatewayResponse = z.object({
  pagination: paginationSchema,
  data: z.array(gatewaySchema),
});

export type TGatewayFilters = z.infer<typeof gatewayFiltersSchema>;
export type TPagination = z.infer<typeof paginationSchema>;
export type TGateway = z.infer<typeof gatewaySchema>;
export type TGatewayList = z.infer<typeof gatewayResponse>;

export const GatewayPayload = z.object({
  name: z.string(),
  locationId: z.string(),
  registrationCode: z.string(),
  description: z.string().nullish(),
  commissionDate: z.string().nullish(),
});

export type TGatewayPayload = z.infer<typeof GatewayPayload>;

export const DeviceBrandsCompatibleWithWatts = z
  .array(
    z.object({
      id: z.string(),
      name: z.string(),
      models: z
        .array(
          z.object({
            id: z.string(),
            brandId: z.string(),
            name: z.string(),
            isWattsGatewayCompatible: z.boolean(),
          })
        )
        .transform((data) => data.filter((model) => model.isWattsGatewayCompatible)),
    })
  )
  .transform((data) => data.filter((brand) => brand.models.length > 0));

export type TDeviceBrandsCompatibleWithWatts = z.infer<typeof DeviceBrandsCompatibleWithWatts>;

export const Command = z.object({
  gatewayId: z.string(),
  connectionId: z.string(),
  addressId: z.string(),
});

export type TCommand = z.infer<typeof Command>;

export const Operation = Command.extend({
  operationCode: z.string(),
});

export type TOperation = z.infer<typeof Operation>;

export const CommandPayload = z.object({
  datapoints: z.array(
    z.object({
      name: z.string(),
      value: z.string(),
    })
  ),
});

export type TCommandPayload = z.infer<typeof CommandPayload>;
