import { useEquipmentStatus } from '../../../../hooks/use-equipment-status.hook';
import { EquipmentStatusBar } from './equipment-status-bar.component';

interface IEquipmentStatusProps {
  fullWidth?: boolean;
}

export const EquipmentStatusIntellistation = ({ fullWidth }: IEquipmentStatusProps) => {
  const { statusLabel, displayTime, operationalIconTag, operationalStatusLabel, isOperational, isSanitizing } =
    useEquipmentStatus({
      statusDatapointName: 'overrideMode',
      modeMap: 'intellistation',
    });

  return (
    <EquipmentStatusBar
      fullWidth={fullWidth}
      isEquipmentOperational={isOperational}
      isErrorModeLabel={isSanitizing}
      statusLabel={statusLabel}
      operationalStatusLabel={operationalStatusLabel}
      operationalIconTag={operationalIconTag}
      displayTime={displayTime}
    />
  );
};
