import { SettingRow, SettingsSectionSkeleton } from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { useEquipmentSettings } from '../../../hooks/settings/use-settings-datapoints.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(22),
    // TODO: remove this padding when we add padding to section container
    padding: `0 ${theme.typography.pxToRem(16)}`,
  },
  displayedValue: {
    color: theme.palette.text.secondary,
  },
}));

export const ScheduleTemperatureSection = () => {
  const { classes } = useStyles();
  const equipmentId = useIdFromPathname();
  const { data: settings = [], isLoading } = useEquipmentSettings({
    equipmentId,
    expandedGroup: SETTINGS_GROUP.TEMPERATURE,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.container} data-testid="schedule-temperature-section">
      <Typography className={classes.sectionTitle}>{content.TEMPERATURE}</Typography>
      {isLoading ? (
        <SettingsSectionSkeleton />
      ) : (
        <div>
          {settings
            .filter((setting) => setting.name === 'tempSetbackDiff' || setting.name === 'tempSetpointTarget')
            .map((setting, index) => (
              <SettingRow
                key={setting.name}
                label={
                  setting.name === 'tempSetpointTarget' ? content.OCCUPIED_DATAPOINT(setting.label) : setting.label
                }
                name={setting.name}
                isLast={index === settings.length - 1}
                dataTestId={setting.label.toLowerCase().replace(/\s+/g, '-')}
                isFirst={index === 0}
                displayedValue={setting.displayedValue}
                tooltipText={content.SETTINGS_DISABLED_TOOLTIP}
              >
                {!isMobile ? (
                  <Typography variant="body2" data-testid="setting-list-item-value" className={classes.displayedValue}>
                    {setting.displayedValue}
                  </Typography>
                ) : null}
              </SettingRow>
            ))}
        </div>
      )}
    </div>
  );
};
