import { isEmptyChartData } from '@marlin/shared/ui/chart';
import {
  IApexAxisChartThresholdSeries,
  IChartSeries,
  RANGE_FILTER,
  TBucketOption,
  TChartDisplayType,
  getTimeFormat,
} from '@marlin/shared/utils-chart';
import { createDateString, dateAdapter, formatDate } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, getFormattedValue } from '@marlin/shared/utils-format-reading';
import get from 'lodash/get';
import { useObservableState } from 'observable-hooks';
import Plotly, { Config, Datum, Layout, PlotMouseEvent } from 'plotly.js';
import { useCallback, useMemo, useState } from 'react';
import { BehaviorSubject, debounceTime, map, pairwise } from 'rxjs';

interface IChartOptionsParams {
  chartData: IChartSeries[];
  chartDataWithoutMargins?: IChartSeries[];
  thresholdsData?: IApexAxisChartThresholdSeries[];
  rangeFilter: RANGE_FILTER;
  from?: number;
  to?: number;
  max: number;
  min: number;
  handleZoomChange: (zoom?: { min: number; max: number }) => void;
  isZoomed: boolean;
  chartDisplayType: TChartDisplayType;
  chartId: string;
  isFullScreen?: boolean;
  onFullScreenClick?: () => void;
  customIconClassName?: string;
  hideUomOnAxis?: boolean;
  additionalAxisUom?: TUnitOfMeasure;
  mainDatapoint?: string;
  bucketOption?: TBucketOption | '';
}

interface ITooltipData {
  color: string;
  displayedValue: string;
  name: string;
  formattedDate: string;
  date: string;
}

interface ITooltip {
  top: number;
  left: number;
  tooltipData: ITooltipData[] | null;
}

export interface IChartOptions {
  layout: Partial<Layout>;
  config: Partial<Config>;
  handleRelayout: (e: Plotly.PlotRelayoutEvent) => void;
  handleHover: (event: Plotly.PlotHoverEvent) => void;
  handleUnhover: (event: Readonly<PlotMouseEvent>) => void;
  isEmpty: boolean;
  tooltip: (Partial<ITooltip> | null) & {
    direction: 'right' | 'left';
    showTooltip: boolean;
  };
}

const numberTypeGuard = (value: unknown): value is number => typeof value === 'number';

const dateStringToTimestamp = (date: Datum): number => {
  switch (typeof date) {
    case 'string':
      return new Date(date).getTime();
    case 'number':
      return date;
    default:
      return 0;
  }
};
export const useChartOptionsV2 = ({
  max,
  min,
  chartData,
  chartDataWithoutMargins,
  thresholdsData = [],
  rangeFilter,
  from,
  to,
  isZoomed,
  handleZoomChange,
  chartDisplayType,
  chartId,
  isFullScreen,
  customIconClassName,
  onFullScreenClick,
  hideUomOnAxis,
  additionalAxisUom,
  mainDatapoint,
  bucketOption,
}: IChartOptionsParams): IChartOptions => {
  const isEmpty = isEmptyChartData({ chartData, from, to });
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltip$ = useMemo(() => new BehaviorSubject<ITooltip | null>(null), []);
  const [dragMode, setDragMode] = useState<
    'zoom' | 'pan' | 'select' | 'lasso' | 'orbit' | 'turntable' | false | undefined
  >(undefined);

  const [tooltip] = useObservableState(() => tooltip$.pipe(debounceTime(10)), null);
  const [tooltipDirection] = useObservableState(
    () =>
      tooltip$.pipe(
        pairwise(),
        map(([prev, curr]): 'right' | 'left' => {
          if (!prev || !curr) {
            return 'right';
          }

          const isPrevDateAfterCurrDate = dateAdapter
            ?.date(prev?.tooltipData?.[0].date)
            ?.isAfter(dateAdapter?.date(curr?.tooltipData?.[0].date));

          return isPrevDateAfterCurrDate ? 'right' : 'left';
        }),
        debounceTime(10)
      ),
    'left'
  );
  const setTooltip = useCallback((value: ITooltip | null) => tooltip$.next(value), [tooltip$]);

  const layout: Partial<Layout> = {
    barmode: 'overlay',
    hovermode: 'x unified',
    showlegend: false,
    xaxis: {
      type: 'date',
      range: from && to ? [new Date(from).toISOString(), new Date(to).toISOString()] : undefined,
    },
    yaxis: {
      fixedrange: true,
      side: 'right',
      range: [min, max],
    },
    height: isFullScreen ? 640 : 360,
    margin: {
      t: 50,
      b: 50,
    },
    dragmode: dragMode,
  };

  const config: Partial<Config> = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtons: !isFullScreen
      ? [
          [
            'zoomIn2d',
            'zoomOut2d',
            'zoom2d',
            'pan2d',
            {
              title: 'Full screen',
              name: 'autoscale',
              icon: {
                width: 1792,
                path: 'M883 1056q0 13-10 23l-332 332 144 144q19 19 19 45t-19 45-45 19h-448q-26 0-45-19t-19-45v-448q0-26 19-45t45-19 45 19l144 144 332-332q10-10 23-10t23 10l114 114q10 10 10 23zm781-864v448q0 26-19 45t-45 19-45-19l-144-144-332 332q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l332-332-144-144q-19-19-19-45t19-45 45-19h448q26 0 45 19t19 45z',
                ascent: 1792,
                descent: 0,
              },
              click: () => {
                onFullScreenClick?.();
              },
              toggle: true,
            },
          ],
        ]
      : [['zoomIn2d', 'zoomOut2d', 'zoom2d', 'pan2d']],
  };

  const handleRelayout = useCallback(
    (e: Plotly.PlotRelayoutEvent) => {
      const x1 = get(e, 'xaxis.range[0]', null) || get(e, 'xaxis.range', [])[0];
      const x2 = get(e, 'xaxis.range[1]', null) || get(e, 'xaxis.range', [])[1];

      const selectionX1 = dateStringToTimestamp(x1);
      const selectionX2 = dateStringToTimestamp(x2);

      const minuteInMs = 60 * 1000 * 60;

      if (selectionX1 && numberTypeGuard(selectionX1) && selectionX2 && numberTypeGuard(selectionX2)) {
        const isFromSelectionChanged = Math.abs(from || 0 - (selectionX1 || 0)) > minuteInMs;
        const isToSelectionChanged = Math.abs((selectionX2 || 0) - (to || 0)) > minuteInMs;

        if (isFromSelectionChanged || isToSelectionChanged) {
          handleZoomChange({
            min: selectionX1,
            max: selectionX2,
          });
        }
      }
      const newDragMode = get(e, 'dragmode', null);
      if (newDragMode && newDragMode !== dragMode) {
        setDragMode(newDragMode);
      }
    },
    [dragMode, from, to, handleZoomChange]
  );

  const handleHover = useCallback(
    (event: Plotly.PlotHoverEvent) => {
      const points: ITooltipData[] = event.points.map((point): ITooltipData => {
        const uom = point.data.customdata[0] as TUnitOfMeasure;

        return {
          color: point.data.marker.color?.toString() || '',
          displayedValue: getFormattedValue(point.y?.toString() || '', uom),
          name: point.data.name,
          date: typeof point.x === 'string' ? point.x.toString() : '',
          formattedDate: formatDate(
            createDateString(typeof point.x === 'string' ? new Date(point?.x) : undefined),
            getTimeFormat(rangeFilter)
          ),
        };
      });

      setTooltip({
        top: event.event.clientY,
        left: event.event.clientX,
        tooltipData: points,
      });
      setShowTooltip(true);
    },
    [rangeFilter, setTooltip]
  );

  const handleUnhover = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return {
    layout,
    config,
    handleRelayout,
    isEmpty,
    handleHover,
    handleUnhover,
    tooltip: { ...(tooltip ?? {}), direction: tooltipDirection, showTooltip },
  };
};
