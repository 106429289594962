import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isCollapsed, desktopNavbarLocked }) => ({
  wrapper: {
    position: desktopNavbarLocked ? 'unset' : !isCollapsed ? 'fixed' : 'unset',
    width: desktopNavbarLocked
      ? theme.typography.pxToRem(256)
      : isCollapsed
      ? theme.typography.pxToRem(80)
      : theme.typography.pxToRem(256),
    flexShrink: 0,
    zIndex: !isCollapsed ? 1301 : 'unset', // 1301 - higher than device drawer
    '& .MuiDrawer-paper': {
      width: desktopNavbarLocked
        ? theme.typography.pxToRem(256)
        : isCollapsed
        ? theme.typography.pxToRem(80)
        : theme.typography.pxToRem(256),
      boxSizing: 'border-box',
      transition: 'width 0.3s ease-in-out',
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
  },
  optionsWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.typography.pxToRem(32),
    paddingLeft: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(8),
  },
  bottomWrapper: {
    marginBottom: theme.typography.pxToRem(24),
  },
  logoutButton: {
    display: 'flex',
    height: theme.typography.pxToRem(46),
    alignItems: 'center',
    cursor: 'pointer',
  },
  logoutIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(32),
    marginLeft: theme.typography.pxToRem(18),
  },
  upgradeNexaBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
