export const content = {
  supportLabel: 'Support',
  csmLabel: 'CSR',
  adminLabel: 'Admin',
  viewerLabel: 'Viewer',
  managerLabel: 'Manager',
  deletedLabel: 'Deleted',
  invitationStatusPendingLabel: 'Pending',
  invitationStatusExpiredLabel: 'Expired',
};
