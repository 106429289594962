import { autocompleteRestrictions, getAutocompleteService } from '@marlin/shared/utils/maps-api';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

export const usePlacePrediction = (
  sessionToken: google.maps.places.AutocompleteSessionToken | undefined,
  inputValue: string,
  createSessionToken: () => void,
  value: google.maps.places.AutocompletePrediction | null
) => {
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [addressFallback, setAddressFallback] = useState<boolean>(false);

  const fetchPredictions = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: google.maps.places.AutocompletePrediction[] | null) => void
        ) => {
          if (sessionToken) {
            getAutocompleteService()?.getPlacePredictions(
              {
                ...request,
                componentRestrictions: autocompleteRestrictions,
                sessionToken,
              },
              callback
            );
          }
        },
        500
      ),
    [sessionToken]
  );

  useEffect(() => {
    if (!getAutocompleteService()) {
      return;
    }

    if (inputValue === '') {
      setOptions([]);
      return;
    }

    if (value && value.structured_formatting.main_text === inputValue) {
      setOptions([value]);
      return;
    }

    if (!sessionToken) {
      createSessionToken();
    }

    fetchPredictions({ input: inputValue }, (results?: google.maps.places.AutocompletePrediction[] | null) => {
      setOptions(results || []);
      if (!results || results.length === 0) {
        setAddressFallback(true);
      } else {
        setAddressFallback(false);
      }
    });
  }, [createSessionToken, fetchPredictions, inputValue, sessionToken, value]);

  return { options, addressFallback };
};
