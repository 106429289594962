import * as z from 'zod';

export type TDurationType = 'hours' | 'days' | 'months';

export type TCurrentDuration = {
  from: string;
  to: string;
  isZoomed: boolean;
};

export type TDurationDetails<T extends string> = Record<
  T,
  {
    currentDuration: TCurrentDuration;
    handleZoomChange: (zoom?: { min: number; max: number }) => void;
  }
>;
export enum AVERAGING_FUNCTION_FILTER {
  AVERAGE = 0,
  MIN = 2,
  MAX = 3,
  STANDARD_DEVIATION = 4,
  SUM = 5,
  VALUE_25 = 6,
  VALUE_50 = 7, // median is 50 percentiles
  VALUE_75 = 8,
  VALUE_90 = 9,
  VALUE_95 = 10,
  VALUE_99 = 11,
  RAW = 100,
}
export const AveragingFunctionFilter = z.nativeEnum(AVERAGING_FUNCTION_FILTER);

const bucketOptions = ['minutes', 'hours', 'days', 'weeks', 'months'] as const;
export const BucketOption = z.enum(bucketOptions);
export type TBucketOption = z.infer<typeof BucketOption>;
