import { useDeleteEquipment } from '@marlin/asset/data-access/equipment';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo } from 'react';

import { content } from '../content';
import { ModalFooter } from '../delete-modal.footer';

export interface IDeleteEquipmentModalFooterProps {
  assetId?: string;
  handleAfterDelete?: () => void;
  deviceCount?: number | null;
}

export const DeleteEquipmentModalFooter = ({
  assetId,
  handleAfterDelete,
  deviceCount,
}: IDeleteEquipmentModalFooterProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { modalDispatch } = useContext(ModalContext);
  const router = useRouter();
  const onSuccess = () => {
    enqueueSnackbar(content.DELETE_SUCCESS, { variant: 'success', preventDuplicate: true });
  };
  const onError = () => {
    enqueueSnackbar(content.DELETE_ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const deleteEquipmentMutation = useDeleteEquipment({ onSuccess, onError });

  const canDelete = useMemo(() => {
    return deviceCount === 0;
  }, [deviceCount]);

  const postDeleteAction = useCallback(() => {
    if (handleAfterDelete) {
      handleAfterDelete();
    } else {
      router.goTo(routes.equipments.list.url());
    }
    handleCloseModal();
  }, [handleAfterDelete, handleCloseModal, router]);

  const deleteAssetMethod = useMemo(() => {
    return async () => {
      try {
        await deleteEquipmentMutation.mutateAsync({ equipmentId: assetId || '' });
        postDeleteAction();
      } catch (error) {
        handleCloseModal();
      }
    };
  }, [assetId, deleteEquipmentMutation, handleCloseModal, postDeleteAction]);

  return <ModalFooter canDelete={canDelete} onClose={handleCloseModal} deleteAssetMethod={deleteAssetMethod} />;
};
