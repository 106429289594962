import { EquipmentListUi, useEquipmentHubContextMenu, usePagination } from '@marlin/asset/shared/ui/equipment-list';
import {
  DeleteEquipmentModalBody,
  DeleteEquipmentModalFooter,
  DeleteEquipmentModalTitle,
  DeregisterEquipmentModalBody,
  DeregisterEquipmentModalFooter,
  DeregisterEquipmentModalTitle,
} from '@marlin/asset/ui/delete-equipment-modal';
import { PageContainer } from '@marlin/shared/ui-page';
import { Paper } from '@mui/material';

import { useStyles } from './equipment-hub.component.styles';
import { Filters } from './filtering/filtering.component';
import { EquipmentHubHeader } from './header.component';

export const DesktopEquipmentHub = () => {
  const { classes } = useStyles();
  const { allowDelete, allowEdit } = useEquipmentHubContextMenu();
  const { setPage, setPageSize, paginationModel, onFiltersChange, filterParams } = usePagination();

  return (
    <PageContainer>
      <div className={classes.wrapper}>
        <EquipmentHubHeader />
        <Paper variant="outlined" className={classes.filterWrapper} data-testid="card">
          <Filters onFiltersChange={onFiltersChange} />
          <div className={classes.dataGridWrapper} data-testid="grid-table-wrapper">
            <EquipmentListUi
              payloadDeregister={{
                title: () => <DeregisterEquipmentModalTitle />,
                body: ({ name, id }) => <DeregisterEquipmentModalBody deleteAssetName={name} assetId={id} />,
                footer: ({ id, deviceCount }) => (
                  <DeregisterEquipmentModalFooter deviceCount={deviceCount} assetId={id} />
                ),
              }}
              payloadDelete={{
                title: ({ deviceCount }) => <DeleteEquipmentModalTitle deviceCount={deviceCount} />,
                body: ({ name, id, deviceCount }) => (
                  <DeleteEquipmentModalBody deleteAssetName={name} deviceCount={deviceCount} assetId={id} />
                ),
                footer: ({ id, deviceCount }) => <DeleteEquipmentModalFooter deviceCount={deviceCount} assetId={id} />,
              }}
              filterParams={filterParams}
              allowDelete={allowDelete}
              allowEdit={allowEdit}
              setPage={setPage}
              setPageSize={setPageSize}
              paginationModel={paginationModel}
            />
          </div>
        </Paper>
      </div>
    </PageContainer>
  );
};
