import { datapointToUom } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { useDatapointsContext } from '../../context/datapoints.context';
import type { TEquipmentProductDatapoint } from '../../utils/types';

export const useDatapointTiles = (productDatapoints?: Array<TEquipmentProductDatapoint>) => {
  const { datapoints, isLoading } = useDatapointsContext();
  const datapointTilesData = useMemo(() => {
    return (productDatapoints || [])
      .map((productDatapoint) => {
        const datapointValues = datapoints.find(
          (datapoint) => productDatapoint.name.toLowerCase() === datapoint.name.toLowerCase()
        );
        if (datapointValues) {
          return {
            ...datapointValues,
            ...productDatapoint,
            unitOfMeasure:
              productDatapoint.unitOfMeasure || datapointValues.unitOfMeasure || datapointToUom(datapointValues.name),
          };
        }

        return {
          ...productDatapoint,
          unitOfMeasure: productDatapoint.unitOfMeasure || datapointToUom(productDatapoint.name),
        };
      })
      .filter(Boolean);
  }, [datapoints, productDatapoints]);

  return { datapointTilesData, isLoading };
};
