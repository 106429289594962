import { AVERAGING_FUNCTION_FILTER, TBucketOption } from '@marlin/shared/utils-chart';
import { IRangeFilter, RANGE_FILTER } from '@marlin/shared/utils-chart';
import { createStoreContext, useContext } from '@marlin/shared/utils-component-context';
import moment from 'moment';
import { FC } from 'react';

import { IAddChartParams, TSensorMetadata } from '../types';

interface IMultiChartsStore {
  rangeFilter: IRangeFilter;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
  addChart: IAddChartParams;
  metadata: TSensorMetadata;
  chartIdList: string[];
  bucketOptions: Array<{ chartId: string; bucketOption: TBucketOption }>;
}

const {
  useStore: useMultiChartsStore,
  Provider: MultiChartsProvider,
  StoreContext: MultiChartsStoreContext,
} = createStoreContext<IMultiChartsStore>({
  rangeFilter: {
    range: RANGE_FILTER.DAYS_7,
    currentDate: moment().utc(),
  },
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER.AVERAGE,
  metadata: {},
  addChart: {
    chartType: '',
    chartName: '',
    selectedDatapoints: [],
  },
  chartIdList: [],
  bucketOptions: [],
});

export function useSetMultiChartsStore() {
  const context = useContext(MultiChartsStoreContext);

  return context.set;
}

export function withMultiChartsContext(Component: FC) {
  return () => (
    <MultiChartsProvider>
      <Component />
    </MultiChartsProvider>
  );
}

export { useMultiChartsStore };
