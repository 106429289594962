import { useBrushChartRangeTelemetry } from '@marlin/asset/data-access/telemetry';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { useTheme } from '@mui/material';
import get from 'lodash/get';
import { Data } from 'plotly.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import Plot from 'react-plotly.js';

import { IChartPoint } from '../../types';
import { useEnabledAutomationRulesHistory } from './automations/use-automation.hook';
import { createAlertSeries, createAnnotaionSeries } from './chart-utils';
import { useExtendedChartContext } from './context/extended-chart.context';
import { ChartTooltip } from './hooks/chart-tooltip/chart-tooltip.component';
import { useTooltip } from './hooks/chart-tooltip/use-tooltip.hook';
import { useGroupedAlerts } from './hooks/group-alerts/use-grouped-alerts.hook';
import { CHART_SELECTION_TYPE } from './hooks/use-chart-selection.hook';
import { useMainChart } from './hooks/use-main-chart.hook';

export const getAlertId = (seriesName: string) => seriesName.split('alert-')[1];
const numberTypeGuard = (value: unknown): value is number => typeof value === 'number';

export const Chart = (): JSX.Element => {
  const [dragMode, setDragMode] = useState<
    'zoom' | 'pan' | 'select' | 'lasso' | 'orbit' | 'turntable' | false | undefined
  >(undefined);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    switch (event.key) {
      case 'z': {
        setDragMode('zoom');

        break;
      }
      case 'p': {
        setDragMode('pan');

        break;
      }
      case 's': {
        setDragMode('select');

        break;
      }
      // No default
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const {
    deviceName,
    deviceType,
    manufacturerId,
    automations,
    automationsSettings,
    selection,
    changeChartSelection,
    brushSelection,
    uoM,
  } = useExtendedChartContext();

  const theme: MarlinTheme = useTheme();

  const { data, alertData, isLoading, ticks, isFetching } = useMainChart(selection, deviceType, manufacturerId);

  const { groupedAlertData, groupedAlerts } = useGroupedAlerts(alertData, data, ticks);

  const { automationRulesSeries, automationRulesSeriesAnnotation } = useEnabledAutomationRulesHistory(
    automationsSettings,
    selection,
    ticks,
    automations,
    uoM
  );

  const filterAlerts = useCallback(
    (alert: { ruleId: string }) =>
      automationsSettings.alerts ? automationsSettings.automations?.includes(alert.ruleId) : false,
    [automationsSettings.alerts, automationsSettings.automations]
  );

  const validAlertData = useMemo(() => groupedAlertData.filter(filterAlerts), [filterAlerts, groupedAlertData]);

  const brushRangeFilter = useMemo(
    () => ({
      from: dateAdapter.date(brushSelection?.from)?.utc().toISOString() ?? '',
      to: dateAdapter.date(brushSelection?.to)?.utc().toISOString() ?? '',
    }),
    [brushSelection]
  );

  const brushChartQuery = useBrushChartRangeTelemetry({
    ...brushRangeFilter,
    manufacturerId: manufacturerId || '',
    enabled: !!manufacturerId && !!manufacturerId.length,
    keepPreviousData: true,
  });

  const brushData: IChartPoint[] = useMemo(() => {
    if ((!brushChartQuery.data || !brushChartQuery.data.chartData) && !data) {
      return [];
    }

    if (data && (!brushChartQuery.data || !brushChartQuery.data.chartData)) {
      return data;
    }

    const brushData =
      brushChartQuery.data?.chartData?.map((telemetryData) => {
        return {
          timestamp: dateAdapter.date(telemetryData.eventDateTime)?.toDate().getTime() ?? 0,
          value: parseDeviceReadings(telemetryData.value, deviceType).value,
        };
      }) || [];

    const filteredData = brushData.filter((d) => d.timestamp >= selection.from && d.timestamp <= selection.to);

    if (!data) {
      return brushData;
    }

    return [...data, ...filteredData].sort((a, b) => a.timestamp - b.timestamp);
  }, [brushChartQuery.data, data, deviceType, selection.from, selection.to]);

  const { triggers, pointAnnotations, automationSeriesShapes } = useMemo(
    () => createAnnotaionSeries(automationRulesSeriesAnnotation, automationRulesSeries, selection.from, selection.to),
    [automationRulesSeriesAnnotation, automationRulesSeries, selection.from, selection.to]
  );
  const { alertShapes, alertTraces } = useMemo(() => createAlertSeries(validAlertData), [validAlertData]);

  const maxBrushChartValue = useMemo(
    () =>
      dateAdapter
        ?.date(
          Math.max(
            dateAdapter?.date(brushRangeFilter.to)?.valueOf() || 0,
            dateAdapter?.date(selection.to)?.valueOf() || 0
          )
        )
        ?.toISOString(true),
    [brushRangeFilter.to, selection.to]
  );

  const chartData: Partial<Data>[] = useMemo(() => {
    return [
      {
        type: 'scatter',
        mode: 'lines',
        name: 'data',
        x: brushData.map((d) => d.timestamp),

        y: brushData.map((d) => d.value).filter((v): v is number => typeof v === 'number'),
        hoverinfo: 'none',
      },
      ...automationSeriesShapes,
      ...alertTraces,
    ];
  }, [alertTraces, automationSeriesShapes, brushData]);

  const layoutOptions: Partial<Plotly.Layout> = useMemo(
    () => ({
      barmode: 'stack',
      hovermode: 'x unified',
      showlegend: false,
      margin: { t: 80, b: 80, l: 80, r: 80, pad: 20 },
      dragmode: dragMode,
      xaxis: {
        range: [selection.from, selection.to],
        // rangeslider: {
        //   range: [brushRangeFilter.from, brushRangeFilter.to],
        // },
      },
      yaxis: { fixedrange: true, side: 'right', range: [ticks.lowest, ticks.highest] },
      shapes: [...alertShapes, ...triggers],
      annotations: [...pointAnnotations],
    }),
    [alertShapes, pointAnnotations, selection.from, selection.to, ticks.highest, ticks.lowest, triggers, dragMode]
  );

  const separatePlotLayoutOptions: Partial<Plotly.Layout> = useMemo(
    () => ({
      modebar: {
        remove: [
          'toImage',
          'sendDataToCloud',
          'autoScale2d',
          'hoverClosestCartesian',
          'hoverCompareCartesian',
          'zoom2d',
          'lasso2d',
        ],
      },
      dragmode: 'select',
      barmode: 'stack',
      height: 230,
      margin: { t: 50, b: 50, l: 50, r: 50 },
      xaxis: {
        range: [brushRangeFilter.from, maxBrushChartValue],
        maxallowed: maxBrushChartValue,
      },
      selections: [
        {
          xref: 'x',
          yref: 'y',
          x0: dateAdapter?.date(selection.from)?.toISOString(true),
          x1: dateAdapter?.date(selection.to)?.toISOString(true),
          y0: 0,
          y1: ticks.highest,
          type: 'rect',
        },
      ],
      yaxis: { fixedrange: true, side: 'right', range: [0, ticks.highest] },
    }),
    [brushRangeFilter.from, maxBrushChartValue, selection.from, selection.to, ticks.highest]
  );

  const handleRelayout = useCallback(
    (e: Plotly.PlotRelayoutEvent) => {
      const x1 = get(e, 'xaxis.range[0]', null) || get(e, 'xaxis.range', [])[0];
      const x2 = get(e, 'xaxis.range[1]', null) || get(e, 'xaxis.range', [])[1];

      const from = x1;
      const to = x2;

      const minuteInMs = 60 * 1000 * 60;

      if (numberTypeGuard(from) && numberTypeGuard(to)) {
        const isFromSelectionChanged = Math.abs(selection.from - (from || 0)) > minuteInMs;
        const isToSelectionChanged = Math.abs((to || 0) - selection.to) > minuteInMs;

        if (isFromSelectionChanged || isToSelectionChanged) {
          changeChartSelection({
            type: CHART_SELECTION_TYPE.MAIN_CHART,
            selection: {
              from,
              to,
            },
          });
        }
      }

      const newDragMode = get(e, 'dragmode', null);
      if (newDragMode && newDragMode !== dragMode) {
        setDragMode(newDragMode);
      }
    },
    [changeChartSelection, dragMode, selection.from, selection.to]
  );

  const handleBrushSelection = useCallback(
    (e: Plotly.PlotSelectionEvent) => {
      const x1 = get(e, 'range.x[0]', null);
      const x2 = get(e, 'range.x[1]', null);

      const from = x1 ? dateAdapter?.date(x1)?.valueOf() : null;
      const to = x2 ? dateAdapter?.date(x2)?.valueOf() : null;

      const minuteInMs = 60;

      if (numberTypeGuard(from) && numberTypeGuard(to)) {
        const isFromSelectionChanged = Math.abs(selection.from - (from || 0)) > minuteInMs;
        const isToSelectionChanged = Math.abs((to || 0) - selection.to) > minuteInMs;

        if (isFromSelectionChanged || isToSelectionChanged) {
          changeChartSelection({
            type: CHART_SELECTION_TYPE.BRUSH_CHART,
            selection: {
              from,
              to,
            },
          });
        }
      }
    },
    [changeChartSelection, selection.from, selection.to]
  );

  const { openTooltip, closeTooltip, tooltipOpen, tooltipData, anchorEl } = useTooltip({ deviceType, groupedAlerts });

  const brushPlotData: Partial<Data>[] = useMemo(() => {
    const brush = brushChartQuery?.data?.chartData || [];

    return [
      {
        type: 'bar',
        mode: 'lines',
        name: 'data',
        x: brush.map((d) => d.eventDateTime),
        y: brush.map((d) => d.value).filter((v): v is number => typeof v === 'number'),
      },
    ];
  }, [brushChartQuery.data]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {isFetching && (
        <div style={{ display: 'flex', alignItems: 'center', gap: 16, marginTop: 16 }}>
          <LoadingSpinner /> Please wait, chart data is fetching...
        </div>
      )}
      <Plot
        data={chartData}
        layout={layoutOptions}
        config={{ displaylogo: false }}
        onRelayout={handleRelayout}
        onHover={openTooltip}
        onUnhover={closeTooltip}
      />
      <Plot
        data={brushPlotData}
        layout={separatePlotLayoutOptions}
        config={{ displaylogo: false }}
        onSelected={handleBrushSelection}
      />

      <ChartTooltip
        open={tooltipOpen}
        anchorEl={anchorEl}
        dataSeries={tooltipData?.dataSeries || null}
        alertSeries={tooltipData?.alertSeries || []}
        deviceName={deviceName || ''}
        theme={theme}
      />
    </>
  );
};
