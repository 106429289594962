import { ApexOptions } from 'apexcharts';

import { IChartSeries } from '../chart-data-types';

interface IDataSeries {
  x: string;
  y: (number | null)[] | [];
}
export const getTimeLineChartData = ({
  chartData,
  to,
  possibleDatapoints,
}: {
  chartData: IChartSeries[];
  to?: number;
  possibleDatapoints: string[];
}): ApexOptions['series'] => {
  const dataSeries: IDataSeries[] = [];

  possibleDatapoints.forEach((datapoint) => {
    const item = chartData.find((item) => item.name === datapoint);

    if (!item) {
      return;
    }

    const periods = [];
    let startTime = null;

    for (const { x, y } of item.data) {
      if (y && startTime === null) {
        startTime = x;
      } else if (!y && startTime !== null) {
        periods.push({
          x: item.name,
          y: [startTime, x],
          fillColor: item.color,
        });
        startTime = null;
      }
    }

    if (startTime !== null) {
      periods.push({
        x: item.name,
        y: [startTime, to ?? null],
        fillColor: item.color,
      });
    }

    if (!periods.length) {
      periods.push({
        x: item.name,
        y: [],
        fillColor: item.color,
      });
    }

    dataSeries.push(...periods);
  });

  return [{ data: dataSeries }];
};
