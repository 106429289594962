import { MarlinTheme } from '@marlin/shared/theme';
import { ChartTooltip } from '@marlin/shared/ui/chart';
import { IChartData, IChartSeries, RANGE_FILTER, findMinMax, renderTooltip } from '@marlin/shared/utils-chart';
import { getUserTimezoneAbbreviation } from '@marlin/shared/utils-common-date';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { renderToString } from 'react-dom/server';

import { content } from './content';

interface IChartOptionsParams {
  chartData: Pick<IChartSeries, 'data' | 'uom' | 'name'>[];
  from: number;
  to: number;
  timeRange: RANGE_FILTER;
}

export const useDashboardChartOptions = ({ chartData, from, to, timeRange }: IChartOptionsParams) => {
  const theme = useTheme<MarlinTheme>();
  const mergedData = useMemo(
    () => chartData.reduce<IChartData[]>((acc, datapoint) => [...acc, ...datapoint.data], []),
    [chartData]
  );

  const { max, min } = useMemo(() => findMinMax(mergedData), [mergedData]);

  const xAxisOptions: ApexCharts.ApexOptions['xaxis'] = useMemo(
    () => ({
      title: {
        style: {
          fontSize: `${theme.typography.fontSize}px`,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.secondary,
        },
        text: content.TIMEZONE_VALUE(getUserTimezoneAbbreviation()),
      },
      type: 'datetime',
      min: from,
      max: to,
      labels: {
        datetimeUTC: false,
      },
    }),
    [theme, from, to]
  );

  const yAxisOptions: ApexCharts.ApexOptions['yaxis'] = useMemo(
    () =>
      chartData.map((item, index) => ({
        labels: {
          formatter: (value) => getFormattedValue(String(value), item.uom),
        },
        min,
        max,
        forceNiceScale: true,
        opposite: true,
        show: index === 0,
      })),
    [chartData, max, min]
  );

  const chartOptions: ApexCharts.ApexOptions = useMemo(
    () => ({
      stroke: {
        width: [2, 1.5, 1.5],
      },
      colors: [
        theme.palette.charting.breezyBlue,
        theme.palette.charting.freshGreen,
        theme.palette.charting.flushedPink,
      ],
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        id: 'basic-chart',
      },
      xaxis: {
        ...xAxisOptions,
        tooltip: { enabled: false },
      },
      yaxis: yAxisOptions,
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return renderToString(
            renderTooltip({
              series,
              seriesIndex,
              dataPointIndex,
              w,
              chartData: chartData.map((item) => ({ name: item.name, uom: item.uom })),
              timeRange,
              TooltipComponent: ChartTooltip,
            })
          );
        },
        enabled: true,
        shared: true,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: -20,
        offsetY: -20,
        fontFamily: theme.typography.fontFamily,
        fontSize: `${theme.typography.fontSize}px`,
        itemMargin: { horizontal: 4, vertical: 0 },
      },
      title: {
        // without text in title, the legend is not visible
        text: 'Mixed Outlet Temp',
        align: 'left',
        position: 'top',
        style: {
          color: 'transparent',
        },
      },
      // TODO: add no data message in future
    }),
    [theme, xAxisOptions, yAxisOptions, chartData, timeRange]
  );

  return chartOptions;
};
