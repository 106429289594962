import { ElkNode, LayoutOptions } from 'elkjs/lib/elk.bundled';
import { Node } from 'reactflow';

import { arrayToTreeByKey } from './utils/array-to-tree-by-key';
import { treeToFlatArray } from './utils/tree-to-flat-array';

export const elkAdapter = {
  nodesFromReactFlow: (Nodes: Node[], layoutOptions?: LayoutOptions): ElkNode[] =>
    arrayToTreeByKey(Nodes, 'parentNode', layoutOptions),
  nodesToReactFlow: (Nodes: ElkNode[]): Node[] =>
    treeToFlatArray(Nodes)
      .map(({ x, y, ...node }) => ({
        ...(node as Node),
        position: { x: x || 0, y: y || 0 },
      }))
      .map(({ width, height, ...node }) => ({
        ...node,
        ...(node.data?.assetType === 'LOCATION' || node.data?.assetType === 'EQUIPMENT' ? {} : { width, height }),
      })),
};
