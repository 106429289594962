import { DatapointDisplayValue } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { useDatapointsContext } from '../../../../context/datapoints.context';
import { useEquipmentAlertsContext } from '../../../../context/equipment-alerts.context';

type TDatapointBoxProps = {
  label: string;
  name: string;
};

type TStyles = {
  emptyValue: boolean;
};

const useStyles = makeStyles<TStyles>()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(8),
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const DatapointInfo = ({ label, name }: TDatapointBoxProps) => {
  const { getDatapoint } = useDatapointsContext();
  const { getAlertsForDatapoint } = useEquipmentAlertsContext();
  const datapoint = getDatapoint(name);
  const datapointAlerts = getAlertsForDatapoint(name);
  const { classes } = useStyles({ emptyValue: !datapoint?.value });
  const controlState = datapointAlerts?.length ? 'error' : datapoint?.controlState;

  return (
    <div className={classes.container} data-testid={label}>
      <DatapointDisplayValue
        value={datapoint?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
        controlState={controlState}
        alerts={datapointAlerts}
      />
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
};

export default DatapointInfo;
