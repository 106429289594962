import { useRecipientById, useUpdateRecipient } from '@marlin/account-data-access-recipient';
import { IUpsertRecipientProfile, RecipientUpsertForm } from '@marlin/account-ui-recipient-upsert-form';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Paper } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ICreateRecipientErrorProps, errorHandler } from './error-handler';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
  },
}));

export const RecipientEditForm = () => {
  const { classes } = useStyles();
  const { goTo } = useRouter();
  const [errorMessage, setErrorMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { recipientId } = useParams();
  const recipientQuery = useRecipientById(recipientId ?? '');
  const { mutateAsync: updateRecipient } = useUpdateRecipient({ recipientId: recipientId ?? '' });

  const recipient = useMemo(() => recipientQuery?.data, [recipientQuery?.data]);

  const handleSubmit = (editedRecipient: IUpsertRecipientProfile) => {
    return updateRecipient({
      id: recipientId ?? '',
      data: {
        firstName: editedRecipient.firstName,
        lastName: editedRecipient.lastName,
        phoneNumber: editedRecipient.phone,
        email: editedRecipient.email,
      },
    })
      .then((res) => {
        enqueueSnackbar(content.SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });
        goTo(routes.settings.people.url());
        return res;
      })
      .catch((error: AxiosError<ICreateRecipientErrorProps>) => {
        errorHandler({ error, onError });
      });
  };

  const onError = (message: string) => {
    setErrorMessage(message);
  };

  const resetError = () => {
    setErrorMessage('');
  };

  const handleCancel = () => {
    goTo(routes.settings.people.url());
  };

  if (!recipient) {
    return null;
  }

  return (
    <Paper className={classes.formContainer} data-testid="card">
      <RecipientUpsertForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        resetError={resetError}
        errorMessage={errorMessage}
        recipient={recipient}
        editMode={true}
      />
    </Paper>
  );
};
