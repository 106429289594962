import { useStyles } from './context-menu.styles';

export interface IOption {
  label: string;
  onClick: (id: string) => void;
}

interface IMenuProps {
  menu: {
    id: string;
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    options: IOption[];
  };
}

export const ContextMenu = ({ menu: { id, top, left, bottom, right, options } }: IMenuProps) => {
  const { classes } = useStyles();
  return (
    <div data-testid={`context-menu`} className={classes.contextMenu} style={{ top, left, right, bottom }}>
      {options.map((option) => {
        return (
          <div
            data-testid={`context-menu-${option.label.split(' ').join('-').toLowerCase()}`}
            key={option.label}
            className={classes.contextMenuItem}
            onClick={() => option.onClick(id)}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};
