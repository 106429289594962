import { MarlinTheme } from '@marlin/shared/theme';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import { TLastReadingValue, TMetadataGroup } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { useCommonStyles } from '../../common.styles';
import { useDetailedNodeSensorsFilter } from '../hooks/use-detailed-node-sensors-filter.hook';
import { useRegisteredEquipmentContext } from '../hooks/use-registered-equipment-context';
import { TDirection, TStatus } from '../types';
import { getBackgroundByEquipmentStatus, getBorderColorByEquipmentStatus } from '../utils/utils';
import { Sensor } from './sensor.component';

export interface IMetadataGroupProps {
  metadataGroup: TMetadataGroup;
  direction: TDirection;
  lastReadingValues: TLastReadingValue[];
  nodeId: string;
  isDetailed?: boolean;
}

export interface IStylesProps {
  direction: TDirection;
  status: TStatus;
}

export const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { direction, status }) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: theme.typography.pxToRem(4),
    justifyContent: direction === 'left' ? 'flex-start' : direction === 'right' ? 'flex-end' : 'center',
    alignItems: 'center',
    position: 'relative',
    outline: status === 'success' ? 'none' : `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    backgroundColor:
      status === 'success' ? theme.palette.systemMap.equipment : getBackgroundByEquipmentStatus(status, theme),
  },
  sensors: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    alignItems: direction === 'left' ? 'flex-start' : direction === 'right' ? 'flex-end' : 'center',
    width: '100%',
  },
  handles: {
    position: 'absolute',
    left: direction === 'left' ? 0 : '100%',
    top: '50%',
  },
  handleLabels: {
    position: 'absolute',
    left: direction === 'left' ? '-150%' : '100%',
    top: `calc(50% - ${theme.typography.pxToRem(24)})`,
    width: '150%',
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(12)}`,
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: direction === 'left' ? 'right' : 'left',
    whiteSpace: 'nowrap',
    color: theme.palette.text.disabled,
  },
  connected: {
    color: theme.palette.text.primary,
  },
}));

export const MetadataGroup = ({
  nodeId,
  metadataGroup,
  direction,
  lastReadingValues,
  isDetailed = false,
}: IMetadataGroupProps) => {
  const { getStatusByDisplayDataPointNames } = useRegisteredEquipmentContext();
  const { sensors } = metadataGroup;
  const filterSensors = useDetailedNodeSensorsFilter({ isDetailed });
  const filteredSensors = useMemo(() => filterSensors(sensors), [filterSensors, sensors]);

  const { getEdges } = useReactFlow();
  const edge = getEdges().find(
    (edge) =>
      (edge.source === nodeId && edge.sourceHandle === metadataGroup.handleId) ||
      (edge.target === nodeId && edge.targetHandle === metadataGroup.handleId)
  );

  const status = useMemo(
    () => getStatusByDisplayDataPointNames(sensors.map((sensor) => sensor.displayDataPointName || '')),
    [getStatusByDisplayDataPointNames, sensors]
  );
  const { classes, cx } = useStyles({ direction, status });
  const { classes: commonClasses } = useCommonStyles();

  // Note: we need to override default positioning of the handles
  const handleStyles = {
    position: 'absolute' as const,
    left: '50%',
    top: '50%',
  };

  if (filteredSensors.length === 0) {
    return null;
  }

  return (
    <div
      className={classes.container}
      data-testid={createDataTestId('metadataGroup', metadataGroup.handleName, status)}
    >
      <div
        className={classes.sensors}
        data-testid={createDataTestId('metadataGroup-sensors', metadataGroup.handleName)}
      >
        {filteredSensors.map((sensor) => {
          return (
            <Sensor
              sensor={sensor}
              lastReadingValues={lastReadingValues}
              direction={direction}
              isDetailed={isDetailed}
              key={sensor.displayDataPointName}
            />
          );
        })}
      </div>
      <div className={classes.handles}>
        <Handle
          type="target"
          id={metadataGroup.handleId}
          position={direction === 'left' ? Position.Left : Position.Right}
          className={commonClasses.handle}
          style={handleStyles}
        />
        <Handle
          type="source"
          id={metadataGroup.handleId}
          position={direction === 'left' ? Position.Left : Position.Right}
          className={commonClasses.handle}
          style={handleStyles}
        />
      </div>
      {!isDetailed && (
        <div className={classes.handleLabels}>
          <div
            className={cx(classes.label, { [classes.connected]: !!edge })}
            data-testid={createDataTestId('metadataGroup-handleName', metadataGroup.handleName)}
          >
            {metadataGroup.handleName}
          </div>
        </div>
      )}
    </div>
  );
};
