import { IAlertNotification } from '@marlin/alert/data-access/alert-action';
import { useAlertSubscription } from '@marlin/alert/data-access/alert-action';
import { CRITICALITY } from '@marlin/shared/ui-criticality';
import { useSnackbar } from 'notistack';

export const useAlertSnackbarSubscription = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onAlert = (alert: IAlertNotification | undefined) => {
    if (alert && alert.criticality === CRITICALITY.HIGH) {
      enqueueSnackbar('', {
        variant: 'alertSnackbar',
        key: `alertSnackbar-${alert.deviceId}-${alert.ruleId}`,
        alert,
      });
    }
  };

  return useAlertSubscription({ onAlert });
};
