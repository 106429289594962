import { useQuery } from '@marlin/shared/utils/react-query';

import { getProfile } from '../infrastructure/get-profile';
import { queryKey } from './query-key.enum';

export const useGetProfile = () => {
  return useQuery({
    queryKey: queryKey.PROFILE(),
    queryFn: getProfile,
  });
};
