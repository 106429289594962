import { useOnboard } from '@marlin/account-data-access-onboarding';
import { useUpdateOrg } from '@marlin/account-data-access-organization';
import { getLogger } from '@marlin/shared/utils/logger';
import { useCallback } from 'react';

import { StepHeader } from '../../../components/step-header';
import { content } from '../../../content';
import { useOnboardingState } from '../../use-onboarding-state.hook';
import { ONBOARDING_STEP } from '../steps';
import { EquipmentSwitcher } from './equipment-switcher.component';
import { RegisterEquipmentButtons } from './register-equipment-buttons.component';
import { useStyles } from './regsiter-equipment.styles';
import { StepFooter } from './step-footer.component';

interface IError {
  status: number;
  errorCode?: number;
}

const getErrorStep = (error: IError): ONBOARDING_STEP => {
  if (error.status === 500) {
    return ONBOARDING_STEP.REGISTRATION_FAILED_ERROR;
  }
  if (error.status === 422) {
    switch (error.errorCode) {
      case 11:
      case 401:
      case 403:
      case 421:
      case 422:
        return ONBOARDING_STEP.REGISTRATION_DATA_ERROR;
      case 100:
        return ONBOARDING_STEP.ORGANIZATION_CREATION_ERROR;
      case 405:
      case 402:
        return ONBOARDING_STEP.ALREADY_REGISTERED_ERROR;
      default:
        return ONBOARDING_STEP.REGISTRATION_FAILED_ERROR;
    }
  }
  if (error.status === 400) {
    return ONBOARDING_STEP.REGISTRATION_DATA_ERROR;
  } else if (error.errorCode === 100) {
    return ONBOARDING_STEP.ORGANIZATION_CREATION_ERROR;
  }
  return ONBOARDING_STEP.REGISTRATION_FAILED_ERROR;
};

interface IRequestEquipmentProps {
  onNextStep: (step: ONBOARDING_STEP, completed?: ONBOARDING_STEP[]) => void;
}

export const RegisterEquipment = ({ onNextStep }: IRequestEquipmentProps) => {
  const { classes } = useStyles();
  const { onboardingState } = useOnboardingState();
  const { mutateAsync: onboard, isLoading } = useOnboard();
  const { mutateAsync: updateOrg } = useUpdateOrg({ shouldRefetch: false });

  const onSubmit = useCallback(() => {
    onboard({
      organizationName: onboardingState.propertyName,
      organizationId: onboardingState.organizationId,
      address: onboardingState.propertyAddress.address,
      equipment: onboardingState.equipment,
    })
      .then(async ({ organizationId }) => {
        await updateOrg(organizationId);

        getLogger()?.track(content.LOGGER.SELF_SERVICE.REGISTER_EQUIPMENT_SUCCESS, {
          organizationId,
          organizationName: onboardingState.propertyName,
          address: onboardingState.propertyAddress.address,
          equipment: onboardingState.equipment,
        });
        return onNextStep(ONBOARDING_STEP.SETUP_COMPLETE, [
          ONBOARDING_STEP.SETUP_ORGANIZATION,
          ONBOARDING_STEP.REGISTER_EQUIPMENT,
        ]);
      })
      .catch((reason) => {
        const errorStep = getErrorStep(reason.response.data);
        onNextStep(errorStep, [ONBOARDING_STEP.SETUP_ORGANIZATION, ONBOARDING_STEP.REGISTER_EQUIPMENT]);
      });
  }, [
    onboard,
    onboardingState.propertyName,
    onboardingState.propertyAddress.address,
    onboardingState.equipment,
    onboardingState.organizationId,
    updateOrg,
    onNextStep,
  ]);

  const handleCancel = () => {
    onNextStep(ONBOARDING_STEP.SETUP_ORGANIZATION, []);
  };

  return (
    <div className={classes.container}>
      <StepHeader title={content.REGISTER_EQUIPMENT_TITLE} subtitle={content.REGISTER_EQUIPMENT_SUBTITLE} />
      <EquipmentSwitcher />
      <RegisterEquipmentButtons isLoading={isLoading} onNext={onSubmit} onCancel={handleCancel} />
      <StepFooter />
    </div>
  );
};
