import { TChartId, chartIdList } from '@marlin/asset/shared/equipment-config';
import { TChartDatapointsGroup } from '@marlin/shared/utils-chart';
import { TDeviceMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useCallback, useMemo, useState } from 'react';

export const useActiveChartingDatapoints = (chartDatapointsMetadata: TDeviceMetadataResponse['chartDatapoints']) => {
  const datapointGroups = useMemo(
    () =>
      chartDatapointsMetadata.reduce((acc, group) => {
        const normalizedGroupName = group.groupName.toLowerCase();
        if (isChartId(normalizedGroupName)) {
          acc[normalizedGroupName] = group;
        }
        return acc;
      }, {} as Record<TChartId, TChartDatapointsGroup>),
    [chartDatapointsMetadata]
  );

  const [activeDatapoints, setActiveDatapoints] = useState<Record<TChartId, string[]>>(getEmptyDatapoints());
  const [activeThresholds, setActiveThresholds] = useState<Record<TChartId, string[]>>(getEmptyDatapoints());

  const defaultDatapointNames = getDefaultNames(datapointGroups, 'datapoint');
  const defaultThresholdNames = getDefaultNames(datapointGroups, 'threshold');

  const toggleDatapointVisibility = useCallback((chartId: TChartId, name: string) => {
    setActiveDatapoints((prev) => {
      return {
        ...prev,
        [chartId]: toggleVisibility(prev[chartId], name),
      };
    });
  }, []);

  const toggleThresholdVisibility = useCallback((chartId: TChartId, name: string) => {
    setActiveThresholds((prev) => {
      return {
        ...prev,
        [chartId]: toggleVisibility(prev[chartId], name),
      };
    });
  }, []);

  const setDefault = useCallback(() => {
    setActiveDatapoints(defaultDatapointNames);
    setActiveThresholds(defaultThresholdNames);
  }, [defaultDatapointNames, defaultThresholdNames]);

  const clearAll = useCallback(() => {
    setActiveDatapoints(getEmptyDatapoints());
    setActiveThresholds(getEmptyDatapoints());
  }, []);

  const clearGroup = useCallback((chartId: TChartId) => {
    setActiveDatapoints((prev) => {
      return {
        ...prev,
        [chartId]: [],
      };
    });
    setActiveThresholds((prev) => {
      return {
        ...prev,
        [chartId]: [],
      };
    });
  }, []);

  return {
    datapointGroups,
    activeDatapoints,
    activeThresholds,
    toggleDatapointVisibility,
    toggleThresholdVisibility,
    setDefault,
    clearAll,
    clearGroup,
  };
};

const getDefaultNames = (
  datapointGroups: Record<TChartId, TChartDatapointsGroup>,
  displayType: 'datapoint' | 'threshold'
) => {
  return Object.entries(datapointGroups).reduce((acc, [chartId, group]) => {
    const normalizedChartId = chartId.toLowerCase();

    if (isChartId(normalizedChartId)) {
      acc[normalizedChartId] = group.datapoints
        .filter((datapoint) => datapoint.isDefault && datapoint.displayType === displayType)
        .map((datapoint) => datapoint.name);
    }

    return acc;
  }, getEmptyDatapoints());
};

const getEmptyDatapoints = (): Record<TChartId, string[]> => ({
  flow: [],
  pressure: [],
  temperature: [],
  pumpvalve: [],
  other: [],
  flamestrength: [],
  pumpstatus: [],
});

const toggleVisibility = (prev: string[], name: string) => {
  if (prev.includes(name)) {
    return prev.filter((chip) => chip !== name);
  }

  return [...prev, name];
};

const isChartId = (chartId: string): chartId is TChartId => {
  const chartIdListLower = chartIdList.map((id) => id.toLowerCase());

  return (chartIdListLower as unknown as string[]).includes(chartId.toLowerCase());
};
