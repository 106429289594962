import { getLogger } from '@marlin/shared/utils/logger';
import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { TOrganizationResponseSchema } from '../infrastructure/organization.schema';
import { IUser } from '../user.model';
import { updateOrgId } from '../utils/organization.utils';
import { queryKey } from './query-key.enum';
import { getTierName } from './use-get-cached-tiers';

const snackbarDelay = 1500;

interface IUpdateOrgProps {
  shouldRefetch: boolean;
  onSuccess?: () => void;
}

export const useUpdateOrg = ({ shouldRefetch = true, onSuccess }: IUpdateOrgProps) => {
  return useMutation({
    mutationFn: async (orgId: string) => await updateOrgId(orgId), // updateOrgId is sync
    onSuccess: (currentUserData: IUser | null) => {
      const userData: TOrganizationResponseSchema | undefined = queryClient.getQueryData(queryKey.CURRENT_USER());
      getTierName(userData?.currentOrganization?.features?.tierId).then((tierName) => {
        getLogger()?.registerProperties({
          organizationId: currentUserData?.organizationId,
          organizationName: currentOrgData?.organizationName,
          tier: tierName,
          userRole: userData?.currentOrganization?.roles,
        });
      });

      const currentOrgData = userData?.organizations.find(
        (org) => org.organizationId === currentUserData?.organizationId
      );
      getLogger()?.track('Organization change');
      if (userData && currentOrgData) {
        queryClient.setQueryData(queryKey.CURRENT_USER(), {
          ...userData,
          currentOrganization: currentOrgData,
        });
      }

      if (shouldRefetch) {
        // clear cache and refetch all active queries after organization change
        queryClient.resetQueries();
        queryClient.clear();
      }
      if (onSuccess) {
        setTimeout(() => {
          onSuccess();
        }, snackbarDelay);
      }
    },
  });
};
