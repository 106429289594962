import { MarlinTheme } from '@marlin/shared/theme';
import { IRadioButton, RadioButtons } from '@marlin/shared/ui-form-common';
import { CustomSizedModal } from '@marlin/shared/ui-modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    columnGap: theme.typography.pxToRem(8),
  },
  modalBody: {
    display: 'block',
  },
  content: {
    width: '100%',
  },
  radioButtons: {
    marginTop: theme.typography.pxToRem(16),
  },
}));

const radioButtons: IRadioButton[] = [{ label: content.EXPORT_CSV_MODAL.SUMMARIZED_DATA, value: 'summarized' }];

export const ExportCsvModal = ({ submitExport, onClose }: { submitExport: () => void; onClose: () => void }) => {
  const { classes } = useStyles();

  const [selectedValue, setSelectedValue] = useState<string>('summarized');

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  }, []);

  const onExportClick = useCallback(() => {
    submitExport();
    onClose();
  }, [onClose, submitExport]);

  return (
    <CustomSizedModal
      width={500}
      onClose={onClose}
      title={content.EXPORT_CSV_MODAL.TITLE}
      body={
        <div className={classes.modalBody}>
          <Typography variant="body1">{content.EXPORT_CSV_MODAL.BODY}</Typography>
          <RadioButtons
            buttons={radioButtons}
            className={classes.radioButtons}
            value={selectedValue}
            onChange={onChange}
          />
        </div>
      }
      footer={
        <div className={classes.modalFooter}>
          <Button variant="text" data-testid="export-csv-modal-cancel-button" onClick={onClose}>
            {content.EXPORT_CSV_MODAL.CANCEL}
          </Button>
          <Button variant="contained" data-testid="export-csv-modal-export-button" onClick={onExportClick}>
            {content.EXPORT_CSV}
          </Button>
        </div>
      }
    />
  );
};
