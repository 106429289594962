import { MarlinTheme } from '@marlin/shared/theme';
import { IApexAxisChartThresholdSeries } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

import { IChartMetadata, IEquipmentChartThreshold } from '../types';
import { createRange } from './create-threshold-range';

export const mapThresholdsToApexData = (
  convertedThresholds: IEquipmentChartThreshold[],
  thresholdsMetadata: IChartMetadata['thresholdsMetadata'],
  max: number,
  min: number,
  theme: MarlinTheme
) => {
  if (!convertedThresholds) return [];

  const thresholdsGrouped: IApexAxisChartThresholdSeries[] = [];

  convertedThresholds.forEach((item) => {
    const thresholdWithMetadata = thresholdsMetadata.find(
      (metadataItem) => metadataItem.name.toLowerCase() === item.name.toLowerCase()
    );
    const name: string = thresholdWithMetadata?.label || item.name;
    const existingThreshold = thresholdsGrouped.find(
      (threshold) => threshold.name === name && threshold.type === 'rangeArea'
    );

    if (existingThreshold) {
      const existingLine = thresholdsGrouped.find((threshold) => threshold.name === name && threshold.type === 'line');

      const thresholdData = item.thresholdMax
        ? getThresholdData(item.from, item.to, item.thresholdMax, max)
        : getThresholdData(item.from, item.to, item.thresholdMin ?? 0);

      if (existingLine && (item.thresholdMax || item.thresholdMin)) {
        const valueForLine = item.thresholdMax ? item.thresholdMax : item.thresholdMin ?? 0;
        const lineData = getLineData(item.from, item.to, valueForLine);
        existingLine.data = [...existingLine.data, ...lineData];
      }

      existingThreshold.data = [...existingThreshold.data, ...thresholdData];
    } else {
      const baseThreshold = {
        name: thresholdWithMetadata?.label || name,
        color: ['A', 'D'].includes(thresholdWithMetadata?.alias ?? '')
          ? theme.palette.thresholds.persianRed
          : theme.palette.thresholds.clementine,
        alias: thresholdWithMetadata?.alias || '',
        uom: item.uom,
      };

      const thresholdData = getThreshold({
        thresholdMin: item.thresholdMin,
        thresholdFrom: item.from,
        thresholdMax: item.thresholdMax,
        max,
        min,
        baseThreshold,
        thresholdTo: item.to,
      });
      if (thresholdData) {
        thresholdsGrouped.push(...createRange({ ...thresholdData, isMin: Boolean(item.thresholdMin) }));
      }
    }
  });

  return thresholdsGrouped.sort((a, b) => {
    if (a.type === b.type) return 0;
    if (a.type === 'line') return 1;
    return -1;
  });
};

type TBaseThreshold = {
  name: string;
  color: string;
  alias: string;
  uom: TUnitOfMeasure | null;
};

interface IGetThresholdParams {
  baseThreshold: TBaseThreshold;
  thresholdMin: number | null;
  thresholdMax: number | null;
  thresholdFrom: number;
  thresholdTo: number;
  max: number;
  min: number;
}

const getThreshold = ({
  thresholdMin,
  thresholdFrom,
  thresholdMax,
  max,
  min,
  baseThreshold,
  thresholdTo,
}: IGetThresholdParams) => {
  if (thresholdMin) {
    return {
      ...baseThreshold,
      data: getThresholdData(thresholdFrom, thresholdTo, thresholdMin, undefined, min),
    };
  }

  if (thresholdMax) {
    return {
      ...baseThreshold,
      data: getThresholdData(thresholdFrom, thresholdTo, thresholdMax, max),
    };
  }

  return undefined;
};

const getThresholdData = (
  from: number,
  to: number,
  value: number,
  max?: number,
  min?: number
): { x: number; y: [number, number] }[] => {
  if (max) {
    return [
      {
        x: from,
        y: [value, max],
      },
      {
        x: to,
        y: [value, max],
      },
    ];
  }

  return [
    {
      x: from,
      y: [min ? min : 0, value],
    },
    {
      x: to,
      y: [min ? min : 0, value],
    },
  ];
};

const getLineData = (from: number, to: number, value: number | null) => [
  {
    x: from - 1,
    y: null,
  },
  {
    x: from,
    y: value,
  },
  {
    x: to,
    y: value,
  },
  {
    x: to + 1,
    y: null,
  },
];
