import { useRemoveEquipment } from '@marlin/asset/ui/delete-equipment-modal';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { TEquipment } from '@marlin/shared/utils/zod';
import { Button } from '@mui/material';

import { content } from './content';

interface IDeregisterEquipmentButtonProps {
  equipment?: TEquipment;
}

export const DeregisterEquipmentButton = ({ equipment }: IDeregisterEquipmentButtonProps) => {
  const { removeEquipment } = useRemoveEquipment();

  const isGatewayEquipment = Boolean(equipment?.additionalProperties?.['gatewayId']);

  if (isGatewayEquipment) {
    const link = `${routes.gateway.root}/${routes.gateway.configuration.url(
      equipment?.additionalProperties?.gatewayId ?? ''
    )}`;

    return (
      <Restricted to={PERMISSIONS.DEREGISTER_EQUIPMENT}>
        <Tooltip text={content.DEREGISTER_GATEWAY_EQUIPMENT(link)}>
          <div>
            <Button
              onClick={() => removeEquipment(equipment)}
              variant="outlined"
              color="error"
              data-testid="deregister-button"
              disabled
            >
              {content.DEREGISTER}
            </Button>
          </div>
        </Tooltip>
      </Restricted>
    );
  }

  return (
    <Restricted to={equipment?.isRegistered ? PERMISSIONS.DEREGISTER_EQUIPMENT : PERMISSIONS.DELETE_EQUIPMENT}>
      <Button
        onClick={() => removeEquipment(equipment)}
        variant="outlined"
        color="error"
        data-testid="deregister-button"
      >
        {equipment?.isRegistered ? content.DEREGISTER : content.DELETE}
      </Button>
    </Restricted>
  );
};
