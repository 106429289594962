import { MarlinTheme } from '@marlin/shared/theme';
import { PageHeader } from '@marlin/shared/ui-page';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useRouter } from '../../shared/hooks/use-router.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    width: theme.typography.pxToRem(123),
  },
}));

const DevicesHubHeader = () => {
  const { goToNewSensorPage } = useRouter();
  const { classes } = useStyles();
  return (
    <PageHeader
      prefix="devices-hub"
      title={content.SENSORS_HUB_TITLE}
      subtitle={content.SENSORS_HUB_SUBTITLE}
      icon={<SensorsRoundedIcon />}
      actions={
        <Restricted to={PERMISSIONS.CREATE_NEW_DEVICE}>
          <Button id="devices-hub-button" variant="contained" onClick={goToNewSensorPage} className={classes.button}>
            {content.CREATE_NEW_BUTTON}
          </Button>
        </Restricted>
      }
    />
  );
};

export { DevicesHubHeader };
