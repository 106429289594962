import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TUserProfileForm, User } from '../schemas/user';
import { paths } from './api';

export const profileUpdate = async (data: TUserProfileForm) => {
  const response = await getHttpClient().put(paths.USER_PROFILE, data);

  return User.parse(response);
};
