import { useCurrentUser, useGetOrganizationStatus, useUpdateOrg } from '@marlin/account-data-access-organization';
import { FullLogo } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

import { content } from '../translation-keys';
import { useStyles } from './organization-loader.styles';

interface IInvitationNotAcceptedProps {
  redirectUrl: string;
  organizationId: string;
}

export const OrganizationLoader = ({ redirectUrl, organizationId }: IInvitationNotAcceptedProps): JSX.Element => {
  const { classes } = useStyles();
  const { isSuccess } = useGetOrganizationStatus({ organizationId });
  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = () =>
    enqueueSnackbar(content.ORGANIZATION_HAS_BEEN_CHANGED, {
      variant: 'success',
      preventDuplicate: true,
    });
  const { mutateAsync: updateOrg } = useUpdateOrg({ shouldRefetch: false, onSuccess });
  const { refetch: getCurrentUser } = useCurrentUser(false);

  const { goTo } = useRouter();

  useEffect(() => {
    const updateOrganization = async () => {
      if (isSuccess) {
        await getCurrentUser();
        await updateOrg(organizationId);
        enqueueSnackbar(content.SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });
        goTo(redirectUrl);
      }
    };
    updateOrganization();
  }, [enqueueSnackbar, getCurrentUser, goTo, isSuccess, organizationId, redirectUrl, updateOrg]);

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>
        <FullLogo height={40} mobileHeight={18} />
      </h1>
      <div className={classes.innerContainer}>
        <CircularProgress />
        <p className={classes.body}>{content.LOADER}</p>
      </div>
    </div>
  );
};
