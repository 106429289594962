import { ReactNode } from 'react';
import { EdgeMarker } from 'reactflow';

import { TMarlinMarkerProps, useMarlinMarkerDefinitions } from './use-marker-definitions.hook';

export type TMarkerProps = Omit<EdgeMarker, 'type'> & {
  id: string;
  children: ReactNode;
};

const Marker = ({
  id,
  width = 12.5,
  height = 12.5,
  markerUnits = 'strokeWidth',
  orient = 'auto-start-reverse',
  children,
}: TMarkerProps) => {
  return (
    <marker
      id={id}
      markerWidth={`${width}`}
      markerHeight={`${height}`}
      viewBox="-10 -10 20 20"
      markerUnits={markerUnits}
      orient={orient}
      refX="0"
      refY="0"
    >
      {children}
    </marker>
  );
};

export const MarkerDefinitions = () => {
  const markers = useMarlinMarkerDefinitions();

  if (!markers.length) {
    return null;
  }

  return (
    // style needed to prevent svg from being rendered on the top of system map
    <svg style={{ position: 'absolute', top: 0, left: 0 }}>
      <defs>
        {markers.map((marker: TMarlinMarkerProps) => (
          <Marker id={marker.type} key={marker.type}>
            {marker.children}
          </Marker>
        ))}
      </defs>
    </svg>
  );
};
