import * as yup from 'yup';
import * as z from 'zod';

import { content } from '../content';

// todo remove yup
export const emailSchema = yup
  .string()
  .email(content.EMAIL_CONTROL_VALIDATION)
  .required(content.EMAIL_CONTROL_VALIDATION);

export const emailZodSchema = z.string().email(content.EMAIL_CONTROL_VALIDATION);
