import moment, { Moment } from 'moment/moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TDurationDetails } from '../model';
import { getSelectionTimeAxis } from '../utils/get-time-axis';
import { IRangeFilter, RANGE_FILTER } from '../utils/get-time-range-with-format';

const DateFormat = 'YYYY-MM-DDTHH:mm:ss';

const rangeTime = new Map<RANGE_FILTER, () => Moment>([
  [RANGE_FILTER.HOURS_8, () => moment().utc().subtract(8, 'hours')],
  [RANGE_FILTER.HOURS_24, () => moment().utc().subtract(1, 'days')],
  [RANGE_FILTER.DAYS_7, () => moment().utc().subtract(7, 'days')],
  [RANGE_FILTER.DAYS_30, () => moment().utc().subtract(30, 'days')],
  [RANGE_FILTER.MONTHS_12, () => moment().utc().subtract(12, 'months')],
]);

interface IUseDuration<T extends string> {
  key?: T;
  fromDate: Moment | null;
  toDate: Moment | null;
  rangeFilter: IRangeFilter;
  setDetailsWithPrev?: (value: TDurationDetails<T> | ((prevState: TDurationDetails<T>) => TDurationDetails<T>)) => void;
  updateDetails?: (key: T, details: TDurationDetails<T>[T]) => void;
}

export const useDuration = <T extends string>({
  key,
  toDate,
  fromDate,
  rangeFilter: { range, currentDate },
  setDetailsWithPrev,
  updateDetails,
}: IUseDuration<T>) => {
  const { to, from } = useMemo(() => {
    return {
      to: currentDate.format(DateFormat),
      from: rangeTime.get(range)?.().format(DateFormat) ?? currentDate.subtract(8, 'hours').format(DateFormat),
    };
  }, [currentDate, range]);

  const timeRange = useMemo(() => {
    const { timeRange } = getSelectionTimeAxis({
      rangeFilter: range,
    });

    return timeRange;
    // currentData is needed to update the timeRange on refresh withouth changing the range
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, currentDate]);

  const [zoom, setZoom] = useState<{ zoomFrom: string; zoomTo: string; min: number; max: number } | undefined>();

  const handleZoomChange = useCallback((zoom?: { min: number; max: number }) => {
    if (!zoom) {
      setZoom(undefined);

      return;
    }

    setZoom({
      min: zoom.min,
      max: zoom.max,
      zoomFrom: moment(zoom.min).utc().format(DateFormat),
      zoomTo: moment(zoom.max).utc().format(DateFormat),
    });
  }, []);

  const durationParams = useMemo(() => {
    if (zoom) {
      return {
        fromDate: zoom.zoomFrom,
        toDate: zoom.zoomTo,
        minSelection: zoom.min,
        maxSelection: zoom.max,
        handleZoomChange,
        isZoomed: true,
        zoomedRange: moment(zoom.zoomTo).diff(moment(zoom.zoomFrom)),
      };
    }

    if (range !== RANGE_FILTER.CUSTOM || !fromDate || !toDate) {
      return { fromDate: from, toDate: to, minSelection: timeRange[0], maxSelection: timeRange[1], handleZoomChange };
    }

    const toDateEndOfDay = toDate.clone().set('hour', 23).set('minute', 59).set('second', 59);

    return {
      fromDate: fromDate.clone().utc().format(DateFormat),
      toDate: toDateEndOfDay.utc().format(DateFormat),
      minSelection: fromDate.valueOf(),
      maxSelection: toDateEndOfDay.valueOf(),
      handleZoomChange,
    };
  }, [from, fromDate, handleZoomChange, range, timeRange, to, toDate, zoom]);

  useEffect(() => {
    if (!key) return;

    if (setDetailsWithPrev) {
      setDetailsWithPrev((prev) => ({
        ...prev,
        [key]: {
          currentDuration: {
            from: durationParams.fromDate,
            to: durationParams.toDate,
            isZoomed: durationParams.isZoomed,
          },
          handleZoomChange,
        },
      }));
    }

    if (updateDetails) {
      updateDetails(key, {
        currentDuration: {
          from: durationParams.fromDate,
          to: durationParams.toDate,
          isZoomed: Boolean(durationParams.isZoomed),
        },
        handleZoomChange,
      });
    }
  }, [durationParams, handleZoomChange, key, setDetailsWithPrev, updateDetails]);

  return durationParams;
};
