import { LayoutOptions } from 'elkjs/lib/elk.bundled';

import { MARKER_TYPE } from './edges/use-marker-definitions.hook';

export const systemMapSpacing = 120;
export const nodePadding = 30;
export const maxLabelLength = 24;

export const gridSize = 8;

export const levelLayoutOptions: LayoutOptions = {
  'elk.layered.spacing.nodeNodeBetweenLayers': systemMapSpacing.toString(),
  'elk.layered.spacing.edgeEdgeBetweenLayers': systemMapSpacing.toString(),
  'elk.layered.spacing.edgeNodeBetweenLayers': systemMapSpacing.toString(),
  'elk.spacing.nodeNode': systemMapSpacing.toString(),
  'elk.spacing.edgeNode': systemMapSpacing.toString(),
  'elk.spacing.edgeEdge': systemMapSpacing.toString(),
  'elk.layered.spacing.componentComponent': systemMapSpacing.toString(),
  'elk.layered.mergeEdges': 'true',
  'elk.alignment': 'LEFT',
  'elk.padding': `[top=${nodePadding},left=${nodePadding},bottom=${nodePadding},right=${nodePadding}]`,
};

export const layoutOptions: LayoutOptions = {
  'elk.algorithm': 'layered',
  'elk.hierarchyHandling': 'INCLUDE_CHILDREN',
  ...levelLayoutOptions,
};

export const defaultEdgeOptions = {
  zIndex: 99,
  type: 'systemMap',
  markerEnd: MARKER_TYPE.ARROW_DEFAULT,
};
