import { InputAdornment } from '@mui/material';
import IMask from 'imask';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isRegExp from 'lodash/isRegExp';
import isString from 'lodash/isString';
import { useMemo } from 'react';

import { InputMask } from './input-mask.component';
import { INPUT_TYPE } from './input-type.enum';
import { useStyles } from './input.styles';

export interface IEndAdornment {
  endAdornment: JSX.Element | string;
}

interface IMask {
  mask: string | RegExp;
  unmask?: boolean;
}

export type TMask = string | RegExp | IMask;

const hasMask = (mask: TMask | undefined): boolean => {
  const isMaskObject = (mask: TMask | undefined): mask is IMask => {
    if (!isObject(mask) || isRegExp(mask)) {
      return false;
    }

    return (isString(mask.mask) && !!mask.mask.length) || isRegExp(mask.mask);
  };

  return !isNil(mask) && (isString(mask) || isRegExp(mask) || isMaskObject(mask));
};

const createMaskObj = (mask: TMask | undefined) => {
  if (!hasMask(mask)) {
    return {};
  }

  if (isString(mask) || isRegExp(mask)) {
    return {
      mask,
    };
  }

  return { ...mask };
};

export const useInputProps = (
  type: INPUT_TYPE,
  mask?: TMask,
  tooltip?: JSX.Element,
  externalEndAdornment?: IEndAdornment,
  prepare?: (value: string, masked: unknown, flags: IMask.AppendFlags) => string
) => {
  const { classes } = useStyles({ tooltip: !!tooltip });

  const isInputMask = useMemo(() => {
    if (!hasMask(mask) && type !== INPUT_TYPE.NUMBER) {
      return {};
    }

    const inputMode = type === INPUT_TYPE.NUMBER ? ({ inputMode: 'numeric', pattern: '[0-9]*' } as const) : {};

    return {
      inputProps: {
        ...createMaskObj(mask ?? /^[0-9]+$/),
        ...inputMode,
        prepare,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: InputMask as any,
    };
  }, [mask, prepare, type]);

  const endAdornment = useMemo(
    () =>
      externalEndAdornment ??
      (tooltip
        ? {
            endAdornment: (
              <InputAdornment position="end" className={classes.tooltip}>
                {tooltip}
              </InputAdornment>
            ),
          }
        : {}),
    [externalEndAdornment, tooltip, classes.tooltip]
  );

  const inputProps = useMemo(
    () => ({
      ...isInputMask,
      ...endAdornment,
    }),
    [isInputMask, endAdornment]
  );

  return { inputProps };
};
