import { InfiniteAutocompleteControl, SearchWrapper, TFormFieldProps } from '@marlin/shared/ui-form-common';
import { FieldValues } from 'react-hook-form';

import { IEquipmentTypeOption, useEquipmentTypeOptions } from '../constants/equipment-type-options';
import { content } from '../content';

export const EquipmentTypeControl = <TFieldValues extends FieldValues>(props: TFormFieldProps<TFieldValues>) => {
  const equipmentTypeOptions = useEquipmentTypeOptions();

  return (
    <SearchWrapper>
      {({ search, term }) => (
        <InfiniteAutocompleteControl<TFieldValues, IEquipmentTypeOption>
          fieldName={props.fieldName}
          control={props.control}
          testId="equipment-type-filter"
          label={content.EQUIPMENT_TYPE_LABEL}
          term={term}
          search={search}
          isFetchingNextPage={false}
          hasNextPage={false}
          isLoading={false}
          isFetching={false}
          fetchNextPage={() => {}}
          options={equipmentTypeOptions}
          multiple
        />
      )}
    </SearchWrapper>
  );
};
