import { dateAdapter } from '@marlin/shared/utils-common-date';

import { TBucketOption } from '../model';

export const DateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const getDatesForBarChart = (from: string, to: string, bucketOption?: TBucketOption) => {
  const dateFrom = dateAdapter.date(from);
  const dateTo = dateAdapter.date(to);

  if (!dateFrom || !dateTo || !bucketOption) return { dateFrom: from, dateTo: to };

  const getDateFrom = () => {
    switch (bucketOption) {
      case 'minutes':
        return dateFrom.set('seconds', 0);
      case 'hours':
        return dateFrom.set('minutes', 0).set('seconds', 0);
      case 'days':
      case 'weeks':
      case 'months':
        return dateFrom.startOf(bucketOption.slice(0, -1) as 'day' | 'week' | 'month');
    }
  };

  const getDateTo = () => {
    switch (bucketOption) {
      case 'minutes':
        return dateTo.set('seconds', 0);
      case 'hours':
        return dateTo.set('minutes', 59).set('seconds', 59);
      case 'days':
      case 'weeks':
      case 'months':
        return dateTo.endOf(bucketOption.slice(0, -1) as 'day' | 'week' | 'month');
    }
  };

  return {
    dateFrom: getDateFrom().utc().format(DateFormat),
    dateTo: getDateTo().utc().format(DateFormat),
  };
};
