import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { UpsertEquipment } from '../form/upsert-equipment.component';
import { content } from './content';
import { DeregisterEquipmentButton } from './deregister-equipment-button.component';
import { useUpdateEquipment } from './use-update-equipment.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
  buttonWrapper: {
    [theme.breakpoints.down('md')]: {
      marginRight: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(8),
    },
  },
}));

export const UpdateEquipment = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    equipment,
    loadingUpdatedEquipment,
    isLoading,
    successfullyUpdatedEquipment,
    onSubmit,
    goToNextRoute,
    updateEquipmentErrorCode,
    isEquipmentError,
  } = useUpdateEquipment();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (successfullyUpdatedEquipment) {
    return <Navigate to={routes.equipments.root} />;
  }

  if (isEquipmentError) {
    return <Navigate to={routes.equipments.list.path} />;
  }

  return (
    <PageContainer prefix="update-equipment">
      <PageHeader
        icon={null}
        title={equipment?.name || ''}
        subtitle={content.SUBTITLE}
        prefix="device-update-header"
        topActions={
          isMobile ? (
            <Button
              className={classes.button}
              startIcon={<CloseRoundedIcon />}
              variant="outlined"
              onClick={goToNextRoute}
            >
              {content.BUTTON_CANCEL}
            </Button>
          ) : undefined
        }
        rightSideActions={
          <div className={classes.buttonWrapper}>
            <DeregisterEquipmentButton equipment={equipment} />
          </div>
        }
      />
      <UpsertEquipment
        onSubmit={onSubmit}
        onCancel={goToNextRoute}
        disableSubmit={loadingUpdatedEquipment}
        defaultValues={equipment}
        equipmentId={equipment?.id}
        upsertEquipmentErrorCode={updateEquipmentErrorCode}
      />
    </PageContainer>
  );
};
