import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TFlowMeterUpdateSchema, flowMeterUpdateRequestSchema } from './schema/flow-meter.schema';

export const updateHomeFlowMeter = async (data: TFlowMeterUpdateSchema): Promise<unknown> => {
  const body = safeParseData(data, flowMeterUpdateRequestSchema);
  const res = await getHttpClient().put(paths.FLOW_METER, body);

  return res;
};
