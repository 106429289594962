import { ALERT_STATUS_FILTER, useRefetchFilteredAlertsWithLoadMore } from '@marlin/alert/data-access/alert-action';
import { useRefetchDeviceDetails } from '@marlin/asset/data-access/device';
import { TTelemetry, useRangeTelemetry } from '@marlin/asset/data-access/telemetry';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';

import { content } from '../../../content';
import { useDeviceDetailsContext } from '../../../context/device-details.context';
import { useSensorId } from '../../../hooks/use-sensor-id.hook';
import { useTelemetryFormattedReading } from '../../../hooks/use-telemetry-reading';
import { IRange } from '../context/chart-range.context';
import { DEVICE_TYPE } from '../types';

export interface IFormattedReading {
  formattedValue: string;
  formattedDate: string;
}

export interface IReadingsProps {
  range: IRange;
}

export interface IReadings {
  isLoading: boolean;
  isLeakUnitOfMeasure: boolean;
  recentReading: IFormattedReading | null;
  lowReading: IFormattedReading | null;
  highReading: IFormattedReading | null;
  refreshData: () => void;
  telemetry: TTelemetry | undefined;
  isFirstTimeLoading: boolean;
}

interface IAlertParams {
  status: ALERT_STATUS_FILTER;
  pageSize: number;
  locationIds: string[];
  equipmentIds: string[];
  deviceIds: string[];
  deviceTypes: string[];
}

const defaultParams: IAlertParams = {
  status: ALERT_STATUS_FILTER.CURRENT,
  pageSize: 10,
  locationIds: [],
  equipmentIds: [],
  deviceIds: [],
  deviceTypes: [],
};

const formatToIsoString = (timestamp: number): string => {
  return dateAdapter.date(timestamp)?.utc().toISOString() || '';
};

export const useReadings = ({ range }: IReadingsProps): IReadings => {
  const deviceId = useSensorId();
  const deviceDetails = useDeviceDetailsContext();
  const refetchCurrentAlerts = useRefetchFilteredAlertsWithLoadMore();
  const refetchDeviceDetails = useRefetchDeviceDetails();
  const { enqueueSnackbar } = useSnackbar();

  const deviceIds = useMemo(() => (deviceId ? [deviceId] : []), [deviceId]);
  const manufacturerId = useMemo(() => deviceDetails?.manufacturerId || '', [deviceDetails?.manufacturerId]);

  const telemetryQueryParams = useMemo(() => {
    return {
      to: formatToIsoString(range.to),
      from: formatToIsoString(range.from),
      manufacturerId: manufacturerId || '',
      enabled: !!manufacturerId && !!manufacturerId.length && !!range,
      keepPreviousData: true,
      excludeAlerts: true,
      calculateBuckets: false,
    };
  }, [manufacturerId, range]);

  const telemetryQuery = useRangeTelemetry(telemetryQueryParams);

  const isLoading = useMemo(
    () => telemetryQuery.isLoading || telemetryQuery.isRefetching,
    [telemetryQuery.isLoading, telemetryQuery.isRefetching]
  );

  const refreshData = useCallback(
    () =>
      Promise.all([
        telemetryQuery.refetch(),
        refetchCurrentAlerts({ ...defaultParams, deviceIds }),
        refetchDeviceDetails(deviceId),
      ]),
    [telemetryQuery, refetchCurrentAlerts, deviceIds, refetchDeviceDetails, deviceId]
  );

  const recentReading = useTelemetryFormattedReading(telemetryQuery.data?.recent, { formatDateMode: 'durationString' });
  const highReading = useTelemetryFormattedReading(telemetryQuery.data?.high, { formatDateMode: 'dateString' });
  const lowReading = useTelemetryFormattedReading(telemetryQuery.data?.low, { formatDateMode: 'dateString' });
  const isLeakUnitOfMeasure = useMemo(
    () => telemetryQuery?.data?.recent?.deviceType === DEVICE_TYPE.LEAK,
    [telemetryQuery?.data?.recent?.deviceType]
  );

  useEffect(() => {
    if (telemetryQuery.data?.chartData?.length === 0) {
      enqueueSnackbar(content.EMPTY_CHART_DATA_MESSAGE, {
        variant: 'warning',
      });
    }
  }, [enqueueSnackbar, telemetryQuery.data]);

  return {
    isLoading,
    isLeakUnitOfMeasure,
    recentReading,
    lowReading,
    highReading,
    refreshData,
    telemetry: telemetryQuery.data,
    isFirstTimeLoading: telemetryQuery.isLoading,
  };
};
