import { IAutomationOptions, TAutomationSeriesName } from './types';

export const tresholdLines: TAutomationSeriesName[] = ['LOW_THRESHOLD_LINE', 'HIGH_THRESHOLD_LINE'];
export const tresholdAreas: TAutomationSeriesName[] = ['LOW_THRESHOLD', 'HIGH_THRESHOLD'];
export const deadbandLines: TAutomationSeriesName[] = ['LOW_DEADBAND_LINE', 'HIGH_DEADBAND_LINE'];
export const deadbandAreas: TAutomationSeriesName[] = ['LOW_DEADBAND', 'HIGH_DEADBAND'];

export const lineSeriesNames: TAutomationSeriesName[] = [...tresholdLines, ...deadbandLines];

export const defaultAutomationOptions: IAutomationOptions = {
  stroke: {
    curve: 'straight',
    width: [],
    dashArray: [],
    colors: [],
  },
  fill: {
    opacity: [],
    colors: [],
  },
  markers: {
    colors: [],
    fillOpacity: [],
    width: [],
  },
};

export const offsetX = -20;
export const deadbandColorOpacity = 0.24;
export const triggerColorOpacity = 0.12;
export const alertColorOpacity = 0.3;

export const annotationStrokeWidth = 2;
export const invisibleStrokeWidth = 0;
export const annotationDashWidth = 4;
