export const content = {
  DEFAULT_HOME_VIEW: 'Default Home View',
  SELECT_LAYOUT_SUBTITLE: 'Select which layout should be set as default home view.',
  DASHBOARD_LAYOUT: 'Dashboard layout',
  DASHBOARD_LAYOUT_SUBTITLE: 'Compact view presenting the health of your system at glance.',
  LOCATIONS_LAYOUT: 'Locations layout',
  LOCATIONS_LAYOUT_SUBTITLE: 'Locations view presenting the detailed view of alerts.',
  DEFAULT_HOME_VIEW_SUCCESS: 'Default Home View has been changed',
  DEFAULT_UOM_SUCCESS: 'Default Unit of Measurement has been changed',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  ACTIVE: 'Active',
  UNITS_OF_MEASUREMENT: 'Equipment Units of Measurement',
  TIME_FORMAT: 'Time Format',

  FLOW_LITERS: 'L/min - Liters per minute',
  FLOW_GALLONS: 'gpm - Gallons per minute',
  FLOW_CUBIC_METER: 'm³/h - Cubic meter per hour',

  TEMPERATURE_CELCIUS: '°C - Celsius',
  TEMPERATURE_FAHRENHEIT: '°F - Fahrenheit',

  GAS_FEET: 'SCF - Standard cubic feet',
  GAS_METERS: 'Sm³ - Standard cubic meters',

  PRESSURE_POUNDS: 'psi - Pounds per square inch',
  PRESSURE_KILOPASCAL: 'kPa - Kilopascal',

  ENERGY_MBTU: 'MBTU - Thousand British thermal units',
  ENERGY_KBTU: 'kBTU - Thousand British thermal units',
  ENERGY_MBH: 'MBH - Thousand British thermal units per hour',
  ENERGY_GIGAJOULE: 'GJ - Gigajoule',
  ENERGY_KWH: 'kWh - Kilowatt-hour',
  ENERGY_THERM: 'thm - Therm',

  TEMPERATURE_CONTROL_LABEL: 'Temperature',
  FLOW_CONTROL_LABEL: 'Flow',
  PRESSURE_CONTROL_LABEL: 'Pressure',
  ENERGY_CONTROL_LABEL: 'Energy',
  GAS_CONTROL_LABEL: 'Gas',
};
