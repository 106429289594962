import { content } from '../content';
import { useDatapointsContext } from '../context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../context/equipment-details-config-context';
import { useEquipmentContext } from '../context/equipment.context';
import {
  mapAdditionalPropertiesDataSource,
  mapDatapointsDataSource,
  mapDatapointsMixNames,
  mapDeviceDataSource,
  mapEquipmentDataSource,
  mapLastReadingTime,
} from '../utils/equipment-details-section-mappers';
import { useLastReadingTimeCounter } from './use-last-reading-time-counter.hook';

export type TRow = { label: string; value?: number | string; tooltip?: string };

export const useEquipmentDetailsSectionData = (): TRow[] => {
  const { equipment, lastReadingTime } = useEquipmentContext();
  const { getDatapoint, timestamp, datapoints } = useDatapointsContext();
  const {
    config: {
      details,
      dashboard: { isLastReadingTimeCounter },
    },
  } = useEquipmentDetailsConfigContext();

  useLastReadingTimeCounter({ disableCounter: !isLastReadingTimeCounter });

  if (!equipment) {
    return [];
  }

  return details.map((row) => {
    const emptyRow = {
      label: row.label,
      value: content.EMPTY_DATAPOINT_VALUE,
      tooltip: row.tooltip,
    };

    // exception for lastReadingTime related to few possible sources depending on which is available
    if (row.name === 'lastReadingTime') {
      return mapLastReadingTime(row, equipment, timestamp, lastReadingTime);
    }

    switch (row.valueSource) {
      case 'equipment':
        return mapEquipmentDataSource(row, equipment) ?? emptyRow;
      case 'datapoints':
        return mapDatapointsDataSource(row, datapoints, getDatapoint) ?? emptyRow;
      case 'mix':
        return mapDatapointsMixNames(row, datapoints, equipment) ?? emptyRow;
      case 'device':
        return mapDeviceDataSource(row, equipment) ?? emptyRow;
      case 'additionalProperties':
        return mapAdditionalPropertiesDataSource(row, equipment) ?? emptyRow;
    }

    return assertUnreachable(row);
  });
};

const assertUnreachable = (x: never): never => {
  throw new Error('Unexpected object: ' + x);
};
