import { ErrorResponseWithCode } from '@marlin/shared/utils/zod';
import { useSnackbar } from 'notistack';
import { UseFormReturn } from 'react-hook-form';

import { content } from './content';
import { GATEWAY_REGISTRATION_ERROR, GatewayRegistrationErrorCodeEnum, TUpsertGatewayForm } from './form-types';

// TODO: move this and from equipment to shared and make generic
export const getRegistrationErrorCode = (error: unknown): GATEWAY_REGISTRATION_ERROR | undefined => {
  const parsedError = ErrorResponseWithCode.safeParse(error);

  if (parsedError.success) {
    const errorCode = parsedError.data.response.data.errorCode;
    const parsedErrorCode = GatewayRegistrationErrorCodeEnum.safeParse(errorCode);

    if (parsedErrorCode.success) {
      return parsedErrorCode.data;
    }
  }

  return undefined;
};

export const handleRegistrationError = (
  errorCode: GATEWAY_REGISTRATION_ERROR | undefined,
  setError: UseFormReturn<TUpsertGatewayForm>['setError'],
  enqueueSnackbar: ReturnType<useSnackbar>['enqueueSnackbar']
) => {
  switch (errorCode) {
    case GATEWAY_REGISTRATION_ERROR.INVALID_GATEWAY_MODE:
      enqueueSnackbar(content.INVALID_GATEWAY_MODE, {
        variant: 'error',
        preventDuplicate: true,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.GATEWAY_IS_NOT_CONFIGURED:
      enqueueSnackbar(content.GATEWAY_IS_NOT_CONFIGURED, {
        variant: 'error',
        preventDuplicate: true,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.REGISTRATION_CODE_ALREADY_USED:
      setError('registrationCode', {
        type: 'custom',
        message: content.REGISTER_CODE_ALREADY_USED,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.NAME_MUST_BE_UNIQUE:
      setError('name', {
        type: 'custom',
        message: content.EQUIPMENT_NAME_NOT_UNIQUE,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.REGISTRATION_CODE_EXPIRED:
    case GATEWAY_REGISTRATION_ERROR.REGISTRATION_CODE_OBSOLETE:
      setError('registrationCode', {
        type: 'custom',
        message: content.REGISTER_CODE_EXPIRED,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.INSTALLATION_DATE_CANNOT_BE_IN_FUTURE:
      setError('commissionDate', {
        type: 'custom',
        message: content.DATE_VALIDATION_FUTURE,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.INVALID_DEVICE:
      setError('registrationCode', {
        type: 'custom',
      });
      enqueueSnackbar(content.GATEWAY_ERROR_MESSAGE, {
        variant: 'error',
        preventDuplicate: true,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.ALREADY_REGISTERED:
      setError('registrationCode', {
        type: 'custom',
        message: content.ALREADY_REGISTERED,
      });
      break;
    case GATEWAY_REGISTRATION_ERROR.REGISTRATION_CODE_DOES_NOT_EXIST:
      setError('registrationCode', {
        type: 'custom',
        message: content.NOT_EXISTS,
      });
      break;
    default:
      break;
  }
};
