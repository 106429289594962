import { TGateway, useDeregisterGateway } from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, alpha } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useNavigation } from './use-navigation.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cancelButton: {
    marginRight: theme.typography.pxToRem(16),
  },
  name: {
    fontWeight: 'bold',
  },
  tableHead: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.alternative,
    height: theme.typography.pxToRem(32),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
    borderRadius: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)} 0 0`,
    borderBottom: 'none',
  },
  tableTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: theme.typography.pxToRem(24),
  },
  textWrapper: {
    padding: theme.typography.pxToRem(16),
  },
  row: {
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  linkInfo: {
    marginBottom: theme.typography.pxToRem(8),
  },
  cell: {
    minHeight: theme.typography.pxToRem(36),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    width: theme.typography.pxToRem(512),
    borderBottom: 'none',
  },
  assetLinkWrapper: {
    color: theme.palette.primary.main,
    marginLeft: theme.typography.pxToRem(8),
    '& > a': {
      color: theme.palette.primary.main,
    },
  },
  bodyWrapper: {
    maxHeight: theme.typography.pxToRem(198),
    overflow: 'scroll',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)}`,
  },
  assetWrapper: {
    display: 'flex',
    color: alpha(theme.palette.action.active, 0.54),
  },
  equipmentTypeName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    flex: 1,
    minWidth: 0,
    boxSizing: 'border-box',
  },
}));

export const useDeregisterModal = ({ gatewayId, refreshData }: { gatewayId: string; refreshData: () => void }) => {
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);
  const { enqueueSnackbar } = useSnackbar();

  const deregisterGatewayMutation = useDeregisterGateway({
    onSuccess: () => {
      refreshData();
      enqueueSnackbar(content.SUCCESS_REMOVE_MESSAGE, {
        variant: 'success',
        preventDuplicate: true,
      });
    },
  });

  const deregisterGateway = useCallback(
    (gatewayId: string) => {
      deregisterGatewayMutation.mutateAsync({ gatewayId });
    },
    [deregisterGatewayMutation]
  );

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleRemove = useCallback(() => {
    deregisterGateway(gatewayId);
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch, gatewayId, deregisterGateway]);

  const modalRemoveConnection = (gateway: TGateway) => ({
    title: <div>{content.DEREGISTER_MODAL.TITLE}</div>,
    body: (
      <div>
        <div>
          {content.DEREGISTER_MODAL.BODY_TEXT_1ST_PART} <span className={classes.name}>{gateway.gatewayName}</span>?
        </div>
        <br />
        {gateway.assignedDevices && gateway.assignedDevices.length > 0 ? (
          <div className={classes.linkInfo}>
            <div className={classes.linkInfo}>{content.DEREGISTER_MODAL.FLOW_LINK_INFO}</div>
            <div>
              <AttachmentsTable
                onCloseModal={handleCloseModal}
                title={content.DEREGISTER_MODAL.FLOW_LINKS_TABLE}
                data={gateway.assignedDevices}
              />
            </div>
          </div>
        ) : null}
        <div>
          <b>{content.DEREGISTER_MODAL.BODY_TEXT_2ND_PART}</b>
        </div>
      </div>
    ),
    footer: (
      <div data-testid="remove-connection-modal-footer">
        <Button
          className={classes.cancelButton}
          variant="text"
          data-testid="deregister-modal-footer-cancel"
          onClick={handleCloseModal}
        >
          {content.DEREGISTER_MODAL.CANCEL}
        </Button>
        <Button variant="contained" color="error" data-testid="deregister-modal-footer-confirm" onClick={handleRemove}>
          {content.DEREGISTER_MODAL.DEREGISTER}
        </Button>
      </div>
    ),
  });

  const openDeregisterModal = (gateway: TGateway) => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: modalRemoveConnection(gateway),
    });
  };

  return { openDeregisterModal };
};

interface IAttachmentsTableProps {
  data: { id: string; name: string }[];
  title: string;
  onCloseModal: () => void;
}

const AttachmentsTable = ({ data, title, onCloseModal }: IAttachmentsTableProps) => {
  const { classes } = useStyles();
  const { getEquipmentDetailsPageLink } = useNavigation();

  return (
    <Table aria-label="simple table">
      <TableHead className={classes.tableHead}>
        <div className={classes.textWrapper}>
          <Typography className={classes.tableTitle}>{title}</Typography>
        </div>
      </TableHead>
      <TableBody>
        <div className={classes.bodyWrapper}>
          {data?.map((link) => {
            return (
              <TableRow className={classes.row} key={link.id}>
                <TableCell className={classes.cell} component="td" scope="row">
                  <div className={classes.assetWrapper}>
                    <DeviceHubRoundedIcon />
                    <div className={classes.assetLinkWrapper}>
                      <Link onClick={onCloseModal} to={getEquipmentDetailsPageLink(link.id, true)}>
                        {link.name}
                      </Link>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </TableBody>
    </Table>
  );
};
