import moment from 'moment/moment';

import { TBucketOption } from '../model';
import { RANGE_FILTER } from '../utils/get-time-range-with-format';
import { getNumberOfBucketsForCustomBucketOption } from './get-number-of-buckets-for-custom-bucket-option';

export const getNumberOfBuckets = ({
  minSelection,
  maxSelection,
  rangeFilter,
  shouldHaveSpecialCalculations,
  bucketOption,
}: {
  rangeFilter: RANGE_FILTER;
  shouldHaveSpecialCalculations: boolean;
  minSelection: number;
  maxSelection: number;
  bucketOption?: TBucketOption | '';
}) => {
  if (shouldHaveSpecialCalculations) {
    if (bucketOption) {
      return getNumberOfBucketsForCustomBucketOption(bucketOption, minSelection, maxSelection);
    }

    const calculateNumberOfBucketsForCustomPeriod = () => {
      const from = moment(minSelection);
      const to = moment(maxSelection);
      const diff = to.diff(from, 'days');

      if (!diff) {
        return 24;
      }

      if (diff <= 2) {
        return diff * 12;
      }

      if (diff <= 30) {
        return diff;
      }

      return 12;
    };

    switch (rangeFilter) {
      case RANGE_FILTER.HOURS_8:
        return 32;
      case RANGE_FILTER.HOURS_24:
        return 24;
      case RANGE_FILTER.DAYS_7:
        return 42;
      case RANGE_FILTER.DAYS_30:
        return 30;
      case RANGE_FILTER.MONTHS_12:
        return 48;
      case RANGE_FILTER.CUSTOM:
        return calculateNumberOfBucketsForCustomPeriod();
      default:
        return 12;
    }
  }

  const windowWidthWithMargins = window.innerWidth * 0.8;
  const optimizationDivider = 4; // TODO: this may be corrected in the future - we can lower this for more precise data or increase for better performance
  return Math.floor(windowWidthWithMargins / optimizationDivider);
};
