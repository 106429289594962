import { MarlinTheme } from '@marlin/shared/theme';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import { makeStyles } from 'tss-react/mui';

import { Avatar } from './avatar.component';
import { IUser, TChannelType } from './model';
import { ShowMore } from './show-more.component';

interface IRecipientTags {
  users: IUser[];
  numberOfUsersToDisplay?: number;
  channelType: TChannelType;
  automation?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
  },
}));

export const RecipientTags = ({
  users,
  channelType,
  numberOfUsersToDisplay = 3,
  automation = false,
}: IRecipientTags) => {
  const { classes } = useStyles();

  const usersToDisplay = numberOfUsersToDisplay < 0 ? 0 : numberOfUsersToDisplay;
  const hiddenUsers = users.slice(usersToDisplay);

  return (
    <div className={classes.wrapper} data-testid={createDataTestId('recipients-section-tags', channelType)}>
      {users.slice(0, usersToDisplay).map((user, index) => (
        <Avatar user={user} key={user.id} channelType={channelType} />
      ))}
      <ShowMore users={hiddenUsers} automation={automation} />
    </div>
  );
};
