import { THomeAlertSchema, THomeEquipmentSchema, THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Dot } from '@marlin/shared/ui-criticality';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import { CRITICALITY } from '@marlin/shared/utils/zod';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { Divider, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { content } from '../../content';
import { HomeCardDevice } from '../card-device';
import { useStyles } from './card-equipment.component.styles';

type THomeCardEquipmentProps = {
  equipment: THomeEquipmentSchema;
  homeLocation: THomeLocationSchema;
};

const HomeCardEquipment = ({ equipment, homeLocation }: THomeCardEquipmentProps) => {
  const { classes, cx } = useStyles();
  const devices = useMemo(() => equipment.devices, [equipment.devices]);
  const router = useRouter();
  const goToEquipment = useCallback(
    () => router.goTo(routes.equipments.details.url(equipment?.equipmentId as string)),
    [equipment?.equipmentId, router]
  );

  const equipmentAlerts: THomeAlertSchema[] = useMemo(
    () => equipment.devices?.flatMap((device) => device.alerts || []) || [],
    [equipment.devices]
  );
  const equipmentHasLowAlerts = useMemo(
    () => equipmentAlerts.find((alert: THomeAlertSchema) => alert.criticality === CRITICALITY.LOW),
    [equipmentAlerts]
  );
  const equipmentHasHighAlerts = useMemo(
    () => equipmentAlerts.find((alert: THomeAlertSchema) => alert.criticality === CRITICALITY.HIGH),
    [equipmentAlerts]
  );
  const alertClass = useMemo(() => {
    if (equipmentHasLowAlerts && equipmentHasHighAlerts) {
      return classes.errorRow;
    } else if (equipmentHasHighAlerts) {
      return classes.errorRow;
    } else if (equipmentHasLowAlerts) {
      return classes.warningRow;
    }
    return classes.errorRow;
  }, [classes.errorRow, classes.warningRow, equipmentHasHighAlerts, equipmentHasLowAlerts]);

  const alertCriticality = useMemo(() => {
    if (equipmentHasHighAlerts) {
      return CRITICALITY.HIGH;
    }
    return CRITICALITY.LOW;
  }, [equipmentHasHighAlerts]);

  return (
    <div className={classes.wrapper}>
      <div className={cx(classes.equipmentWrapper, { [`${alertClass}`]: !!equipmentAlerts.length })}>
        <div className={classes.equipmentRow}>
          <div className={classes.equipmentNameWrapper}>
            <DeviceHubRoundedIcon className={classes.equipmentIcon} />
            <Typography
              className={classes.equipmentName}
              variant="body1"
              onClick={goToEquipment}
              data-testid={createDataTestId('card-assets-equipment-name', equipment?.equipmentName)}
            >
              {equipment?.equipmentName}
            </Typography>
          </div>

          {equipmentAlerts.length ? (
            <Tooltip placement="top" text={content.EQUIPMENT_ALERTS}>
              <Dot variant={alertCriticality} labelClassName={classes.dotLabel}>
                <>{content.IMPACT_MOBILE(equipmentAlerts.length)}</>
              </Dot>
            </Tooltip>
          ) : null}
        </div>
      </div>
      {!!devices?.length && <Divider />}
      {devices?.map((device) => (
        <HomeCardDevice key={device.deviceId} device={device} />
      ))}
    </div>
  );
};

export { HomeCardEquipment };
