import { RANGE_FILTER, TBucketOption } from '@marlin/shared/utils-chart';

import { minutesIn24Hours, minutesIn365Days, minutesIn7Days, minutesIn8Hours, minutesIn90Days } from '../consts';

export function onZoomChange(
  bucketOption: TBucketOption,
  setBucketOption: (bucketOption: TBucketOption) => void,
  dateDifferenceInMinutes: number
) {
  const dateDifferenceInMinutesWithMargin = dateDifferenceInMinutes + 1;

  if (!isBucketOptionValid(bucketOption, RANGE_FILTER.CUSTOM, dateDifferenceInMinutesWithMargin, true)) {
    if (dateDifferenceInMinutesWithMargin <= minutesIn8Hours) {
      setBucketOption('minutes');
    } else if (dateDifferenceInMinutesWithMargin <= minutesIn7Days) {
      setBucketOption('hours');
    } else if (dateDifferenceInMinutesWithMargin <= minutesIn365Days) {
      setBucketOption('days');
    } else if (dateDifferenceInMinutesWithMargin <= minutesIn90Days) {
      setBucketOption('weeks');
    } else {
      setBucketOption('months');
    }
  }
}

export const onRangeFilterChange = (
  rangeFilter: RANGE_FILTER,
  bucketOption: TBucketOption | '',
  setBucketOption: (bucketOption: TBucketOption) => void
) => {
  if (bucketOption && isBucketOptionWithRangeFilterValid(bucketOption, rangeFilter)) return;

  switch (rangeFilter) {
    case RANGE_FILTER.HOURS_8:
      setBucketOption('minutes');
      break;
    case RANGE_FILTER.HOURS_24:
    case RANGE_FILTER.DAYS_7:
      setBucketOption('hours');
      break;
    case RANGE_FILTER.DAYS_30:
    case RANGE_FILTER.MONTHS_12:
      setBucketOption('days');
      break;
    default:
      setBucketOption('days');
      break;
  }
};

const isBucketOptionWithRangeFilterValid = (bucketOption: TBucketOption, rangeFilter: RANGE_FILTER) => {
  switch (bucketOption) {
    case 'minutes':
      return rangeFilter === RANGE_FILTER.HOURS_8;
    case 'hours':
      return [RANGE_FILTER.HOURS_8, RANGE_FILTER.HOURS_24, RANGE_FILTER.DAYS_7].includes(rangeFilter);
    case 'days':
      return [RANGE_FILTER.DAYS_7, RANGE_FILTER.DAYS_30, RANGE_FILTER.MONTHS_12].includes(rangeFilter);
    case 'weeks':
      return rangeFilter === RANGE_FILTER.MONTHS_12;
    case 'months':
      return rangeFilter === RANGE_FILTER.MONTHS_12;
    default:
      return false;
  }
};

export const isBucketOptionValid = (
  bucketOption: TBucketOption,
  rangeFilter: RANGE_FILTER,
  dateDifferenceInMinutes: number,
  isZoomed: boolean
) => {
  if (rangeFilter === RANGE_FILTER.CUSTOM || isZoomed) {
    switch (bucketOption) {
      case 'minutes':
        return dateDifferenceInMinutes <= minutesIn8Hours;
      case 'hours':
        return dateDifferenceInMinutes <= minutesIn7Days && dateDifferenceInMinutes >= minutesIn8Hours;
      case 'days':
        return dateDifferenceInMinutes >= minutesIn24Hours && dateDifferenceInMinutes <= minutesIn365Days;
      case 'weeks':
        return dateDifferenceInMinutes >= minutesIn90Days;
      case 'months':
        return dateDifferenceInMinutes >= minutesIn90Days;
      default:
        return false;
    }
  }

  return isBucketOptionWithRangeFilterValid(bucketOption, rangeFilter);
};
