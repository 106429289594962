import isNil from 'lodash/isNil';
import { useLocation, useNavigate } from 'react-router-dom';

interface IState {
  [key: string]: unknown;
}

interface IQueryParams {
  [key: string]: string | number | boolean | null | undefined;
}

interface IUseRouter<TState = IState> {
  getUrl: () => string;
  refresh: (reload?: boolean) => void;
  getState: () => TState;
  goTo: (url: string, state?: IState) => void;
  updateQueryParams: (queryParams: IQueryParams) => void;
  getParams: () => URLSearchParams;
}

export const useRouter = <TState = IState>(): IUseRouter<TState> => {
  const location = useLocation();
  const navigate = useNavigate();

  return {
    refresh: (reload = true): void => {
      if (reload) {
        navigate(location.pathname);
      } else {
        navigate(location.pathname, { replace: true });
      }
    },
    getState: (): TState => {
      return location.state as TState; // TODO: parametrize history
    },
    getParams: (): URLSearchParams => {
      return new URLSearchParams(location.search);
    },
    getUrl: (): string => {
      return location.pathname;
    },
    goTo: (url: string, state?: IState): void => {
      navigate(url, state);
    },
    updateQueryParams: (queryParams: IQueryParams) => {
      const urlSearchParams = new URLSearchParams();

      for (const property in queryParams) {
        const param = queryParams[property];

        if (!isNil(param)) {
          urlSearchParams.append(property, param.toString());
        }
      }

      urlSearchParams.sort();

      navigate(`${location.pathname}?${urlSearchParams.toString()}`);
    },
  };
};
