import { EQUIPMENT_ENUM, TEquipment } from '../../onboarding.schema';

export const checkIfDuplicatedNames = (equipments: TEquipment[]) => {
  const nameSet = new Set();

  for (const item of equipments) {
    if (nameSet.has(item.equipmentName)) {
      return true;
    }
    nameSet.add(item.equipmentName);
  }

  return false;
};

export const checkIfDuplicatedSerialNumbers = (equipments: TEquipment[]) => {
  const serialNumberSet = new Set();

  for (const item of equipments) {
    if (item.equipmentType === EQUIPMENT_ENUM.INTELLISTATION) {
      if (serialNumberSet.has(item.registrationCode)) {
        return true;
      }
      serialNumberSet.add(item.registrationCode);
    }
    if (
      item.equipmentType === EQUIPMENT_ENUM.AERCO_BENCHMARK ||
      item.equipmentType === EQUIPMENT_ENUM.AERCO_INNOVATION
    ) {
      if (serialNumberSet.has(item.serialNumber)) {
        return true;
      }
      serialNumberSet.add(item.serialNumber);
    }
  }

  return false;
};
