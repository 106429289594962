import { TAddressForm } from '../form/address-form-data-schema';

export function mapAddressToMapApi(address: TAddressForm): string[] {
  const addressLines: string[] = [];

  addressLines.push(address.address1);

  if (address.address2) {
    addressLines.push(address.address2);
  }

  addressLines.push(`${address.city}, ${address.state} ${address.postalCode}`);
  addressLines.push(address.country);

  return addressLines;
}
