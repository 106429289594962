import { TDeviceUserMeasuresPreferences, TUnitOfMeasure } from '../zod/uom.schema';
import { content } from './content';
import { getConvertedValue } from './convert-value';
import { TRoundMode, roundValue } from './number-utils';

type TUoMFunctionModed = (value: string | number, mode?: TRoundMode) => string;
type TUoMFunctionHours = (value: string | number, shortcut?: boolean) => string;
type TUoMFunction = TUoMFunctionModed | TUoMFunctionHours;

export const parseDisplayedValue = (
  value: string,
  unitOfMeasure: TUnitOfMeasure | null,
  userMeasuresPreferences?: TDeviceUserMeasuresPreferences,
  mode?: TRoundMode
) => {
  if (!value) {
    return content.EMPTY_DATAPOINT_VALUE;
  }

  const { value: convertedValue, uom } = getConvertedValue(value, unitOfMeasure, userMeasuresPreferences);
  const displayFunction: TUoMFunction | undefined = getUomDisplayFunction(uom);

  if (displayFunction) {
    if (mode && unitOfMeasure && isModedUomKey(unitOfMeasure)) {
      return (displayFunction as TUoMFunctionModed)(convertedValue, mode);
    }
    return displayFunction(convertedValue);
  }
  return convertedValue;
};

/**
 * @deprecated
 * Use parseDisplayedValue instead
 */
export const getFormattedValue = (value: string, unitOfMeasure?: TUnitOfMeasure | null, mode?: TRoundMode) => {
  const formatValue = getUomDisplayFunction(unitOfMeasure);

  if (!formatValue) return value;

  if (mode && unitOfMeasure && isModedUomKey(unitOfMeasure)) {
    return (formatValue as TUoMFunctionModed)(value, mode);
  }

  return formatValue(value);
};

export const getUomSymbol = (unitOfMeasure?: TUnitOfMeasure | null) => {
  if (!unitOfMeasure) {
    return undefined;
  }

  const uom = unitOfMeasure?.toLowerCase();

  if (!uom || !isUomSymbolKey(uom)) {
    return undefined;
  }

  return uomSymbols[uom];
};

const getUomDisplayFunction = (unitOfMeasure?: TUnitOfMeasure | null): TUoMFunction | undefined => {
  if (!unitOfMeasure) {
    return undefined;
  }

  const uom = unitOfMeasure?.toLowerCase();

  if (!uom || !isUomFunctionKey(uom)) {
    return undefined;
  }
  const displayFunction = uomFunctions[uom];

  if (displayFunction) {
    return displayFunction;
  }

  return undefined;
};

const uomFunctions = {
  f: (value: string | number, mode: TRoundMode = 'decimal') => content.FARENHEIT_VALUE(roundValue(value, mode)),
  c: (value: string | number, mode: TRoundMode = 'half') => content.CELSIUS_VALUE(roundValue(value, mode)),
  psi: (value: string | number, mode?: TRoundMode) => content.PSI_VALUE(roundValue(value, mode)),
  kpa: (value: string | number, mode?: TRoundMode) => content.KPA_VALUE(roundValue(value, mode)),
  gpm: (value: string | number, mode?: TRoundMode) => content.GPM_VALUE(roundValue(value, mode)),
  lpm: (value: string | number, mode?: TRoundMode) => content.LPM_VALUE(roundValue(value, mode)),
  mbtu: (value: string | number, mode?: TRoundMode) => content.MBTU_VALUE(roundValue(value, mode)),
  m3ph: (value: string | number, mode?: TRoundMode) => content.M3PH_VALUE(roundValue(value, mode)),
  kwh: (value: string | number, mode?: TRoundMode) => content.KWH_VALUE(roundValue(value, mode)),
  thm: (value: string | number, mode?: TRoundMode) => content.TH_VALUE(roundValue(value, mode)),
  gj: (value: string | number, mode?: TRoundMode) => content.GJ_VALUE(roundValue(value, mode)),
  '%': (value: string | number, mode: TRoundMode = 'decimal') => content.PERCENT_VALUE(roundValue(value, mode)),
  hr: (value: string | number, shortcut?: boolean) =>
    shortcut ? content.SHORT_HOURS_VALUE(value) : content.HOURS_VALUE(value),
  v: (value: string | number) => content.VOLTAGE_VALUE(value),
  gal: (value: string | number, mode: TRoundMode = 'detail') => content.GALLONS_VALUE(roundValue(value, mode)),
  l: (value: string | number, mode: TRoundMode = 'detail') => content.LITERS_VALUE(roundValue(value, mode)),
  m3: (value: string | number, mode: TRoundMode = 'detail') => content.METERS_VALUE(roundValue(value, mode)),
  waterdetect: (value: string | number) => content.LEAK_VALUE(value),
  valveposition: (value: string | number) => content.VALVE_POSITION_VALUE(value),
  ft3: (value: string | number, mode: TRoundMode = 'detail') => content.FT3_VALUE(roundValue(value, mode)),
  ft3pm: (value: string | number, mode: TRoundMode = 'detail') => content.FT3PM_VALUE(roundValue(value, mode)),
  μa: (value: string | number, mode: TRoundMode = 'detail') => content.MICRO_AMPERE_VALUE(roundValue(value, mode)),
};

const uomSymbols = {
  f: content.FARENHEIT_SYMBOL,
  c: content.CELSIUS_SYMBOL,
  psi: content.PSI_SYMBOL,
  kpa: content.KPA_SYMBOL,
  gpm: content.GPM_SYMBOL,
  lpm: content.LPM_SYMBOL,
  mbtu: content.BTU_SYMBOL,
  m3ph: content.M3PH_SYMBOL,
  kwh: content.KWH_SYMBOL,
  thm: content.TH_SYMBOL,
  gj: content.GJ_SYMBOL,
  '%': content.PERCENT_SYMBOL,
  hr: content.HOURS_SYMBOL,
  v: content.VOLTAGE_SYMBOL,
  gal: content.GALLONS_SYMBOL,
  l: content.LITERS_SYMBOL,
  m3: content.METERS_SYMBOL,
  ft3: content.FT3_SYMBOL,
  ft3pm: content.FT3PM_SYMBOL,
  μa: content.FT3PM_SYMBOL,
};

const isUomFunctionKey = (key: string): key is keyof typeof uomFunctions => {
  return key in uomFunctions;
};

const isUomSymbolKey = (key: string): key is keyof typeof uomSymbols => {
  return key in uomSymbols;
};

type TModedUnit = keyof Omit<typeof uomSymbols, 'v' | 'hr'>;

const isModedUomKey = (key: string): key is TModedUnit => {
  return key in uomSymbols && key !== 'v' && key !== 'hr';
};

export const symbolPerPulse = (uoM: TUnitOfMeasure) => {
  const symbol = isUomSymbolKey(uoM) ? uomSymbols[uoM] : uoM;

  return `${symbol}${content.PER_PULSE}`;
};
