import { TBucketOption } from '@marlin/shared/utils-chart';
import { useCallback, useMemo } from 'react';

import { useMultiChartsStore } from '../context/multi-charts.context';

export const useBucketOptions = (chartId: string) => {
  const [bucketOptions, setMultiChartsStore] = useMultiChartsStore((store) => store.bucketOptions);

  const bucketOption = useMemo(
    () => bucketOptions.find((option) => option.chartId === chartId)?.bucketOption,
    [bucketOptions, chartId]
  );

  const setBucketOption = useCallback(
    (bucketOption: TBucketOption) => {
      const newBucketOptions = [
        ...bucketOptions.filter((option) => option.chartId !== chartId),
        { chartId, bucketOption },
      ];
      setMultiChartsStore({ bucketOptions: newBucketOptions });
    },
    [bucketOptions, chartId, setMultiChartsStore]
  );

  return { bucketOption, setBucketOption };
};
