import { useDeleteRecipient } from '@marlin/account-data-access-recipient';
import { IListFilters } from '@marlin/account-data-access-recipient';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

import { content } from './content';

export interface IUseRemoveRecipient {
  showModal: boolean;
  openModal: (recipientId: string) => void;
  closeModal: () => void;
  removeRecipient: () => void;
}

export const useRemoveRecipient = (listFilterParams?: IListFilters): IUseRemoveRecipient => {
  const [recipientIdToRemove, setRecipientIdToRemove] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const deleteRecipientMutation = useDeleteRecipient(listFilterParams);
  const closeModal = useCallback(() => setRecipientIdToRemove(''), []);
  const openModal = useCallback((recipientId: string) => setRecipientIdToRemove(recipientId), []);

  const removeRecipient = useCallback(() => {
    closeModal();
    return deleteRecipientMutation
      .mutateAsync(recipientIdToRemove)
      .then(() => {
        enqueueSnackbar(content.DELETE_RECIPIENT_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
      })
      .catch(() => {
        enqueueSnackbar(content.DELETE_RECIPIENT_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      });
  }, [closeModal, deleteRecipientMutation, enqueueSnackbar, recipientIdToRemove]);

  const removeRecipientModal = useMemo(
    () => ({
      showModal: !!recipientIdToRemove,
      openModal,
      closeModal,
      removeRecipient,
    }),
    [closeModal, removeRecipient, openModal, recipientIdToRemove]
  );

  return removeRecipientModal;
};
