import { TChartDatapoint } from '@marlin/shared/utils-chart';
import * as z from 'zod';

export const latestAnalyticsJsonSchemaVersion = '1.2';
// On any change in this file, update the schema version

const typesValues = ['sensor', 'equipment'] as const;
export const TypesValues = z.enum(typesValues);
export type TTypesValues = z.infer<typeof TypesValues>;

const groupsValues = ['temperature', 'pressure', 'flow', 'pumpvalve', 'leak'] as const;
export const GroupNames = z.enum(groupsValues);
export type TGroupNames = z.infer<typeof GroupNames>;

const datapointTypeValues = ['temperature', 'pressure', 'onOff', 'flow', 'leak'] as const;
export const DatapointTypeValues = z.enum(datapointTypeValues);
export type TDatapointTypeValues = z.infer<typeof DatapointTypeValues>;

export const customGroupAvailableDatapointsTypes = [
  DatapointTypeValues.Enum.temperature,
  DatapointTypeValues.Enum.pressure,
  DatapointTypeValues.Enum.flow,
] as const;

const chartTypeValues = ['temperature', 'pressure', 'onOff', 'flow', 'leak', 'custom'] as const;
export const ChartType = z.enum(chartTypeValues);
export type TChartType = z.infer<typeof ChartType>;

const SelectedDatapoint = z.object({
  id: z.string(),
  equipmentName: z.string(),
  manufacturerId: z.string(),
  label: z.string(),
  name: z.string(),
  isDefault: z.boolean().optional(),
  color: z.string().optional(),
  locationId: z.string(),
  locationName: z.string(),
  isActive: z.boolean(),
  parentType: TypesValues,
  type: DatapointTypeValues.optional(),
});
export type TSelectedDatapoint = z.infer<typeof SelectedDatapoint>;

const SubGroupData = z.object({
  chartName: z.string(),
  chartDatapoints: SelectedDatapoint.array(),
  chartType: ChartType,
  chartDisplayType: z.string().optional(),
});
const ChartId = z.string();
export const SelectedGroupsWithDatapoints = z.record(ChartId, SubGroupData);
export type TSelectedGroupsWithDatapoints = z.infer<typeof SelectedGroupsWithDatapoints>;

export interface ILocationData {
  id: string;
  name: string;
  equipmentList: { type: string; name: string; manufacturerId: string; model: string }[];
  sensorsList: { type: string; name: string; manufacturerId: string; model: string }[];
}

export type TEquipmentList = Array<{
  name: string;
  id: string;
  sensorList: TSelectedDatapoint[];
}>;

export interface IFullScreen {
  chartId: string | null;
  chartType: TChartType | null;
  title: string;
}

export interface ISensorFiltering {
  selectedLocationId: string;
  selectedEquipmentId: string;
  selectedChartType: TChartType | '';
}

export type TSensorMetadata = Record<string, Array<TChartDatapoint & { group: TGroupNames }>>;

export interface IAddChartParams {
  chartType: TChartType | '';
  selectedDatapoints: TSelectedDatapoint[];
  chartName: string;
}
