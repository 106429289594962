import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  contextMenu: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.primary,
    position: 'absolute',
    borderRadius: theme.typography.pxToRem(4),
    boxShadow: theme.shadows['8'],
    zIndex: 10,
  },
  contextMenuItem: {
    width: '100%',
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(16)}`,
    border: 'none',
    outline: 'none',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.6,
    textAlign: 'left',
    background: 'none',
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
    '&:hover': {
      background: theme.palette.action.hover,
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));
