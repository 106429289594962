import { z } from 'zod';

const volumeSchema = z.object({
  averageVolume: z.number().nullish(),
  totalVolume: z.number().nullish(),
  trend: z.number().nullish(),
});

export const waterUsageSummarySchema = z
  .object({
    devices: z.array(
      z.object({
        deviceId: z.string(),
        deviceName: z.string(),
        locationId: z.string(),
        locationName: z.string(),
      })
    ),
    month: volumeSchema.nullish(),
    week: volumeSchema.nullish(),
    year: volumeSchema.nullish(),
  })
  .nullish();

export type TWaterUsageSummarySchema = z.infer<typeof waterUsageSummarySchema>;
export type TVolumeSchema = z.infer<typeof volumeSchema>;
