import {
  emailZodSchema,
  firstNameZodSchema,
  jobTitleZodSchema,
  lastNameZodSchema,
  phoneZodSchema,
  roleZodSchema,
} from '@marlin/shared/ui-form';
import * as z from 'zod';

import { content } from '../translation-keys';
const regexUniqueDigits = /^(?!(\d)\1{4})\d{5}$/;

export const UpsertProfileFormValidationSchema = z.object({
  firstName: firstNameZodSchema,
  lastName: lastNameZodSchema,
  phone: phoneZodSchema,
  email: emailZodSchema,
  title: jobTitleZodSchema.nullish(),
  role: roleZodSchema,
  deviceAccessCode: z
    .string()
    .min(5, content.MIN_MAX_DIGITS_ERROR)
    .max(5, content.MIN_MAX_DIGITS_ERROR)
    .regex(regexUniqueDigits, content.SAME_DIGITS_ERROR)
    .nullish()
    .or(z.literal('')),
});

export type TUpsertProfileFormValidationSchema = z.infer<typeof UpsertProfileFormValidationSchema>;
