import { ALERT_STATUS_FILTER, ICriticality } from '@marlin/alert/data-access/alert-action';
import { useMetadata } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { ISorting, SORT_DIRECTION } from '@marlin/shared/utils-sorting';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { EventLogList } from './desktop/event-log-list.component';
import { Filters } from './filters/filters.component';
import { MobileEventLogList } from './mobile/event-log-list.component';

export interface IFilterParams {
  search?: string;
  criticality: ICriticality;
  status: ALERT_STATUS_FILTER;
  sorting: ISorting;
}

export const defaultFilterParams = {
  criticality: {
    low: false,
    high: false,
  },
  status: ALERT_STATUS_FILTER.ALL,
  sorting: {
    direction: SORT_DIRECTION.Descending,
    sortBy: 'startTime',
  },
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: 0,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      border: 'none',
    },
  },
  filters: {
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

export const EventLog = () => {
  const [params, setParams] = useState<IFilterParams>(defaultFilterParams);
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const equipmentId = useIdFromPathname();
  const { data, isLoading } = useMetadata({ equipmentId });

  return (
    <Paper className={classes.container} data-testid="event-log-container">
      <div className={classes.filters} data-testid="event-log-filtering">
        <Filters onChangeParams={setParams} params={params} />
      </div>
      {isMobile ? (
        <MobileEventLogList filterParams={params} equipmentId={equipmentId} metadata={data} />
      ) : (
        <EventLogList
          metadata={data}
          isLoadingMetadata={isLoading}
          equipmentId={equipmentId}
          filterParams={params}
          setParams={setParams}
        />
      )}
    </Paper>
  );
};
