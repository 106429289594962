import { z } from 'zod';

export enum SETTINGS_GROUP {
  TEMPERATURE = 'Temp',
  SYSTEM = 'System',
  ALERT = 'Alert',
  SCHEDULE = 'Schedule',
  SETTINGS = 'Settings',
}

export const SettingsGroup = z.nativeEnum(SETTINGS_GROUP);
export type TSettingsGroup = z.infer<typeof SettingsGroup>;

const SettingPayload = z.object({
  name: z.string(),
  value: z.string(),
  prevValue: z.string().optional(),
});

export type TSettingPayload = z.infer<typeof SettingPayload>;

export const SettingsPayload = z.object({
  datapoints: SettingPayload.array(),
});
export const SettingsPayloadValidation = z.object({
  datapoints: SettingPayload.array(),
  settingGroupId: z.string(),
});
export type TSettingsPayload = z.infer<typeof SettingsPayload>;
