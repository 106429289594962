import { useExportTotalFlowTelemetry } from '@marlin/asset/data-access/device';
import { ContextMenu } from '@marlin/shared/ui-context-menu';
import { useModal } from '@marlin/shared/ui-modal';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Divider, IconButton, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { ListItemText, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MouseEvent, useState } from 'react';

import { content } from './content';
import { SelectFlowMeterModal } from './select-flow-meter';
import { TotalSection } from './total-section/total-section.component';
import { TotalizationButton } from './totalization-button/totalization-button.component';
import { useTotalizationCard } from './totalization-card.hooks';
import { useStyles } from './totalization-card.styles';
import { Trend } from './trend.component';
import { TrendMobile } from './trend.mobile.component';

export const TotalizationCard = () => {
  const theme = useTheme();
  const [expand, setExpand] = useState(false);
  const [selectFlowMeterModal, openSelectFlowMeterModal, closeSelectFlowMeterModal] = useModal();
  const { classes } = useStyles();
  const { totalization } = useTotalizationCard();
  const { mutateAsync: exportTotalData } = useExportTotalFlowTelemetry();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const expandCard = (event: MouseEvent): void => {
    event.stopPropagation();
    setExpand((expand) => !expand);
  };

  const handleExportErrors = (error: { errorCode?: number }) => {
    switch (error.errorCode) {
      case 30002: {
        enqueueSnackbar(content.ERROR_NO_DATA, {
          variant: 'error',
          preventDuplicate: true,
        });
        break;
      }
      default: {
        enqueueSnackbar(content.ERROR_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });
        break;
      }
    }
  };

  const exportTotalFlowData = () => {
    exportTotalData()
      .then(({ url }) => {
        window.open(url, '_blank');
        enqueueSnackbar(content.FILE_EXPORTED, {
          variant: 'success',
          preventDuplicate: true,
        });
      })
      .catch((error) => {
        handleExportErrors(error.response.data);
      });
  };

  const contextMenu = (
    <ContextMenu icon={<MoreHorizRoundedIcon />} data-testid="context-menu">
      <MenuItem data-testid="export-menu-item" onClick={exportTotalFlowData}>
        <ListItemText>{content.EXPORT}</ListItemText>
      </MenuItem>
      <MenuItem data-testid="select-flow-meter-item" onClick={openSelectFlowMeterModal}>
        <ListItemText>{content.SELECT_FLOW_METER}</ListItemText>
      </MenuItem>
    </ContextMenu>
  );

  if (!totalization) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <div className={classes.mobileContainer} data-testid="totalization-container">
          <div className={classes.waterUsage}>
            <div className={classes.contextMenuMobileWrapper}>
              <Typography className={classes.waterUsageText} data-testid="water-usage-text">
                {content.WATER_USAGE}
              </Typography>
              {contextMenu}
            </div>
            <TotalizationButton devices={totalization.devices} />
          </div>
          <Divider orientation="horizontal" flexItem />
          <TrendMobile variant="WEEK" volume={totalization.week} />
          <Divider orientation="horizontal" flexItem />
          <TrendMobile variant="MONTH" volume={totalization.month} />
          <Divider orientation="horizontal" flexItem />
          <TrendMobile variant="YEAR" volume={totalization.year} />
        </div>
        {selectFlowMeterModal && (
          <SelectFlowMeterModal open={selectFlowMeterModal} onClose={closeSelectFlowMeterModal} />
        )}
      </>
    );
  }

  return (
    <>
      <div className={classes.container} data-testid="totalization-container">
        <div className={classes.contentWrapper}>
          <div className={classes.waterUsage}>
            <div>
              <Typography className={classes.waterUsageText}>{content.WATER_USAGE}</Typography>
            </div>
            {contextMenu}
          </div>
          <div>
            {expand ? (
              <div className={classes.trends}>
                <TotalizationButton devices={totalization.devices} />
                <IconButton
                  onClick={expandCard}
                  className={classes.iconButton}
                  data-testid="waterusage-expand-trends-button"
                >
                  <KeyboardArrowUpRoundedIcon />
                </IconButton>
              </div>
            ) : (
              <div className={classes.trends}>
                <Trend variant="WEEK" volume={totalization.week} />
                <Divider orientation="vertical" flexItem className={classes.divider} />
                <Trend variant="MONTH" volume={totalization.month} />
                <Divider orientation="vertical" flexItem className={classes.divider} />
                <TotalizationButton devices={totalization.devices} />
                <IconButton
                  onClick={expandCard}
                  className={classes.iconButton}
                  data-testid="waterusage-expand-trends-button"
                >
                  <KeyboardArrowDownRoundedIcon />
                </IconButton>
              </div>
            )}
          </div>
        </div>
        {expand && (
          <div className={classes.expandedContent}>
            <TotalSection variant="WEEK" volume={totalization.week} />
            <TotalSection variant="MONTH" volume={totalization.month} />
            <TotalSection variant="YEAR" volume={totalization.year} />
          </div>
        )}
      </div>
      {selectFlowMeterModal && <SelectFlowMeterModal open={selectFlowMeterModal} onClose={closeSelectFlowMeterModal} />}
    </>
  );
};
