import { MarlinTheme } from '@marlin/shared/theme';
import { dateAdapter, formatDateByTimezone } from '@marlin/shared/utils-common-date';
import { DateTimeValidationError, DateValidationError } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { useCallback, useState } from 'react';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

type TPickerProps = DatePickerProps<Moment>['slotProps'] & DateTimePickerProps<Moment>['slotProps'];

export const getDateValidationErrorMessage = (error: DateValidationError | DateTimeValidationError | undefined) => {
  switch (error) {
    case 'disableFuture':
      return content.DATE_VALIDATION_FUTURE;
    case 'minDate':
      return content.DATE_VALIDATION_MIN;
    case 'invalidDate':
      return content.DATE_VALIDATION_INVALID;
    default:
      return '';
  }
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  input: {
    marginBottom: theme.typography.pxToRem(32),
    flexBasis: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(16),
    },
  },
  inputSmall: {
    '& .MuiOutlinedInput-root': {
      height: theme.typography.pxToRem(40),
    },
  },
  inputMedium: {
    '& .MuiOutlinedInput-root': {
      height: theme.typography.pxToRem(56),
    },
  },
}));

interface ISingleDatePickerProps extends Partial<ControllerRenderProps> {
  label: string;
  size?: 'small' | 'medium';
  error?: FieldError;
  variant?: 'date' | 'datetime';
  helperText?: string;
  timezone?: string;
}

export const SingleDatePicker = ({
  label,
  size,
  error,
  variant,
  helperText,
  timezone,
  ...field
}: ISingleDatePickerProps) => {
  const { classes, cx } = useStyles();

  const [dateValidationErrorMessage, setDateValidationErrorMessage] = useState<string | undefined>(undefined);

  const handleDateError = (error: DateValidationError | DateTimeValidationError | null) => {
    if (error) {
      setDateValidationErrorMessage(getDateValidationErrorMessage(error));
    } else {
      setDateValidationErrorMessage(undefined);
    }
  };

  const Picker = variant === 'datetime' ? DateTimePicker : DatePicker;

  const formatFieldValue = useCallback(() => {
    if (field.value) {
      return formatDateByTimezone(field.value, timezone ?? moment.tz.guess());
    }
    return null;
  }, [field.value, timezone]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <Picker<Moment>
        {...field}
        className={cx(classes.input, size === 'small' ? classes.inputSmall : classes.inputMedium)}
        label={label}
        value={formatFieldValue()}
        onChange={(date) => {
          field.onChange?.(dateAdapter.isValid(date) ? dateAdapter.date(date)?.toISOString() : undefined);
        }}
        onError={handleDateError}
        disableFuture
        timezone={timezone}
        slotProps={
          {
            textField: {
              'data-testid': `date-${field.name}-field`,
              helperText: dateValidationErrorMessage ?? helperText ?? '',
              error: !!dateValidationErrorMessage || !!error,
            },
            openPickerButton: {
              'data-testid': `date-${field.name}-picker-button`,
            },
          } as TPickerProps
        }
      />
    </LocalizationProvider>
  );
};
