import { useCurrentUser } from '@marlin/account-data-access-organization';
import { environment } from '@marlin/environment';
import { Loader } from '@marlin/shared/ui-loader';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface IGuardProps {
  redirectTo: string;
  children: JSX.Element;
}

export const UserAccountExistGuard = ({ children, redirectTo }: IGuardProps): JSX.Element => {
  const user = useCurrentUser();
  const location = useLocation();

  useEffect(() => {
    if (environment.module.automationHelper.extendedQaLogging) {
      // eslint-disable-next-line no-console
      console.log(
        `[Activity Log of Page ${location.pathname}]\nUserAccountExistGuard\n`,
        'queryParams',
        location.search,
        'redirectTo',
        redirectTo,
        'isFetched',
        user.isFetched,
        'isLoading',
        user.isLoading,
        'data',
        JSON.stringify(user.data)
      );
    }
  }, [location.pathname, location.search, redirectTo, user.data, user.isFetched, user.isLoading]);

  if (user.isLoading && !user.isFetched) {
    return <Loader />;
  }

  if (user.isFetched && !user.data?.organizations.length) {
    return <Navigate to={redirectTo} />;
  }

  return <>{children}</>;
};
