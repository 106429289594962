import { useBasicEquipmentStatus } from '../../../../hooks/use-equipment-status.hook';
import { EquipmentStatusBar } from './equipment-status-bar.component';

export const BasicEquipmentStatus = ({ fullWidth }: { fullWidth?: boolean }) => {
  const { statusLabel, isOperational, displayTime } = useBasicEquipmentStatus();

  return (
    <EquipmentStatusBar
      fullWidth={fullWidth}
      isEquipmentOperational={isOperational}
      statusLabel={statusLabel}
      displayTime={displayTime}
    />
  );
};
