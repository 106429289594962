import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer } from '@marlin/shared/ui-page';
import { ErrorPage } from '@marlin/shared/ui/chart';
import { CustomPeriodModalProvider } from '@marlin/shared/utils-chart';
import AddChartIcon from '@mui/icons-material/Addchart';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { AddChartModal } from './components/add-chart/add-chart-modal.component';
import { Chart } from './components/chart/chart.component';
import { Header } from './components/header';
import { NoCharts } from './components/no-charts.component';
import { NoSensorsConnected } from './components/no-sensors-connected.component';
import { SectionHeader } from './components/section-header.component';
import { content } from './content';
import { withDurationDetailsContext } from './context/duration-details.context';
import { useMultiChartsStore, withMultiChartsContext } from './context/multi-charts.context';
import { withSelectedSensorsContext } from './context/selected-sensors.context';
import { withSensorFilteringContext } from './context/sensor-filtering.context';
import { useFullScreenChart } from './hooks/use-full-screen-chart.hook';
import { useMultiChartMetadata } from './hooks/use-multi-chart-metadata.hook';
import { useMultiCharts } from './hooks/use-multi-charts.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    marginTop: theme.typography.pxToRem(16),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(24)}`,
  },
  newChartSectionWrapper: {
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(8),
  },
  newChartSection: {
    margin: `${theme.typography.pxToRem(8)} 0`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function MultiChartsInternal() {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const {
    allOptions,
    isLoading: areLocationsAndMetadataLoading,
    isError,
    hasNoData,
    refresh,
  } = useMultiChartMetadata();
  const { onResetView, isLoading } = useMultiCharts({
    areLocationsAndMetadataLoading,
    allOptions,
  });
  const { toggleFullScreen, fullScreenChart, paperHeight, pageHeaderRef } = useFullScreenChart();

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);
  const openAddChartModal = () => setIsOpen(true);

  const [chartIdList] = useMultiChartsStore((store) => store.chartIdList);

  if (isLoading) return <LoadingSpinner />;

  if (isError || isOffline) return <ErrorPage onRefresh={isOffline ? refreshPage : refresh} />;

  if (hasNoData) return <NoSensorsConnected />;

  const hasNoCharts = isEmpty(chartIdList);

  return (
    <PageContainer>
      <CustomPeriodModalProvider>
        <div ref={pageHeaderRef}>
          <Header
            chartType={fullScreenChart.chartType}
            onResetView={onResetView}
            title={fullScreenChart.title}
            hasNoCharts={hasNoCharts}
          />
        </div>
        {hasNoCharts ? (
          <NoCharts onAddChart={openAddChartModal} onRestoreDefault={onResetView} />
        ) : (
          <Paper className={classes.wrapper}>
            {chartIdList.map((chartId) => (
              <Chart
                key={chartId}
                chartId={chartId}
                fullScreenChartId={fullScreenChart?.chartId}
                toggleFullScreen={toggleFullScreen}
                paperHeight={paperHeight}
                setIsOffline={setIsOffline}
              />
            ))}
            {!fullScreenChart.chartId && (
              <div className={classes.newChartSectionWrapper}>
                <SectionHeader title={content.NEW_CHART} icon={<AddChartIcon />} />
                <div className={classes.newChartSection}>
                  <Typography variant="subtitle1">{content.NEW_CHART_SUBTITLE}</Typography>
                  <Button variant="contained" onClick={openAddChartModal} data-testid="add-new-chart-button">
                    {content.ADD_CHART.SUBMIT}
                  </Button>
                </div>
              </div>
            )}
          </Paper>
        )}
        {isOpen && <AddChartModal onClose={() => setIsOpen(false)} />}
      </CustomPeriodModalProvider>
    </PageContainer>
  );
}

export const MultiCharts = withDurationDetailsContext(
  withMultiChartsContext(withSelectedSensorsContext(withSensorFilteringContext(MultiChartsInternal)))
);
