import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { magnifyingGlass, zoomIn, zoomOut } from '@marlin/shared/ui/chart';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import { useMemo, useState } from 'react';

import { TChartAutomation } from '../../../../manage-automations/use-manage-automations.hook';
import { ICharTicks, IChartPoint, TAlertBuckets, TGroupedAlertAnnotation } from '../../../types';
import { EXTENDED_RANGE_FILTER, lineMarkerSize, scatterMarkerSize } from '../../collapsed-chart/chart-constants';
import { useChartOptions } from '../../collapsed-chart/use-chart-options.hook';
import { IAutomationOptions, IPointAnnotation } from '../automations/types';
import { mainChartId } from '../const';
import { useExtendedChartContext } from '../context/extended-chart.context';
import { IAnnotationForm } from '../extended-chart-form.component';
import { renderMainChartTooltip } from './chart-tooltip/render-tooltip';
import { CHART_SELECTION_TYPE } from './use-chart-selection.hook';
import { IRange } from './use-main-chart.hook';

interface IMainChartOptionsProps {
  automations: TChartAutomation[];
  automationsSettings: IAnnotationForm;
  selection: IRange | null;
  data: IChartPoint[];
  ticks: ICharTicks;
  timeZoneAbbr: string;
  deviceType?: TDeviceTypeSchema;
  deviceName?: string;
  automationRulesSeriesOptions: IAutomationOptions;
  automationRulesSeriesAnnotation: IPointAnnotation[];
  groupedAlertOptions: IAutomationOptions;
  groupedAlertsAnnotations: TGroupedAlertAnnotation[];
  groupedAlerts: TAlertBuckets;
  uoM?: TUnitOfMeasure | null;
}

interface IMainChartOptions {
  mainChartOptions: ApexOptions;
  chartType: 'line' | 'scatter' | 'rangeArea';
}

export const useMainChartOptions = ({
  data,
  ticks,
  deviceType,
  deviceName,
  timeZoneAbbr,
  automationRulesSeriesOptions,
  automationRulesSeriesAnnotation,
  groupedAlertOptions,
  groupedAlertsAnnotations,
  groupedAlerts,
  uoM,
}: IMainChartOptionsProps): IMainChartOptions => {
  const theme = useTheme<MarlinTheme>();
  const [blocked, setBlocked] = useState(false);

  const { changeChartSelection, selection, clearSelection } = useExtendedChartContext();

  const timeRange = useMemo(() => [selection?.from || 0, selection?.to || 0], [selection?.from, selection?.to]);
  const chartType = useMemo(() => (data.length === 1 ? 'scatter' : 'line'), [data.length]);
  const apexOptions = useChartOptions({
    deviceType,
    timeRange,
    ticks,
    rangeFilter: EXTENDED_RANGE_FILTER.CUSTOM,
    chartType,
    timeZoneAbbr,
    isExpanded: true,
    uoM,
  });

  const isDataEmpty: boolean = useMemo(() => !data.filter((point) => point.value !== null).length, [data]);

  const mainChartOptions = useMemo(
    () => ({
      ...apexOptions,
      grid: {
        padding: { left: 0, right: 0 },
      },
      chart: {
        ...apexOptions.chart,
        id: mainChartId,
        type: 'rangeArea' as const,
        toolbar: {
          show: true,
          offsetY: -28,
          tools: {
            download: false,
            selection: false,
            zoom: blocked ? `<img id="zoom" src=${magnifyingGlass} alt="zoom in" width="24" class="disabled" />` : true,
            zoomin: `<img id="zoom-in" src=${zoomIn} alt="zoom in" width="24" class="${blocked ? 'disabled' : ''}"/>`,
            zoomout: `<img id="zoom-out" src=${zoomOut} alt="zoom in" width="24"/>`,
            pan: true,
            reset: false,
          },
          autoSelected: 'selection' as const,
        },
        zoom: {
          type: 'x' as const,
          enabled: true,
        },
        events: {
          beforeZoom(_: unknown, options: { xaxis: { max: number; min: number } }) {
            const newestPeriodDiff = dateAdapter
              .date(options.xaxis.max)
              ?.diff(dateAdapter.date(options.xaxis.min), 'minutes');
            const currentDiff = dateAdapter.date(selection?.to)?.diff(dateAdapter.date(selection?.from), 'minutes');
            // block zoom in and magnifying glass if the chart is already zoomed too much
            if (currentDiff && newestPeriodDiff && currentDiff < 8 && currentDiff > newestPeriodDiff) {
              setBlocked(true);

              return { xaxis: { min: selection?.from, max: selection?.to } };
            }

            setBlocked(newestPeriodDiff ? newestPeriodDiff < 8 : false);
            const currentDate = dateAdapter.date()?.valueOf();

            changeChartSelection({
              selection: {
                from: options.xaxis.min,
                to: currentDate && options.xaxis.max > currentDate ? currentDate : options.xaxis.max,
              },
              type: CHART_SELECTION_TYPE.MAIN_CHART,
            });

            return { xaxis: options.xaxis };
          },
          beforeResetZoom() {
            clearSelection();
          },
          scrolled(_: unknown, options: { xaxis: { max: number; min: number } }) {
            const currentDate = dateAdapter.date()?.valueOf();

            if (currentDate && options.xaxis.max < currentDate) {
              changeChartSelection({
                selection: {
                  from: options.xaxis.min,
                  to: options.xaxis.max,
                },
                type: CHART_SELECTION_TYPE.MAIN_CHART,
              });
            }
          },
        },
      },
      legend: {
        show: false,
      },
      colors: [
        theme.palette.primary.dark,
        ...automationRulesSeriesOptions.fill.colors,
        ...groupedAlertOptions.fill.colors,
      ],
      fill: {
        colors: [
          theme.palette.primary.dark,
          ...automationRulesSeriesOptions.fill.colors,
          ...groupedAlertOptions.fill.colors,
        ],
        opacity: [1, ...automationRulesSeriesOptions.fill.opacity, ...groupedAlertOptions.fill.opacity],
      },
      stroke: {
        ...automationRulesSeriesOptions.stroke,
        colors: [
          theme.palette.primary.dark,
          ...automationRulesSeriesOptions.stroke.colors,
          ...groupedAlertOptions.stroke.colors,
        ],
        width: [2, ...automationRulesSeriesOptions.stroke.width, ...groupedAlertOptions.stroke.width],
        dashArray: [0, ...automationRulesSeriesOptions.stroke.dashArray, ...groupedAlertOptions.stroke.dashArray],
        curve: 'straight' as const,
      },
      yaxis: {
        ...apexOptions.yaxis,
        opposite: true,
        showForNullSeries: true,
        show: !isDataEmpty,
        labels: {
          formatter: (value: number) => {
            if (data.length === 0) {
              return '';
            }
            return parseDisplayedValue(
              value.toString(),
              deviceType === 'LEAK' ? 'WaterDetect' : uoM ?? '',
              undefined,
              'decimal'
            );
          },
        },
      },
      xaxis: {
        ...apexOptions.xaxis,
        labels: {
          format: undefined,
          datetimeUTC: false,
        },
      },
      markers: {
        size: [
          data.length === 1 ? scatterMarkerSize : lineMarkerSize,
          ...automationRulesSeriesOptions.markers.width,
          ...groupedAlertOptions.markers.width,
        ],
        colors: [
          theme.palette.primary.dark,
          ...automationRulesSeriesOptions.markers.colors,
          ...groupedAlertOptions.markers.colors,
        ],
        fillOpacity: [
          1,
          ...automationRulesSeriesOptions.markers.fillOpacity,
          ...groupedAlertOptions.markers.fillOpacity,
        ],
        strokeWidth: 0,
        strokeOpacity: 0,
        hover: {
          sizeOffset: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        points: automationRulesSeriesAnnotation,
        xaxis: groupedAlertsAnnotations.filter(
          (annotation) => annotation.x && annotation.x > timeRange[0] && annotation.x < timeRange[1]
        ),
      },
      tooltip: {
        custom: renderMainChartTooltip({
          deviceType,
          deviceName,
          theme,
          groupedAlerts,
          uoM,
        }),
        enabled: true,
        shared: true,
      },
    }),
    [
      apexOptions,
      automationRulesSeriesAnnotation,
      automationRulesSeriesOptions.fill.colors,
      automationRulesSeriesOptions.fill.opacity,
      automationRulesSeriesOptions.markers.colors,
      automationRulesSeriesOptions.markers.fillOpacity,
      automationRulesSeriesOptions.markers.width,
      automationRulesSeriesOptions.stroke,
      blocked,
      changeChartSelection,
      clearSelection,
      data.length,
      deviceName,
      deviceType,
      groupedAlertOptions.fill.colors,
      groupedAlertOptions.fill.opacity,
      groupedAlertOptions.markers.colors,
      groupedAlertOptions.markers.fillOpacity,
      groupedAlertOptions.markers.width,
      groupedAlertOptions.stroke.colors,
      groupedAlertOptions.stroke.dashArray,
      groupedAlertOptions.stroke.width,
      groupedAlerts,
      groupedAlertsAnnotations,
      selection?.from,
      selection?.to,
      theme,
      timeRange,
      uoM,
    ]
  );

  return useMemo(
    () => ({
      mainChartOptions,
      chartType,
    }),
    [chartType, mainChartOptions]
  );
};
