import { CreateAutomation } from '@marlin/alert/feature/automated-action-create';
import { AutomatedActionList, DeviceHealth, Information } from '@marlin/alert/feature/automated-action-list';
import { UpdateAutomation } from '@marlin/alert/feature/automated-action-update';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const AutomationRoutes = (): JSX.Element => {
  return (
    <Route path={routes.automations.root}>
      <Route path={routes.automations.list.filteredList.path} element={<AutomatedActionList />} />
      <Route
        path={routes.automations.create.withDevice.path}
        element={
          <PermissionGuard
            redirectTo={routes.automations.list.path}
            permissions={[PERMISSIONS.CREATE_NEW_AUTOMATION, PERMISSIONS.CUSTOM_AUTOMATIONS]}
          >
            <CreateAutomation />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.automations.create.path}
        element={
          <PermissionGuard
            redirectTo={routes.automations.list.path}
            permissions={[PERMISSIONS.CREATE_NEW_AUTOMATION, PERMISSIONS.CUSTOM_AUTOMATIONS]}
          >
            <CreateAutomation />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.automations.update.path}
        element={
          <PermissionGuard redirectTo={routes.automations.list.path} permissions={[PERMISSIONS.EDIT_AUTOMATION]}>
            <UpdateAutomation />
          </PermissionGuard>
        }
      />
      <Route path={routes.automations.health.root} element={<DeviceHealth />} />
      <Route path={routes.automations.information.root} element={<Information />} />
    </Route>
  );
};
