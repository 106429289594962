import { useObservableState } from 'observable-hooks';
import { useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

import {
  TEquipment,
  TInvitationFormValues,
  TRegisterEquipment,
  TSelectOrganization,
  TSetupOrganization,
} from './onboarding.schema';

type TOnboardingState = TSetupOrganization & TRegisterEquipment & TSelectOrganization;

export const defaultValues: TOnboardingState = {
  propertyName: '',
  organizationId: null,
  propertyAddress: {
    name: null,
    address: null,
  },
  equipment: [],
};

export const defaultInvitationValues: TInvitationFormValues = {
  users: [{ email: '', role: 'Viewer' }],
};

const onboardingState$ = new BehaviorSubject<TOnboardingState>(defaultValues);
const invitationState$ = new BehaviorSubject<TInvitationFormValues>(defaultInvitationValues);

export const useOnboardingState = () => {
  const [onboardingState] = useObservableState<TOnboardingState>(() => onboardingState$, onboardingState$.getValue());
  const [invitationState] = useObservableState<TInvitationFormValues>(
    () => invitationState$,
    invitationState$.getValue()
  );

  const addAddress = ({ data }: { data: Partial<TOnboardingState> }) => {
    onboardingState$.next({
      ...onboardingState$.value,
      propertyName: data.propertyName ?? onboardingState$.value.propertyName,
      propertyAddress: {
        address: data.propertyAddress?.address,
        name: data.propertyAddress?.name,
      },
    });
  };

  const addOrganization = ({ data, organizationName }: { data: TSelectOrganization; organizationName: string }) => {
    onboardingState$.next({
      ...onboardingState$.value,
      organizationId: data.organizationId,
      propertyName: organizationName,
      propertyAddress: {
        name: null,
        address: null,
      },
    });
  };

  const addEquipment = useCallback(({ data }: { data: TRegisterEquipment }) => {
    onboardingState$.next({
      ...onboardingState$.value,
      ...data,
      equipment: [...onboardingState$.value.equipment, ...data.equipment],
    });
  }, []);

  const updateEquipment = useCallback(({ data, index }: { data: TEquipment; index: number }) => {
    const equipment: TEquipment[] = [...onboardingState$.value.equipment];
    equipment.splice(index, 1, data);
    onboardingState$.next({
      ...onboardingState$.value,
      equipment,
    });
  }, []);

  const deleteEquipment = useCallback(({ index }: { index: number }) => {
    const equipment: TEquipment[] = [...onboardingState$.value.equipment];
    equipment.splice(index, 1);
    onboardingState$.next({
      ...onboardingState$.value,
      equipment,
    });
  }, []);

  const updateInvitationState = useCallback((data: TInvitationFormValues) => {
    invitationState$.next(data);
  }, []);

  const clearOnboardingState = useCallback(() => {
    onboardingState$.next(defaultValues);
    invitationState$.next(defaultInvitationValues);
  }, []);

  return {
    onboardingState,
    invitationState,
    addAddress,
    addEquipment,
    updateEquipment,
    deleteEquipment,
    addOrganization,
    updateInvitationState,
    clearOnboardingState,
  };
};
