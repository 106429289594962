import { CreateRecipient } from '@marlin/account/ui/recipient-create';
import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { AutomatedActionForm as UIAutomatedActionForm } from '@marlin/alert/ui/automated-action-create';
import { DEVICE_TYPE, IDevice } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageLoader } from '@marlin/shared/ui-loader';
import { useModal } from '@marlin/shared/ui-modal';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { uiContent } from './content';
import { useNavigation } from './use-navigation.hook';
import { useStore } from './use-store.hook';
import { useDeviceUrlQuery } from './use-url-query.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.static.defaultWidth, // TODO: (RWD) this should be automated, but side nav is shrinking without it!
  },
}));

export function CreateAutomation() {
  const { classes } = useStyles();
  const router = useRouter();
  const initialDeviceId = useDeviceUrlQuery();

  const { getBackLink } = useNavigation();

  const [recipientModal, openRecipientModal, closeRecipientModal] = useModal();

  const {
    locations,
    recipients,
    defaultValues,
    isSuccess,
    isLoading,
    save,
    saveRecipient,
    saveRecipientErrorCode,
    closeModal,
  } = useStore(closeRecipientModal);

  const onCancel = useCallback(() => {
    router.goTo(getBackLink());
  }, [getBackLink, router]);

  const initialDefaultValues: TCreateAutomation = useMemo(() => {
    if (!initialDeviceId) {
      return defaultValues;
    }

    const device = defaultValues.device as IDevice;
    return {
      ...defaultValues,
      device: { ...device, deviceType: device.deviceType === DEVICE_TYPE.UNKNOWN ? undefined : device.deviceType },
    };
  }, [defaultValues, initialDeviceId]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isSuccess) {
    return <Navigate to={getBackLink()} />;
  }

  return (
    <PageContainer data-testid={`automations-page`} className={classes.container}>
      <PageHeader
        icon={<MiscellaneousServicesRoundedIcon />}
        title={uiContent.TITLE}
        subtitle={uiContent.SUBTITLE}
        prefix="automations-header"
      />
      <UIAutomatedActionForm
        content={uiContent}
        locations={locations}
        recipients={recipients}
        onCancel={onCancel}
        onSubmit={save}
        onAddRecipientButtonClick={openRecipientModal}
        defaultValues={initialDefaultValues}
      />
      {recipientModal && (
        <CreateRecipient onSubmit={saveRecipient} onClose={closeModal} errorCode={saveRecipientErrorCode} />
      )}
    </PageContainer>
  );
}

export default CreateAutomation;
