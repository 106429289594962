import BatteryFullRoundedIcon from '@mui/icons-material/BatteryFullRounded';

import { content } from '../content';
import { useStyles } from './device-state.component.styles';

interface IReplaceBatteryProps {
  batteryLevel: number | null;
}

const ReplaceBattery = ({ batteryLevel }: IReplaceBatteryProps) => {
  const { classes } = useStyles();

  return (
    <>
      <BatteryFullRoundedIcon className={classes.redBatteryIcon} />
      <span className={classes.activeText} data-testid="status-icon-container-text">
        {content.REPLACE_BATTERY} ({batteryLevel}%)
      </span>
    </>
  );
};

export { ReplaceBattery };
