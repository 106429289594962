import { ModelEnum } from '@marlin/shared/utils/zod';

import { useEquipmentContext } from '../../../../context/equipment.context';
import { BasicEquipmentStatus } from './basic-equipment-status.component';
import { EquipmentStatusAerco } from './equipment-status-aerco.component';
import { EquipmentStatusIntellistation } from './equipment-status-intellistation.component';
import { EquipmentStatusSentinel } from './equipment-status-sentinel.component';

interface IEquipmentStatusProps {
  fullWidth?: boolean;
}

export const EquipmentStatus = ({ fullWidth }: IEquipmentStatusProps) => {
  const { equipment } = useEquipmentContext();

  switch (equipment?.model) {
    case ModelEnum.enum.AERCO_BOILER_LN:
    case ModelEnum.enum.AERCO_BOILER_DUAL:
    case ModelEnum.enum.AERCO_WATER_HEATER_N:
    case ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION:
      return <EquipmentStatusAerco fullWidth={fullWidth} />;
    case ModelEnum.enum.SENTINEL:
      return <EquipmentStatusSentinel fullWidth={fullWidth} />;
    case ModelEnum.enum.INTELLISTATION2:
    case ModelEnum.enum.INTELLISTATION:
      return <EquipmentStatusIntellistation fullWidth={fullWidth} />;
    default:
      return <BasicEquipmentStatus fullWidth={fullWidth} />;
  }
};
