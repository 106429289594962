import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { useMemo } from 'react';

import { content } from '../content';
import { EquipmentFormTypes } from './types';

export const useEquipmentType = () => {
  const { boiler, waterHeater, sentinel } = useFeatureFlagsContext();
  const equipmentTypes = useMemo(() => {
    const options = [
      { id: EquipmentFormTypes.enum.intellistation, name: content.INTELLISTATION_TYPE },
      { id: EquipmentFormTypes.enum.aercoBenchmark, name: content.AERCO_BENCHMARK_TYPE },
      { id: EquipmentFormTypes.enum.aercoInnovation, name: content.AERCO_INNOVATION_TYPE },
      { id: EquipmentFormTypes.enum.sentinel, name: content.SENTINEL_TYPE },
      { id: EquipmentFormTypes.enum.other, name: content.OTHER_TYPE, description: content.OTHER_DESCRIPTION },
    ];

    return options.filter(
      (option) =>
        (option.id !== EquipmentFormTypes.enum.aercoBenchmark &&
          option.id !== EquipmentFormTypes.enum.aercoInnovation &&
          option.id !== EquipmentFormTypes.enum.sentinel) ||
        (boiler && option.id === EquipmentFormTypes.enum.aercoBenchmark) ||
        (waterHeater && option.id === EquipmentFormTypes.enum.aercoInnovation) ||
        (sentinel && option.id === EquipmentFormTypes.enum.sentinel)
    );
  }, [sentinel, boiler, waterHeater]);

  return { equipmentTypes };
};
