import { IChartSeries, parseToChartData } from '@marlin/shared/utils-chart';
import { RANGE_FILTER, getSelectionTimeAxis } from '@marlin/shared/utils-chart';
import { TAnalyticsTelemetryResponse } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

interface ITranslation {
  name: string;
  label?: string;
}

interface IUseChartDataProps {
  datapointsGroupMetadata: ITranslation[] | undefined;
  rangeFilter: RANGE_FILTER;
  telemetryData: TAnalyticsTelemetryResponse | undefined;
  datapointNames: Array<string>;
}

export const useConverterChartData = ({
  datapointsGroupMetadata,
  rangeFilter,
  telemetryData,
  datapointNames,
}: IUseChartDataProps) => {
  const {
    timeRange: [minSelection, maxSelection],
  } = getSelectionTimeAxis({
    rangeFilter: rangeFilter as RANGE_FILTER,
  });

  const convertedDatapoints = useMemo(
    () => parseToChartData(telemetryData?.telemetryData),
    [telemetryData?.telemetryData]
  );

  const chartData = useMemo<Pick<IChartSeries, 'data' | 'uom' | 'name'>[]>(() => {
    return datapointNames.map((datapointName) => {
      const datapointMetadata = datapointsGroupMetadata?.find(
        ({ name }) => name.toLowerCase() === datapointName.toLowerCase()
      );
      const datapointItem = convertedDatapoints.find(
        ({ datapointName: convertedDatapointName }) =>
          convertedDatapointName.toLowerCase() === datapointName.toLowerCase()
      );

      return {
        name: datapointMetadata?.label ?? '',
        data: datapointItem?.values || [],
        uom: datapointItem?.unitOfMeasure || null,
      };
    });
  }, [convertedDatapoints, datapointNames, datapointsGroupMetadata]);

  return {
    chartData,
    to: maxSelection,
    from: minSelection,
  };
};
