import { TAnalyticsTelemetryResponse } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { IChartMetadata, IEquipmentChartThreshold } from '../types';

export const useMappedThresholdData = (
  thresholdsData: TAnalyticsTelemetryResponse['telemetryData'] | undefined,
  metadata: IChartMetadata['thresholdsMetadata']
): IEquipmentChartThreshold[] => {
  const flattenedValues = useMemo(() => {
    if (!thresholdsData) return [];

    return thresholdsData.reduce<TAnalyticsTelemetryResponse['telemetryData']>((acc, data) => {
      const flattenedData = flatThresholdData(data.values);

      return [
        ...acc,
        {
          values: flattenedData,
          datapointName: data.datapointName,
          unitOfMeasure: data.unitOfMeasure,
          manufacturerId: data.manufacturerId,
        },
      ];
    }, []);
  }, [thresholdsData]);

  const convertedThresholds = useMemo(() => {
    return flattenedValues.reduce<IEquipmentChartThreshold[]>(
      (acc, { datapointName, values, unitOfMeasure }, index) => {
        if (values.length < 2) return acc;

        const thresholds: IEquipmentChartThreshold[] = [];
        const thresholdMetadata = metadata.find((item) => item.name.toLowerCase() === datapointName.toLowerCase());

        values.forEach((value, index) => {
          if (index >= values.length - 1) return;

          thresholds.push({
            name: datapointName,
            thresholdMin: thresholdMetadata?.position === 'bottom' ? Number(value.y ?? 0) : null,
            thresholdMax: thresholdMetadata?.position === 'top' ? Number(value.y ?? 0) : null,
            from: value.x,
            to: values[index + 1].x,
            label: thresholdMetadata?.label ?? datapointName,
            uom: unitOfMeasure,
          });
        });

        return [...acc, ...thresholds];
      },
      []
    );
  }, [flattenedValues, metadata]);

  return useMemo(() => convertedThresholds, [convertedThresholds]);
};

const flatThresholdData = (values: { x: number; y: string | undefined }[]) => {
  const flatValues = [];
  let currentValue: string | undefined = undefined;

  for (let i = 0; i < values.length; i++) {
    const value = values[i];

    if (i === 0 || value.y !== currentValue) {
      flatValues.push({ x: value.x, y: value.y });
      currentValue = value.y;
    }
  }

  return flatValues;
};
