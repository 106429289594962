import { useCallback, useState } from 'react';

export const useSessionToken = () => {
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>();

  const createSessionToken = useCallback(() => {
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  }, []);

  const cancelSessionToken = useCallback(() => {
    setSessionToken(undefined);
  }, []);

  return { sessionToken, createSessionToken, cancelSessionToken };
};
