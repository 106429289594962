import moment from 'moment';

export const handleBeforeZoom = ({
  xaxis,
  from,
  to,
  setBlocked,
  handleZoomChange,
}: {
  xaxis: { min: number; max: number };
  from: number | undefined;
  to: number | undefined;
  setBlocked: (blocked: boolean) => void;
  handleZoomChange: (zoom?: { min: number; max: number }) => void;
}) => {
  const newestPeriodDiff = moment(xaxis.max).diff(moment(xaxis.min), 'minutes');
  const currentDiff = moment(to).diff(moment(from), 'minutes');
  // block zoom in and magnifying glass if the chart is already zoomed too much
  if (currentDiff < 8 && currentDiff > newestPeriodDiff) {
    setBlocked(true);

    return { xaxis: { min: from, max: to } };
  }

  setBlocked(newestPeriodDiff < 8);
  handleZoomChange(xaxis);

  return { xaxis };
};
