import { z } from 'zod';

export const flowMeterUpdateSchema = z.object({
  flowMeterIds: z.array(z.string()),
});

export const flowMeterUpdateRequestSchema = flowMeterUpdateSchema.transform((data) => ({
  deviceIds: data.flowMeterIds,
}));

export type TFlowMeterUpdateSchema = z.infer<typeof flowMeterUpdateSchema>;
export type TFlowMeterUpdateRequestSchema = z.infer<typeof flowMeterUpdateRequestSchema>;
