import { MarlinTheme } from '@marlin/shared/theme';
import { EmailControl, RoleControl } from '@marlin/shared/ui-form';
import { ArrayPath, Control } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { TInvitationFormValues } from '../../../onboarding.schema';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  input: {
    backgroundColor: theme.palette.background.primary,
    '&> .MuiFormHelperText-root': {
      paddingLeft: theme.typography.pxToRem(5),
      marginLeft: 0,
      marginRight: 0,
      backgroundColor: theme.palette.background.secondary,
    },
  },
}));

interface IInvitationControlField {
  fieldName: ArrayPath<TInvitationFormValues>;
  control: Control<TInvitationFormValues>;
  index: number;
  handleOnEmailBlur: () => void;
}

export const InvitationControlField = ({ control, index, fieldName, handleOnEmailBlur }: IInvitationControlField) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <EmailControl
        fieldName={`${fieldName}.${index}.email`}
        control={control}
        className={classes.input}
        handleOnBlur={() => {
          handleOnEmailBlur();
        }}
      />
      <RoleControl
        fieldName={`${fieldName}.${index}.role`}
        control={control}
        helperTextDisabled
        className={classes.input}
      />
    </div>
  );
};
