import { EdgeMarker } from 'reactflow';

type TSymbolProps = Omit<EdgeMarker, 'type'>;

export const ArrowClosedSymbol = ({ color = 'none', strokeWidth = 1 }: TSymbolProps) => {
  return (
    <polyline
      style={{
        stroke: color,
        fill: color,
        strokeWidth,
      }}
      strokeLinecap="round"
      strokeLinejoin="round"
      points="-5,-4 0,0 -5,4 -5,-4"
    />
  );
};

export const CircleSymbol = ({ color = 'none', strokeWidth = 1 }: TSymbolProps) => {
  return (
    <circle
      style={{
        stroke: color,
        fill: color,
        strokeWidth,
      }}
      cx="0"
      cy="0"
      r="6"
    />
  );
};
