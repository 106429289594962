import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteFlowLink } from '../infrastructure/delete-flow-link';
import { queryKey } from './query-key.enum';

interface IDeleteFlowLink {
  onSuccess: () => void;
  onError: () => void;
}

export const useDeleteFlowLink = ({ onSuccess, onError }: IDeleteFlowLink) => {
  return useMutation<unknown, unknown, { flowLinkId: string }>({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS({}) });
      queryClient.invalidateQueries({ queryKey: queryKey.GRAPH() });
      onSuccess();
    },
    onError,
    mutationFn: deleteFlowLink,
  });
};
