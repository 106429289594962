import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { z } from 'zod';

export const subtypeTypeSchema = z.union([z.literal('Water'), z.literal('Gas')]);

export const subtypesParamsSchema = z.object({
  meterType: subtypeTypeSchema,
  isCompound: z.boolean(),
});

export const subtypesRequestParamsSchema = z.object({
  meterType: subtypeTypeSchema,
  isCompound: z.boolean().transform((isCompound) => isCompound.toString()),
});

export const datapointSchema = z.object({
  isDefault: z.boolean(),
  isRate: z.boolean(),
  name: z.string(),
  systemBaseLineUnit: z.string(),
});

export const subtypeResponseSchema = z.object({
  datapoints: z.array(datapointSchema),
  id: z.string(),
  manufacturer: z.string(),
  model: z.string(),
  type: subtypeTypeSchema,
  unitsPerPulse: z.number(),
  uoM: UnitOfMeasureType,
  isCompound: z
    .boolean()
    .nullish()
    .transform((isCompound) => isCompound ?? false),
});

export const subtypeSchema = subtypeResponseSchema.transform((subtype) => ({
  ...subtype,
  name: subtype.model,
}));

export const subtypesResponseSchema = z.array(subtypeResponseSchema);

// sort by unitsPerPulse - descending
export const subtypesSchema = z
  .array(subtypeSchema)
  // note: change this sort, if order of multipliers needs to be different
  .transform((subtypes) => subtypes.sort((a, b) => b.unitsPerPulse - a.unitsPerPulse))
  .transform((subtypes) => ({
    subtypes,
    manufacturerList: uniq(subtypes.map(({ manufacturer }) => manufacturer)),
    modelsListByManufacturers: Object.entries(groupBy(subtypes, ({ manufacturer }) => manufacturer)).reduce(
      (acc, [key, value]) => {
        acc[key] = uniq(value.map(({ model }) => model));
        return acc;
      },
      {} as Record<string, string[]>
    ),
    modelsListByCompoundType: subtypes.reduce((acc, subtype) => {
      const { manufacturer, name } = subtype;
      if (!acc[manufacturer]) {
        acc[manufacturer] = {};
      }
      if (!acc[manufacturer][name]) {
        acc[manufacturer][name] = [];
      }
      acc[manufacturer][name].push(subtype);
      return acc;
    }, {} as Record<string, Record<string, TSubtype[]>>),
  }));

export type TSubtypeResponse = z.infer<typeof subtypeResponseSchema>;
export type TSubtypesResponse = z.infer<typeof subtypesResponseSchema>;
export type TSubtypesType = z.infer<typeof subtypeTypeSchema>;
export type TSubtypesParams = z.infer<typeof subtypesParamsSchema>;
export type TSubtypes = z.infer<typeof subtypesSchema>;
export type TSubtype = z.infer<typeof subtypeSchema>;
