import { IChartSeries as IChartSwitcherSeries } from '@marlin/shared/utils-chart';
import { ApexOptions } from 'apexcharts';

import { IChartSeries } from '../../hooks/charts/use-equipment-dashboard-chart-data.hook';

interface ICreateRangeBarConfig {
  min: number;
  max: number;
  id: string;
}

export const createRangeBarConfig = ({ min, max, id }: ICreateRangeBarConfig): ApexOptions => ({
  chart: {
    type: 'rangeBar' as ApexChart['type'],
    id: id,
    animations: {
      enabled: false,
    },
    toolbar: {
      show: false,
      offsetY: -20,
      offsetX: -20,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin:
          '<img id="zoom-in" src=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGnSURBVHgB3VWxSgNBEH2aFFrGzggWprCIoGAEi6SMhYWl/ongFwT8An/BVgvBa9NpmRSxSJHiBAVT2KSwcB474y2bvTs4sNAHjz1ub+fNvt2ZA/46Vkrm14Ud4Y6wKWzo+1Q4F46Fz1UF2sIL4RqKQaEkTyhP4EzY1ecpXKYjDUY0lX1ku0qUpQJ95UL4KByiGEzkBG6n/PbOn6wFH3c0ewa/0czLMBNOhAdwZ8XzebfJVSxnD808jQS7UoZIdQ1xDnc5lgSYPf2kz3m2NJB5HoJrphr8MCbQ1jFBdZilrZjAho4pqmOkY9Ne1L3JzYgA/Y5Zcu0909KB9wx/zSp+GfUgEztEy2QQfG+ZXyIOs+bVXvg7MGvaqA4T+IgJTHXcQ3VYHY1jAmxWrGBWYzcnwByZfSF6yOz9aXx+q/iCK/F94bbwRfgZBBkiXoRbcBXMM72HdxPDXvQGV4ktFaLoDMXoaXA2uyRMoBZZMNFxV8kWstB3tiNacSQ8FR5r5gz8EAYr+uEwsN/v80DxW+R03rJfpgnx6rKVWLXzIOkzb94Tsh3+Q3wDSpxTOipVbkAAAAAASUVORK5CYII= alt="zoom in" width="24" class=""/>',
        zoomout:
          '<img id="zoom-out" src=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGKSURBVHgB3VWhTgNBEH2UiiKL43AgECWBhJIgiiwCgYRv4Qv4BX4BJIaEs3VUtqLmBOJIIAGBoALBvOxsdrnu3V4vQcBLXuayezdvdnZmDvjrWInsrwn7wi1hIuzqei58F06F46YCPeGFsINqUCgtEyoTOBMO9DmDiXSizohEOYQ7VaqMCgyVc+GDcIRqMJATmJPy3Tt/c7Xwcl+jp/NrjTyGJ+FMuA9zV7yfV7vZwmL00Mhz1Eeu3xDnMMWxIMDomU/mOZaWEPhNps4PQgI9tSmaw6Z0OySwrnaZ1BQxUZvYhba3uREQuIQrwzIwpVfeM/xvWvhltAuRdOEuGl5kdWFT82wX/BPY1PTQHFbgLSSQqd1Fc9g+moYEOKzYwezGAZbHMVx6xyGBT+GtF0mC+tiEi/5HHxVn0QtMJ7JR9oRfMLMGkcg5HjrqfFQlQMzU7ig5Qua69qGWqTgUngqPYKqRju+Lzqp+OHTsz/syUPwGJZM39su0QixdjhLb7bxIljUr7xHuhP8Q37zLSkGfIcezAAAAAElFTkSuQmCC alt="zoom out" width="24"/>',
        pan: true,
        reset: false,
        customIcons: [],
      },
      autoSelected: 'zoom' as const,
    },
    events: {},
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '42%',
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    enabled: true,
    x: {
      show: false,
    },
  },
  xaxis: {
    title: {
      style: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#5D5D5E',
      },
      text: 'Timezone: CEST',
    },
    type: 'datetime',
    min,
    max,
    labels: {
      datetimeUTC: false,
    },
    tooltip: {
      enabled: false,
    },
  },
});

export interface IMapRangeDataPoints {
  data: IChartSeries[];
  invertBarDatapointNames?: string[];
  colors: string[];
  datapointLabels: Record<string, string>;
}

const mapData = (series: IChartSeries, invertBarDatapointNames?: string[]) => {
  if (Array.isArray(invertBarDatapointNames) && invertBarDatapointNames.includes(series.id)) {
    return series.data.map((point) => ({ ...point, y: !point.y ? 1 : 0 }));
  }

  return series.data;
};

export const mapDataPoints = ({
  data,
  invertBarDatapointNames,
  colors,
  datapointLabels,
}: IMapRangeDataPoints): IChartSwitcherSeries[] => {
  return data.map(
    (data, index): IChartSwitcherSeries => ({
      ...data,
      id: data.id,
      name: datapointLabels[data.id] ?? data.name,
      color: colors[index % colors.length],
      type: 'rangeBar',
      data: mapData(data, invertBarDatapointNames),
    })
  );
};
