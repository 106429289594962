export const content = {
  SENSOR_DETAILS: 'Sensor Details',
  SENSOR_NAME_LABEL: 'Sensor Name',
  MANUFACTURER_ID_LABEL: 'Manufacturer ID',
  SENSOR_TYPE_LABEL: 'Sensor Type',
  TEMPERATURE_LABEL: 'Temperature Sensor',
  PRESSURE_LABEL: 'Pressure Sensor',
  LEAK_LABEL: 'Leak Detector',
  LOCATION_LABEL: 'Location',
  EQUIPMENT_LABEL: 'Linked Equipment',
  PIN_SENSOR_LABEL: 'Pin Sensor to Home',
  PIN_SENSOR_HELPER: 'Use the pinning feature to pull a specific sensor to the top of the home screen.',
  ADDITIONAL_INFORMATION: 'Additional Information',
  MANUFACTURER_LABEL: 'Brand',
  MODEL_LABEL: 'Model',
  COMMISSION_DATE_LABEL: 'Commission Date and Time',
  ORGANIZATION_TIME_ZONE: (timezone?: string) => (timezone ? `Organization Time Zone: ${timezone}` : undefined),
  DESCRIPTION_LABEL: 'Description',
  DESCRIPTION_PLACEHOLDER: 'Add a short description about sensor',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_UPDATE: 'Update',
  EMPTY_LOCATION_HELPER_TEXT: 'Location is required for equipment selection.',
  EMPTY_EQUIPMENT: 'No results found.',
  CLEAR_EQUIPMENT: ' ',
  BUTTON_CREATE: 'Create',
  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  CONNECT_TO_SENSOR: 'Connect to your sensor to monitor and receive alerts.',
  REGISTER_CONNECT: 'Register & Connect',
  MANUFACTURER_ID_IS_NOT_RECOGNIZED: 'Manufacturer ID is not recognized',
  CONECTED_METER: 'Connected Meter',
  COMPOUND_PULSE_COUNTER: 'Compound Pulse Counter',
  SIGNAL_RECEIVER_TYPE: 'Signal Receiver Type',
  COMMISSION_DATE_MODAL: {
    TITLE: 'Change Commission Date?',
    BODY_1: 'Changing the model or type will change the ',
    BODY_2: 'commission date. ',
    BODY_3:
      'This will remove all previous alerts, existing automations, and hide previous data associated with the previous type. ',
    BODY_4: 'Continue?',
    FOOTER_CANCEL: 'Cancel',
    FOOTER_CONFIRM: 'Yes',
  },

  METER_MODEL_LABEL: 'Meter Model',
  METER_MANUFACTURER_LABEL: 'Meter Manufacturer',
  METER_MULTIPLIER_LABEL: 'Meter Multiplier',
  UNITS_OF_MEASUREMENT_LABEL: 'Unit of Measurement',
};
