import { useCancelSanitization } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    columnGap: theme.typography.pxToRem(8),
  },
  modalBody: {
    display: 'block',
  },
}));

export const useStopSanitization = ({ isSanitization }: { isSanitization: boolean }) => {
  const [isStoppingSanitization, setIsStoppingSanitization] = useState(false);
  const { modalDispatch } = useContext(ModalContext);
  const equipmentId = useIdFromPathname();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const cancelSanitizationMutation = useCancelSanitization({
    onError: () => {
      setIsStoppingSanitization(false);
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    },
  });

  useMemo(() => {
    if (isStoppingSanitization && !isSanitization) {
      setIsStoppingSanitization(false);
      enqueueSnackbar(content.SANITIZATION_STOPPED, {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, isSanitization, isStoppingSanitization]);

  const handleStopSanitization = () => {
    const closeModal = () => modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
    const submitStopSanitization = () => {
      setIsStoppingSanitization(true);
      cancelSanitizationMutation.mutate({ equipmentId });
      closeModal();
    };

    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <>{content.STOP_SANITIZATION}</>,
        body: (
          <>
            <div className={classes.modalBody}>{content.STOP_SANITIZATION_MODAL_BODY_FIRST_LINE}</div>
            <div className={classes.modalBody}>{content.STOP_SANITIZATION_MODAL_BODY_SECOND_LINE}</div>
          </>
        ),
        footer: (
          <div className={classes.modalFooter}>
            <Button variant="text" data-testid="sanitization-modal-cancel-button" onClick={closeModal}>
              {content.CANCEL}
            </Button>
            <Button
              variant="contained"
              color="error"
              data-testid="sanitization-modal-submit-button"
              onClick={submitStopSanitization}
            >
              {content.STOP}
            </Button>
          </div>
        ),
      },
    });
  };

  return { isStoppingSanitization, handleStopSanitization };
};
