import { TCreateSystemMapNodeParams } from '@marlin/system-map/data-access/system-map';
import { type MouseEvent as ReactMouseEvent, RefObject, useCallback, useMemo, useState } from 'react';
import { Edge, Node } from 'reactflow';

import { TTeeGraphAsset } from '../types';
import { content } from './content';
import { IOption } from './context-menu/context-menu.component';
import { useAddNodeToTeeContextMenu } from './context-menu/hooks/use-add-node-to-tee-context-menu.hook';
import { useAddTeeContextMenu } from './context-menu/hooks/use-add-tee-context-menu.hook';
import { useDeleteLinkContextMenu } from './context-menu/hooks/use-delete-link-context-menu.hook';
import { useDeleteTeeContextMenu } from './context-menu/hooks/use-delete-tee-context-menu.hook';
import { useRenameTeeContextMenu } from './context-menu/hooks/use-rename-tee-context-menu.hook';

interface IContextMenuProps {
  ref: RefObject<HTMLDivElement> | undefined;
  onAddNodeToTee: (tee: TCreateSystemMapNodeParams) => void;
  openRenameTeeModal: (teeToRename: TTeeGraphAsset) => void;
}

interface IMenuProps {
  id: string;
  top: number;
  left: number;
  options: IOption[];
}

interface IMenuCoords {
  x: number;
  y: number;
}

export const useContextMenu = ({ ref, onAddNodeToTee, openRenameTeeModal }: IContextMenuProps) => {
  const [menu, setMenu] = useState<IMenuProps | null>(null);
  const { deleteTee } = useDeleteTeeContextMenu();
  const { deleteLink } = useDeleteLinkContextMenu();
  const { createTee } = useAddTeeContextMenu();
  const { addNodeToTee } = useAddNodeToTeeContextMenu({ onAddNodeToTee });
  const { renameTee } = useRenameTeeContextMenu({
    openRenameTeeModal,
  });

  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const handleAddFlowLink = useCallback(
    (id: string) => {
      addNodeToTee(id);
      onPaneClick();
    },
    [addNodeToTee, onPaneClick]
  );

  const handleRenameTee = useCallback(
    (teeId: string) => {
      renameTee(teeId);
      onPaneClick();
    },
    [onPaneClick, renameTee]
  );

  const handleDeleteTee = useCallback(
    (id: string) => {
      deleteTee(id);
      onPaneClick();
    },
    [deleteTee, onPaneClick]
  );

  const handleAddTee = useCallback(
    (id: string, coords: IMenuCoords) => {
      createTee(id, coords.x, coords.y);
      onPaneClick();
    },
    [createTee, onPaneClick]
  );

  const handleDeleteFlowLink = useCallback(
    (id: string) => {
      deleteLink(id);
      onPaneClick();
    },
    [deleteLink, onPaneClick]
  );

  const nodeMockedContextMenuOptions: IOption[] = useMemo(
    () => [
      {
        label: content.ADD_FLOW_LINK,
        onClick: (id: string) => handleAddFlowLink(id),
      },
      {
        label: content.RENAME_TEE,
        onClick: (id: string) => handleRenameTee(id),
      },
      {
        label: content.DELETE_TEE,
        onClick: (id: string) => handleDeleteTee(id),
      },
    ],
    [handleAddFlowLink, handleDeleteTee, handleRenameTee]
  );

  const edgeMockedContextMenuOptions: (coords: IMenuCoords) => IOption[] = useCallback(
    ({ x, y }) => [
      {
        label: content.ADD_TEE,
        onClick: (id: string) => handleAddTee(id, { x, y }),
      },
      {
        label: content.DELETE_FLOW_LINK,
        onClick: (id: string) => handleDeleteFlowLink(id),
      },
    ],
    [handleAddTee, handleDeleteFlowLink]
  );

  const onNodeContextMenu = useCallback(
    (event: ReactMouseEvent, node: Node) => {
      event.preventDefault();

      const pane = ref?.current?.getBoundingClientRect();

      const calculateX = () => {
        if (!pane) return event.clientX;
        if (event.clientX + 100 > pane.right) {
          return event.clientX - pane.x - 100;
        }
        return event.clientX - pane.x;
      };

      const calculateY = () => {
        if (!pane) return event.clientY;
        if (event.clientY + 100 > pane.bottom) {
          return event.clientY - pane.y - 100;
        }
        return event.clientY - pane.y;
      };

      if (pane && node.type === 'TEE') {
        setMenu({
          id: node.id,
          top: calculateY(),
          left: calculateX(),
          options: nodeMockedContextMenuOptions,
        });
      }
    },
    [ref, nodeMockedContextMenuOptions]
  );

  const onEdgeContextMenu = useCallback(
    (event: ReactMouseEvent<Element, MouseEvent>, edge: Edge) => {
      event.preventDefault();

      const pane = ref?.current?.getBoundingClientRect();

      const calculateX = () => {
        if (!pane) return event.clientX;
        if (event.clientX + 100 > pane.right) {
          return event.clientX - pane.x - 100;
        }
        return event.clientX - pane.x;
      };

      const calculateY = () => {
        if (!pane) return event.clientY;
        if (event.clientY + 100 > pane.bottom) {
          return event.clientY - pane.y - 100;
        }
        return event.clientY - pane.y;
      };

      if (pane) {
        setMenu({
          id: edge.id,
          top: calculateY(),
          left: calculateX(),
          options: edgeMockedContextMenuOptions({ x: event.clientX, y: event.clientY }),
        });
      }
    },
    [ref, edgeMockedContextMenuOptions]
  );

  return {
    onEdgeContextMenu,
    onNodeContextMenu,
    onPaneClick,
    menu,
  };
};
