import { IApexAxisChartThresholdSeries } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

interface IThresholdRangeParams {
  name: string;
  data: { x: number; y: [number, number] }[];
  color: string;
  alias: string;
  uom: TUnitOfMeasure | null;
  isMin: boolean;
}

export function createRange({
  name,
  data,
  color,
  alias,
  uom,
  isMin,
}: IThresholdRangeParams): IApexAxisChartThresholdSeries[] {
  const lineData = data.map((dataItem) => {
    return {
      x: dataItem.x,
      y: isMin ? dataItem.y[1] : dataItem.y[0],
    };
  });

  return [
    {
      type: 'rangeArea',
      name,
      alias,
      color,
      uom,
      data,
    },
    {
      type: 'line',
      name,
      alias,
      color,
      uom,
      data: lineData,
    },
  ];
}
