import { useCreateDevice, useDeletePinDevice } from '@marlin/asset/data-access/pin-device';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { content } from '../content';
import { useDeviceDetailsContext } from '../context/device-details.context';

export interface IUseDrawerPinDeviceProps {
  deviceId: string;
}

export interface IUseDrawerPinDevice {
  pinned: boolean;
  showModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  handlePinDevice: () => void;
  handleUnpinDevice: () => void;
}

export const useDrawerPinDevice = ({ deviceId }: IUseDrawerPinDeviceProps): IUseDrawerPinDevice => {
  const [showModal, setShowModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const deviceDetails = useDeviceDetailsContext();
  const pinDeviceMutation = useCreateDevice();
  const unpinDeviceMutation = useDeletePinDevice();
  const [pinned, setPinned] = useState(!!deviceDetails?.pinned);

  const showSnackbar = useCallback(
    (message: string, type: 'success' | 'error') => {
      enqueueSnackbar(message, {
        variant: type,
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar]
  );

  const closeModal = useCallback(() => setShowModal(false), []);
  const openModal = useCallback(() => setShowModal(true), []);
  const handlePinDevice = useCallback(() => {
    closeModal();
    return pinDeviceMutation
      .mutateAsync({ data: { deviceId } })
      .then(() => {
        setPinned(true);
        showSnackbar(content.CONFIRMATION_MESSAGE_PIN_SENSOR, 'success');
      })
      .catch(() => {
        showSnackbar(content.PIN_SENSOR_ERROR, 'error');
      });
  }, [closeModal, deviceId, pinDeviceMutation, showSnackbar]);

  const handleUnpinDevice = useCallback(() => {
    closeModal();
    return unpinDeviceMutation
      .mutateAsync(deviceId)
      .then(() => {
        setPinned(false);
        showSnackbar(content.CONFIRMATION_MESSAGE_UNPIN_SENSOR, 'success');
      })
      .catch(() => {
        showSnackbar(content.UNPIN_SENSOR_ERROR, 'error');
      });
  }, [closeModal, deviceId, showSnackbar, unpinDeviceMutation]);

  return {
    pinned,
    showModal,
    openModal,
    closeModal,
    handlePinDevice,
    handleUnpinDevice,
  };
};
