import { MarlinTheme } from '@marlin/shared/theme';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    width: theme.typography.pxToRem(230),
  },
}));

interface IAutomationHeaderActionsProps {
  onHeaderButtonClick: () => void;
}

export function AutomationHeaderActions({ onHeaderButtonClick }: IAutomationHeaderActionsProps): JSX.Element {
  const { classes } = useStyles();
  return (
    <Restricted to={[PERMISSIONS.CREATE_NEW_AUTOMATION, PERMISSIONS.CUSTOM_AUTOMATIONS]}>
      <Button
        onClick={onHeaderButtonClick}
        data-testid="automations-header-button"
        className={classes.button}
        variant="contained"
      >
        {content.HEADER_BUTTON_TITLE}
      </Button>
    </Restricted>
  );
}
