import { ToggleModalBody, ToggleModalFooter } from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useSearchParams } from '@marlin/shared/utils-router';
import { Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { useDatapointsContext } from '../../../context/datapoints.context';
import { useEditSettingContext } from '../../../context/edit-setting.context';

interface IScheduleHeader {
  onSubmitToggleSchedule: (checked: boolean) => Promise<void>;
  scheduleEnabled: boolean;
  isSanitize?: boolean;
  isEditable: boolean;
}

interface IStyles {
  scheduleEnabled: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { scheduleEnabled }) => ({
  headerContainer: {
    width: '100%',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(
      scheduleEnabled ? 8 : 24
    )}`,
    marginBottom: scheduleEnabled ? 0 : theme.typography.pxToRem(8),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginRight: theme.typography.pxToRem(8),
  },
  timezone: { color: theme.palette.text.secondary },
  scheduleTooltipList: {
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(16)}`,
    margin: 0,
  },
  tooltipRow: {
    listStyle: 'disc',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleScheduleLabel: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.17),
    textTransform: 'capitalize',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(22),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const ScheduleHeader = ({
  isSanitize,
  isEditable,
  scheduleEnabled,
  onSubmitToggleSchedule,
}: IScheduleHeader) => {
  const { classes } = useStyles({ scheduleEnabled });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <div className={classes.headerContainer}>
      <Typography className={classes.sectionTitle}>{content.SCHEDULE}</Typography>
      {isSanitize && isEditable && (
        <Tooltip text={content.SETTINGS_DISABLED_TOOLTIP} placement={isMobile ? 'top' : 'right'}>
          <div>
            <ScheduleHeaderSection
              scheduleEnabled={scheduleEnabled}
              isEditable={false}
              onSubmitToggleSchedule={onSubmitToggleSchedule}
            />
          </div>
        </Tooltip>
      )}

      {!isSanitize && (
        <ScheduleHeaderSection
          scheduleEnabled={scheduleEnabled}
          isEditable={isEditable}
          onSubmitToggleSchedule={onSubmitToggleSchedule}
        />
      )}
    </div>
  );
};

const ScheduleHeaderSection = ({ isEditable, scheduleEnabled, onSubmitToggleSchedule }: IScheduleHeader) => {
  const { classes } = useStyles({ scheduleEnabled });
  const { getDatapoint } = useDatapointsContext();

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.titleContainer}>
          <Typography variant="body1" className={classes.title}>
            {content.SCHEDULE}
          </Typography>
          <Tooltip
            text={
              <>
                <span>{content.SCHEDULE_TOOLTIP_TITLE}</span>
                <ul className={classes.scheduleTooltipList}>
                  <li className={classes.tooltipRow}>{content.SCHEDULE_TOOLTIP_ROW_1}</li>
                  <li className={classes.tooltipRow}>{content.SCHEDULE_TOOLTIP_ROW_2}</li>
                </ul>
              </>
            }
          />
        </div>
        <Typography variant="body2" className={classes.timezone}>
          {content.TIMEZONE} {getDatapoint('timezone')?.value ?? ''}
        </Typography>
      </div>
      <SwitchControl
        scheduleEnabled={scheduleEnabled}
        isEditable={isEditable}
        onSubmitToggleSchedule={onSubmitToggleSchedule}
      />
    </div>
  );
};

const SwitchControl = ({ isEditable, scheduleEnabled, onSubmitToggleSchedule }: IScheduleHeader) => {
  const { modalDispatch } = useContext(ModalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isDirty } = useEditSettingContext();
  const { classes } = useStyles({ scheduleEnabled });
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditing = searchParams.has('settingId');

  const closeModal = () => modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });

  const submitChange = async (checked: boolean) => {
    try {
      await onSubmitToggleSchedule(checked);
    } catch {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    } finally {
      closeModal();
    }
  };

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isEditing) {
      setSearchParams((currentParams) => {
        currentParams.delete('settingId');

        return currentParams;
      });
    }

    if (!isDirty) {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <>{content.SCHEDULE}</>,
          body: <ToggleModalBody value={scheduleEnabled} />,
          footer: (
            <ToggleModalFooter
              value={scheduleEnabled}
              checked={checked}
              closeModal={closeModal}
              submitChange={submitChange}
            />
          ),
        },
      });
    }
  };

  return (
    <div>
      <Typography variant="body2" className={classes.toggleScheduleLabel}>
        {scheduleEnabled ? content.ON : content.OFF}
      </Typography>
      <Switch
        size="small"
        disabled={!isEditable}
        checked={scheduleEnabled}
        onChange={handleChange}
        inputProps={{ 'data-testid': `toggle-schedule` } as React.InputHTMLAttributes<HTMLInputElement>}
      />
    </div>
  );
};
