import { useRecipients } from '@marlin/account-data-access-recipient';
import {
  TAutomation,
  TUpdateAutomation,
  useAutomations,
  useDeleteAutomation,
  useUpdateAutomation,
} from '@marlin/alert/data-access/automated-action';
import { IExtendedAutomation, IFilterParams } from '@marlin/alert/ui/automated-action-list';
import { IPagination, IRequestPagination } from '@marlin/shared/ui-page';
import { isDataLoading } from '@marlin/shared/utils/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';

import { content } from './content';
import { mapExtendedAutomation } from './map-extended-automation';
import { useSensorUrlQuery } from './use-url-query.hook';

interface IDeleteAutomationStore {
  deleteAutomation: (automation: IExtendedAutomation) => void;
}

export const useDeleteAutomationStore = (): IDeleteAutomationStore => {
  const { enqueueSnackbar } = useSnackbar();
  const deleteLocationMutation = useDeleteAutomation();

  const deleteAutomation = useCallback(
    async (automation: IExtendedAutomation) => {
      await deleteLocationMutation.mutateAsync(automation.id);
    },
    [deleteLocationMutation]
  );

  useEffect(() => {
    if (deleteLocationMutation.isError) {
      enqueueSnackbar(content.CONFIRMATION_MESSAGE_DELETE_SENSOR_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }

    if (deleteLocationMutation.isSuccess) {
      enqueueSnackbar(content.CONFIRMATION_MESSAGE_DELETE_SENSOR_SUCCESS, {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  }, [deleteLocationMutation.isError, deleteLocationMutation.isSuccess, enqueueSnackbar]);

  return {
    deleteAutomation,
  };
};

interface IUpdateAutomationStore {
  updateAutomation: (automation: TUpdateAutomation) => void;
}

export const useUpdateAutomationStore = (): IUpdateAutomationStore => {
  const updateAutomationMutation = useUpdateAutomation();
  const { enqueueSnackbar } = useSnackbar();

  const updateAutomation = useCallback(
    (automation: TUpdateAutomation) => {
      return updateAutomationMutation
        .mutateAsync(automation)
        .then(() => {
          enqueueSnackbar(
            automation.isEnabled
              ? content.CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_ON_SUCCESS
              : content.CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_OFF_SUCCESS,
            {
              variant: 'success',
              preventDuplicate: true,
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [updateAutomationMutation, enqueueSnackbar]
  );

  return {
    updateAutomation,
  };
};

export type TRequestParams = IFilterParams & IRequestPagination;

interface IStore {
  automations: IExtendedAutomation[];
  pagination: IPagination | undefined;
  isLoading: boolean;
  isListLoading: boolean;
  isError: boolean;
  updateAutomation: (automation: TUpdateAutomation) => void;
}

export const useStore = (params?: TRequestParams): IStore => {
  const automationsQuery = useAutomations({
    params,
  });
  const deviceId = useSensorUrlQuery();
  const recipientsQuery = useRecipients({ filters: { deviceId } });
  const { updateAutomation } = useUpdateAutomationStore();

  const isLoading = useMemo(() => isDataLoading([recipientsQuery]), [recipientsQuery]);

  const isListLoading = useMemo(
    () => isDataLoading([automationsQuery, recipientsQuery]),
    [automationsQuery, recipientsQuery]
  );

  const isError = useMemo(
    () => automationsQuery.isError || recipientsQuery.isError,

    [automationsQuery.isError, recipientsQuery.isError]
  );

  const pagination = useMemo(() => {
    if (!automationsQuery?.data) {
      return undefined;
    }

    return automationsQuery.data.pagination;
  }, [automationsQuery]);

  const automations: IExtendedAutomation[] = useMemo(() => {
    if (!automationsQuery?.data) {
      return [];
    }
    return automationsQuery?.data.data.map((automation: TAutomation) =>
      mapExtendedAutomation(automation, recipientsQuery.data)
    );
  }, [automationsQuery?.data, recipientsQuery.data]);

  return {
    automations,
    pagination,
    isLoading,
    isListLoading,
    isError,
    updateAutomation,
  };
};
