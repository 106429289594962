import { useCallback, useMemo } from 'react';

import { sensorsGroupId } from '../consts';
import { useSensorFilteringStore } from '../context/sensor-filtering.context';
import { ISensorFiltering, TSelectedDatapoint } from '../types';
import { useOptions } from './use-options.hook';

type TSensorSelectionMode = 'allSensors' | 'allSensorsInEquipment' | 'noneSensors' | 'noneSensorsInEquipment';
type TSensorsSelectionArgs<TMode extends TSensorSelectionMode> = TMode extends 'allSensors' | 'noneSensors'
  ? { mode: TMode }
  : { mode: TMode; equipmentId: string };

interface ISensorsSelection {
  selectedSensors: TSelectedDatapoint[];
  getSensorSelectionInfo: <TMode extends TSensorSelectionMode>(args: TSensorsSelectionArgs<TMode>) => boolean;
  toggleSensorsInLocation: () => void;
  toggleSensorsInEquipment: (manufacturerId: string) => void;
}

export const useSensorsSelection = ({
  locationId,
  allChartSelectedSensors,
  handleOnSelect,
}: {
  locationId: string;
  allChartSelectedSensors: TSelectedDatapoint[];
  handleOnSelect: (sensor: TSelectedDatapoint[]) => void;
}): ISensorsSelection => {
  const [selectedChartType] = useSensorFilteringStore((store) => store.selectedChartType);

  const { options } = useOptions();

  const optionsForGroup = useMemo(
    () =>
      selectedChartType && selectedChartType !== 'custom'
        ? options.filter((option) => option.type === selectedChartType)
        : options,
    [options, selectedChartType]
  );

  const selectedSensors = getSelectedSensors({ allChartSelectedSensors, locationId, selectedChartType });

  const getSensorSelectionInfo = useCallback(
    <TMode extends TSensorSelectionMode>(args: TSensorsSelectionArgs<TMode>) => {
      const optionsInLocation = optionsForGroup.filter((option) => option.locationId === locationId);
      const allIdsInLocation = Array.from(new Set(optionsInLocation.map((option) => option.manufacturerId)));

      if (args.mode === 'allSensors') {
        return optionsInLocation.every((option) => {
          const selectedSensorsForLocation = selectedSensors.filter(
            (sensor) => sensor.locationId === option.locationId
          );

          return (
            Boolean(selectedSensorsForLocation.length) && selectedSensorsForLocation.length === optionsInLocation.length
          );
        });
      }

      if (args.mode === 'allSensorsInEquipment') {
        const selectedSensorsForEquipment = getFilteredOptions(selectedSensors, args.equipmentId);
        const availableSensorsForEquipment = getFilteredOptions(optionsInLocation, args.equipmentId);

        return (
          Boolean(selectedSensorsForEquipment.length) &&
          selectedSensorsForEquipment.length === availableSensorsForEquipment.length
        );
      }

      if (args.mode === 'noneSensors') {
        return allIdsInLocation.every(
          (manufacturerId) => !selectedSensors.filter((sensor) => sensor.manufacturerId === manufacturerId).length
        );
      }

      if (args.mode === 'noneSensorsInEquipment') {
        return !getFilteredOptions(selectedSensors, args.equipmentId)?.length;
      }

      return false;
    },
    [locationId, optionsForGroup, selectedSensors]
  );

  const toggleSensorsInLocation = useCallback(() => {
    const optionsInLocation = optionsForGroup.filter((option) => option.locationId === locationId);

    handleOnSelect(optionsInLocation);
  }, [handleOnSelect, locationId, optionsForGroup]);

  const toggleSensorsInEquipment = useCallback(
    (equipmentId: string) => {
      const optionsInEquipment = getFilteredOptions(
        optionsForGroup.filter((option) => option.locationId === locationId),
        equipmentId
      );

      handleOnSelect(optionsInEquipment);
    },
    [handleOnSelect, locationId, optionsForGroup]
  );

  return {
    selectedSensors,
    getSensorSelectionInfo,
    toggleSensorsInLocation,
    toggleSensorsInEquipment,
  };
};

const getSelectedSensors = ({
  allChartSelectedSensors,
  locationId,
  selectedChartType,
}: {
  allChartSelectedSensors: TSelectedDatapoint[];
  locationId: string;
  selectedChartType: ISensorFiltering['selectedChartType'];
}) => {
  if (selectedChartType && allChartSelectedSensors) {
    return allChartSelectedSensors.filter((sensor) => sensor.locationId === locationId);
  }

  return [];
};

const getFilteredOptions = (options: TSelectedDatapoint[], equipmentId: string) =>
  options.filter((option) =>
    equipmentId === sensorsGroupId ? option.parentType === 'sensor' : option.manufacturerId === equipmentId
  );
