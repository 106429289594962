const assets = 'api/Assets';
const searchableAssets = `${assets}/filter`;
const systemMap = 'api/SystemMap';
const searchableNodes = `${systemMap}/node/filter`;

export const paths = {
  ASSETS: searchableAssets,
  NODES: searchableNodes,
  GRAPH: systemMap,
  SYSTEM_MAP_NODES_POSITIONS: `${systemMap}/nodes-positions`,
  SYSTEM_MAP_LINK: `${systemMap}/link`,
  SYSTEM_MAP_LINK_FILTER: `${systemMap}/link/filter`,
  SYSTEM_MAP_LINK_DETAILS: `${systemMap}/link/{flowLinkId}`,
  SYSTEM_MAP_NODE: `${systemMap}/link/{flowLinkId}/node`,
  SYSTEM_MAP_NODE_DETAILS: `${systemMap}/node/{nodeId}`,
  SYSTEM_MAP_NODE_DELETE: `${systemMap}/node/{flowLinkId}/merge`,
};
