export const content = {
  ADD_FLOW_LINK: 'Add flow link',
  RENAME_TEE: 'Rename tee',
  DELETE_TEE: 'Delete tee',
  ADD_TEE: 'Add tee',
  DELETE_FLOW_LINK: 'Delete flow link',
  EQUIPMENT: {
    PERFORMANCE: {
      label: 'Performance',
      success: 'All Operational',
      warning: 'Open Alerts',
      error: 'Open Alerts',
    },
    HEALTH: {
      label: 'Health',
      success: 'All Operational',
      warning: 'Perform System Maintenance',
      error: 'Check Communication & Errors',
    },
    SANITIZATION_IN_PROGRESS: 'Sanitization in progress',
    SETPOINT: 'Setpoint',
    TIME_REMAINING: 'Time remaining',
    START_UP_IN_PROGRESS: 'Start up in progress',
    BURNER: {
      LABEL: 'Burner',
      ON: 'On',
      OFF: 'Off',
    },
    NOT_APPLICABLE: 'N/A',
  },
};
