import { useLocation } from '@marlin/asset/data-access/location';
import { LOCATION_TYPE } from '@marlin/asset/data-access/location';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { routes } from '@marlin/shared/utils-routes';
import { useMediaQuery, useTheme } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';

import { LocationDetailsDesktop } from './desktop/location-details.component';
import { LocationDetailsMobile } from './mobile/location-details.component';

export const LocationDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { locationId = '' } = useParams();
  const { data, isLoading } = useLocation({ locationId });
  const { plant } = useFeatureFlagsContext();
  const isPlant = data?.locationType === LOCATION_TYPE.PLANT;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (plant && isPlant) {
    return <Navigate to={routes.locations.plant.url(locationId)} replace />;
  }

  if (isMobile) {
    return <LocationDetailsMobile />;
  }

  return <LocationDetailsDesktop />;
};
