import { MarlinTheme } from '@marlin/shared/theme';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useSnackbar } from 'notistack';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    marginLeft: theme.typography.pxToRem(8),
    cursor: 'pointer',
  },
}));

interface ICopyProps {
  value: string;
  testId: string;
}

export const Copy = ({ value, testId }: ICopyProps) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const copy = () =>
    navigator?.clipboard.writeText(value).then(() => {
      enqueueSnackbar(content.API_KEY_COPY_SUCCESS, {
        variant: 'success',
      });
    });

  return (
    <ContentCopyRoundedIcon
      className={classes.icon}
      color="primary"
      onClick={copy}
      data-testid={`copy-button-${testId}`}
    />
  );
};
