import { content } from './content';
import { aercoDatapointLabelsMap } from './datapoint-mappers-aerco.config';
import { intellistationDatapointLabelsMap } from './datapoint-mappers-intellistation.config';

function convertKeysToLowerCase(map: Map<string, string>): Map<string, string> {
  const newMap = new Map();
  for (const [key, value] of map) {
    newMap.set(key.toLowerCase(), value);
  }
  return newMap;
}

export const datapointsLabelsMap = new Map([
  ...convertKeysToLowerCase(intellistationDatapointLabelsMap),
  ...convertKeysToLowerCase(aercoDatapointLabelsMap),
]);

export const datapointsOptionsLabelsMap = new Map([
  ['on', content.DATAPOINT_OPTIONS_LABELS.ON],
  ['off', content.DATAPOINT_OPTIONS_LABELS.OFF],
  ['open', content.DATAPOINT_OPTIONS_LABELS.OPEN],
  ['closed', content.DATAPOINT_OPTIONS_LABELS.CLOSED],
  ['opening', content.DATAPOINT_OPTIONS_LABELS.OPENING],
  ['closing', content.DATAPOINT_OPTIONS_LABELS.CLOSING],
  ['auto', content.DATAPOINT_OPTIONS_LABELS.AUTO],
  ['error', content.DATAPOINT_ERROR_LABEL],
]);

export const datapointsStatusLabelsMap = new Map([
  ['noDemand', content.EMPTY_DATAPOINT_VALUE],
  ['absent', content.EMPTY_DATAPOINT_VALUE],
  ['error', content.DATAPOINT_ERROR_LABEL],
]);
