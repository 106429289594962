import { useEquipmentDetails } from '@marlin/asset/data-access/equipment';
import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import { TChartId } from '@marlin/asset/shared/equipment-config';
import { MarlinTheme } from '@marlin/shared/theme';
import {
  getDataWithoutMargins,
  isAercoDevice,
  parseToChartData,
  removeEmptyChartData,
  useCustomPeriodModalContextStore,
} from '@marlin/shared/utils-chart';
import { IChartData, IThresholdsSeriesNullable, findMinMax } from '@marlin/shared/utils-chart';
import { IApexAxisChartThresholdSeries, IChartSeries } from '@marlin/shared/utils-chart';
import { RANGE_FILTER, TChartDisplayType } from '@marlin/shared/utils-chart';
import { useDuration } from '@marlin/shared/utils-chart';
import { getBucketSize, getNumberOfBuckets } from '@marlin/shared/utils-chart';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { useDurationContext } from '../context/context-duration.context';
import { IChartMetadata } from '../types';
import { mapThresholdsToApexData } from '../utils/map-thresholds-to-apex-data';
import { useMappedThresholdData } from './use-mapped-threshold-data.hook';

interface IUseChartData {
  datapointsWithMetadata: IChartMetadata;
  activeDatapoints: string[];
  activeThresholds: string[];
  chartId: TChartId;
  chartDisplayType: TChartDisplayType;
}

export const useChartData = ({
  datapointsWithMetadata: { datapointsMetadata, thresholdsMetadata },
  activeDatapoints,
  activeThresholds,
  chartId,
  chartDisplayType,
}: IUseChartData) => {
  const equipmentId = useIdFromPathname();
  const theme = useTheme<MarlinTheme>();
  const { data: { devices = [], model } = {} } = useEquipmentDetails({ equipmentId });
  const manufacturerId = useMemo(() => devices[0]?.manufacturerId, [devices]);
  const activeDatapointsLower = useMemo(() => activeDatapoints.map((name) => name.toLowerCase()), [activeDatapoints]);
  const activeThresholdsLower = useMemo(() => activeThresholds.map((name) => name.toLowerCase()), [activeThresholds]);

  const { rangeFilter, setDetails } = useDurationContext();

  const [fromDateModal] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDateModal] = useCustomPeriodModalContextStore((store) => store.toDate);

  const {
    fromDate: from,
    toDate: to,
    minSelection,
    maxSelection,
    handleZoomChange,
    isZoomed,
    zoomedRange,
  } = useDuration({
    key: chartId,
    rangeFilter,
    setDetailsWithPrev: setDetails,
    fromDate: fromDateModal,
    toDate: toDateModal,
  });

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations: chartId === 'flow',
      }),
    [minSelection, maxSelection, rangeFilter.range, chartId]
  );

  const bucketPerMinute = useMemo(() => getBucketSize(from, to, numberOfBuckets), [from, numberOfBuckets, to]);

  const { data, isFetching, isLoading } = useAnalyticsTelemetry(
    {
      dateFrom: from,
      dateTo: to,
      requestedTelemetry: [{ manufacturerId, datapoints: [...activeDatapointsLower, ...activeThresholdsLower] }],
      numberOfBuckets,
    },
    {
      select: (data) => {
        const clearedData =
          (rangeFilter.range === RANGE_FILTER.HOURS_8 || (zoomedRange && zoomedRange < 8 * 60 * 60 * 1000)) &&
          isAercoDevice(model)
            ? removeEmptyChartData({ data })
            : data;

        return {
          ...clearedData,
          telemetryData: clearedData.telemetryData.map(({ values, datapointName, ...rest }) => ({
            ...rest,
            datapointName,
            values: activeThresholdsLower.includes(datapointName.toLowerCase())
              ? values.filter(({ x }) => x >= minSelection && x <= maxSelection)
              : values,
          })),
        };
      },
    }
  );

  const datapointsRawData = useMemo(
    () => data?.telemetryData.filter((item) => activeDatapointsLower.includes(item.datapointName.toLowerCase())),
    [activeDatapointsLower, data?.telemetryData]
  );
  const thresholdsRawData = useMemo(
    () => data?.telemetryData.filter((item) => activeThresholdsLower.includes(item.datapointName.toLowerCase())),
    [activeThresholdsLower, data?.telemetryData]
  );

  const convertedDatapoints = useMemo(
    () => parseToChartData(datapointsRawData, chartDisplayType, bucketPerMinute, minSelection, maxSelection),
    [maxSelection, datapointsRawData, chartDisplayType, bucketPerMinute]
  );
  const convertedThresholds = useMappedThresholdData(thresholdsRawData, thresholdsMetadata);

  const chartData = useMemo<IChartSeries[]>(() => {
    const convertedChartData = convertedDatapoints.map(({ datapointName, values, unitOfMeasure }) => {
      const datapointWithMetadata = datapointsMetadata.find(
        (item) => item.name.toLowerCase() === datapointName.toLowerCase()
      );

      return {
        name: datapointWithMetadata?.label ?? datapointName,
        data: values || [],
        color: datapointWithMetadata?.color ?? '#FBAC13',
        type: chartDisplayType,
        uom: unitOfMeasure ?? null,
        id: datapointName,
      };
    });

    return sortByOrder(convertedChartData, datapointsMetadata);
  }, [convertedDatapoints, datapointsMetadata, chartDisplayType]);

  const chartDataWithoutMargins = useMemo(
    () => getDataWithoutMargins(chartData, minSelection, maxSelection),
    [chartData, maxSelection, minSelection]
  );

  const { max, min } = useMemo(
    () =>
      findMinMax([
        ...chartData.reduce<IChartData[]>((acc, datapoint) => [...acc, ...datapoint.data], []),
        ...(convertedThresholds || []).reduce<IThresholdsSeriesNullable[]>(
          (acc, item) => [...acc, { x: item.from, y: [item.thresholdMin, item.thresholdMax] }],
          []
        ),
      ]),
    [chartData, convertedThresholds]
  );

  const thresholdsData = useMemo<IApexAxisChartThresholdSeries[]>(
    () => mapThresholdsToApexData(convertedThresholds, thresholdsMetadata, max, min, theme),
    [convertedThresholds, max, min, theme, thresholdsMetadata]
  );

  const totalVolume = useMemo<string | undefined>(() => {
    if (chartId !== 'flow') return undefined;

    const uom = chartData.find((datapoint) => datapoint.data.length)?.uom;

    const total = chartData.reduce((acc, datapoint) => {
      datapoint.data.forEach((point) => {
        if (point.y && point.x >= minSelection && point.x <= maxSelection) {
          acc += point.y;
        }
      });

      return acc;
    }, 0);

    return getFormattedValue(String(total), uom);
  }, [chartData, chartId, maxSelection, minSelection]);

  return {
    chartData,
    chartDataWithoutMargins,
    thresholdsData,
    to: maxSelection,
    from: minSelection,
    isLoading,
    isFetching,
    max,
    min,
    handleZoomChange,
    isZoomed: !!isZoomed,
    totalVolume,
  };
};

const sortByOrder = (
  arrayToSort: IChartSeries[],
  arrayWithProperOrder: { name: string; isDefault: boolean; label: string; color: string }[]
): IChartSeries[] => {
  const properOrderedArray = arrayWithProperOrder.map((item) => item.label);

  return arrayToSort.sort((a, b) => properOrderedArray.indexOf(a.name) - properOrderedArray.indexOf(b.name));
};
