import { z } from 'zod';

const unitOfMeasureList = [
  'c',
  'f',
  'kpa',
  'psi',
  '%',
  'hr',
  'min',
  'sec',
  'mbh',
  'vdc',
  'gpm',
  'mbtu',
  'kbtu',
  'lpm',
  'm3ph',
  'kwh',
  'gj',
  'thm',
  'v',
  '',
  'gal',
  'l',
  'm3',
  'WaterDetect',
  'ft3',
  'ft3pm',
  'mw',
  'ValvePosition',
  'milliwatt',
  'ft',
  'μa',
] as const;

export const UnitOfMeasure = z.enum(unitOfMeasureList);
export type TUnitOfMeasure = z.infer<typeof UnitOfMeasure>;

const apiUnitOfMeasureList = [
  'F',
  'C',
  'PSI',
  'KPA',
  'KPa',
  'GPM',
  'LPM',
  'M3PH',
  'KBTU',
  'MBTU',
  'KWH',
  'GJ',
  'THM',
  '%',
  'V',
  'v',
  'MIN',
  'HR',
  'SEC',
  'MBH',
  'VDC',
  '',
  'GALLONS',
  'Gal',
  'LITERS',
  'METERS',
  'M3',
  'WATERDETECT',
  'Ft3',
  'Ft3PM',
  'L',
  'MW',
  'VALVEPOSITION',
  'MILLIWATT',
  'FEET',
  'MA',
  'μa'.toUpperCase(),
] as const;

export const ApiUnitOfMeasures = z.enum(apiUnitOfMeasureList).or(z.string());
export type TApiUnitOfMeasures = z.infer<typeof ApiUnitOfMeasures>;
export const apiUnitOfMeasuresToDevice = new Map<string, TUnitOfMeasure>([
  ['F', 'f'],
  ['C', 'c'],
  ['PSI', 'psi'],
  ['KPA', 'kpa'],
  ['KPa', 'kpa'],
  ['GPM', 'gpm'],
  ['MBTU', 'mbtu'],
  ['KBTU', 'kbtu'],
  ['LPM', 'lpm'],
  ['M3PH', 'm3ph'],
  ['KWH', 'kwh'],
  ['GJ', 'gj'],
  ['THM', 'thm'],
  ['%', '%'],
  ['V', 'v'],
  ['MIN', 'min'],
  ['HR', 'hr'],
  ['SEC', 'sec'],
  ['MBH', 'mbh'],
  ['VDC', 'vdc'],
  // TODO check if those two are correct
  ['', ''],
  ['GALLONS', 'gal'],
  ['GAL', 'gal'],
  ['LITERS', 'l'],
  ['L', 'l'],
  ['METERS', 'm3'],
  ['M3', 'm3'],
  ['WATERDETECT', 'WaterDetect'],
  ['VALVEPOSITION', 'ValvePosition'],
  ['FT3', 'ft3'],
  ['FT3PM', 'ft3pm'],
  ['FT3PM', 'ft3pm'],
  ['MW', 'mw'],
  ['MILLIWATT', 'milliwatt'],
  ['FEET', 'ft'],
  ['MA', 'μa'],
  ['μa'.toUpperCase(), 'μa'],
]);

export const TransformApiUnitOfMeasuresToDevice = ApiUnitOfMeasures.transform((measureType) => {
  const uom = apiUnitOfMeasuresToDevice.get(measureType);

  if (uom) {
    return uom;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown unit of measure: ${measureType}`);
  return null;
});

export const DeviceUserMeasuresPreferences = z.object({
  temperatureUnit: TransformApiUnitOfMeasuresToDevice.optional(),
  flowUnit: TransformApiUnitOfMeasuresToDevice.optional(),
  energyUnit: TransformApiUnitOfMeasuresToDevice.optional(),
  pressureUnit: TransformApiUnitOfMeasuresToDevice.optional(),
});
export type TDeviceUserMeasuresPreferences = z.infer<typeof DeviceUserMeasuresPreferences>;

export const UnitOfMeasureType = UnitOfMeasure.or(
  ApiUnitOfMeasures.nullish().transform((item) => {
    if (!item) return null;

    const unit = item.toUpperCase();

    if (unit == 'μa'.toUpperCase()) {
      return 'μa';
    }

    if (apiUnitOfMeasuresToDevice.has(unit)) {
      return apiUnitOfMeasuresToDevice.get(unit) ?? null;
    }

    // eslint-disable-next-line no-console
    console.warn(`Unknown unit of measure: ${unit}`);
    return null;
  })
);
