import { TDateString, createDateString, dateAdapter } from '@marlin/shared/utils-common-date';

import { TDurationType } from '../model';
import { RANGE_FILTER } from './get-time-range-with-format';

const calculateTimeRange = (
  durationValue: number,
  durationType: TDurationType,
  lastTimestamp: number,
  addBuffer = true
): TDateString[] => [
  createDateString(dateAdapter.date()?.subtract(durationValue, durationType).toDate()),
  createDateString(
    dateAdapter
      .date(lastTimestamp)
      ?.add(addBuffer ? 1 : 0, durationType)
      .toDate()
  ),
];

export const getTimeRange = (rangeType: RANGE_FILTER, lastTimestamp: number, addBuffer = true): TDateString[] => {
  switch (rangeType) {
    case 'HOURS_24':
      return calculateTimeRange(24, 'hours', lastTimestamp, addBuffer);
    case 'DAYS_7':
      return calculateTimeRange(7, 'days', lastTimestamp, addBuffer);
    case 'HOURS_8':
      return calculateTimeRange(8, 'hours', lastTimestamp, addBuffer);
    case 'DAYS_30':
      return calculateTimeRange(30, 'days', lastTimestamp, addBuffer);
    case 'MONTHS_12':
      return calculateTimeRange(12, 'months', lastTimestamp, addBuffer);
    default:
      return [];
  }
};
