import {
  DeleteEquipmentModalBody,
  DeleteEquipmentModalFooter,
  DeleteEquipmentModalTitle,
} from '@marlin/asset/ui/delete-equipment-modal';
import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { TEquipment } from '@marlin/shared/utils/zod';
import { Button } from '@mui/material';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

interface IActionButtons {
  detailEditLink: string;
  equipment?: TEquipment;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  editDeleteButtonsWrapper: {
    display: 'flex',
  },

  editDeleteButton: {
    width: theme.typography.pxToRem(56),
    marginLeft: theme.typography.pxToRem(16),
  },
}));

export const ActionButtons = ({ detailEditLink, equipment }: IActionButtons) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();

  const { modalDispatch } = useContext(ModalContext);

  const openDeleteModal = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <DeleteEquipmentModalTitle deviceCount={equipment?.deviceCount ?? 0} />,
        body: (
          <DeleteEquipmentModalBody
            deleteAssetName={equipment?.name}
            deviceCount={equipment?.deviceCount ?? 0}
            assetId={equipment?.id}
          />
        ),
        footer: <DeleteEquipmentModalFooter deviceCount={equipment?.deviceCount ?? 0} assetId={equipment?.id} />,
      },
    });
  };

  return (
    <div>
      <div className={classes.editDeleteButtonsWrapper}>
        <Restricted to={PERMISSIONS.DELETE_EQUIPMENT}>
          <Button className={classes.editDeleteButton} onClick={openDeleteModal}>
            {content.DELETE_BUTTON}
          </Button>
        </Restricted>
        <Restricted to={PERMISSIONS.EDIT_EQUIPMENT}>
          <Button
            className={classes.editDeleteButton}
            variant="outlined"
            onClick={() => navigate(detailEditLink, { state: { prevLocation: location.pathname } })}
          >
            {content.EDIT_BUTTON}
          </Button>
        </Restricted>
      </div>
    </div>
  );
};
