import { organizationWrapperAdapterSchema } from '../components/organization-wrapper.schema';
import { useOrganizationList } from '../queries/get-organization-list.query';

export const useGetUserOrganizationsList = () => {
  const userQuery = useOrganizationList();
  const data = organizationWrapperAdapterSchema.parse(userQuery.data?.organizations || []);

  return {
    organizations: data,
    isLoading: userQuery.isLoading,
    isError: userQuery.isError,
  };
};
