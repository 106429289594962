import { content } from '../content';

export const ModeMap = new Map([
  ['startup', content.MODE.STARTUP],
  ['auto', content.MODE.AUTO],
  ['idle', content.MODE.IDLE],
  ['manual', content.MODE.MANUAL],
  ['sanitize', content.MODE.SANITIZE],
  ['cooldown', content.MODE.COOLDOWN],
  ['disabled', content.MODE.DISABLED],
  ['standby', content.MODE.STANDBY],
  ['remote', content.MODE.REMOTE],
  ['fault', content.MODE.FAULT],
  ['home', content.MODE.HOME],
  ['away', content.MODE.AWAY],
]);
