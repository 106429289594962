import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { ALERT_STATUS_FILTER, DEVICE_TYPE, IAlert } from '../types';
import { AlertIntervalSection } from './sections/alert-interval-section';
import { LastReadingSection } from './sections/last-reading-section.component';
import { MetadataSection } from './sections/metadata-section.component';
import { RecipientsSection } from './sections/recipients-section.component';
import { SnoozeSection } from './sections/snooze-section.component';
import { WarningIcon } from './sections/warning-icon.component';

interface IAlertCurrent {
  alert: IAlert;
  displayIconColumn: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.typography.pxToRem(2),
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },

  time: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },

  lastReadingSection: {
    paddingLeft: theme.typography.pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(24),
    borderLeft: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(24),
    alignItems: 'flex-start',
    paddingTop: theme.typography.pxToRem(10),
    paddingBottom: theme.typography.pxToRem(10),
  },
}));

export function AlertLostCommunicationCard({ alert, displayIconColumn }: IAlertCurrent) {
  const { classes } = useStyles();
  const warningIcon = displayIconColumn ? <WarningIcon /> : undefined;

  const startTime = useMemo(() => {
    return formatDate(alert.startTime, defaultDateTime);
  }, [alert.startTime]);

  const isResolvedAlert = alert.status === ALERT_STATUS_FILTER.RESOLVED;

  return (
    <Grid item xs={12}>
      <Paper testId="alert-card">
        <Grid container>
          <Grid item xs={6}>
            <RecipientsSection alert={alert} icon={warningIcon} />
            {alert.device.type !== DEVICE_TYPE.GATEWAY && (
              <>
                <AlertIntervalSection snoozeIntervalInMinutes={alert.snoozeIntervalInMinutes} />
                <MetadataSection alert={alert} />
              </>
            )}
          </Grid>
          <Grid item xs={2}>
            <div className={classes.container}>
              <div className={classes.label}>{content.CARD.CURRENT.LABEL.START_TIME}</div>
              <div className={classes.time}>{startTime}</div>
              <div>
                <CriticalityControl severity={alert.criticality} />
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className={classes.lastReadingSection}>
            {!isResolvedAlert && (
              <SnoozeSection
                id={alert.id}
                deviceId={alert.device.id}
                snoozeUntil={alert.snoozeUntil}
                automationName={alert.automation.name}
                automationId={alert.automation.id}
                deviceName={alert.device.name}
              />
            )}
            <LastReadingSection alert={alert} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
