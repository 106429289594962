import { RANGE_FILTER } from '../../constant';

export const tickMargin = 7;

export const timeFormat = 'HH:mm';
export const dateFormat = 'ddd';
export const dateMonthFormat = 'MMM DD';
export const customFormat = 'MM/DD/YY HH:mm A z';

export const enum EXTENDED_RANGE_FILTER {
  MONTHS_12 = 'MONTHS_12',
  CUSTOM = 'CUSTOM',
}

export const TimestampFormat: Record<RANGE_FILTER | EXTENDED_RANGE_FILTER, string> = {
  HOURS_8: timeFormat,
  HOURS_24: timeFormat,
  DAYS_7: dateFormat,
  MONTHS_12: dateMonthFormat,
  CUSTOM: customFormat,
};

export const xAxisType: ApexXAxis['type'] = 'datetime';
export const strokeWidth = 2;
export const scatterMarkerSize = 4;
export const alertMarkerSize = 6;
export const lineMarkerSize = 1;

export const yAxisBorder = {
  show: false,
};
export const xAxisBorder = {
  show: true,
  height: 1,
};

export const measureChartTicks = 5;

export const leakChartTicks = 1;
export const leakChartMin = -0.05;
export const leakChartMax = 1.05;

export const leakChartCurve: ApexStroke['curve'] = 'stepline';

export const alertSeriesIndex = 0;
