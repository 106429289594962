import { MarlinTheme } from '@marlin/shared/theme';
import { deviceTypeOptions } from '@marlin/shared/ui-device-type';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { DEVICE_TYPE, ISystemMapAlert, getSystemMapAlert } from '@marlin/shared/utils/format-alert';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useCallback, useMemo } from 'react';
import { Handle, NodeProps, Position, useStore } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useCommonStyles } from './common.styles';
import {
  getAlert,
  hasHighPrioAlerts,
  hasLostCommunicationAlert,
  hasLowPrioAlerts,
  isTelemetryError,
} from './utils/node-errors.utils';
import { NodeTooltip } from './utils/node-tooltip.component';
import { useDeviceId } from './utils/use-device-id.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  device: {
    backgroundColor: theme.palette.systemMap.device,
    width: theme.typography.pxToRem(100),
    height: theme.typography.pxToRem(100),
    borderRadius: '50%',
    padding: 0,
  },
  deviceNodeContent: {
    lineHeight: 1.6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  attachment: {
    color: theme.palette.systemMap.alternative,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  alertLow: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  telemetryError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  alertHigh: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  alertTooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
  },
  alertTooltipLabel: {
    borderStyle: 'solid',
    borderWidth: theme.typography.pxToRem(1),
    borderColor: theme.palette.primary.contrastText,
    borderRadius: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(2),
    paddingBottom: theme.typography.pxToRem(2),
    paddingLeft: theme.typography.pxToRem(4),
    paddingRight: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(16),
  },
}));

type TNodeData = TGraphAsset['data'] & { label: string };

export const DeviceNode = ({ data, id }: NodeProps<TNodeData>) => {
  const { classes, cx } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { Icon } = deviceTypeOptions[data.deviceType || 'TEMPERATURE'];
  const deviceId = useDeviceId();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  const draggable = useStore((store) => store.nodesDraggable);

  const deviceClasses = useMemo(() => {
    return cx(commonClasses.node, classes.device, {
      [classes.telemetryError]: isTelemetryError(data),
      [classes.alertLow]: hasLowPrioAlerts(data),
      [classes.alertHigh]: hasHighPrioAlerts(data) || hasLostCommunicationAlert(data),
      [classes.active]: deviceId === data.assetId,
    });
  }, [
    classes.active,
    classes.alertHigh,
    classes.alertLow,
    classes.device,
    classes.telemetryError,
    commonClasses.node,
    cx,
    data,
    deviceId,
  ]);

  const onNodeTap = useCallback(() => {
    if (data.assetId && !draggable) {
      if (data.alerts?.length) {
        router.goTo(routes.deviceDrawer.open(currentUrl, data.assetId, 'alerts'));
      } else {
        router.goTo(routes.deviceDrawer.open(currentUrl, data.assetId));
      }
    }
  }, [currentUrl, data.alerts?.length, data.assetId, draggable, router]);

  const nodeTooltip = useMemo(() => {
    if (data.alerts && data.alerts.length) {
      const alert = getAlert(data.alerts);
      // note: types for alerts need to be unified with zod
      const systemMapAlert = getSystemMapAlert(alert as ISystemMapAlert, data.deviceType as DEVICE_TYPE);
      return (
        <>
          <div className={classes.alertTooltipTitle}>{data.name || ''}</div>
          <div>{systemMapAlert}</div>
          {data.alerts.length > 1 && (
            <span className={classes.alertTooltipLabel}>{content.MORE_ALERTS(data.alerts.length)}</span>
          )}
        </>
      );
    }

    return data.name || '';
  }, [classes.alertTooltipLabel, classes.alertTooltipTitle, data.alerts, data.deviceType, data.name]);

  return (
    <NodeTooltip text={nodeTooltip}>
      <div className={deviceClasses} data-testid={`device-node-${id}`} onClick={onNodeTap}>
        <div className={classes.deviceNodeContent}>
          <Icon />
          <span data-testid={`device-node-${id}-label`}>{data.reading}</span>
        </div>
        {data.nodeType === 'ATTACHMENT' && (
          <div className={classes.attachment}>
            <LinkRoundedIcon />
          </div>
        )}
        <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
        <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
        <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
        <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
      </div>
    </NodeTooltip>
  );
};
