import { MarlinTheme } from '@marlin/shared/theme';
import { CircularProgress } from '@mui/material';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  loaderContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: theme.typography.pxToRem(8),
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.success.main,
    padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(16)}`,
    borderRadius: theme.typography.pxToRem(4),
    boxShadow: theme.shadows[6],
  },
  content: {
    color: theme.palette.success.contrastText,
    fontSize: theme.typography.pxToRem(14),
  },
  circularProgress: {
    color: theme.palette.success.contrastText,
  },
}));

const snackbarKey = 'loadingSnackbar';

export const LoadingSnackbar = forwardRef<HTMLDivElement, CustomContentProps & { title: string }>((props, ref) => {
  const { classes } = useStyles();

  const { message, title, ...other } = props;

  return (
    <SnackbarContent ref={ref} role="alert" key={snackbarKey} {...other}>
      <div className={classes.container}>
        <div className={classes.loaderContainer}>
          <CircularProgress size={16} classes={{ root: classes.circularProgress }} />
        </div>
        <div className={classes.content}>
          <div>{title}</div>
          <div>{message}</div>
        </div>
      </div>
    </SnackbarContent>
  );
});
