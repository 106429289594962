import { IDevice, useDevice, useUpdateDevice } from '@marlin/asset/data-access/device';
import { routes } from '@marlin/shared/utils-routes';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { content } from './content';

type TLocationState = {
  prevLocation: string;
};

export interface IEditDevice {
  isLoading: boolean;
  successfullyUpdatedDevice: boolean;
  loadingUpdatedDevice: boolean;
  nextRoute: string;
  device: IDevice | undefined;
  onSubmit: (value: IDevice) => void;
  goToNextRoute: () => void;
}

export const useEditDevice = (): IEditDevice => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const locationState = state as TLocationState;
  const nextRoute = locationState?.prevLocation || routes.sensors.root;
  const deviceId = pathname.substring(pathname.lastIndexOf('/') + 1);
  const deviceQuery = useDevice({ deviceId, enabled: deviceId.length > 0 });
  const onError = () =>
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_DEVICE_ERROR, { variant: 'error', preventDuplicate: true });

  const onSuccess = () =>
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_DEVICE_SUCCESS, {
      variant: 'success',
      preventDuplicate: true,
    });

  const updateDeviceMutation = useUpdateDevice({ onError, onSuccess });
  const onSubmit = useCallback(
    (data: IDevice) => {
      return updateDeviceMutation.mutateAsync({ data: data, deviceId });
    },
    [deviceId, updateDeviceMutation]
  );

  const goToNextRoute = useCallback(() => {
    navigate(nextRoute);
  }, [navigate, nextRoute]);

  const device = useMemo(() => deviceQuery?.data, [deviceQuery?.data]);

  const successfullyUpdatedDevice = useMemo(() => updateDeviceMutation.isSuccess, [updateDeviceMutation.isSuccess]);
  const loadingUpdatedDevice = useMemo(() => updateDeviceMutation.isLoading, [updateDeviceMutation.isLoading]);
  const isLoading = useMemo(
    () => deviceQuery.isLoading || deviceQuery.isInitialLoading,
    [deviceQuery.isInitialLoading, deviceQuery.isLoading]
  );

  return {
    isLoading,
    successfullyUpdatedDevice,
    loadingUpdatedDevice,
    nextRoute,
    device,
    goToNextRoute,
    onSubmit,
  };
};
