import { MarlinTheme } from '@marlin/shared/theme';
import { Grid, Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IAsset {
  id: string;
  name: string;
}

interface ICardHeader {
  asset: IAsset;
  goTo?: (id: string) => void;
  icon: JSX.Element;
  children?: JSX.Element;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 0,
    marginBottom: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  headerRow: {
    justifyContent: 'space-between',
    marginBottom: theme.typography.pxToRem(8),
  },

  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: `calc(100% - ${theme.typography.pxToRem(36)})`,
  },

  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  assetName: {
    fontWeight: theme.typography.fontWeightBold,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  icon: {
    '& svg': {
      color: theme.palette.action.active,
    },
  },
}));

export function CardHeader({ asset, children, goTo, icon }: ICardHeader) {
  const { classes } = useStyles();

  return (
    <Grid className={classes.headerRow} container>
      <Grid item className={classes.titleRow}>
        <span className={classes.icon}> {icon}</span>
        {goTo ? (
          <Link
            component="button"
            onClick={() => goTo(asset.id)}
            className={classes.title}
            data-testid={`assetMobileCardHeader-${asset.id}`}
            variant="h5"
          >
            {asset.name}
          </Link>
        ) : (
          <Typography variant="body1" className={classes.assetName}>
            {asset.name}
          </Typography>
        )}
      </Grid>
      {children && (
        <Grid item className={classes.actionsRow} data-testid={`assetMobileCardActions-${asset.id}`}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}
