import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Button } from '@mui/material';
import { useMemo } from 'react';

import { content } from '../content';

interface ITotalizationButtonProps {
  devices: {
    deviceId: string;
    deviceName: string;
    locationId: string;
    locationName: string;
  }[];
}

export const TotalizationButton = ({ devices }: ITotalizationButtonProps) => {
  const router = useRouter();

  const currentUrl = useMemo(() => router.getUrl(), [router]);

  const isOnlyOneFlowMeterSelected = useMemo(() => devices.length === 1, [devices]);

  const handleOpenFlowMeter = () => {
    if (devices[0].deviceId) {
      router.goTo(routes.deviceDrawer.open(currentUrl, devices[0].deviceId));
    }
  };

  const handleShowHistory = () => {
    router.goTo(routes.analytics.root);
  };

  return (
    <Button
      onClick={isOnlyOneFlowMeterSelected ? handleOpenFlowMeter : handleShowHistory}
      variant="outlined"
      data-testid={isOnlyOneFlowMeterSelected ? 'view-flow-meter-button' : 'show-history-button'}
    >
      {isOnlyOneFlowMeterSelected ? content.VIEW_FLOW_METER : content.SHOW_HISTORY}
    </Button>
  );
};
