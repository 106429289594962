import { content } from '../../../../content';
import { useEquipmentStatus } from '../../../../hooks/use-equipment-status.hook';
import { EquipmentStatusBar } from './equipment-status-bar.component';

interface IEquipmentStatusProps {
  fullWidth?: boolean;
}

export const EquipmentStatusAerco = ({ fullWidth }: IEquipmentStatusProps) => {
  const { statusLabel, displayTime, operationalIconTag, operationalStatusLabel, isOperational } = useEquipmentStatus({
    statusDatapointName: 'UnitStatusIdx',
    modeMap: 'aerco',
  });

  return (
    <EquipmentStatusBar
      fullWidth={fullWidth}
      isEquipmentOperational={isOperational}
      isErrorModeLabel={operationalStatusLabel === content.EQUIPMENT_MODE_LABELS_AERCO.FAULT}
      isErrorStatusDot={
        operationalStatusLabel === content.EQUIPMENT_MODE_LABELS_AERCO.FAULT ||
        operationalStatusLabel === content.EQUIPMENT_MODE_LABELS_AERCO.DISABLED
      }
      statusLabel={statusLabel}
      operationalStatusLabel={operationalStatusLabel}
      operationalIconTag={operationalIconTag}
      displayTime={displayTime}
    />
  );
};
