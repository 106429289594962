import { IAlert } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { getSettingValueLabel } from '../../../../utils/get-setting-value-from-metadata';

interface IChangelogProps {
  alert: IAlert;
  metadata: TDeviceMetadataDatapointsResponse[];
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  rowTitle: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'uppercase',
    letterSpacing: theme.typography.pxToRem(1),
    lineHeight: theme.typography.pxToRem(32),
    color: theme.palette.text.secondary,
  },
  rowValue: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.17),
    lineHeight: theme.typography.pxToRem(22.4),
  },
  row: {
    gap: theme.typography.pxToRem(8),
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(4),
    width: '100%',
  },
}));

export const Changelog = ({ alert, metadata }: IChangelogProps) => {
  const { classes } = useStyles();
  const isAdmin = /^[-0]+$/.test(alert?.additionalValues?.userId || '');

  const userName = useMemo(() => {
    if (isAdmin) return content.LOCAL_ADMIN;

    if (alert?.additionalValues?.firstName && alert?.additionalValues?.lastName) {
      return `${alert.additionalValues.firstName} ${alert.additionalValues.lastName}`;
    }

    return content.EMPTY_DATAPOINT_VALUE;
  }, [alert?.additionalValues?.firstName, alert?.additionalValues?.lastName, isAdmin]);

  return (
    <div data-testid="information-event-additional-values">
      <div className={classes.row}>
        <span className={classes.rowTitle}>{content.VALUE_CHANGED}:</span>
        <span className={classes.rowValue} data-testid="setting-name">
          {getSettingValueLabel(alert.additionalValues.settingName, alert.additionalValues.settingUom, metadata) ||
            content.EMPTY_DATAPOINT_VALUE}
        </span>
      </div>
      <div className={classes.row}>
        <span className={classes.rowTitle}>{content.NEW_VALUE_LABEL}:</span>
        <span className={classes.rowValue} data-testid="new-value">
          {getSettingValueLabel(
            alert.additionalValues.settingName,
            alert.additionalValues.settingUom,
            metadata,
            alert.additionalValues.newSettingValue
          ) || content.EMPTY_DATAPOINT_VALUE}
        </span>
      </div>
      <div className={classes.row}>
        <span className={classes.rowTitle}>{content.ORIGINAL_VALUE}:</span>
        <span className={classes.rowValue} data-testid="prev-value">
          {getSettingValueLabel(
            alert.additionalValues.settingName,
            alert.additionalValues.settingUom,
            metadata,
            alert.additionalValues.prevSettingValue
          ) || content.EMPTY_DATAPOINT_VALUE}
        </span>
      </div>
      <div className={classes.row}>
        <span className={classes.rowTitle}>{content.GENERATED_BY}:</span>
        <span className={classes.rowValue} data-testid="generated-by">
          {userName}
        </span>
      </div>
    </div>
  );
};
