import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { globalConfigIntellistation } from '@marlin/asset/shared/equipment-config';
import { intellistationImage, intellistationLogo } from '@marlin/asset/shared/ui/assets';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import { HistoricalChart } from '../../../components/chart/historical-chart';
import { EquipmentSettings } from '../../../components/settings/equipment-settings.component';
import { content } from '../../../content';
import { EventLog } from '../../components/event-log/event-log.component';
import { OverrideModeBanner } from '../override-mode-banner/override-mode-banner.component';
import { detailsConfig } from './details-config';
import { intellistationEventCodes } from './event-codes';
import { sectionConfig } from './section-config';

export const intellistationConfig: IConfig = {
  headerBanner: <OverrideModeBanner />,
  tabs: {
    [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
    [EQUIPMENT_TAB_VALUES.SETTINGS]: <EquipmentSettings />,
    [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
  },
  details: detailsConfig,
  dashboard: {
    ...globalConfigIntellistation.dashboard,
    productDetails: {
      datapoints: [
        { name: 'setpointTarget', label: content.DATAPOINT_LABELS.SETPOINT_TEMP },
        {
          name: 'mixingTemp',
          label: content.DATAPOINT_LABELS.OUTLET_TEMP,
          eventCodes: intellistationEventCodes.readings.mixedOutlet,
        },
      ],
      imageSrc: intellistationImage,
      logoSrc: intellistationLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
    },
    enableStartStopData: true,
    sections: sectionConfig,
    chart: {
      datapointNames: ['mixingTemp', 'setpointTarget'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
      isAdvancedButton: true,
      calculateValues: true,
    },
    isLastReadingTimeCounter: false,
  },
};
