import { TPowerSource } from '../types';
import { Battery } from './battery.component';
import { Line } from './line.component';
import { getPowerSourceType } from './utils';

export interface IPowerSourceProps {
  batteryLevel: number;
  powerSource?: TPowerSource;
}

export const PowerSource = ({ batteryLevel, powerSource: powerSourceProps }: IPowerSourceProps) => {
  const powerSource: TPowerSource = powerSourceProps ?? getPowerSourceType(batteryLevel);

  switch (powerSource) {
    case 'LINE': {
      return <Line />;
    }

    case 'BATTERY': {
      return <Battery batteryLevel={batteryLevel} />;
    }

    default: {
      return null;
    }
  }
};
