import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import { NodeProps } from 'reactflow';

import { ZOOM_LEVEL, useContextualZoom } from '../use-contextual-zoom.hook';
import { RegisteredEquipmentNodeDefault } from './equipment-node/registered-equipment-node-default.component';
import { RegisteredEquipmentNodeDetailed } from './equipment-node/registered-equipment-node-detailed.component';
import { RegisteredEquipmentNode } from './equipment-node/registered-equipment-node.component';
import { UnregisteredEquipmentNode } from './equipment-node/unregistered-equipment-node.component';

type TNodeData = TGraphAsset['data'] & { label: string };

export const EquipmentNode = (props: NodeProps<TNodeData>) => {
  const { variant } = useContextualZoom();

  if (props.data?.metadata) {
    if (variant === ZOOM_LEVEL.MAX) return <RegisteredEquipmentNode {...props} />;
    if (variant === ZOOM_LEVEL.DETAILED) return <RegisteredEquipmentNodeDetailed {...props} />;
    if (variant === ZOOM_LEVEL.DEFAULT) return <RegisteredEquipmentNodeDefault {...props} />;
  }

  return <UnregisteredEquipmentNode {...props} />;
};
