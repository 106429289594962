import { useGetProfile } from '@marlin/account-data-access-organization';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { useCallback, useEffect, useState } from 'react';

import { HelpForm } from '../components/form/help-form.component';
import { SendSuccess } from '../components/send-success/send-success.component';
import { content } from '../content';
import { HelpTabs, TAB_VALUES } from './tabs/tabs.component';

export function MobileHelpHub() {
  const [tabValue, setTabValue] = useState<TAB_VALUES>(TAB_VALUES.Help);
  const queryParams = useQueryParameters();
  const { goTo } = useRouter();
  const tabQueryParam = queryParams.get('tab');

  const updateTab = useCallback(
    (tab: TAB_VALUES) => {
      goTo(routes.help.root);
    },
    [goTo]
  );

  useEffect(() => {
    switch (tabQueryParam) {
      case TAB_VALUES.Help:
        setTabValue(TAB_VALUES.Help);
        break;
      default:
        setTabValue(TAB_VALUES.Help);
        break;
    }
  }, [tabQueryParam]);

  const [sendSuccess, setSendSuccess] = useState<boolean>(false);

  const { data } = useGetProfile();

  if (!data) return null;

  return (
    <PageContainer prefix="location-hub-page">
      <PageHeader
        icon={<SensorsRoundedIcon />}
        title={content.HELP_HUB_TITLE}
        prefix="device-hub-header"
        bottomActions={<HelpTabs tabValue={tabValue} updateTab={updateTab} />}
      />
      {sendSuccess ? (
        <SendSuccess email={data.email} onSuccess={setSendSuccess} />
      ) : (
        <HelpForm firstName={data.firstName} lastName={data.lastName} onSuccess={setSendSuccess} />
      )}
    </PageContainer>
  );
}
