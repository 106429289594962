import { IDevice, useCreateDevice } from '@marlin/asset/data-access/device';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';

import { content } from './content';
import { IError } from './types';
import { getErrorMessage } from './utils';

export interface INewDevice {
  successfullyCreatedDevice: boolean;
  loadingCreatedDevice: boolean;
  onSubmit: (value: IDevice) => void;
  goToNextRoute: () => void;
}

export const useNewDevice = (): INewDevice => {
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = () =>
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_DEVICE_SUCCESS, {
      variant: 'success',
      preventDuplicate: true,
    });
  const nextRoute = routes.sensors.list.url();
  const { goTo } = useRouter();
  const goToNextRoute = useCallback(() => {
    goTo(nextRoute);
  }, [goTo, nextRoute]);

  const createDeviceMutation = useCreateDevice({ onSuccess });
  const onSubmit = useCallback(
    (data: IDevice) => {
      return createDeviceMutation.mutateAsync({ data: data }).catch((error: AxiosError<IError>) => {
        const message = getErrorMessage(error?.response?.data?.errorCode);
        enqueueSnackbar(message, {
          variant: 'error',
          preventDuplicate: true,
        });
      });
    },
    [createDeviceMutation, enqueueSnackbar]
  );
  const successfullyCreatedDevice = useMemo(() => createDeviceMutation.isSuccess, [createDeviceMutation.isSuccess]);
  const loadingCreatedDevice = useMemo(() => createDeviceMutation.isLoading, [createDeviceMutation.isLoading]);

  return {
    successfullyCreatedDevice,
    loadingCreatedDevice,
    onSubmit,
    goToNextRoute,
  };
};
