import { DateSchema } from '@marlin/shared/utils-common-date';
import {
  AssetTypeResponseSchema,
  AssetTypeSchema,
  AssetTypeSchemaParser,
  DeviceTypeResponseSchema,
  DeviceTypeSchema,
  DeviceTypeSchemaParser,
  NodeTypeResponseSchema,
  NodeTypeSchema,
  NodeTypeSchemaParser,
  TAssetType,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

import { mapDynamicMetadata } from './dynamic-metadata-mapper';
import { mapReading } from './flow-map.utils';
import { LastReadingValueSchema, filterUnsupportedElementsSchema } from './last-reading-values.schema';
import { elementTagsSchema, metadataSchema } from './metadata-model.schema';
import { NodeAlertSchema } from './node-alerts.model.schema';

export const AssetResponseSchema = z.object({
  position: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .nullable(),
  data: z.object({
    alerts: z.array(NodeAlertSchema).nullish(),
    id: z.string(),
    assetId: z.string().nullish(),
    linkedId: z.string().nullish(),
    name: z.string().nullish(),
    assetType: AssetTypeResponseSchema.nullish(),
    nodeType: NodeTypeResponseSchema.nullish(),
    deviceType: DeviceTypeResponseSchema.nullish(),
    lastReading: z.number().nullish(),
    lastReadingTime: DateSchema.nullish(),
    lastReadingValues: filterUnsupportedElementsSchema,
    parentId: z.string().optional().nullish(),
    metadata: metadataSchema.nullish(),
    nodeTags: z.array(elementTagsSchema).nullish(),
  }),
});

export const AssetSchema = AssetResponseSchema.transform((asset) => {
  const deviceType = DeviceTypeSchemaParser.parse(asset.data.deviceType);
  const assetType: TAssetType | undefined =
    asset.data.deviceType === 'Equipment' ? 'EQUIPMENT' : AssetTypeSchemaParser.parse(asset.data.assetType);
  const nodeType = NodeTypeSchemaParser.parse(asset.data.nodeType);
  const reading = mapReading(asset.data.lastReading, asset.data.lastReadingTime, deviceType);

  return {
    ...asset,
    position: asset.position || undefined,
    data: {
      id: asset.data.id,
      assetId: asset.data.assetId,
      linkedId: asset.data.linkedId || '',
      name: asset.data.name,
      deviceType,
      assetType,
      nodeType,
      lastReadingTime: asset.data.lastReadingTime,
      lastReading: asset.data.lastReading,
      lastReadingValues: asset.data.lastReadingValues,
      reading,
      parent: asset.data.parentId ?? undefined,
      alerts: asset.data.alerts,
      metadata: mapDynamicMetadata(asset.data.metadata, asset.data.lastReadingValues),
      nodeTags: asset.data.nodeTags,
    },
  };
});

export const LinkResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    outlet: z.string(),
    inlet: z.string(),
    isAttachment: z.boolean(),
    inletDescription: z.string().nullish(),
    outletDescription: z.string().nullish(),
    linkDescription: z.string().nullish(),
    tags: z.array(elementTagsSchema).nullish(),
  }),
});

export const LinkSchema = LinkResponseSchema.transform((link) => ({
  ...link,
  data: {
    ...link.data,
    source: link.data.outlet,
    target: link.data.inlet,
    isAttachment: link.data.isAttachment,
    sourceHandle: link.data.tags?.find((tag) => tag.name === 'OutletHandleId')?.value,
    targetHandle: link.data.tags?.find((tag) => tag.name === 'InletHandleId')?.value,
  },
}));

export const GraphResponseSchema = z.object({
  nodes: z.array(AssetResponseSchema),
  links: z.array(LinkResponseSchema),
});

export const GraphSchema = z.object({
  nodes: z.array(AssetSchema).nullish(),
  links: z.array(LinkSchema).nullish(),
});

const GraphNodeDataSchema = z.object({
  id: z.string(),
  assetId: z.string().nullish(),
  linkedId: z.string().nullish(),
  name: z.string().nullish(),
  nodeType: NodeTypeSchema.optional(),
  assetType: AssetTypeSchema.optional(),
  deviceType: DeviceTypeSchema.optional(),
  lastReading: z.number().nullish(),
  lastReadingTime: DateSchema.nullish(),
  lastReadingValues: z.array(LastReadingValueSchema),
  reading: z.string(),
  parent: z.string().optional(),
  alerts: z.array(NodeAlertSchema).nullish(),
  metadata: metadataSchema.nullish(),
});

const GraphNodeSchema = z.object({
  position: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .optional(),
  data: GraphNodeDataSchema,
});

const UpdateNodeSchema = z.object({
  position: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .optional(),
  data: z.object({
    id: z.string(),
    assetId: z.string().nullish(),
    metadata: metadataSchema.nullish(),
    nodeTags: z.array(elementTagsSchema).nullish(),
  }),
});

const UpdateAssetSchema = UpdateNodeSchema.transform((asset) => {
  return {
    position: {
      x: typeof asset.position?.x === 'number' ? Math.round(asset.position.x) : undefined,
      y: typeof asset.position?.y === 'number' ? Math.round(asset.position.y) : undefined,
    },
    data: {
      id: asset.data.id,
      assetId: asset.data.assetId,
      metadata: asset.data.metadata,
      nodeTags: asset.data.nodeTags,
    },
  };
});

const UpdateGraphParamsSchema = z.object({
  nodes: z.array(UpdateAssetSchema),
  links: z.array(LinkSchema),
});

const UpdateLinkSchema = LinkSchema.transform((link) => {
  return {
    data: {
      id: link.data.id,
      inlet: link.data.inlet,
      outlet: link.data.outlet,
      isAttachment: link.data.isAttachment,
    },
  };
});

export const UpdateGraphSchema = UpdateGraphParamsSchema.transform(({ nodes, links }) => {
  return {
    nodes: z.array(UpdateAssetSchema).parse(nodes),
    links: z.array(UpdateLinkSchema).parse(links),
  };
});

export const CytoscapeElementsJsonSchema = z.object({
  elements: z.object({
    nodes: z.array(UpdateAssetSchema),
    edges: z.array(LinkSchema),
  }),
  zoom: z.number().optional(),
  pan: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .optional(),
});

export const SystemMapFullNotificationSchema = z.object({
  organizationId: z.string().optional(),
});

export type TGraphAsset = z.infer<typeof GraphNodeSchema>;
export type TGraphSaveAsset = z.infer<typeof UpdateAssetSchema>;
export type TGraphLink = z.infer<typeof LinkSchema>;
export type TGraphResponse = z.infer<typeof GraphResponseSchema>;
export type TGraph = z.infer<typeof GraphSchema>;
export type TSystemMapFullNotification = z.infer<typeof SystemMapFullNotificationSchema>;

export type TUpdateGraphParams = z.infer<typeof UpdateGraphParamsSchema>;
export type TCytoscapeElementsJson = z.infer<typeof CytoscapeElementsJsonSchema>;
export type TGraphNodeData = z.infer<typeof GraphNodeDataSchema>;
