import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Button, ButtonProps, IconButton } from '@mui/material';
import { ReactNode } from 'react';
import { ArrayPath, Control, FieldArray, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';

import { useStyles } from './array-field.component.styles';

type TRenderControlProps<TFieldValues extends FieldValues> = {
  fieldName: ArrayPath<TFieldValues>;
  control: Control<TFieldValues>;
  index: number;
};

type TFieldArrayControlProps<TFieldValues extends FieldValues> = {
  fieldName: ArrayPath<TFieldValues>;
  defaultValue: FieldArray<TFieldValues, ArrayPath<TFieldValues>>;
  renderControl: ({ fieldName, index, control }: TRenderControlProps<TFieldValues>) => ReactNode;
  addButtonProps?: Omit<ButtonProps, 'onClick'> & { children: ReactNode };
  handleDeleteItem?: (index?: number) => void;
};

export const FieldArrayControl = <TFieldValues extends FieldValues>({
  fieldName,
  defaultValue,
  renderControl,
  addButtonProps,
  handleDeleteItem,
}: TFieldArrayControlProps<TFieldValues>) => {
  const { classes } = useStyles();
  const { control } = useFormContext<TFieldValues>();
  const { fields, append, remove } = useFieldArray<TFieldValues>({
    control,
    name: fieldName,
  });

  return (
    <div>
      <div>
        {fields.map((field, index) => (
          <div className={classes.arrayFieldControl} key={field.id}>
            {renderControl({ fieldName, index, control })}
            {fields.length > 1 && (
              <div className={classes.removeButton}>
                <IconButton
                  onClick={() => {
                    remove(index);
                    handleDeleteItem?.(index);
                  }}
                  color="primary"
                >
                  <RemoveCircleRoundedIcon />
                </IconButton>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.buttonWrapper}>
        <Button {...addButtonProps} onClick={() => append(defaultValue)}>
          {addButtonProps?.children}
        </Button>
      </div>
    </div>
  );
};
