import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { Node } from 'reactflow';

import { useTeeIntersection } from './use-tee-intersection.hook';

export const useDragDrop = () => {
  const { moveSingleOverlappingTee } = useTeeIntersection();

  const onNodeDragStop = useCallback(
    (event: MouseEvent, node: Node) => {
      if (node.type === 'TEE') {
        moveSingleOverlappingTee(node);
      }
    },
    [moveSingleOverlappingTee]
  );

  return { onNodeDragStop };
};
