import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Link } from '@marlin/shared/ui-page';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import { makeStyles } from 'tss-react/mui';

interface IExtendedItem {
  icon: JSX.Element;
  label: string | undefined;
  link: string | undefined;
  disabled?: boolean;
  type?: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginTop: theme.typography.pxToRem(5),
    width: theme.typography.pxToRem(155),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  icon: {
    height: theme.typography.pxToRem(16),
    '& > svg': {
      color: theme.palette.action.active,
    },
    marginRight: theme.typography.pxToRem(3),
  },

  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.typography.pxToRem(3),
    marginTop: theme.typography.pxToRem(6),
    cursor: 'pointer',
    width: theme.typography.pxToRem(180),
    zIndex: 1,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  disabledLink: {
    pointerEvents: 'none',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled,
    textDecoration: 'none',
  },
}));

interface ILabel {
  label?: string;
  link?: string;
  disabled?: boolean;
  type?: string;
}

const Label = ({ link, label, disabled, type }: ILabel) => {
  const { classes, cx } = useStyles();

  if (!link || !label) {
    return <div>--</div>;
  }
  return (
    <Tooltip placement="top" text={label}>
      <Link
        to={link}
        className={cx(classes.label, disabled && classes.disabledLink)}
        data-testid={createDataTestId(type ? `metadata-item-${type}` : 'metadata-item', label)}
      >
        {label}
      </Link>
    </Tooltip>
  );
};

export const MetadataItem = ({ icon, label, link, disabled, type }: IExtendedItem) => {
  const { classes } = useStyles();

  return (
    <div
      className={classes.wrapper}
      data-testid={createDataTestId(type ? `metadata-item-${type}` : 'metadata-item', label)}
    >
      <span className={classes.icon}>{icon}</span>
      <Label link={link} label={label} disabled={disabled} />
    </div>
  );
};
