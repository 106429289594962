import { LoadingSpinner } from '@marlin/shared/ui-loader';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { EmptyOrganizationItem } from '../../utils/empty-organization-item';
import { IEntity } from '../../utils/types';
import { content } from '../content';
import { OrganizationItem } from '../organization-item';
import { useStyles } from './organizations-list.styles';

interface IOrganizationsListProps {
  entityId?: string;
  entities: IEntity[];
  onChange: (organizationId: string) => void;
  handleOrgSearchValue: (value: string) => void;
  orgSearchValue: string;
  fetchNextPage?: () => void;
  isLoading: boolean;
  isPortfolio: boolean;
  hasNextPage: boolean;
}

const maxAllowedOrganizationsWithoutSearchInput = 5;
const heightOfListWithSearchInput = 340;
const widthOfListWith = 320;
const listItemHeight = 40;
const virtualizedListOffset = 150;

interface IRenderRowProps {
  data: IEntity;
  index: number;
}

const renderRow = (
  { data }: IRenderRowProps,
  currentOrgId: string,
  onChange: (organizationId: string) => void,
  isPortfolio: boolean
) => {
  return (
    <OrganizationItem
      isCurrentOrg={currentOrgId === data.id}
      key={data.id}
      onChange={() => onChange(data.id)}
      orgName={data.name}
      tierName={data.tierName || ''}
      isPortfolio={isPortfolio}
    />
  );
};

export const OrganizationsList = ({
  entities,
  isLoading,
  fetchNextPage,
  orgSearchValue,
  handleOrgSearchValue,
  entityId,
  onChange,
  isPortfolio,
  hasNextPage,
}: IOrganizationsListProps) => {
  const { classes } = useStyles();
  const [searchInputplaceholder, setSearchInputPlaceholder] = useState(content.SEARCH_PLACEHOLDER_BLURED);

  const clearSearchFilter = () => {
    handleOrgSearchValue('');
  };

  const endAdornment = () => {
    if (orgSearchValue.length > 0) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={clearSearchFilter}>
            <CloseRoundedIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  const renderItems = useCallback(
    (index: number, data: IEntity) => {
      return renderRow({ index, data }, entityId ?? '', onChange, isPortfolio);
    },
    [entityId, onChange, isPortfolio]
  );

  const calculatedHeightOfOrgList = useMemo(() => {
    if (entities.length < maxAllowedOrganizationsWithoutSearchInput) {
      return entities.length * listItemHeight;
    }

    return heightOfListWithSearchInput;
  }, [entities.length]);

  return (
    <div className={classes.container}>
      <TextField
        variant="standard"
        onChange={(event) => handleOrgSearchValue(event.target.value)}
        placeholder={searchInputplaceholder}
        value={orgSearchValue}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        }}
        onFocus={() => {
          setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_FOCUSED);
        }}
        onBlur={() => {
          setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_BLURED);
        }}
        autoFocus={false}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: endAdornment(),
        }}
      />
      <Divider />
      {isLoading && entities.length === 0 ? (
        <EmptyOrganizationItem />
      ) : (
        <Virtuoso
          data={entities}
          style={{
            height: `${calculatedHeightOfOrgList}px`,
            width: `${widthOfListWith}px`,
          }}
          totalCount={entities.length}
          itemContent={renderItems}
          increaseViewportBy={virtualizedListOffset}
          endReached={fetchNextPage}
          context={{ isLoading, hasNextPage: hasNextPage || false }}
          components={{ Footer }}
        />
      )}
    </div>
  );
};
const Footer = ({ context }: { context?: { isLoading: boolean; hasNextPage: boolean } }) => {
  const { classes } = useStyles();

  if (!context?.hasNextPage) return null;
  return <div className={classes.loadingContainer}>{context?.isLoading && <LoadingSpinner />}</div>;
};
