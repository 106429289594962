export const content = {
  HEADER: (name: string | undefined) => (name ? `${name} Chart` : 'Chart'),
  TEMPERATURE: 'Temperature',
  PRESSURE: 'Pressure',
  POMP: 'Pump & Valve Status',
  FLOW: 'Flow',
  TIMEZONE_VALUE: (value: string) => `Timezone: ${value}`,
  CLEAR_ALL: 'Clear All',
  DAYS_30: '30 days',
  MONTH_12: '12 months',
  CUSTOM: 'Custom period',
  CANCEL: 'Cancel',
  SET_PERIOD: 'Set period',
  NO_DATA_ON_MAINCHART: `No data for selected range`,
  NO_DATA_ON_MAINCHART_RANGE: (from: string, to: string) => `${from} to ${to}`,
  RESET_VIEW: 'Reset view',
  DOWNLOAD_CSV: 'Download CSV',
  BACK: 'Back',
  LINE_CHART: 'Line Chart',
  BAR_CHART: 'Bar Chart',
  TOTAL_VOLUME: 'Total volume',
  AVERAGE_FLOW: 'Average Flow Rate',
  DATAPOINT_ERROR_LABEL: 'Error',
  ENERGY_USED: 'Energy used',
  LOAD_FLOW: 'Load flow highest',
  MONITOR: 'Monitor',
  FULL_SCREEN: 'Full screen',
  EXIT_FULL_SCREEN: 'Exit full screen',
};
