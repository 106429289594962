import { MarlinTheme } from '@marlin/shared/theme';
import { Input } from '@marlin/shared/ui-form-common';
import { TOrgAddress } from '@marlin/shared/utils/zod';
import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import {
  Autocomplete,
  AutocompleteCloseReason,
  Button,
  FormHelperText,
  InputLabel,
  PaperProps,
  Typography,
} from '@mui/material';
import { AutocompleteClasses } from '@mui/material/Autocomplete/autocompleteClasses';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { AddAddressAutocompleteOption } from './add-address-autocomplete-option';
import { AutocompleteOption } from './autocomplete-option.component';
import { CustomPaper } from './custom-paper.component';
import { ManualAddressEntryModal } from './manual-address-entry-modal.component';
import { usePlaceDetails } from './place-details.hook';
import { usePlacePrediction } from './place-prediction.hook';
import { usePlaceValidation } from './place-validation.hook';
import { useSessionToken } from './session-token.hook';

const formatAddress = (address: TOrgAddress | null) => {
  if (!address) {
    return '';
  }
  const address2 = address.address2 ? `, ${address.address2}` : '';
  return `${address.address1}${address2}, ${address.city}, ${address.state} ${address.postalCode}, ${address.country}`;
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  input: {
    '&> .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      backgroundColor: theme.palette.background.primary,
      height: '100%',
      width: '100%',
    },
  },
  readonlyBox: {
    backgroundColor: theme.palette.background.primary,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
  },
  label: {
    paddingLeft: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(12),
  },
  line: {
    padding: theme.typography.pxToRem(4),
  },
  editButton: {
    marginTop: theme.typography.pxToRem(8),
    justifyContent: 'flex-start',
  },
}));

interface IAddressValue {
  address?: TOrgAddress | null;
  name?: string | null;
}

interface IAddressAutocompleteProps {
  testid?: string;
  label: string;
  propClasses?: Partial<AutocompleteClasses>;
  fullWidth?: boolean;
  required?: boolean;
  onChange?: (newValue: IAddressValue) => void;
  defaultValue?: IAddressValue | null;
  inputError?: FieldError;
}

export const AddressAutocomplete = ({
  testid,
  label,
  fullWidth,
  propClasses,
  required,
  onChange,
  defaultValue,
  inputError,
  ...props
}: IAddressAutocompleteProps) => {
  const { classes } = useStyles();
  const [value, setValue] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [fallbackValue, setFallbackValue] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [editable, setEditable] = useState(true);
  const { sessionToken, createSessionToken, cancelSessionToken } = useSessionToken();
  const { options, addressFallback } = usePlacePrediction(sessionToken, inputValue, createSessionToken, value);
  const { address, name, clearAddress, setData, error } = usePlaceDetails(
    sessionToken,
    value,
    cancelSessionToken,
    setEditable
  );
  const { fallbackOptions, fallbackAddress, fallbackName, errorFields, setFallbackData, clearFallbackAddress } =
    usePlaceValidation(addressFallback, inputValue, fallbackValue, value, cancelSessionToken, setEditable);
  const [isManualEntryModalOpen, setIsManualEntryModalOpen] = useState(false);

  const onInputChange = useCallback(
    (event: unknown, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'clear') {
        clearAddress();
        clearFallbackAddress();
        setValue(null);
        setFallbackValue(null);
        setInputValue('');
        onChange && onChange({ address: null, name: null });
        return;
      }
      setInputValue(newInputValue || '');
    },
    [clearAddress, clearFallbackAddress, onChange]
  );

  const onValueChange = useCallback(
    (event: unknown, newValue: google.maps.places.AutocompletePrediction | null | string) => {
      if (typeof newValue === 'string') {
        return;
      }
      if (addressFallback) {
        setFallbackValue(newValue);
      } else {
        setValue(newValue);
      }
    },
    [addressFallback]
  );

  const onClose = useCallback(
    (event: unknown, reason: AutocompleteCloseReason) => {
      if (reason === 'selectOption' && address) {
        setEditable(false);
        onChange && onChange({ address, name });
      }
    },
    [address, name, onChange]
  );

  const onEdit = useCallback(() => {
    const isCustomAddress = !!address && !name;

    if (isCustomAddress) {
      setIsManualEntryModalOpen(true);
      return;
    }

    setEditable(true);
    setInputValue(name || fallbackName || '');
    setValue(null);
    setFallbackValue(null);
    setData({ address: null, name: null });
    setFallbackData({ address: null, name: null });
    onChange && onChange({ address: null, name: null });
  }, [address, fallbackName, name, onChange, setData, setFallbackData]);

  const onBlur = useCallback(() => {
    if ('onBlur' in props && typeof props.onBlur === 'function') {
      props.onBlur();
    }
  }, [props]);

  useEffect(() => {
    if (defaultValue?.address && defaultValue?.name) {
      setData(defaultValue);
      setFallbackData(defaultValue);
      setEditable(false);
    }
  }, [defaultValue, setData, setFallbackData]);

  useEffect(() => {
    if (addressFallback) {
      onChange && onChange({ address: fallbackAddress, name: fallbackName });
    } else {
      onChange && onChange({ address, name });
    }
  }, [address, addressFallback, fallbackAddress, fallbackName, name, onChange]);

  const autocompleteOptions = useMemo(() => {
    const opts = addressFallback ? fallbackOptions : options;
    return [...opts, 'add_address'] as const;
  }, [addressFallback, fallbackOptions, options]);

  const onManualEntryClose = useCallback(() => {
    setIsManualEntryModalOpen(false);
  }, []);

  const onManualEntrySave = useCallback(
    (address: TOrgAddress) => {
      setEditable(false);
      setData({ address, name: null });
      setFallbackData({ address, name: null });
      setIsManualEntryModalOpen(false);
    },
    [setData, setFallbackData]
  );

  const onManualEntryOpen = useCallback(() => {
    setIsManualEntryModalOpen(true);
  }, []);

  if (editable) {
    return (
      <div>
        <Autocomplete
          data-testid={testid}
          classes={propClasses}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.structured_formatting.main_text)}
          getOptionKey={(option) => (typeof option === 'string' ? option : option.place_id)}
          filterOptions={(x) => x}
          options={autocompleteOptions}
          autoComplete
          freeSolo
          fullWidth={fullWidth}
          isOptionEqualToValue={(option, value) =>
            typeof option !== 'string' && typeof value !== 'string' && option.place_id === value.place_id
          }
          value={value}
          inputValue={inputValue}
          onChange={onValueChange}
          onInputChange={onInputChange}
          onClose={onClose}
          renderInput={(params) => (
            <Input
              required={required}
              error={inputError}
              className={classes.input}
              label={label}
              {...params}
              testId="address-filter"
            />
          )}
          renderOption={(props, option) =>
            typeof option === 'string' ? (
              <AddAddressAutocompleteOption props={props} key={option} onClick={onManualEntryOpen} />
            ) : (
              <AutocompleteOption key={option.place_id} props={props} option={option} inputValue={inputValue} />
            )
          }
          PaperComponent={(props: PaperProps) => <CustomPaper {...props} />}
          selectOnFocus
          onBlur={onBlur}
        />
        {(error || !!errorFields.length) && !inputError && (
          <FormHelperText error>{content.ADDRESS_ERROR}</FormHelperText>
        )}
        {isManualEntryModalOpen && (
          <ManualAddressEntryModal onClose={onManualEntryClose} onSave={onManualEntrySave} defaultValue={address} />
        )}
      </div>
    );
  }

  return (
    <>
      <fieldset className={classes.readonlyBox} data-testid={`${testid}-fieldset`}>
        <legend>
          <InputLabel className={classes.label} required={required} data-testid={`${testid}-fieldset-label`}>
            {label}
          </InputLabel>
        </legend>
        <div className={classes.line}>
          <Typography variant="body1" data-testid={`${testid}-fieldset-name`}>
            {name || fallbackName || value?.structured_formatting.main_text}
          </Typography>
        </div>
        <div className={classes.line}>
          <Typography variant="body2" color="text.secondary" data-testid={`${testid}-fieldset-address`}>
            {addressFallback ? formatAddress(fallbackAddress) : formatAddress(address)}
          </Typography>
        </div>
        <Button
          variant="text"
          className={classes.editButton}
          onClick={onEdit}
          data-testid={`${testid}-fieldset-edit-button`}
        >
          {content.EDIT_LABEL(label)}
        </Button>
      </fieldset>
      {isManualEntryModalOpen && (
        <ManualAddressEntryModal onClose={onManualEntryClose} onSave={onManualEntrySave} defaultValue={address} />
      )}
    </>
  );
};
