export const createGoogleResponse = (response: string, callback: string) =>
  `/**/${callback} && ${callback}(${response})`;

export const getSearchInput = (urlSearch: string) => {
  const searchInput = urlSearch
    .substring(1) // remove the leading '?'
    .split('&')
    .find((el) => el.startsWith('1s'));
  if (searchInput) {
    const decodedInput = decodeURIComponent(searchInput).replace('1s', '');
    const isMockRequested = decodedInput.startsWith('$');
    return { decodedInput, isMockRequested };
  }
  return { decodedInput: '', isMockRequested: false };
};
