import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  arrayFieldControl: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.typography.pxToRem(32),
  },

  removeButton: {
    position: 'absolute',
    top: theme.typography.pxToRem(8),
    right: theme.typography.pxToRem(-48),
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
