import { MarlinTheme } from '@marlin/shared/theme';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

enum SIGNAL_STRENGTH {
  EXCELLENT = 'Excellent',
  GOOD = 'Good',
  FAIR = 'Fair',
  POOR = 'Poor',
  NO_SIGNAL = 'NoSignal',
}

interface IStyles {
  signal: SIGNAL_STRENGTH;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { signal }) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.typography.pxToRem(6),
  },

  signal: {
    color: getColor(signal, theme),
  },
}));

export interface ISignalStrengthCellProps {
  signalStrength: number;
  gatewayModel: string;
  isOnline: boolean;
}

const signalBoundries = {
  nexa: {
    high: -65,
    medium: -75,
    low: -85,
  },
  monit: {
    high: 75,
    medium: 50,
    low: 11,
  },
};

export const ConnectionStatus = ({ signalStrength, gatewayModel, isOnline }: ISignalStrengthCellProps) => {
  const signal = getSignalPerModel({ signalStrength, gatewayModel, isOnline });
  const { classes, cx } = useStyles({ signal });
  const label = content.GATEWAY_SIGNAL.get(signal.toUpperCase());

  return (
    <div className={classes.cell}>
      <SignalCellularAltRoundedIcon className={cx(classes.icon, classes.signal)} />
      {label}
    </div>
  );
};

const getColor = (signal: SIGNAL_STRENGTH, theme: MarlinTheme) => {
  switch (signal) {
    case SIGNAL_STRENGTH.EXCELLENT:
      return theme.palette.success.light;
    case SIGNAL_STRENGTH.GOOD:
      return theme.palette.success.light;
    case SIGNAL_STRENGTH.FAIR:
      return theme.palette.warning.main;
    case SIGNAL_STRENGTH.POOR:
      return theme.palette.error.main;
    case SIGNAL_STRENGTH.NO_SIGNAL:
      return theme.palette.text.disabled;
    default:
      return theme.palette.error.main;
  }
};

export const getSignalPerModel = ({ signalStrength, gatewayModel, isOnline }: ISignalStrengthCellProps) => {
  const { high, medium, low } = gatewayModel === 'Gateway' ? signalBoundries.nexa : signalBoundries.monit;

  if (!isOnline) {
    return SIGNAL_STRENGTH.NO_SIGNAL;
  }

  if (signalStrength > high) {
    return SIGNAL_STRENGTH.EXCELLENT;
  }
  if (signalStrength > medium) {
    return SIGNAL_STRENGTH.GOOD;
  }
  if (signalStrength > low) {
    return SIGNAL_STRENGTH.FAIR;
  }
  return SIGNAL_STRENGTH.POOR;
};
