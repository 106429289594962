import { useMutation } from '@marlin/shared/utils/react-query';

import { profileCreate } from '../infrastructure/profile-create';
import { ICreateProfile, IUser } from '../user.model';

export const useCreateProfile = () => {
  return useMutation<IUser, unknown, ICreateProfile>({
    mutationFn: profileCreate,
  });
};
