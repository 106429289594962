import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { AVERAGING_FUNCTION_FILTER } from '@marlin/shared/utils-chart';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactNode } from 'react';

import { content } from './content';
import { useStyles } from './select-averaging-function.styles';
import { TooltipContent } from './tooltip-content.component';

export interface IChartRangeOption {
  id: AVERAGING_FUNCTION_FILTER;
  name: ReactNode;
}

export const chartAveragingFunctionTypeOptions: IChartRangeOption[] = [
  {
    id: AVERAGING_FUNCTION_FILTER.AVERAGE,
    name: content.AVERAGING_FUNCTION_AVERAGE,
  },
  {
    id: AVERAGING_FUNCTION_FILTER.MIN,
    name: content.AVERAGING_FUNCTION_MIN,
  },
  {
    id: AVERAGING_FUNCTION_FILTER.MAX,
    name: content.AVERAGING_FUNCTION_MAX,
  },
  {
    id: AVERAGING_FUNCTION_FILTER.VALUE_50,
    name: content.AVERAGING_FUNCTION_MEDIAN,
  },
];

interface ISelectAveragingFunction {
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
  onAveragingFunctionFilterChange: (event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) => void;
  customRenderValue?: (id: AVERAGING_FUNCTION_FILTER) => ReactNode;
}

const tooltipOptions = [content.TOOLTIP_AVERAGE, content.TOOLTIP_MIN, content.TOOLTIP_MAX, content.TOOLTIP_MEDIAN];

export const SelectAveragingFunction = ({
  averagingFunctionFilter,
  onAveragingFunctionFilterChange,
  customRenderValue,
}: ISelectAveragingFunction) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper} data-testid="averaging-function-select">
      <FormControl size="small">
        <Select
          renderValue={customRenderValue ? (id) => customRenderValue(id) : undefined}
          fullWidth
          value={averagingFunctionFilter}
          onChange={onAveragingFunctionFilterChange}
          className={classes.select}
        >
          {chartAveragingFunctionTypeOptions.map(({ name, id }, index) => {
            return (
              <MenuItem key={index} className={classes.menuItem} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div className={classes.iconWrapper}>
        <Tooltip
          text={
            <TooltipContent
              tooltipHeader={content.TOOLTIP_LABEL}
              tooltipFooter={content.TOOLTIP_NOTE}
              tooltipOptions={tooltipOptions}
            />
          }
        >
          <InfoRoundedIcon className={classes.icon} />
        </Tooltip>
      </div>
    </div>
  );
};
