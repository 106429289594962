import { MarlinTheme } from '@marlin/shared/theme';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { useContext, useMemo } from 'react';
import { Handle, NodeProps, NodeResizeControl, Position, getNodesBounds, useReactFlow, useStore } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { EditModeContext } from '../../edit-mode.context';
import { useTouchedState$ } from '../buttons/use-observable-touched-state.hook';
import { maxLabelLength, nodePadding } from '../const';
import { useCommonStyles } from './common.styles';
import { NodeTooltip } from './utils/node-tooltip.component';

const calculateParentColor = (ancestors: number, theme: MarlinTheme): string => {
  const shouldUseSecondaryColor = ancestors % 2 === 1;
  if (shouldUseSecondaryColor) {
    return theme.palette.systemMap.group;
  }
  return theme.palette.primary.contrastText;
};

export const useStyles = makeStyles<{ width: number; height: number; ancestors: number }>()(
  (theme: MarlinTheme, { width, height, ancestors }) => ({
    location: {
      backgroundColor: theme.palette.systemMap.location,
      borderStyle: 'dashed',
      borderColor: theme.palette.systemMap.main,
      borderWidth: theme.typography.pxToRem(1),
    },
    groupLocation: {
      backgroundColor: calculateParentColor(ancestors, theme),
      padding: 0,
      minHeight: height,
      height: '100%',
      minWidth: width,
      width: '100%',
      fontWeight: theme.typography.fontWeightRegular,
    },
    groupLabel: {
      position: 'relative',
      top: height,
    },
    resizeControl: {
      '&.react-flow__resize-control.handle': {
        transform: `translate(-${theme.typography.pxToRem(32)}, -${theme.typography.pxToRem(32)})`,

        background: 'transparent',
        border: 'none',
      },
    },
    resizeIcon: {
      transform: 'rotate(90deg)',
    },
  })
);

type TNodeData = TGraphAsset['data'] & { label: string };

type TIsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
};

function isEqual(prev: TIsEqualCompareObj, next: TIsEqualCompareObj): boolean {
  return prev.minWidth === next.minWidth && prev.minHeight === next.minHeight;
}

export const LocationNode = ({ data, id }: NodeProps<TNodeData>) => {
  const { isEditMode } = useContext(EditModeContext);
  const [, setTouched] = useTouchedState$(false);
  const { getNode, getNodes } = useReactFlow();
  const nodes = getNodes();
  const node = getNode(id);
  const { width, height, isGroup } = useMemo(
    () => ({
      width: node?.width ?? 10,
      height: node?.height ?? 10,
      isGroup: nodes.filter((n) => n.parentNode === id).length > 0,
    }),
    [node, id, nodes]
  );

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeInternals.values()).filter((n) => n.parentNode === id);
    const rect = getNodesBounds(childNodes);

    return {
      minWidth: Math.abs(rect.x - (node?.positionAbsolute?.x || rect.x)) + rect.width + nodePadding,
      minHeight: Math.abs(rect.y - (node?.positionAbsolute?.y || rect.y)) + rect.height + nodePadding,
    };
  }, isEqual);

  const ancestors = useStore((store) => {
    if (!isGroup) {
      return 0;
    }
    const nodes = Array.from(store.nodeInternals.values());
    const getNumberOfAncestors = (nodeId: string): number => {
      const node = nodes.find((n) => n.id === nodeId);
      if (node && node.parentNode) {
        return 1 + getNumberOfAncestors(node.parentNode);
      }
      return 1;
    };

    return getNumberOfAncestors(id);
  });

  const { classes, cx } = useStyles({ width, height, ancestors });
  const { classes: commonClasses } = useCommonStyles();

  if (isGroup) {
    return (
      <div
        className={cx(commonClasses.node, classes.location, classes.groupLocation)}
        data-testid={`location-node-${id}`}
      >
        <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
        <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
        <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
        <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
        <div data-testid={`location-node-${id}-group`}>
          <div className={classes.groupLabel} data-testid={`location-node-${id}-label`}>
            {data.name}
          </div>
        </div>
        {isEditMode && (
          <NodeResizeControl
            className={classes.resizeControl}
            minWidth={minWidth}
            minHeight={minHeight}
            onResizeStart={() => setTouched(true)}
          >
            <OpenInFullRoundedIcon className={classes.resizeIcon} />
          </NodeResizeControl>
        )}
      </div>
    );
  }

  const sliceName = !!(data.name && data.name?.length > maxLabelLength);

  return (
    <NodeTooltip text={data.name || ''} when={sliceName}>
      <div className={cx(commonClasses.node, classes.location)} data-testid={`location-node-${id}`}>
        <div className={commonClasses.nodeContent}>
          <span className={commonClasses.nodeLabel} data-testid={`location-node-${id}-label`}>
            {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data.name}
          </span>
          <LocationOnRoundedIcon />
        </div>
        <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
        <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
        <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
        <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
      </div>
    </NodeTooltip>
  );
};
