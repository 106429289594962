import { MarlinTheme } from '@marlin/shared/theme';
import { MODEL } from '@marlin/shared/utils/zod';
import { NodeProps } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { useCommonStyles } from '../common.styles';
import { EquipmentHeader } from './components/equipment-header.component';
import { HealthStatus } from './components/health-status.component';
import { MetadataGrid } from './components/metadata-grid.component';
import { PerformanceStatus } from './components/performance-status.component';
import { SanitizationHeader } from './components/sanitization-header.component';
import { StartupHeader } from './components/startup-header.component';
import { RegisteredEquipmentContext } from './hooks/use-registered-equipment-context';
import { useRegisteredEquipment } from './hooks/use-registered-equipment.hook';
import { TNodeData, TStatus } from './types';
import { getBorderColorByEquipmentStatus, getEquipmentIcon } from './utils/utils';

export const useStyles = makeStyles<{ status: TStatus }>()((theme: MarlinTheme, { status }) => ({
  equipment: {
    padding: 0,
  },
  lastContainer: {
    borderRadius: `0 0 ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
  },
  metadataWrapper: {
    borderRight: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderLeft: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderBottom: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
  },
}));

export const RegisteredEquipmentNode = ({ data, id }: NodeProps<TNodeData>) => {
  const registeredEquipment = useRegisteredEquipment(data);
  const { modeValue, headerStatus, performanceStatus, healthStatus, status } = registeredEquipment;
  const { classes, cx } = useStyles({ status });
  const { classes: commonClasses } = useCommonStyles();
  const isModeEnabled = modeValue === 'startup' || modeValue === 'sanitize';

  return (
    <RegisteredEquipmentContext.Provider value={registeredEquipment}>
      {modeValue === 'startup' && (
        <StartupHeader lastReadingValues={data?.lastReadingValues || []} id={data.linkedId || ''} />
      )}
      {modeValue === 'sanitize' && (
        <SanitizationHeader lastReadingValues={data?.lastReadingValues || []} variant="max" id={data.linkedId || ''} />
      )}
      <div className={cx(commonClasses.node, classes.equipment)} data-testid={`equipment-node-${id}`}>
        <EquipmentHeader
          name={data.name || ''}
          model={data?.metadata?.tags?.model || ''}
          status={headerStatus}
          isModeEnabled={isModeEnabled}
          icon={getEquipmentIcon(data?.metadata?.tags?.model as MODEL)}
          id={data.linkedId || ''}
          testId={id}
        />
        <div className={classes.metadataWrapper}>
          <PerformanceStatus status={performanceStatus} />
          {data?.metadata?.layout && (
            <MetadataGrid
              metadataLayout={data?.metadata?.layout}
              metadataGroups={data?.metadata?.metadataGroups || []}
              lastReadingValues={data?.lastReadingValues || []}
              nodeId={id}
            />
          )}
          <HealthStatus status={healthStatus} className={classes.lastContainer} />
        </div>
      </div>
    </RegisteredEquipmentContext.Provider>
  );
};
