const devices = '/api/device';
const devicesFilter = `${devices}/filter`;
const device = `${devices}/{deviceId}`;
const deviceDetails = `${device}/details`;
const exportTelementry = '/api/telemetry/export';
const exportTotalFlowTelementry = '/api/flowmeter/export';
const getManufacturerData = `${devices}/{manufacturerId}/info`;
const subtypes = `${devices}/subtypes`;

export const paths = {
  DEVICES: devices,
  DEVICES_FILTER: devicesFilter,
  DEVICE: device,
  DEVICE_DETAILS: deviceDetails,
  EXPORT_TELEMETRY: exportTelementry,
  EXPORT_TOTAL_FLOW_TELEMETRY: exportTotalFlowTelementry,
  GET_MANUFACTURER_DATA: getManufacturerData,
  SUBTYPES: subtypes,
};
