import { Edge } from 'reactflow';

import { TGraphEdge, TGraphLink } from '../schema/cytoscape.schema';

type TExtendedEdge = Edge & {
  isAttachment?: boolean;
};

export const cytoToReactFlowEdgeAdapter = (edge: TGraphEdge): Edge<TGraphEdge['data']> => {
  return {
    ...edge.data,
    data: edge.data,
  };
};

export const reactFlowToCytoEdgeAdapter = (edge: TExtendedEdge): TGraphLink => {
  return {
    data: {
      id: edge.id,
      outlet: edge.source,
      inlet: edge.target,
      source: edge.source,
      target: edge.target,
      isAttachment: edge.isAttachment,
    },
  };
};
