export const content = {
  SENSOR_TYPE_TEMPERATURE_LABEL: 'Temperature Sensor',
  SENSOR_TYPE_PRESSURE_LABEL: 'Pressure Sensor',
  SENSOR_TYPE_LEAK_LABEL: 'Leak Detector',
  SENSOR_TYPE_FLOW_LABEL: 'Flow Meter',
  EQUIPMENT_LABEL: 'Equipment',
  EQUIPMENT_TYPE_LABEL: 'Equipment Type',
  EQUIPMENT_TYPE: {
    DIGITAL_MIXING_VALVE: 'Digital Mixing Valve',
    BOILER: 'Boiler',
    HOT_WATER_HEATER: 'Water Heater',
    NONE: 'Other',
    LEAK_PREVENTION: 'Leak Prevention',
    PARTITION_CONTROLLER: 'Partition Controller',
    PRESSURE_REDUCING_VALVE: 'Pressure Reducing Valve (PRV)',
    PUMP: 'Pump',
    SHUT_OFF_VALVE: 'Shut Off Valve',
    WATER_TANK: 'Water Tank',
  },
};
