import { ALERT_CAUSE, THomeDeviceSchema } from '@marlin/asset/data-access/home-dashboard';
import { formatSensorDatapoints } from '@marlin/shared/utils-format-reading';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { content } from '../../content';
import { useStyles } from './card-device.component.styles';
import { ReadingCriticality } from './device-readings-criticality.component';

type THomeCardDeviceProps = {
  device: THomeDeviceSchema;
};

const HomeCardDevice = ({ device }: THomeCardDeviceProps) => {
  const { classes } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);

  const currentReading = device.deviceLastReadings ? formatSensorDatapoints(device.deviceLastReadings) : content.NA;
  const routeToDeviceDrawerOnPage = useCallback(
    () => routes.deviceDrawer.open(currentUrl, device.deviceId),
    [device.deviceId, currentUrl]
  );

  const deviceAlerts = useMemo(() => device.alerts || [], [device.alerts]);

  const isLostCommunication = useMemo(() => {
    return deviceAlerts.some((dev) => dev.alertCause === ALERT_CAUSE.LOST_COMMUNICATION);
  }, [deviceAlerts]);

  const isLowBattery = useMemo(() => {
    return deviceAlerts.some((dev) => dev.alertCause === ALERT_CAUSE.LOW_BATTERY);
  }, [deviceAlerts]);

  return (
    <div className={classes.wrapper} onClick={() => router.goTo(routeToDeviceDrawerOnPage())}>
      <div className={classes.deviceNameWrapper}>
        <Typography
          className={classes.deviceName}
          variant="body2"
          data-testid={createDataTestId('card-assets-device-name', device?.deviceName)}
        >
          {device?.deviceName}
        </Typography>
      </div>
      <ReadingCriticality tooltipContent={content.SENSOR_ALERT} criticalities={deviceAlerts}>
        {isLostCommunication ? content.LOST_COMMUNICATION : isLowBattery ? content.LOW_BATTERY : currentReading}
      </ReadingCriticality>
    </div>
  );
};

export { HomeCardDevice };
