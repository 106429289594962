import { useEquipmentDetails, useMetadata } from '@marlin/asset/data-access/equipment';
import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import {
  IChartSeries as IChartSeriesBase,
  IRangeFilter,
  RANGE_FILTER,
  getNumberOfBuckets,
  isAercoDevice,
  parseToChartData,
  removeEmptyChartData,
  useDuration,
} from '@marlin/shared/utils-chart';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';

export interface IChartSeries extends Pick<IChartSeriesBase, 'data' | 'uom' | 'name' | 'id'> {}

interface IUseChartDataProps {
  manufacturerId: string | undefined;
  rangeFilter: IRangeFilter;
  datapointGroupNames: string[];
  datapointNames: Array<string>;
  useLastReadings?: boolean;
}

interface ITranslation {
  name: string;
  label?: string;
}

export const useChartData = ({
  manufacturerId = '',
  rangeFilter,
  datapointGroupNames,
  datapointNames,
}: IUseChartDataProps) => {
  const equipmentId = useIdFromPathname();
  const { data: metadataResponse, isLoading } = useMetadata({ equipmentId, enabled: false });
  const datapointGroupNamesLower = datapointGroupNames.map((name) => name.toLowerCase());
  const datapointsGroupMetadata = metadataResponse?.chartDatapoints.reduce<ITranslation[]>((acc, group) => {
    if (datapointGroupNamesLower.includes(group.groupName.toLowerCase())) {
      return [...acc, ...group.datapoints];
    }

    return acc;
  }, []);

  const {
    fromDate: from,
    toDate: to,
    maxSelection,
    minSelection,
  } = useDuration({ rangeFilter, fromDate: null, toDate: null });
  const { data: { model } = {} } = useEquipmentDetails({ equipmentId });

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations: false,
      }),
    [minSelection, maxSelection, rangeFilter.range]
  );

  const {
    data,
    isLoading: isDataLoading,
    refetch,
    isFetching,
  } = useAnalyticsTelemetry(
    {
      dateFrom: from,
      dateTo: to,
      requestedTelemetry: [{ manufacturerId, datapoints: datapointNames }],
      numberOfBuckets,
    },
    {
      select: (data) =>
        rangeFilter.range === RANGE_FILTER.HOURS_8 && isAercoDevice(model) ? removeEmptyChartData({ data }) : data,
    }
  );

  const convertedDatapoints = useMemo(() => parseToChartData(data?.telemetryData), [data]);

  const chartData = useMemo<IChartSeries[]>(() => {
    return datapointNames.map((datapointName) => {
      const datapointMetadata = datapointsGroupMetadata?.find(
        ({ name }) => name.toLowerCase() === datapointName.toLowerCase()
      );
      const datapointItem = convertedDatapoints.find(
        ({ datapointName: convertedDatapointName }) =>
          convertedDatapointName.toLowerCase() === datapointName.toLowerCase()
      );

      return {
        id: datapointName,
        name: datapointMetadata?.label ?? datapointItem?.datapointName ?? '',
        data: datapointItem?.values || [],
        uom: datapointItem?.unitOfMeasure || null,
      };
    });
  }, [convertedDatapoints, datapointNames, datapointsGroupMetadata]);

  return {
    chartData,
    to: maxSelection,
    from: minSelection,
    isLoading: isDataLoading || isLoading || isFetching,
    refetch,
  };
};
