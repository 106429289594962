import { useUpdateUser, useUser } from '@marlin/account-data-access-organization';
import { TUser } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { UserUpdateForm } from './user-update-form/user-profile-upsert-form.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
  },
}));

export const SettingsEditUser = () => {
  const { classes } = useStyles();
  const { userId } = useParams();
  const { goTo } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const onError = () =>
    enqueueSnackbar(content.COMMON_ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });
  const userQuery = useUser({ userId: userId ?? '', onError });
  const { mutateAsync: updateUser } = useUpdateUser({ userId: userId ?? '' });

  const user = useMemo(() => userQuery?.data, [userQuery?.data]);

  const handleSubmit = async (editedUser: Omit<TUser, 'id'>) => {
    try {
      const res = await updateUser({
        userId: userId ?? '',
        newUser: editedUser,
      });
      enqueueSnackbar(content.SUCCESS_MESSAGE, {
        variant: 'success',
        preventDuplicate: true,
      });
      goTo(routes.settings.people.url());
      return res;
    } catch (error) {
      enqueueSnackbar(content.ERROR_MESSAGE, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
    return;
  };

  const handleCancel = () => {
    goTo(routes.settings.people.url());
  };

  if (!user) {
    return null;
  }

  return (
    <Paper className={classes.formContainer} data-testid="edit-user-form">
      <UserUpdateForm user={user} onSubmit={handleSubmit} onCancel={handleCancel} />
    </Paper>
  );
};
