import { createDateString } from '@marlin/shared/utils-common-date';
import { lastReadingValuesSchema } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { emptyTextSchema, paginationSchema } from './utils.schema';

export const DeviceTypeResponseSchema = z.union([
  z.literal('Pressure'),
  z.literal('Temperature'),
  z.literal('Leak'),
  z.literal('FlowMeter'),
  z.literal('PulseMeter'),
]);

export const deviceTypeSchema = DeviceTypeResponseSchema.transform((deviceType) => {
  switch (deviceType) {
    case 'Temperature': {
      return 'TEMPERATURE' as const;
    }
    case 'Pressure': {
      return 'PRESSURE' as const;
    }
    case 'Leak': {
      return 'LEAK' as const;
    }
    case 'FlowMeter':
    case 'PulseMeter': {
      return 'PULSE_METER' as const;
    }
    default: {
      return undefined;
    }
  }
});

export const meterTypeSchema = z.union([z.literal('Gas'), z.literal('Water')]).nullish();

export const deviceResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  deviceType: DeviceTypeResponseSchema,
  locationId: z.string().nullish(), //TODO: getting null data from BE
  locationName: z.string().nullish(), //TODO: getting null data from BE
  equipmentName: emptyTextSchema,
  lastReadingValue: z.number(),
  lastReadingTime: z.string().nullish(),
  lastReadingValues: z.array(lastReadingValuesSchema),
  manufacturerId: z.string(),
  displayManufacturerId: z.string(),
  meterType: meterTypeSchema,
});

export const deviceSchema = deviceResponseSchema.transform((device) => ({
  ...device,
  deviceType: deviceTypeSchema.parse(device.deviceType),
  lastReadingTime: createDateString(device.lastReadingTime ?? undefined),
}));

export const getDeviceResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(deviceResponseSchema).nullable(),
});

export const getDeviceSchema = z.object({
  pagination: paginationSchema,
  data: z.array(deviceSchema).nullable(),
});

export const deviceDetailsSchema = z.object({
  id: z.string(),
  name: z.string(),
  manufacturerId: z.string(),
  displayManufacturerId: z.string(),
  deviceType: deviceTypeSchema,
  locationId: z.string(),
  locationName: z.string(),
  parentLocationId: z.string().nullish(),
  parentLocationName: z.string().nullish(),
  manufacturer: z.string(),
  model: z.string(),
  description: z.string().nullish(),
  organizationId: z.string(),
  gatewayId: z
    .string()
    .nullish()
    .transform((gatewayId: string | null | undefined) => gatewayId?.replace('monnit-', '')),
  pinned: z.boolean(),
  automationCount: z.number(),
  lastReadingValue: z.number(),
  lastReadingValues: z.array(lastReadingValuesSchema),
});

export type TDeviceDetails = z.infer<typeof deviceDetailsSchema>;
export type TGetDeviceResponseSchema = z.infer<typeof getDeviceResponseSchema>;
export type TGetDeviceSchema = z.infer<typeof getDeviceSchema>;
export type TDeviceSchema = z.infer<typeof deviceSchema>;
export type TDeviceTypeSchema = z.infer<typeof deviceTypeSchema>;
export type TMeterType = z.infer<typeof meterTypeSchema>;
