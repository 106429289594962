import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';

import { content } from './content';

interface IConfirmationModal {
  automationName: string;
  text?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmationModal = ({ onConfirm, onClose, automationName, text }: IConfirmationModal) => {
  return (
    <ModalMedium
      onClose={onClose}
      title={content.TOGGLE_AUTOMATION_MODAL.TITLE}
      body={<div>{text ? text : content.TOGGLE_AUTOMATION_MODAL.BODY(automationName)}</div>}
      footer={
        <>
          <Button variant="text" onClick={onClose}>
            {content.TOGGLE_AUTOMATION_MODAL.CANCEL}
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            {content.TOGGLE_AUTOMATION_MODAL.TURN_OFF}
          </Button>
        </>
      }
    />
  );
};
