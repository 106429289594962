import { TExtractFnReturnType, TQueryConfig, getHttpClient } from '@marlin/shared/utils/react-query';
import { useQuery } from '@marlin/shared/utils/react-query';
import { Equipment } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { QUERY_KEY } from './query-key.enum';

// TODO: old code, for desktop backword compatibility
export const getEquipmentDetails = async ({ equipmentId }: { equipmentId: string }) => {
  const response = await getHttpClient().get(paths.EQUIPMENT_DETAILS, { params: { equipmentId } });

  return Equipment.parse(response);
};

type TQueryFnType = typeof getEquipmentDetails;

type TUseEquipmentOptions = {
  equipmentId: string;
  config?: TQueryConfig<TQueryFnType>;
};

export const useEquipmentDetails = ({ equipmentId, config }: TUseEquipmentOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    ...config,
    queryKey: [QUERY_KEY.EQUIPMENT_DETAILS, equipmentId],
    queryFn: () => getEquipmentDetails({ equipmentId }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
      if (config?.onError) config.onError(error);
    },
  });
};
