import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IAlert } from '../types';
import { SnoozeSection } from './snooze-section.component';
import { TimeSection } from './time-section.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(-10),
    },
  },
}));

interface IAlertContentHeaderMobileProps {
  alert: IAlert;
}

export function SnoozedAlertContentHeaderMobile({ alert }: IAlertContentHeaderMobileProps) {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.row}>
        <CriticalityControl severity={alert.criticality} />
        <SnoozeSection
          id={alert.id}
          deviceId={alert.device.id}
          automationName={alert.automation.name}
          snoozeUntil={alert.snoozeUntil}
          automationId={alert.automation.id}
          deviceName={alert.device.name}
        />
      </div>
      <TimeSection label={content.CARD.TIME_SNOOZED} datetime={alert.snoozeUntil} />
    </>
  );
}
