import { invalidateAnalyticsTelemetry, queryKey } from '@marlin/asset/data-access/telemetry';
import { DurationModal, durationOptions } from '@marlin/asset/shared/ui/custom-duration-modal';
import { MarlinTheme } from '@marlin/shared/theme';
import { SelectAveragingFunction, SelectDuration } from '@marlin/shared/ui-form';
import { PageHeader } from '@marlin/shared/ui-page';
import { RANGE_FILTER, isOfChartRangeType, useCustomPeriodModalContextStore } from '@marlin/shared/utils-chart';
import { AVERAGING_FUNCTION_FILTER } from '@marlin/shared/utils-chart';
import { queryClient } from '@marlin/shared/utils/react-query';
import BarChartRounded from '@mui/icons-material/BarChartRounded';
import { Box, Modal, SelectChangeEvent } from '@mui/material';
import noop from 'lodash/noop';
import moment from 'moment';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useDurationDetailsStore } from '../context/duration-details.context';
import { useMultiChartsStore } from '../context/multi-charts.context';
import { useSaveAnalyticsUserData } from '../hooks/use-save-user-data.hook';
import { TChartType } from '../types';
import { getChartIcon } from '../utils/get-chart-icon-name';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
    marginLeft: theme.typography.pxToRem(16),
  },
  buttonWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  showLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.4),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
  },
  switches: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
  },
  switchLabel: {
    margin: 0,
  },
  bottomWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chartIcon: {
    height: theme.typography.pxToRem(32),
    width: 'auto',
  },
  modal: {
    backgroundColor: theme.palette.background.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },
}));

export const Header = ({
  chartType,
  title,
  hasNoCharts,
}: {
  chartType: TChartType | null;
  onResetView: () => void;
  title: string;
  hasNoCharts?: boolean;
}) => {
  const { classes } = useStyles();
  const [range] = useMultiChartsStore((store) => store.rangeFilter.range);
  const [averagingFunctionFilter, setMultiChartsStore] = useMultiChartsStore((store) => store.averagingFunctionFilter);
  const [durationDetails] = useDurationDetailsStore((store) => store);
  const { onAveragingFunctionFilterSave } = useSaveAnalyticsUserData();

  const [open] = useCustomPeriodModalContextStore((store) => store.open);

  const onRangeFilterChange = useCallback(
    (event: SelectChangeEvent<RANGE_FILTER>) => {
      const newRangeFilter = event.target.value as RANGE_FILTER;

      if (newRangeFilter === RANGE_FILTER.CUSTOM) {
        return;
      }

      if (isOfChartRangeType(newRangeFilter)) {
        Object.keys(durationDetails).forEach((chartId) => {
          durationDetails[chartId].handleZoomChange();
        });

        setMultiChartsStore({
          rangeFilter: {
            currentDate: moment().utc(),
            range: newRangeFilter,
          },
        });
      }
    },
    [durationDetails, setMultiChartsStore]
  );

  const onAveragingFunctionFilterChange = useCallback(
    (event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) => {
      const newRangeFilter = event.target.value as AVERAGING_FUNCTION_FILTER;
      setMultiChartsStore({ averagingFunctionFilter: newRangeFilter });
      onAveragingFunctionFilterSave(newRangeFilter);
    },
    [onAveragingFunctionFilterSave, setMultiChartsStore]
  );

  /* TODO: commented to MVP as there is only duration to reset*/
  // const handleReset = () => {
  //   setRangeFilter(RANGE_FILTER.DAYS_7);
  //   resetActiveDatapoints();
  //   onResetView();

  //   queryClient.invalidateQueries(queryKey.ANALYTICS_TELEMETRY());
  // };

  const handleRefresh = () => {
    if (range === RANGE_FILTER.CUSTOM) {
      queryClient.invalidateQueries({ queryKey: queryKey.ANALYTICS_TELEMETRY() });

      return;
    }

    invalidateAnalyticsTelemetry(durationDetails);

    setMultiChartsStore({
      rangeFilter: {
        range,
        currentDate: moment().utc(),
      },
    });
  };

  const onCustomDurationChange = useCallback(
    (option: RANGE_FILTER) => {
      Object.keys(durationDetails).forEach((chartId) => {
        durationDetails[chartId].handleZoomChange();
      });

      setMultiChartsStore({
        rangeFilter: {
          range: option,
          currentDate: moment().utc(),
        },
      });
    },
    [durationDetails, setMultiChartsStore]
  );

  if (hasNoCharts) {
    return (
      <PageHeader
        icon={<BarChartRounded className={classes.chartIcon} />}
        prefix="multi-charts"
        title={content.HEADER.TITLE}
      />
    );
  }

  return (
    <>
      <PageHeader
        isTitleInline={!!title}
        prefix="multi-charts"
        title={title ? title : content.HEADER.TITLE}
        icon={chartType ? getChartIcon(chartType) : <BarChartRounded className={classes.chartIcon} />}
        actions={
          <div className={classes.buttons}>
            {/* TODO: commented to MVP*/}
            {/*<Button variant="outlined" className={classes.buttonWithIcon}>*/}
            {/*  {content.HEADER.DOWNLOAD_CSV}*/}
            {/*  <DownloadIcon />*/}
            {/*</Button>*/}
            {/*<Button variant="outlined" className={classes.buttonWithIcon}>*/}
            {/*  {content.HEADER.PRINT}*/}
            {/*  <PrintIcon />*/}
            {/*</Button>*/}
            <SelectDuration
              rangeFilter={range}
              onRangeFilterChange={onRangeFilterChange}
              onRefresh={handleRefresh}
              options={durationOptions}
              customRenderValue={RenderValue}
            />
            <SelectAveragingFunction
              averagingFunctionFilter={averagingFunctionFilter}
              onAveragingFunctionFilterChange={onAveragingFunctionFilterChange}
            />
            {/* TODO: commented to MVP as there is only duration to reset*/}
            {/* <Button variant="outlined" onClick={handleReset}>
              {content.HEADER.RESET_VIEW}
            </Button> */}
            {/* TODO: commented to MVP*/}
            {/*<Button variant="outlined">{content.HEADER.HELP}</Button>*/}
          </div>
        }
        // TODO: commented to MVP
        // bottomActions={
        //   <div className={classes.bottomWrapper}>
        //     <FormGroup row className={classes.switches}>
        //       <Typography className={classes.showLabel}>{content.HEADER.SHOW}</Typography>
        //       <FormControlLabel
        //         className={classes.switchLabel}
        //         control={<Switch size="small" defaultChecked disabled />}
        //         label={content.HEADER.LEGENDS}
        //       />
        //       <FormControlLabel
        //         className={classes.switchLabel}
        //         control={<Switch size="small" disabled />}
        //         label={content.HEADER.TRIGGERS}
        //       />
        //       <FormControlLabel
        //         className={classes.switchLabel}
        //         control={<Switch size="small" disabled />}
        //         label={content.HEADER.ALERT}
        //       />
        //       <FormControlLabel
        //         className={classes.switchLabel}
        //         control={<Switch size="small" disabled />}
        //         label={content.HEADER.DEADBAND}
        //       />
        //     </FormGroup>
        //   </div>
        // }
      />
      <Modal open={open} onClose={noop} data-testid="header-section-duration-modal">
        <Box className={classes.modal} data-testid="header-section-duration-modal-body">
          <DurationModal setRangeFilter={onCustomDurationChange} />
        </Box>
      </Modal>
    </>
  );
};

const RenderValue = (id: RANGE_FILTER) => {
  const [fromDate] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDate] = useCustomPeriodModalContextStore((store) => store.toDate);

  if (id === RANGE_FILTER.CUSTOM) {
    return (
      <div>
        {content.CUSTOM_PERIOD} {fromDate?.format('MM/DD/YY')} - {toDate?.format('MM/DD/YY')}
      </div>
    );
  }

  return <div>{durationOptions.find(({ id: currentId }) => currentId === id)?.name}</div>;
};
