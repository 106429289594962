// eslint-disable-next-line @nx/enforce-module-boundaries
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

import { isEmailInternal } from './interal-users';
import { ILogger, ITrackedUser, TMixpanelProperties } from './logger.types';

export class Logger implements ILogger {
  private mixpanel: OverridedMixpanel;

  public constructor(token: string) {
    mixpanel.init(token, {
      debug: false,
      track_pageview: 'full-url',
      persistence: 'localStorage',
    });
    this.mixpanel = mixpanel;
  }

  public async identify(user: ITrackedUser): Promise<void> {
    const { email, distinctId: b2cId } = user;

    if (isEmailInternal(email)) {
      this.mixpanel.opt_out_tracking();
      return Promise.resolve();
    }

    this.mixpanel.opt_in_tracking();
    const identifyResult = await this.mixpanel.identify(email);
    this.registerProperties({ b2cId, email });
    return identifyResult;
  }

  public identifyWarranty(): void {
    this.mixpanel.opt_in_tracking();
  }
  public track(event_name: string, properties?: TMixpanelProperties): void {
    return this.mixpanel.track(event_name, properties);
  }
  public registerProperties(properties: TMixpanelProperties): void {
    return this.mixpanel.register(properties);
  }
  public reset(): void {
    return this.mixpanel.reset();
  }
}
