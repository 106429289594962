import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';

import { content } from '../content';
import { usePrintIframe } from '../hooks/use-print-iframe.hook';
import { JsonViewer } from './json-viewer.component';

interface IConfigFileDialog {
  isOpen: boolean;
  onClose: () => void;
  configValue: string;
}

export const ConfigFileDialog = ({ isOpen, onClose, configValue }: IConfigFileDialog) => {
  const { enqueueSnackbar } = useSnackbar();

  const onCopyClick = async () => {
    await navigator?.clipboard.writeText(configValue).then(() => {
      enqueueSnackbar(content.TEST_CONFIGURATION.COPY_SUCCESS, { variant: 'success' });
    });
  };

  const { onPrintClick } = usePrintIframe(configValue);

  return (
    <Dialog onClose={onClose} data-testId="config-file-modal" open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>{content.CONFIG_FILE_DIALOG.SHOW_CONFIG_FILE}</DialogTitle>
      <DialogContent>
        <JsonViewer value={configValue} label={content.CONFIG_FILE_DIALOG.MODBUS_CONFIGURATION} height={500} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} data-testId="config-file-modal-cancel-button">
          {content.CONFIG_FILE_DIALOG.CANCEL}
        </Button>
        <Button variant="outlined" data-testId="config-file-modal-print-button" onClick={onPrintClick}>
          {content.CONFIG_FILE_DIALOG.PRINT}
        </Button>
        <Button variant="outlined" data-testId="config-file-modal-copy-button" onClick={onCopyClick}>
          {content.CONFIG_FILE_DIALOG.COPY}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
