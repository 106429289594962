import { useDeleteDevice } from '@marlin/asset/data-access/device';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { content } from './content';

interface IDeleteAutomationStore {
  deleteDevice: (deviceId: string) => void;
}

export const useDeleteDeviceStore = (): IDeleteAutomationStore => {
  const { enqueueSnackbar } = useSnackbar();
  const deleteDeviceMutation = useDeleteDevice();

  const deleteDevice = useCallback(
    async (deviceId: string) => {
      try {
        await deleteDeviceMutation.mutateAsync(deviceId);

        //Note: mutation changes aren't detected so snackbar have to be defined here
        enqueueSnackbar(content.CONFIRMATION_MESSAGE_DELETE_SENSOR_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (e) {
        enqueueSnackbar(content.CONFIRMATION_MESSAGE_DELETE_SENSOR_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [deleteDeviceMutation, enqueueSnackbar]
  );

  return {
    deleteDevice,
  };
};
