import { Equipment, EquipmentTypeEnum } from '@marlin/shared/utils/zod';
import { z } from 'zod';

export const EquipmentFormTypes = z.enum([
  'intellistation',
  'aercoBenchmark',
  'aercoInnovation',
  'sentinel',
  'other',
] as const);

export type TEquipmentFormTypes = z.infer<typeof EquipmentFormTypes>;

export enum REGISTERED_EQUIPMENT_TYPE {
  TEKMAR = 'Tekmar',
  SENTINEL = 'Sentinel',
  AERCO_WATER_HEATER = 'AercoWaterHeater',
  AERCO_BOILER = 'AercoBoiler',
}

export const EquipmentPayloadBase = z.object({
  name: z.string(),
  locationId: z.string(),
  description: z.string().optional(),
  commissionDate: z.string().optional(),
  serialNumber: z.string().optional(),
});
export const OtherEquipmentPayload = z
  .object({
    brand: z.string().optional(),
    model: z.string().optional(),
    type: z.string().optional(),
  })
  .merge(EquipmentPayloadBase);
export type TOtherEquipmentPayload = z.infer<typeof OtherEquipmentPayload>;

export const RegisteredEquipmentPayload = z
  .object({
    registrationCode: z.string(),
  })
  .merge(EquipmentPayloadBase)
  .transform((data) => ({ ...data, registrationFlow: REGISTERED_EQUIPMENT_TYPE.TEKMAR }));
export type TRegisteredEquipmentPayload = z.infer<typeof RegisteredEquipmentPayload>;

export const AercoWaterHeaterEquipmentPayload = RegisteredEquipmentPayload.transform((data) => ({
  ...data,
  registrationFlow: REGISTERED_EQUIPMENT_TYPE.AERCO_WATER_HEATER,
}));
export type TAercoWaterHeaterEquipmentPayload = z.infer<typeof AercoWaterHeaterEquipmentPayload>;

export const AercoBoilerEquipmentPayload = RegisteredEquipmentPayload.transform((data) => ({
  ...data,
  registrationFlow: REGISTERED_EQUIPMENT_TYPE.AERCO_BOILER,
}));
export type TAercoBoilerEquipmentPayload = z.infer<typeof AercoBoilerEquipmentPayload>;

export const SentinelEquipmentPayload = RegisteredEquipmentPayload.transform((data) => ({
  ...data,
  registrationFlow: REGISTERED_EQUIPMENT_TYPE.SENTINEL,
}));
export type TSentinelEquipmentPayload = z.infer<typeof SentinelEquipmentPayload>;

export const EquipmentsResponse = z.array(Equipment);

export const RegisteredEquipmentResponse = z.object({
  name: z.string(),
  locationId: z.string().nullable(),
  serialNumber: z.string().nullable(),
  brand: z.string().nullable(),
  model: z.string().nullable(),
  description: z.string().nullable(),
  commissionDate: z.string().nullish(),
  type: EquipmentTypeEnum.nullish(),
  isRegistered: z.boolean().optional(),
  registrationStatusChangeDate: z.string().nullish(),
  id: z.string(),
  organizationId: z.string().nullish(),
  deviceCount: z.number().nullish(),
  locationName: z.string().nullish(),
});
export type TRegisteredEquipmentResponse = z.infer<typeof RegisteredEquipmentResponse>;
