import { ITriggerEntry } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { checkIfTwoRangesOverlapped, convertDateStringToTimestamp } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

import { ICharTicks, TBucketedAlert } from '../../../types';
import { IAnnotationTooltip } from '../annotations/types';
import { IAnnotationForm } from '../extended-chart-form.component';
import { IRange } from '../hooks/use-main-chart.hook';
import {
  alertColorOpacity,
  annotationDashWidth,
  annotationStrokeWidth,
  deadbandAreas,
  deadbandColorOpacity,
  deadbandLines,
  invisibleStrokeWidth,
  lineSeriesNames,
  offsetX,
  tresholdAreas,
  tresholdLines,
  triggerColorOpacity,
} from './const';
import {
  IAutomationMetadata,
  IAutomationOptions,
  IAutomationSeries,
  ICreateAreaSeriesProps,
  ICreateLineSeriesProps,
  IExtendedAutomationSeries,
  IPointAnnotation,
  IRuleSeriesHistory,
} from './types';

export const createRangeAreaSeries = ({
  from,
  to,
  value,
  name,
  ...restAutomationData
}: ICreateAreaSeriesProps): IExtendedAutomationSeries => ({
  name,
  type: 'rangeArea' as const,
  data: [
    { x: convertDateStringToTimestamp(from), y: value },
    { x: convertDateStringToTimestamp(to), y: value },
  ],
  ...restAutomationData,
});

export const createLineSeries = ({
  range,
  from,
  to,
  value,
  name,
  ...restAutomationData
}: ICreateLineSeriesProps): IExtendedAutomationSeries => ({
  name,
  type: 'line' as const,
  data: [
    {
      x: range && convertDateStringToTimestamp(from) < range.from ? range.from : convertDateStringToTimestamp(from),
      y: value,
    },
    { x: range && convertDateStringToTimestamp(to) > range.to ? range.to : convertDateStringToTimestamp(to), y: value },
  ],
  ...restAutomationData,
});

export const getOverlappingTriggers = (trigger: ITriggerEntry, triggerEntries: ITriggerEntry[]) =>
  triggerEntries.filter(({ from, to }) =>
    checkIfTwoRangesOverlapped({ from, to }, { from: trigger.from, to: trigger.to })
  );

export const createLowDeadbandSeries = (
  trigger: ITriggerEntry,
  triggerEntries: ITriggerEntry[],
  restAutomationData: IAutomationMetadata,
  range: IRange | null
): IExtendedAutomationSeries[] => {
  const overlappingTriggers = getOverlappingTriggers(trigger, triggerEntries);

  if (overlappingTriggers.length) {
    return overlappingTriggers
      .map((thresholdTrigger: ITriggerEntry, index) => {
        const seriesStart = index === 0 ? trigger.from : thresholdTrigger.from;
        const seriesEnd = index === overlappingTriggers.length - 1 ? trigger.to : thresholdTrigger.to;
        const value: [number, number] = [thresholdTrigger.value, trigger.value];

        return [
          createRangeAreaSeries({
            from: seriesStart,
            to: seriesEnd,
            value,
            name: 'LOW_DEADBAND',
            ...restAutomationData,
          }),
          createLineSeries({
            range,
            from: seriesStart,
            to: seriesEnd,
            value: trigger.value,
            name: 'LOW_DEADBAND_LINE',
            ...restAutomationData,
          }),
        ];
      })
      .flat();
  }

  return [];
};

export const createHighDeadbandSeries = (
  trigger: ITriggerEntry,
  triggerEntries: ITriggerEntry[],
  restAutomationData: IAutomationMetadata,
  range: IRange | null
): IExtendedAutomationSeries[] => {
  const overlappingTriggers = getOverlappingTriggers(trigger, triggerEntries);

  if (overlappingTriggers.length) {
    return overlappingTriggers
      .map((thresholdTrigger: ITriggerEntry, index) => {
        const seriesStart = index === 0 ? trigger.from : thresholdTrigger.from;
        const seriesEnd = index === overlappingTriggers.length - 1 ? trigger.to : thresholdTrigger.to;
        const value: [number, number] = [trigger.value, thresholdTrigger.value];

        return [
          createRangeAreaSeries({
            from: seriesStart,
            to: seriesEnd,
            value,
            name: 'HIGH_DEADBAND',
            ...restAutomationData,
          }),
          createLineSeries({
            range,
            from: seriesStart,
            to: seriesEnd,
            value: trigger.value,
            name: 'HIGH_DEADBAND_LINE',
            ...restAutomationData,
          }),
        ];
      })
      .flat();
  }

  return [];
};

export const createSeriesForGivenRule = (
  ruleHistory: IRuleSeriesHistory,
  ticks: ICharTicks,
  automationsSettings: IAnnotationForm,
  range: IRange | null
): IExtendedAutomationSeries[] => {
  const { thresholdMin, thresholdMax, deadbandMin, deadbandMax, ...restAutomationData } = ruleHistory;

  let deadbandMinSeries: IExtendedAutomationSeries[] = [];
  let deadbandMaxSeries: IExtendedAutomationSeries[] = [];
  let thresholdMinSeries: IExtendedAutomationSeries[] = [];
  let thresholdMaxSeries: IExtendedAutomationSeries[] = [];

  if (deadbandMin.length && thresholdMin.length && automationsSettings.deadband) {
    deadbandMinSeries = deadbandMin
      .map((deadbandMinTrigger) => createLowDeadbandSeries(deadbandMinTrigger, thresholdMin, restAutomationData, range))
      .flat();
  }

  if (deadbandMax.length && thresholdMax.length && automationsSettings.deadband) {
    deadbandMaxSeries = deadbandMax
      .map((deadbandMaxTrigger) =>
        createHighDeadbandSeries(deadbandMaxTrigger, thresholdMax, restAutomationData, range)
      )
      .flat();
  }

  if (thresholdMin.length && automationsSettings.trigger) {
    thresholdMinSeries = thresholdMin
      .map((trigger) => [
        createRangeAreaSeries({
          from: trigger.from,
          to: trigger.to,
          value: [ticks.lowest, trigger.value],
          name: 'LOW_THRESHOLD',
          ...restAutomationData,
        }),
        createLineSeries({
          range,
          from: trigger.from,
          to: trigger.to,
          value: trigger.value,
          name: 'LOW_THRESHOLD_LINE',
          ...restAutomationData,
        }),
      ])
      .flat();
  }

  if (thresholdMax.length && automationsSettings.trigger) {
    thresholdMaxSeries = thresholdMax
      .map((trigger) => [
        createRangeAreaSeries({
          from: trigger.from,
          to: trigger.to,
          value: [trigger.value, ticks.highest],
          name: 'HIGH_THRESHOLD',
          ...restAutomationData,
        }),
        createLineSeries({
          range,
          from: trigger.from,
          to: trigger.to,
          value: trigger.value,
          name: 'HIGH_THRESHOLD_LINE',
          ...restAutomationData,
        }),
      ])
      .flat();
  }

  return [...deadbandMinSeries, ...deadbandMaxSeries, ...thresholdMinSeries, ...thresholdMaxSeries];
};

export const sortSeriesByType = (b: IAutomationSeries, a: IAutomationSeries) => {
  if (a.type === 'rangeArea' && b.type === 'line') {
    return -1;
  }

  if (a.type === 'line' && b.type === 'rangeArea') {
    return 1;
  }

  return 0;
};

export const reduceAutomationSeriesTypesToOptionsFactory =
  (theme: MarlinTheme) =>
  (options: IAutomationOptions, series: IExtendedAutomationSeries): IAutomationOptions => {
    if (tresholdLines.includes(series.name)) {
      const color = series.criticality === 'HIGH' ? theme.palette.error.main : theme.palette.warning.main;

      return {
        fill: {
          colors: [...options.fill.colors, color],
          opacity: [...options.fill.opacity, triggerColorOpacity],
        },
        stroke: {
          ...options.stroke,
          colors: [...options.stroke.colors, color],
          width: [...options.stroke.width, annotationStrokeWidth],
          dashArray: [...options.stroke.dashArray, annotationDashWidth],
        },
        markers: {
          colors: [...options.markers.colors, theme.palette.primary.dark],
          fillOpacity: [...options.markers.fillOpacity, 0],
          width: [...options.markers.width, 0],
        },
      };
    }

    if (deadbandLines.includes(series.name)) {
      return {
        fill: {
          colors: [...options.fill.colors, theme.palette.secondary.main],
          opacity: [...options.fill.opacity, deadbandColorOpacity],
        },
        stroke: {
          ...options.stroke,
          colors: [...options.stroke.colors, theme.palette.secondary.main],
          width: [...options.stroke.width, annotationStrokeWidth],
          dashArray: [...options.stroke.dashArray, annotationDashWidth],
        },
        markers: {
          colors: [...options.markers.colors, theme.palette.primary.dark],
          fillOpacity: [...options.markers.fillOpacity, 0],
          width: [...options.markers.width, 0],
        },
      };
    }

    if (tresholdAreas.includes(series.name)) {
      const color = series.criticality === 'HIGH' ? theme.palette.error.main : theme.palette.warning.main;

      return {
        fill: {
          colors: [...options.fill.colors, color],
          opacity: [...options.fill.opacity, triggerColorOpacity],
        },
        stroke: {
          ...options.stroke,
          colors: [...options.stroke.colors, color],
          width: [...options.stroke.width, invisibleStrokeWidth],
          dashArray: [...options.stroke.dashArray, invisibleStrokeWidth],
        },
        markers: {
          colors: [...options.markers.colors, theme.palette.primary.dark],
          fillOpacity: [...options.markers.fillOpacity, 0],
          width: [...options.markers.width, 0],
        },
      };
    }

    if (deadbandAreas.includes(series.name)) {
      return {
        fill: {
          colors: [...options.fill.colors, theme.palette.secondary.main],
          opacity: [...options.fill.opacity, deadbandColorOpacity],
        },
        stroke: {
          ...options.stroke,
          colors: [...options.stroke.colors, theme.palette.secondary.main],
          width: [...options.stroke.width, invisibleStrokeWidth],
          dashArray: [...options.stroke.dashArray, invisibleStrokeWidth],
        },
        markers: {
          colors: [...options.markers.colors, theme.palette.primary.dark],
          fillOpacity: [...options.markers.fillOpacity, 0],
          width: [...options.markers.width, 0],
        },
      };
    }

    return options;
  };

export const reduceAlertGroupsToOptionsFactory =
  (theme: MarlinTheme) =>
  (options: IAutomationOptions, series: TBucketedAlert): IAutomationOptions => {
    const color = series.criticality === 'HIGH' ? theme.palette.error.main : theme.palette.warning.main;

    return {
      fill: {
        colors: [...options.fill.colors, color],
        opacity: [...options.fill.opacity, alertColorOpacity],
      },
      stroke: {
        ...options.stroke,
        colors: [...options.stroke.colors, color],
        width: [...options.stroke.width, invisibleStrokeWidth],
        dashArray: [...options.stroke.dashArray, invisibleStrokeWidth],
      },
      markers: {
        colors: [...options.markers.colors, theme.palette.primary.dark],
        fillOpacity: [...options.markers.fillOpacity, 0],
        width: [...options.markers.width, 0],
      },
    };
  };

const getAnnotationLabelColor = (theme: MarlinTheme, series: IExtendedAutomationSeries): string => {
  if (deadbandLines.includes(series.name)) {
    return theme.palette.secondary.main;
  }

  if (series.criticality === 'HIGH') {
    return theme.palette.error.main;
  }

  return theme.palette.warning.main;
};

const getAnnotationTooltipType = (series: IExtendedAutomationSeries): IAnnotationTooltip['annotationType'] => {
  switch (series.name) {
    case 'LOW_THRESHOLD_LINE': {
      return 'TRESHOLD_MIN';
    }
    case 'HIGH_THRESHOLD_LINE': {
      return 'TRESHOLD_MAX';
    }
    case 'LOW_DEADBAND_LINE': {
      return 'DEADBAND_MIN';
    }
    case 'HIGH_DEADBAND_LINE': {
      return 'DEADBAND_MAX';
    }
    default: {
      return 'TRESHOLD_MIN';
    }
  }
};

const isAfterRange = (range: IRange | null, timestamp: number) => {
  if (range?.to) {
    return timestamp > range.to;
  }

  return false;
};

const createAnnotation = (
  theme: MarlinTheme,
  series: IExtendedAutomationSeries,
  calculateOffset: (y: number) => number,
  range: IRange | null,
  onAnnotationLabelEnter?: (annotationTooltip: IAnnotationTooltip) => void,
  onAnnotationLabelLeave?: () => void,
  uoM?: TUnitOfMeasure | null
): IPointAnnotation | null => {
  if (typeof series.data[1].y === 'number') {
    const color = getAnnotationLabelColor(theme, series);
    const x = isAfterRange(range, series.data[1].x) ? range?.to || 0 - 20 : series.data[1].x;
    const y = series.data[1].y;

    return {
      x: x + calculateOffset(y),
      y: y,
      label: {
        borderColor: color,
        text: series.label || '',
        borderRadius: 20,
        offsetY: 13,

        style: {
          background: color,
          color: theme.palette.primary.contrastText,
          fontWeight: 700,
          padding: {
            top: 2,
            bottom: 4,
          },
        },
        mouseEnter: function (settings: YAxisAnnotations, event: MouseEvent) {
          if (event.target instanceof Element && typeof series.data[1].y === 'number') {
            onAnnotationLabelEnter?.({
              currentAnnotation: event.target,
              value: series.data[1].y,
              deviceType: series.deviceType as IAnnotationTooltip['deviceType'],
              name: series.automationName || '',
              annotationType: getAnnotationTooltipType(series),
              uoM,
            });
          }
        },
        mouseLeave: onAnnotationLabelLeave,
      },
    };
  }

  return null;
};

export const reduceAutomationSeriesTypesToPointAnnotationFactory =
  (
    theme: MarlinTheme,
    range: IRange | null,
    onAnnotationLabelEnter?: (annotationTooltip: IAnnotationTooltip) => void,
    onAnnotationLabelLeave?: () => void,
    uoM?: TUnitOfMeasure | null
  ) =>
  (annotations: IPointAnnotation[], series: IExtendedAutomationSeries) => {
    if (lineSeriesNames.includes(series.name)) {
      const calculateOffset = (y: number) => annotations.filter((annotation) => annotation.y === y).length * offsetX;

      const annotation = createAnnotation(
        theme,
        series,
        calculateOffset,
        range,
        onAnnotationLabelEnter,
        onAnnotationLabelLeave,
        uoM
      );

      if (annotation) {
        return [...annotations, annotation];
      }
    }

    return annotations;
  };
