import { MultiCharts } from '@marlin/asset/feature/multi-charts';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const EmbeddedAnalyticsRoutes = (): JSX.Element => {
  return (
    <Route
      path={routes.analytics.root}
      element={
        <PermissionGuard redirectTo={routes.home.dashboard} permissions={[PERMISSIONS.DATA_EXPORT]}>
          <MultiCharts />
        </PermissionGuard>
      }
    />
  );
};
