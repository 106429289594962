import { MarlinTheme } from '@marlin/shared/theme';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { TStatus } from '../types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(10),
    backgroundColor: theme.palette.systemMap.equipment,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
      2
    )} ${theme.typography.pxToRem(4)}`,
    borderRadius: theme.typography.pxToRem(32),
    gap: theme.typography.pxToRem(4),
    color: theme.palette.text.alternative,
    fontWeight: theme.typography.fontWeightMedium,

    svg: {
      height: theme.typography.pxToRem(16),
      width: theme.typography.pxToRem(16),
    },
  },
  error: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,
  },
}));

const statusIcon: Record<TStatus, ReactNode> = {
  success: <CheckCircleRoundedIcon />,
  warning: <WarningRoundedIcon />,
  error: <WarningRoundedIcon />,
  notAvailable: null,
};

interface IEquipmentStatusProps {
  title: string;
  status: TStatus;
  message: string;
  className?: string;
}
export const BaseStatus = ({ title, status, message, className }: IEquipmentStatusProps) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)} data-testid={createDataTestId('equipment-status', title, status)}>
      <div>{title}</div>
      <div
        className={cx(classes.status, {
          [classes.error]: status === 'error',
          [classes.warning]: status === 'warning',
          [classes.success]: status === 'success',
        })}
      >
        {statusIcon[status]}
        {message}
      </div>
    </div>
  );
};
