import { AxiosError } from 'axios';
import { FC, PropsWithChildren } from 'react';
import { PromiseValue } from 'type-fest';

import {
  DefaultOptions,
  Query,
  QueryClient,
  QueryClientProvider,
  UseMutationOptions,
  UseQueryOptions,
} from './react-query-wrapper';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: (query: Query) => {
      return query.isStaleByTime(1000 * 5);
    },
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TExtractFnReturnType<FnType extends (...args: any) => any> = PromiseValue<ReturnType<FnType>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TQueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<TExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TMutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  TExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>;

const testQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const createTestReactQueryWrapper = () => {
  const wrapper: FC<PropsWithChildren> = ({ children }) => (
    <QueryClientProvider client={testQueryClient}>{children}</QueryClientProvider>
  );
  return { wrapper, testQueryClient };
};
