import { z } from 'zod';

// TODO: '' role means that the user is a support user, so for now we are assigning admin role
// TODO: remove '' role when support user in portfolio is implemented
export const RoleSchema = z
  .union([z.literal('Admin'), z.literal('Viewer'), z.literal(''), z.literal('Deleted')])
  .transform((role) => (role === '' ? 'Support' : role));

// TODO: add support when support user in portfolio is implemented
// TODO: '' role means that the user is removed from organization
// TODO: remove '' role when BE will fix this
export const PortfolioRoleSchema = z.union([
  z.literal('Manager'),
  z.literal('Viewer'),
  z.literal('Support'),
  z.literal('Deleted'),
]);

export type TPortfolioRole = z.infer<typeof PortfolioRoleSchema>;
