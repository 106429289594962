import { environment } from '@marlin/environment';
import Axios, { AxiosInstance } from 'axios';

import { TAddressRequest, TAddressResponse } from './address.schema';
import { addressValidationApiUrl } from './config';

export class AddressValidationService {
  private httpClient: AxiosInstance;
  public constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }
  public async validateAddress(address: TAddressRequest): Promise<TAddressResponse> {
    const response = await this.httpClient.post<TAddressResponse>(addressValidationApiUrl, address, {
      params: {
        key: environment.module.googleMapsApiKey,
      },
    });
    return response.data;
  }
}

export const initValidationService = () => {
  const instance = Axios.create({ timeout: 60000 });
  return new AddressValidationService(instance);
};
