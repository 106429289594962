import { MarlinTheme } from '@marlin/shared/theme';
import { TChartDisplayType } from '@marlin/shared/utils-chart';
import BarChartRounded from '@mui/icons-material/BarChartRounded';
import LineAxisRounded from '@mui/icons-material/LineAxisRounded';
import { Button, ButtonGroup } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chartTypeButtonGroup: {
    marginLeft: theme.typography.pxToRem(16),
  },
  typeSelectButton: {
    maxHeight: theme.typography.pxToRem(30),
    fontSize: theme.typography.pxToRem(13),
    maxWidth: theme.typography.pxToRem(140),
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(10)}`,
    letterSpacing: theme.typography.pxToRem(0.46),
    lineHeight: theme.typography.pxToRem(22),
  },
  chartTypeButtonContent: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    alignItems: 'center',
  },
  buttonIcon: {
    width: theme.typography.pxToRem(18),
    height: theme.typography.pxToRem(18),
  },
}));

interface IChartTypeSelect {
  chartDisplayType: TChartDisplayType;
  setChartDisplayType: (chartDisplayType: TChartDisplayType) => void;
}

export const ChartTypeSwitcher = ({ chartDisplayType, setChartDisplayType }: IChartTypeSelect) => {
  const { classes } = useStyles();
  const isBarChart = chartDisplayType === 'bar';

  return (
    <ButtonGroup className={classes.chartTypeButtonGroup}>
      <Button
        className={classes.typeSelectButton}
        variant={!isBarChart ? 'contained' : 'outlined'}
        onClick={() => setChartDisplayType('rangeArea')}
      >
        <span className={classes.chartTypeButtonContent} data-testid="line-chart-button">
          <LineAxisRounded className={classes.buttonIcon} />
          {content.LINE_CHART}
        </span>
      </Button>
      <Button
        className={classes.typeSelectButton}
        variant={isBarChart ? 'contained' : 'outlined'}
        onClick={() => setChartDisplayType('bar')}
      >
        <span className={classes.chartTypeButtonContent} data-testid="bar-chart-button">
          <BarChartRounded className={classes.buttonIcon} />
          {content.BAR_CHART}
        </span>
      </Button>
    </ButtonGroup>
  );
};
