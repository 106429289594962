import { TAssetLink } from '@marlin/asset/data-access/asset';
import { AttachmentsTable } from '@marlin/asset/shared/ui/attachment-validation';
import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { content } from './content';
import { useStyles } from './delete-modal.styles';

interface IModalBodyProps {
  isLoading: boolean;
  deleteAssetName?: string;
  deleteAssetId?: string;
  canDelete: boolean;
  assetLinks?: TAssetLink;
  flowLinkInfo: string;
  flowLinksConfirmation: string;
  confirmationMessage: (value: string) => JSX.Element;
}

export function ModalBody({
  isLoading,
  deleteAssetName,
  deleteAssetId,
  canDelete,
  assetLinks,
  flowLinkInfo,
  flowLinksConfirmation,
  confirmationMessage,
}: IModalBodyProps): JSX.Element | null {
  const { classes } = useStyles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!canDelete) {
    return <div>{content.DELETE_DEFAULT_CONFIRMATION_MESSAGE(content.EQUIPMENT)}</div>;
  }

  if (assetLinks?.links && assetLinks.links.length > 0) {
    return (
      <div>
        <div>{flowLinkInfo}</div>
        <div className={classes.linksTable}>
          <AttachmentsTable assetId={deleteAssetId} title={content.FLOW_LINKS_TABLE} data={assetLinks.links} />
        </div>
        <div className={classes.confirmText}>{flowLinksConfirmation}</div>
      </div>
    );
  }

  return (
    <>
      {confirmationMessage(deleteAssetName || '')}
      <div className={classes.confirmText}>{content.ACTION_CAN_NOT_BE_UNDONE}</div>
    </>
  );
}
