import {
  TEquipmentSchedulePayload,
  useUpdateEquipmentSchedule,
  useUpdateEquipmentSettings,
} from '@marlin/asset/data-access/equipment';
import {
  ControlSwitcher,
  SettingsErrorPage,
  SettingsLeaveGuard,
  SettingsSectionAccordion,
  SettingsSectionSkeleton,
} from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useSearchParams } from '@marlin/shared/utils-router';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Backdrop } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useDatapointsContext } from '../../context/datapoints.context';
import { useEditSettingContext } from '../../context/edit-setting.context';
import { useEquipmentContext } from '../../context/equipment.context';
import { useEquipmentSettingsSections } from '../../hooks/settings/use-equipment-settings-sections.hook';
import { useEquipmentSettings } from '../../hooks/settings/use-settings-datapoints.hook';
import { ScheduleSettings } from './schedule/schedule.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(746),
  },
  loadingSpinnerWrapper: {
    minHeight: theme.typography.pxToRem(350),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.indexPosition.foreground,
  },
}));

export const EquipmentSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = useCallback(() => {
    enqueueSnackbar(content.SAVE_SUCCESS_MSG, { variant: 'success', preventDuplicate: true });
  }, [enqueueSnackbar]);
  const onError = () => {
    enqueueSnackbar(content.ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });
  };
  const { classes } = useStyles();
  const equipmentId = useIdFromPathname();
  const [searchParams, setSearchParams] = useSearchParams();
  const settingGroupId = searchParams.get('settingGroupId');
  const settingsGroups = useEquipmentSettingsSections();
  const { mutateAsync: updateSettings, isLoading: isSaving } = useUpdateEquipmentSettings({
    equipmentId,
    settingGroupId: settingGroupId,
    onSuccess,
    onError,
  });
  const { mutateAsync: updateEquipmentSchedule, isLoading: isUpdating } = useUpdateEquipmentSchedule({
    equipmentId,
    onSuccess,
    onError,
  });
  const { isEquipmentError, refetchEquipment, isLoading: isEquipmentLoading } = useEquipmentContext();

  useEffect(() => {
    if (searchParams.has('settingGroupId') || searchParams.has('settingId')) {
      setSearchParams((currentParams) => {
        if (currentParams.has('settingGroupId')) {
          currentParams.delete('settingGroupId');
        }

        if (currentParams.has('settingId')) {
          currentParams.delete('settingId');
        }

        return currentParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSetting = async (name: string, value: string, prevValue: string) => {
    return await updateSettings({ name, value, prevValue });
  };
  const saveSchedule = async (equipmentId: string, value: TEquipmentSchedulePayload) =>
    await updateEquipmentSchedule({
      equipmentId,
      data: value,
    });
  const editSettingContext = useEditSettingContext();

  if (isEquipmentError) {
    return <SettingsErrorPage onRefreshClick={refetchEquipment} />;
  }

  if (isEquipmentLoading) {
    return (
      <div className={classes.loadingSpinnerWrapper}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {settingsGroups.map(({ title, id }) => {
        return (
          <SettingsSectionAccordion title={title} id={id} key={id}>
            {id === settingGroupId ? <SectionBody updateSettings={saveSetting} /> : null}
          </SettingsSectionAccordion>
        );
      })}
      <SettingsSectionAccordion title={content.SCHEDULE} id={SETTINGS_GROUP.SCHEDULE} key={SETTINGS_GROUP.SCHEDULE}>
        {settingGroupId === SETTINGS_GROUP.SCHEDULE && <ScheduleSettings saveSchedule={saveSchedule} />}
      </SettingsSectionAccordion>
      {settingGroupId !== SETTINGS_GROUP.SCHEDULE && (
        <SettingsLeaveGuard saveSetting={saveSetting} {...editSettingContext} />
      )}
      <Backdrop className={classes.backdrop} open={isSaving || isUpdating}>
        <LoadingSpinner />
      </Backdrop>
    </div>
  );
};

const SectionBody = ({
  updateSettings,
}: {
  updateSettings: (name: string, value: string, prevValue: string) => Promise<void>;
}) => {
  const equipmentId = useIdFromPathname();
  const [searchParams] = useSearchParams();
  const settingGroupId = searchParams.get('settingGroupId');
  const { data: settings = [], isLoading: isSectionLoading } = useEquipmentSettings({
    equipmentId,
    expandedGroup: settingGroupId,
  });

  const { getDatapoint } = useDatapointsContext();
  const overrideMode = getDatapoint('overrideMode');
  const isEditable = usePermission(PERMISSIONS.UPDATE_EQUIPMENT_SETTINGS);
  const isSanitize = overrideMode?.value === 'sanitize';
  const editSettingContext = useEditSettingContext();

  if (isSectionLoading) {
    return <SettingsSectionSkeleton />;
  }

  return (
    <ControlSwitcher
      datapoints={settings}
      updateSettings={updateSettings}
      disabled={isSanitize || !isEditable}
      disabledTooltipText={content.SETTINGS_DISABLED_TOOLTIP}
      displayDisabledTooltip={isSanitize && isEditable}
      {...editSettingContext}
    />
  );
};
