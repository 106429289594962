import { createContext, useContext } from 'react';

export enum FEATURE_FLAGS {
  HOT_SOS = 'hotSos',
  PORTFOLIO = 'portfolio',
  SUPPORT = 'support',
  SUPPORT_SETTINGS = 'supportSettings',
  BOILER = 'boiler',
  WATER_HEATER = 'waterHeater',
  PLANT = 'plant',
  GATEWAY = 'gateway',
  SENTINEL = 'sentinel',
}

export type TFeatureFlagsContext = Record<FEATURE_FLAGS, boolean>;

export const FeatureFlagsContext = createContext<TFeatureFlagsContext>({
  hotSos: false,
  portfolio: false,
  support: false,
  supportSettings: false,
  boiler: false,
  waterHeater: false,
  plant: false,
  gateway: false,
  sentinel: false,
});

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
