import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  activeNavLink: {
    display: 'flex',
    height: theme.typography.pxToRem(46),
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.14),
    borderRadius: theme.typography.pxToRem(4),
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.14),
    },

    '& svg': {
      color: theme.palette.primary.main,
    },
  },

  navLink: {
    display: 'flex',
    height: theme.typography.pxToRem(46),
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    border: `1px solid transparent`,
    borderRadius: theme.typography.pxToRem(4),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },

  navLinkContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(32),
    marginLeft: theme.typography.pxToRem(18),
  },

  navLinkContentColapsed: {
    margin: 'auto',
  },

  expandContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  expandIcon: {
    marginLeft: theme.typography.pxToRem(48),
  },

  nestedItemsContainer: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
  },
  container: {
    border: `${theme.typography.pxToRem(1)} solid transparent`,
    borderRadius: theme.typography.pxToRem(4),
  },
  nestedContainer: {
    borderRadius: theme.typography.pxToRem(4),
  },
}));
