import { environment } from '@marlin/environment';
import { useTheme } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

import { MarlinTheme } from '../theme';

interface ILogoProps {
  height?: number;
  mobileHeight?: number;
}

const defaultHeight = environment.app.name === 'Nexa' ? 40 : 48;
const defaultMobileHeight = environment.app.name === 'Nexa' ? 20 : undefined;

export const useStyles = makeStyles<ILogoProps>()((theme: MarlinTheme, { height, mobileHeight }) => ({
  logo: {
    display: 'inline-flex',
    '& svg': {
      height: theme.typography.pxToRem(height || defaultHeight),
      [theme.breakpoints.down('lg')]: {
        height: theme.typography.pxToRem(mobileHeight || height || defaultMobileHeight || defaultHeight),
      },
    },
  },
}));

export const FullLogo = ({ height, mobileHeight }: ILogoProps) => {
  const theme = useTheme<MarlinTheme>();
  const { classes } = useStyles({ height, mobileHeight });

  return <span className={classes.logo}>{theme.icon.fullLogo}</span>;
};
