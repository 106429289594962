import { environment } from '@marlin/environment';

export const content = {
  SUCCESS_MESSAGE: 'Recipient has been updated.',
  ERROR_UNKNOWN: 'Unknown error',
  ERROR_MESSAGE: 'Error during update recipient.',
  ERROR_INVITATION_ALREADY_IN_PROGRESS:
    'You cannot change this e-mail. It looks like a user has been already invited and waiting for confirmation.',
  ERROR_USER_ALREADY_EXISTS: `It looks like the user you are trying to add is already an Alert Recipient. If you would like this recipient to also have access to the ${environment.app.name} platform, please delete them from the Alert Recipient table, and take the steps necessary to add them as a user.`,
  ERROR_INVITATION_EXPIRED: 'It looks like this invitation has been expired.',
  ERROR_INVITATION_ALREADY_ACCEPTED: 'It looks like this invitation has been accepted already.',
  ERROR_INVALID_INVITATION: 'It looks like this invitation is no longer valid.',
  ERROR_RECIPIENT_ALREADY_IN_ORGANIZATION:
    'It looks like an Alert Recipient with the provided email already exist. Please view the table of Alert Recipients to make additional edits.',
};
