import { MODEL } from '@marlin/shared/utils/zod';

import conquest100 from './Conquest_100.png';
import conquest1100 from './Conquest_1100-1200.png';
import conquest130 from './Conquest_130.png';

export const getConquestLogo = (type: string) => {
  switch (type) {
    case MODEL.CONQUEST_100_20L100A_GCL:
    case MODEL.CONQUEST_100_25L100A_GCL:
    case MODEL.CONQUEST_100_30L100A_GCL:
      return conquest100;
    case MODEL.CONQUEST_130_40L130A_GCML:
    case MODEL.CONQUEST_130_40L130A_GCMLW:
    case MODEL.CONQUEST_130_50L130A_GCML:
    case MODEL.CONQUEST_130_50L130A_GCMLW:
    case MODEL.CONQUEST_130_60L130A_GCML:
    case MODEL.CONQUEST_130_60L130A_GCMLW:
    case MODEL.CONQUEST_130_70L130AGCMLW:
    case MODEL.CONQUEST_130_70L130A_GCML:
    case MODEL.CONQUEST_130_80L130A_GCML:
    case MODEL.CONQUEST_130_80L130A_GCMLW:
    case MODEL.CONQUEST_130_90L130A_GCML:
    case MODEL.CONQUEST_130_100L130A_GCML:
      return conquest130;
    case MODEL.CONQUEST_1100_CQT1100:
    case MODEL.CONQUEST_1100_CQTO1100:
    case MODEL.CONQUEST_1200_CQT1200:
    case MODEL.CONQUEST_1200_CQTO1200:
      return conquest1100;
    default:
      return conquest100;
  }
};
