import { CREATE_RECIPIENT_ERROR_CODE, useCreateRecipient, useRecipients } from '@marlin/account-data-access-recipient';
import { ICreateRecipient } from '@marlin/account/ui/recipient-create';
import {
  CRITICALITY,
  TCreateAutomation,
  useAutomation,
  useUpdateAutomation,
} from '@marlin/alert/data-access/automated-action';
import { isDataLoading } from '@marlin/shared/utils/react-query';
// TODO: move useAssets to assets domain
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { ASSET_TYPE, useAssets } from '@marlin/system-map/data-access/system-map';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';

import { content } from './content';

const defaultValues: TCreateAutomation = {
  name: '',
  criticality: CRITICALITY.LOW,
  device: {
    id: '',
    name: '',
    manufacturerId: '',
  },
  manufacturerId: '',
  channels: {},
  snoozeIntervalInMinutes: 0,
  conditions: {},
  duration: {
    value: null,
    unit: null,
  },
};

export type TCreateRecipientError = AxiosError<{ errorCode: CREATE_RECIPIENT_ERROR_CODE }>;

export const useStore = (automationId: string, closeRecipientModal: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const automationQuery = useAutomation({ automationId });
  const locationsQuery = useAssets(
    {
      filter: ASSET_TYPE.LOCATION,
    },
    true
  );
  const updateAutomationMutation = useUpdateAutomation();

  const recipientsQuery = useRecipients();
  const createRecipientMutation = useCreateRecipient();

  const isLoading = useMemo(
    () => isDataLoading([locationsQuery, automationQuery]) || updateAutomationMutation.isLoading,
    [automationQuery, locationsQuery, updateAutomationMutation.isLoading]
  );

  const isError = useMemo(
    () => automationQuery.isError || locationsQuery.isError,
    [automationQuery.isError, locationsQuery.isError]
  );

  const isSuccess = useMemo(() => updateAutomationMutation.isSuccess, [updateAutomationMutation.isSuccess]);

  const saveRecipient = useCallback(
    (data: ICreateRecipient) =>
      createRecipientMutation
        .mutateAsync(data)
        .then(() => {
          enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_RECIPIENT_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
          closeRecipientModal();
        })
        .catch((error) => {
          if (!error.response?.data?.errorCode) {
            enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_RECIPIENT_ERROR, {
              variant: 'error',
              preventDuplicate: true,
            });
          }
        }),
    [createRecipientMutation, enqueueSnackbar, closeRecipientModal]
  );

  const save = useCallback(
    (data: TCreateAutomation) => {
      return updateAutomationMutation.mutateAsync({
        ...automationQuery.data,
        ...data,
        id: automationId,
        isEnabled: automationQuery.data?.isEnabled || false,
      });
    },
    [automationId, automationQuery.data, updateAutomationMutation]
  );

  useEffect(() => {
    if (updateAutomationMutation.isError) {
      enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }

    if (updateAutomationMutation.isSuccess) {
      enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_SUCCESS, {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  }, [updateAutomationMutation.isError, updateAutomationMutation.isSuccess, enqueueSnackbar]);

  const saveRecipientErrorCode = useMemo(() => {
    const error = createRecipientMutation.error as TCreateRecipientError;
    return error?.response?.data?.errorCode;
  }, [createRecipientMutation.error]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  }, [isError, enqueueSnackbar]);

  const closeModal = useCallback(() => {
    closeRecipientModal();
    createRecipientMutation.reset();
  }, [closeRecipientModal, createRecipientMutation]);

  return {
    locations: locationsQuery?.data ?? [],
    defaultValues: automationQuery.data ?? defaultValues,
    recipients: recipientsQuery?.data ?? [],
    ruleType: automationQuery.data?.ruleType,
    saveRecipient,
    saveRecipientErrorCode,
    isLoading,
    isSuccess,
    save,
    isError,
    closeModal,
  };
};
