import {
  AssetTypeSchema,
  NodeTypeSchema,
  UpdateAssetTypeSchema,
  UpdateNodeTypeSchema,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

const tagSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});

export const createSystemMapNodeParamsSchema = z.object({
  nodeType: NodeTypeSchema,
  nodeId: z.string().nullish(),
  assetId: z.string().nullish(),
  assetType: AssetTypeSchema.nullish(),
  positionX: z.number().nullish(),
  positionY: z.number().nullish(),
  name: z.string().nullish(),
  inletTags: z.array(tagSchema).nullish(),
  outletTags: z.array(tagSchema).nullish(),
  tags: z.array(tagSchema).nullish(),
});

export const createSystemMapNodeRequestSchema = z
  .object({
    nodeType: UpdateNodeTypeSchema,
    nodeId: z.string().nullish(),
    assetId: z.string().nullish(),
    assetType: UpdateAssetTypeSchema.nullish(),
    deviceType: UpdateAssetTypeSchema.nullish(),
    positionX: z.number().nullish(),
    positionY: z.number().nullish(),
    name: z.string().nullish(),
    tags: z.array(tagSchema).nullish(),
    inletTags: z.array(tagSchema).nullish(),
    outletTags: z.array(tagSchema).nullish(),
  })
  .transform((params) => {
    if (params.assetType !== 'Equipment') {
      return params;
    }

    const isSmartEquipment = params.tags?.some((tag) => tag.name === 'Model' && tag.value !== undefined);

    return {
      ...params,
      assetType: isSmartEquipment ? 'Device' : 'Equipment',
      deviceType: isSmartEquipment ? 'Equipment' : params.deviceType,
    };
  });

export const updateSystemMapNodeParamsSchema = z.object({
  name: z.string(),
});

export const updateSystemMapNodeRequestSchema = updateSystemMapNodeParamsSchema.transform((params) => ({
  name: params.name,
}));

export const createSystemMapLinkParamsSchema = z.object({
  inletData: createSystemMapNodeParamsSchema,
  outletData: createSystemMapNodeParamsSchema,
  flowMapId: z.string().nullish(),
  linkOptions: z
    .object({
      tags: z.array(tagSchema).nullish(),
    })
    .nullish(),
});

export const createSystemMapLinkRequestSchema = z.object({
  inletData: createSystemMapNodeRequestSchema,
  outletData: createSystemMapNodeRequestSchema,
  flowMapId: z.string().nullish(),
  linkOptions: z
    .object({
      tags: z.array(tagSchema).nullish(),
    })
    .nullish(),
});

export type TCreateSystemMapNodeParams = z.infer<typeof createSystemMapNodeParamsSchema>;
export type TCreateSystemMapNodeRequest = z.infer<typeof createSystemMapNodeRequestSchema>;
export type TCreateSystemMapLinkParams = z.infer<typeof createSystemMapLinkParamsSchema>;
export type TCreateSystemMapLinkRequest = z.infer<typeof createSystemMapLinkRequestSchema>;
export type TUpdateSystemMapNodeParams = z.infer<typeof updateSystemMapNodeParamsSchema>;
export type TUpdateSystemMapNodeRequest = z.infer<typeof updateSystemMapNodeRequestSchema>;
export type TTag = z.infer<typeof tagSchema>;
