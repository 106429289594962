import { z } from 'zod';

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

export const meterTypeSchema = z
  .string()
  .nullish()
  .transform((value) => {
    if (value === 'Gas') {
      return 'Gas';
    }
    return 'Water';
  });

export type TMeterType = z.infer<typeof meterTypeSchema>;

export interface IDevice {
  id: string;
  name: string;
  locationId: string;
  equipmentId: string;
  deviceType: DEVICE_TYPE;
  manufacturerId: string;
  manufacturer?: string;
  model?: string;
  description?: string | null;
  createdByUserId: string;
  createdDateTime: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  lastReadingValue?: number;
  pinned?: boolean;
  gatewayId?: string;
  commissionDate?: string | null;
  pulseMeterTypeId?: string | null;
  pulseMeterTypeIds?:
    | {
        pulseMeterTypeId?: string;
        order: number;
      }[]
    | null;
  meterType?: TMeterType;
  isCompound?: boolean;
  devices?: IDevice[];
}

export enum UNIT_OF_MEASURE {
  LEAK = 'WaterDetect',
  FAHRENHEIT = 'Fahrenheit',
  PRESSURE = 'PSI',
}

export interface IChartData {
  batteryLevel?: number;
  enqueuedDateTime?: string;
  eventDateTime?: string;
  eventId?: string;
  manufacturerId?: string;
  signalStrength?: number;
  dataPoint?: number;
  unitOfMeasure?: UNIT_OF_MEASURE;
  voltage?: number;
}

export interface ITimeSeriesSummary {
  chartData: IChartData[];
  high: IChartData;
  low: IChartData;
  recent: IChartData;
}

export interface IDeviceDetails extends IDevice {
  equipment?: string;
  location?: string;
  timeSeriesSummary?: ITimeSeriesSummary;
}

export interface IDeviceResponse {
  id: string;
  name: string;
  locationId: string;
  equipmentId: string;
  deviceType: string;
  manufacturerId: string;
  createdByUserId: string;
  createdDateTime: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  manufacturer?: string;
  description?: string;
  lastReadingValue?: number;
  model?: string;
  pinned?: boolean;
  gatewayId?: string;
  commissionDate?: string;
  pulseMeterTypeId?: string;
  meterType?: string;
  devices?: IDeviceResponse[];
}

export interface IDeviceDetailsResponse {
  id: string;
  name: string;
  locationId: string;
  equipmentId: string;
  deviceType: string;
  manufacturerId: string;
  createdByUserId: string;
  createdDateTime: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  equipment?: string;
  location?: string;
  timeSeriesSummary?: ITimeSeriesSummary;
  gatewayId?: string;
}
