import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { DEVICE_DRAWER_ACTION_TYPE, useDeviceDrawerContext } from '@marlin/shared/utils/sensor-provider';
import { ReactNode, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DeviceDrawerContainer, DeviceDrawerHeader, Header } from './components';
import { DeviceDetailsContext } from './context/device-details.context';
import { useDeviceDrawer } from './hooks/use-device-drawer.hook';
import { DeviceDrawerTabs } from './tabs.component';

interface IDeviceDrawerProps {
  controls?: ReactNode;
}

const useStyles = makeStyles()(() => ({
  loader: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

const DeviceDrawer = ({ controls }: IDeviceDrawerProps) => {
  const { shouldRender, deviceDetails, isLoading } = useDeviceDrawer();
  const { deviceDrawerDispatch } = useDeviceDrawerContext();
  const { classes } = useStyles();

  useEffect(() => {
    if (!shouldRender) {
      deviceDrawerDispatch({
        type: DEVICE_DRAWER_ACTION_TYPE.RESET_SENSORS_CONTEXT,
      });
    }
  }, [deviceDrawerDispatch, shouldRender]);

  if (!shouldRender) {
    return null;
  }

  if (isLoading) {
    return (
      <DeviceDrawerContainer>
        <div className={classes.loader}>
          <LoadingSpinner />
        </div>
      </DeviceDrawerContainer>
    );
  }

  return (
    <DeviceDrawerContainer>
      <DeviceDetailsContext.Provider value={deviceDetails}>
        <Header>{controls}</Header>
        <DeviceDrawerHeader />
        <DeviceDrawerTabs />
      </DeviceDetailsContext.Provider>
    </DeviceDrawerContainer>
  );
};

export { DeviceDrawer };
