import { TUnitOfMeasure, datapointToUom, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import {
  TControlState,
  TControlType,
  TDatapoint,
  TDatapointWithMetadata,
  TDatapointWithUiMetadata,
  TDeviceMetadataDatapointsResponse,
  TMetadataStatus,
  content,
  datapointsLabelsMap,
  datapointsOptionsLabelsMap,
  datapointsStatusLabelsMap,
} from '@marlin/shared/utils/datapoint-mappers';

import { getControlType } from './get-datapoint-type';

const customControlTypesUom: TUnitOfMeasure[] = ['ValvePosition', 'WaterDetect'];

export const parseValue = (datapoint: TDatapoint, metadata: TDeviceMetadataDatapointsResponse | undefined) => {
  if (metadata?.type === 'bool') {
    return datapoint.value === '1' || datapoint.value === 'True' ? 'on' : 'off';
  }
  if (!metadata?.options.length) {
    return datapoint.value;
  }

  const option = metadata.options.find((option) => option.id === datapoint.value);
  return option?.name ?? 'error';
};

export const getControlState = (parsedValue: string, controlType: TControlType): TControlState => {
  const inactiveValues = ['', 'off', 'closed', 'closing', 'absent', 'noDemand'];
  const errorValues = ['error'];

  if (controlType === 'occupancy') {
    return parsedValue === 'on' ? 'success' : 'error';
  }

  if (errorValues.includes(parsedValue)) return 'error';

  if (inactiveValues.includes(parsedValue)) return 'inactive';

  return 'active';
};

const getStatus = (datapointStatus?: string | null, metadataStatuses?: TMetadataStatus[]) => {
  const status = metadataStatuses?.find((status: TMetadataStatus) => status.id === datapointStatus)?.name;
  const errorValues = ['open', 'short'];
  const okValues = ['okay', 'demand'];

  if (!status || okValues.includes(status)) {
    return null;
  }

  if (errorValues.includes(status)) {
    return 'error';
  }

  return status;
};

export const mergeDatapointsWithMetadata = (
  metadataList: TDeviceMetadataDatapointsResponse[] | undefined,
  datapoints: TDatapoint[] | undefined,
  datapointCallback?: (datapoint: TDatapointWithMetadata, allDatapoints: TDatapoint[]) => TDatapointWithMetadata
): TDatapointWithMetadata[] => {
  if (!metadataList || !datapoints) {
    return [];
  }

  const metadataListWithStatuses = mapMetadataWithStatuses(metadataList);
  const datapointsWithStatuses = mapDatapointsWithStatuses(datapoints);

  const datapointsWithMetadata: TDatapointWithMetadata[] = [];

  for (const datapoint of datapointsWithStatuses) {
    const metadata = metadataListWithStatuses.find(
      (metadataListItem) => metadataListItem.name.toLowerCase() === datapoint.name.toLowerCase()
    );
    const status = getStatus(datapoint.status, metadata?.availableStatuses);
    const controlType = getControlType(metadata, status);
    const value = !status ? parseValue(datapoint, metadata) : status;

    const datapointWithMetadata = {
      name: datapoint.name,
      value,
      unitOfMeasure: datapoint.unitOfMeasure ?? metadata?.unitOfMeasure ?? null,
      controlState: getControlState(value, controlType),
      controlType,
      lastReadingTime: datapoint.lastReadingTime,
    };

    if (datapointCallback) {
      datapointsWithMetadata.push(datapointCallback(datapointWithMetadata, datapoints));
      continue;
    }

    datapointsWithMetadata.push(datapointWithMetadata);
  }

  return datapointsWithMetadata;
};

export const getDatapointWithUiMetadata = (datapoint: TDatapointWithMetadata): TDatapointWithUiMetadata => {
  return {
    ...datapoint,
    label: datapointsLabelsMap.get(datapoint.name.toLowerCase()) ?? datapoint.name,
    displayedValue: getDisplayedValue(datapoint),
  };
};

export const getUomFromApiDatapoints =
  (apiDatapoints: TDatapoint[]) =>
  (datapoint: TDatapointWithMetadata): TDatapointWithMetadata => {
    const apiUnitOfMeasure =
      apiDatapoints.find((apiDatapoint) => apiDatapoint.name === datapoint.name)?.unitOfMeasure ??
      datapoint.unitOfMeasure;

    return {
      ...datapoint,
      unitOfMeasure: datapoint.unitOfMeasure?.length === 0 ? apiUnitOfMeasure : datapoint.unitOfMeasure,
    };
  };

export const mapMetadataWithStatuses = (metadata: TDeviceMetadataDatapointsResponse[]) => {
  return metadata.map((metadataItem) => {
    if (metadataItem.name.toLowerCase().endsWith('status')) {
      return metadataItem;
    }

    return {
      ...metadataItem,
      availableStatuses:
        (metadata.find(
          (metadataItemStatus) => metadataItemStatus.name.toLowerCase() === `${metadataItem.name.toLowerCase()}status`
        )?.options as unknown as TMetadataStatus[]) ?? null,
    };
  });
};

export const mapDatapointsWithStatuses = (datapoints: TDatapoint[]) => {
  return datapoints.map((datapoint) => {
    if (datapoint.name.toLowerCase().endsWith('status')) {
      return datapoint;
    }

    return {
      ...datapoint,
      status:
        datapoints.find(
          (metadataItemStatus) => metadataItemStatus.name.toLowerCase() === `${datapoint.name.toLowerCase()}status`
        )?.value ?? null,
    };
  });
};

export const getDisplayedValue = (datapoint: TDatapointWithMetadata) => {
  if (!datapoint.value) {
    return content.EMPTY_DATAPOINT_VALUE;
  }

  const uom = datapointToUom(datapoint.name, datapoint.unitOfMeasure);

  if (uom && customControlTypesUom.includes(uom)) {
    return parseDisplayedValue(datapoint.value, uom);
  }

  switch (datapoint.controlType) {
    case 'option':
      return datapointsOptionsLabelsMap.get(datapoint.value) ?? datapoint.value;
    case 'boolean':
      return datapoint.value === 'on' ? content.DATAPOINT_OPTIONS_LABELS.ON : content.DATAPOINT_OPTIONS_LABELS.OFF;
    case 'status':
      return datapointsStatusLabelsMap.get(datapoint.value) ?? datapoint.value;
    case 'occupancy':
      return datapoint.value === 'on'
        ? content.DATAPOINT_OPTIONS_LABELS.OCCUPIED
        : datapoint.value === 'off'
        ? content.DATAPOINT_OPTIONS_LABELS.UNOCCUPIED
        : content.DATAPOINT_OPTIONS_LABELS.NOT_USED;
    default:
      return parseDisplayedValue(datapoint.value, uom);
  }
};
