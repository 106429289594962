import { elkAdapter } from '@marlin/system-map/utils/system-map-adapters';
import ELK, { ElkExtendedEdge, ElkNode } from 'elkjs/lib/elk.bundled.js';
import { useCallback, useEffect, useState } from 'react';
import { Edge, Node } from 'reactflow';

import { layoutOptions, levelLayoutOptions } from './const';

interface ITag {
  name?: string | null;
  value?: string | null;
}

const elk = new ELK();

const isValidCoordinate = (coordinate?: number) => {
  return coordinate !== undefined;
};

const isValidPosition = (position?: { x: number; y: number }) => {
  return isValidCoordinate(position?.x) && isValidCoordinate(position?.y);
};

const hasRfPosition = (node?: Node) => {
  return node?.data?.nodeTags?.find((tag: ITag) => tag.name === 'rfPosition');
};

export const useLayout = (initialNodes: Node[], initialEdges: Edge[]) => {
  const [layoutNodes, setLayoutNodes] = useState<Node[]>([]);
  const [layoutEdges, setLayoutEdges] = useState<Edge[]>([]);

  const applyLayout = useCallback(
    async ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) => {
      const elkChildren = elkAdapter.nodesFromReactFlow(nodes, levelLayoutOptions);
      const elkEdges = edges as unknown as ElkExtendedEdge[];

      const { children: layoutNodes, edges: layoutEdges }: ElkNode = await elk.layout({
        id: 'root',
        children: elkChildren,
        edges: elkEdges,
        layoutOptions,
      });
      const reactFlowNodes = elkAdapter.nodesToReactFlow(layoutNodes || []);

      const nodesWithInitialPosition = reactFlowNodes.map((node) => {
        const originalNode = initialNodes.find((initialNode) => initialNode.id === node.id);
        if (isValidPosition(originalNode?.position) && hasRfPosition(originalNode)) {
          return {
            ...node,
            position: {
              x: originalNode?.position?.x || 0,
              y: originalNode?.position?.y || 0,
            },
          } as Node;
        }
        return node as Node;
      });

      return {
        layoutNodes: nodesWithInitialPosition,
        layoutEdges: layoutEdges as unknown as Edge[],
      };
    },
    [initialNodes]
  );

  useEffect(() => {
    if (!initialNodes.length || !initialEdges.length) {
      setLayoutNodes(initialNodes);
      setLayoutEdges(initialEdges);
      return;
    }
    const prepareNodes = async () => {
      const { layoutNodes, layoutEdges } = await applyLayout({
        nodes: initialNodes,
        edges: initialEdges,
      });

      setLayoutNodes(layoutNodes);
      setLayoutEdges(layoutEdges);
    };

    prepareNodes();
  }, [applyLayout, initialEdges, initialNodes]);

  return { layoutNodes, layoutEdges };
};
