import { modelContent } from '@marlin/shared/utils/datapoint-mappers';
import { ProductDetails } from '@marlin/ui/product-details';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useMemo } from 'react';

import { useDatapointsContext } from '../../../../context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../../../../context/equipment-details-config-context';
import { useEquipmentContext } from '../../../../context/equipment.context';
import { useDatapointTiles } from '../../../../hooks/equipment-product-details/use-datapoint-tiles.hook';
import { useProductDetails } from '../../../../hooks/equipment-product-details/use-product-details.hook';
import type { TEquipmentProductDatapoint } from '../../../../utils/types';
import { EquipmentStatus } from '../equipment-status/equipment-status.component';
import { EquipmentProductDetailsDatapointTiles } from './equipment-product-details-datapoint-tiles.component';

interface IEquipmentProductDetails {
  productDatapoints?: Array<TEquipmentProductDatapoint>;
}

export const EquipmentProductDetails = ({ productDatapoints }: IEquipmentProductDetails) => {
  const { equipment } = useEquipmentContext();
  const {
    config: {
      dashboard: { productDetails },
    },
  } = useEquipmentDetailsConfigContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const statusPanel = useMemo(() => (isMobile ? null : <EquipmentStatus fullWidth />), [isMobile]);

  const { datapointTilesData, isLoading } = useDatapointTiles(productDatapoints);
  const { getDatapoint } = useDatapointsContext();
  const unitSize = getDatapoint('UnitSize', { displayOutdatedValues: true })?.displayedValue ?? '';

  const { role, serialNumber } = useProductDetails();

  if (!equipment) return null;

  const equipmentModel = `${modelContent.MODELS.get(equipment.model ?? '') ?? equipment.model} ${
    getDatapoint('modelVariant', { displayOutdatedValues: true })?.value || ''
  } ${unitSize}`;

  return (
    <ProductDetails
      statusPanel={statusPanel}
      model={equipment.model}
      equipmentModel={equipmentModel}
      logoSrc={productDetails?.logoSrc}
      imageSrc={productDetails?.imageSrc}
      role={role}
      serialNumber={serialNumber}
    >
      <EquipmentProductDetailsDatapointTiles datapoints={datapointTilesData} isLoading={isLoading} />
    </ProductDetails>
  );
};
