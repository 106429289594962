import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { IDevice } from '@marlin/asset/data-access/device';
// TODO: move useAssets to assets domain
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { ASSET_TYPE, TAsset, useAssets } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

import { DeviceFormDataSchema } from './device-form-data-schema';

// TODO: Split form interface from api dto
export interface IUpsertDeviceForm extends IDevice {
  pulseMeterManufacturer?: string | null;
  pulseMeterModel?: string | null;
  signalReceiverType?: string | null;
}

export interface IUpsertDevice {
  form: UseFormReturn<IUpsertDeviceForm>;
  locationsList: TAsset[];
}

export const useUpsertDeviceForm = (defaultValues?: IDevice): IUpsertDevice => {
  const form = useForm<IUpsertDeviceForm>({
    mode: 'onTouched',
    resolver: yupResolver(DeviceFormDataSchema),
    defaultValues,
  });
  const locationsQuery = useAssets(
    {
      filter: ASSET_TYPE.LOCATION,
    },
    true
  );
  const locationsList = useMemo(() => locationsQuery?.data || [], [locationsQuery?.data]);

  return {
    form,
    locationsList,
  };
};
