import { MarlinTheme } from '@marlin/shared/theme';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { ReactNode, useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { getEquipmentIcon } from '../utils/get-equipment-icon';
import { EquipmentTypeMap } from './equipment-type-labels';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  equipmentTypeIcon: {
    color: theme.palette.action.active,
  },
}));

export interface IEquipmentTypeOption {
  id: EQUIPMENT_TYPE;
  name: string;
  Icon?: () => ReactNode;
}

export const useEquipmentTypeOptions = (): IEquipmentTypeOption[] => {
  const { classes } = useStyles();
  const { sentinel: isSentinelEnabled } = useFeatureFlagsContext();

  const featureFlagFilter = useCallback(
    (type: EQUIPMENT_TYPE) => {
      if (type === EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM) {
        return isSentinelEnabled;
      }

      return true;
    },
    [isSentinelEnabled]
  );

  const equipmentTypeOptions: IEquipmentTypeOption[] = useMemo(
    () =>
      Object.values(EQUIPMENT_TYPE)
        .filter(featureFlagFilter)
        .map((type) => ({
          id: type,
          name: EquipmentTypeMap.get(type) || '',
          Icon: () => getEquipmentIcon(type, classes.equipmentTypeIcon),
        })),
    [classes.equipmentTypeIcon, featureFlagFilter]
  );

  return equipmentTypeOptions;
};
