import { screen, userEvent, within } from '@storybook/testing-library';

export const selectOption = async ({
  selectTestId,
  selectedOptionIndex,
}: {
  selectTestId: string;
  selectedOptionIndex: number;
}) => {
  const typeSelect = await within(await screen.findByTestId(selectTestId)).findByRole('combobox');
  await userEvent.click(typeSelect);

  const selectListOfOptions = await screen.findByRole('listbox');
  const options = await within(selectListOfOptions).findAllByRole('option');
  const selectedOption = options[selectedOptionIndex];

  await userEvent.click(selectedOption);

  return selectedOption;
};
