import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { TDeviceMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import { Backdrop } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { useMobileAlerts } from '../../../../hooks/event-log/use-mobile-alerts';
import { IFilterParams } from '../event-log.component';
import { MobileEventLogCard } from './event-log-card.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.typography.pxToRem(8),
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(16)}`,
  },
  emptyResult: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.typography.pxToRem(300),
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
  },
}));

const pageSize = 10;

interface IMobileEventLogListParams {
  filterParams: IFilterParams;
  equipmentId: string;
  metadata?: TDeviceMetadataResponse;
}

export const MobileEventLogList = ({ filterParams, equipmentId, metadata }: IMobileEventLogListParams) => {
  const { classes } = useStyles();

  const alertsQuery = useMobileAlerts({
    ...filterParams,
    deviceIds: [],
    deviceTypes: [],
    locationIds: [],
    equipmentIds: equipmentId ? [equipmentId] : [],
    pageSize,
    ruleCategories: ['Health', 'Performance', 'Information'],
  });

  if (!alertsQuery.isLoading && !alertsQuery.alerts.length) {
    return (
      <div className={classes.emptyResult} data-testid="event-log-mobile-list-empty">
        <div>{content.NO_DATA}</div>
      </div>
    );
  }
  return (
    <div className={classes.container} data-testid="event-log-mobile-list">
      <Backdrop open={alertsQuery.isLoading}>
        <LoadingSpinner />
      </Backdrop>
      {alertsQuery.alerts.map((alert) => (
        <MobileEventLogCard key={alert.id} alert={alert} metadata={metadata?.datapoints || []} />
      ))}
      <PaginationMobile
        totalItems={alertsQuery.totalItems}
        hasNextPage={alertsQuery.hasNextPage}
        fetchNextPage={alertsQuery.fetchNextPage}
        loadedItems={alertsQuery.alerts.length}
      />
    </div>
  );
};
