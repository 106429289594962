import { MarlinTheme } from '@marlin/shared/theme';
import { TDateString, defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  content: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(26),
    color: theme.palette.text.secondary,
  },

  time: {
    textTransform: 'uppercase',
    marginRight: theme.typography.pxToRem(8),
  },

  timeLabel: {
    whiteSpace: 'nowrap',
  },

  timeValue: {
    whiteSpace: 'nowrap',
  },
}));

interface ITimeSectionProps {
  label: string;
  datetime: TDateString | null;
  children?: JSX.Element;
}

export function TimeSection({ label, datetime, children }: ITimeSectionProps) {
  const { classes } = useStyles();

  const formattedTime = useMemo(() => {
    return formatDate(datetime, defaultDateTime);
  }, [datetime]);

  return (
    <div className={classes.content}>
      <div className={classes.time} data-testid={createDataTestId('time-section', label)}>
        <span className={classes.timeLabel} data-testid={createDataTestId('time-label', label)}>
          {label}:
        </span>{' '}
        <span className={classes.timeValue} data-testid={createDataTestId('time-value', label)}>
          {formattedTime}
        </span>
      </div>
    </div>
  );
}
