import { MarlinTheme } from '@marlin/shared/theme';
import { TBucketOption } from '@marlin/shared/utils-chart';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useMultiChartsStore } from '../context/multi-charts.context';
import { isBucketOptionValid, onRangeFilterChange } from '../utils/bucket-options';

interface IBucketOptionSelectProps {
  bucketOption: TBucketOption | '';
  setBucketOption: (bucketOption: TBucketOption) => void;
  from: number;
  to: number;
  isZoomed: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    width: theme.typography.pxToRem(180),
    maxHeight: theme.typography.pxToRem(40),
  },
  menuItem: {
    color: theme.palette.text.secondary,
  },
}));

const allOptions = [
  { id: 'minutes', name: content.BUCKET_OPTIONS.MINUTES },
  { id: 'hours', name: content.BUCKET_OPTIONS.HOURS },
  { id: 'days', name: content.BUCKET_OPTIONS.DAYS },
  { id: 'weeks', name: content.BUCKET_OPTIONS.WEEKS },
  { id: 'months', name: content.BUCKET_OPTIONS.MONTHS },
] as const;

export const BucketOptionSelect = ({ setBucketOption, bucketOption, from, to, isZoomed }: IBucketOptionSelectProps) => {
  const { classes } = useStyles();
  const [rangeFilter] = useMultiChartsStore((store) => store.rangeFilter.range);

  const onChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (event.target.value) {
        setBucketOption(event.target.value as TBucketOption);
      }
    },
    [setBucketOption]
  );

  const dateDifferenceInMinutes = useMemo(() => moment(to).diff(moment(from), 'minutes'), [from, to]);

  const filteredOptions = useMemo(() => {
    return allOptions.filter((option) =>
      isBucketOptionValid(option.id, rangeFilter, dateDifferenceInMinutes, isZoomed)
    );
  }, [dateDifferenceInMinutes, isZoomed, rangeFilter]);

  useEffect(() => {
    const shouldChangeBucketOption =
      !bucketOption || !isBucketOptionValid(bucketOption, rangeFilter, dateDifferenceInMinutes, isZoomed);

    if (!shouldChangeBucketOption || isZoomed) return;

    onRangeFilterChange(rangeFilter, bucketOption, setBucketOption);
  }, [bucketOption, dateDifferenceInMinutes, isZoomed, rangeFilter, setBucketOption]);

  return (
    <FormControl size="small">
      <Select data-testid="bucket-option" fullWidth value={bucketOption} onChange={onChange} className={classes.select}>
        {filteredOptions.map(({ name, id }, index) => {
          return (
            <MenuItem key={index} className={classes.menuItem} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
