import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { useBrushChartRangeTelemetry } from '@marlin/asset/data-access/telemetry';
import { MarlinTheme } from '@marlin/shared/theme';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { isFirefox } from '@marlin/shared/utils-common-user-agent';
import { TUnitOfMeasure, parseDeviceReadings, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';

import { RANGE_FILTER } from '../../../constant';
import { IChartPoint } from '../../../types';
import { rangeToRangeFilter } from '../../../utils/range-filter-to-range';
import {
  EXTENDED_RANGE_FILTER,
  leakChartMax,
  leakChartMin,
  xAxisBorder,
  yAxisBorder,
} from '../../collapsed-chart/chart-constants';
import { renderTooltip } from '../../collapsed-chart/chart-tooltip.component';
import { brushChartId } from '../const';
import { useExtendedChartContext } from '../context/extended-chart.context';
import { CHART_SELECTION_TYPE } from './use-chart-selection.hook';
import { IRange } from './use-main-chart.hook';

const chartLeakValue = 1.05;
const chartNoLeakValue = -0.05;

const compareFloatNumbers = (a: number, b: number) => {
  return Math.abs(a - b) < 0.0001;
};

const isLeakValue = (value: number) => {
  return compareFloatNumbers(chartLeakValue, value);
};

const isNoLeakValue = (value: number) => {
  return compareFloatNumbers(chartNoLeakValue, value);
};

const calculateSelection = (chartContext: IChartContext, xaxis: ISelectionData) => {
  if (isFirefox()) {
    return { minX: xaxis.min, maxX: xaxis.max };
  }

  const { w, zoomPanSelection } = chartContext;
  const { xyRatios } = zoomPanSelection;

  const gridRectDim = zoomPanSelection?.gridRect?.getBoundingClientRect();
  const selectionRect = zoomPanSelection?.selectionRect?.node?.getBoundingClientRect();

  const minX =
    w.globals.xAxisScale.niceMin +
    (selectionRect.left - gridRectDim.left - w.globals.barPadForNumericAxis) * xyRatios.xRatio;
  const maxX =
    w.globals.xAxisScale.niceMin +
    (selectionRect.right - gridRectDim.left - w.globals.barPadForNumericAxis) * xyRatios.xRatio;

  return {
    minX,
    maxX,
  };
};

type TGetBoundingClientRect = () => { left: number; right: number };

interface IChartContext {
  w: {
    globals: {
      xAxisScale: {
        niceMin: number;
      };
      barPadForNumericAxis: number;
    };
  };
  zoomPanSelection: {
    xyRatios: {
      xRatio: number;
    };
    gridRect: {
      getBoundingClientRect: TGetBoundingClientRect;
    };
    selectionRect: {
      node: {
        getBoundingClientRect: TGetBoundingClientRect;
      };
    };
  };
}

interface ISelectionData {
  min: number;
  max: number;
}

interface ISelection {
  xaxis: ISelectionData;
  yaxis: ISelectionData;
}

export const defaultBrushChartParams = {
  period: 12,
  format: 'M' as const,
};

interface IUseBrushChart {
  data: IChartPoint[];
  recent: number;
  refetch: () => void;
  isRefetching: boolean;
  isUpdating: boolean;
  isFetched: boolean;
}

export const useBrushChartOptions = (
  deviceType: TDeviceTypeSchema | undefined,
  setCustomRangeFilter?: (rangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER) => void,
  isFetched?: boolean,
  uoM?: TUnitOfMeasure | null,
  isDataEmpty: boolean = false
) => {
  const theme = useTheme<MarlinTheme>();
  const { changeChartSelection, selection } = useExtendedChartContext();

  return useMemo(
    () => ({
      chart: {
        id: brushChartId,
        toolbar: {
          show: false,
          autoSelected: 'selection' as const,
        },
        animations: {
          enabled: false,
        },
        selection: {
          enabled: true,
          type: 'x',
        },
        events: {
          selection: (chartContext: IChartContext, { xaxis }: ISelection) => {
            // note: fixes Apex Chart Bug https://github.com/apexcharts/apexcharts.js/issues/2718
            try {
              const { minX, maxX } = calculateSelection(chartContext, xaxis);

              if (selection && rangeToRangeFilter({ from: minX, to: maxX }) === EXTENDED_RANGE_FILTER.CUSTOM) {
                setCustomRangeFilter && setCustomRangeFilter(EXTENDED_RANGE_FILTER.CUSTOM);
              }

              if (
                isFetched &&
                (!compareFloatNumbers(minX, selection.from) || !compareFloatNumbers(maxX, selection.to))
              ) {
                changeChartSelection({
                  selection: {
                    from: minX,
                    to: maxX,
                  },
                  type: CHART_SELECTION_TYPE.BRUSH_CHART,
                });
              }
            } catch (error) {
              /* empty */
            }
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        custom: renderTooltip({ rangeFilter: EXTENDED_RANGE_FILTER.MONTHS_12, deviceType, theme, uoM }), // todo: change to time/date format
      },
      yaxis:
        deviceType === 'LEAK'
          ? {
              show: !isDataEmpty,
              axisBorder: yAxisBorder,
              opposite: true,
              min: leakChartMin,
              max: leakChartMax,
              // NOTE: leak chart ticks are 2 because there is an error in the apex chart library with the calculation of the selection
              tickAmount: 2,
              labels: {
                formatter: (value: number) => {
                  if (isLeakValue(value)) {
                    return parseDisplayedValue('True', 'WaterDetect');
                  }

                  if (isNoLeakValue(value)) {
                    return parseDisplayedValue('False', 'WaterDetect');
                  }

                  return '';
                },
              },
            }
          : {
              show: !isDataEmpty,
              axisBorder: yAxisBorder,
              opposite: true,
              labels: {
                formatter: (value: number) => parseDisplayedValue(value.toString(), uoM ?? '', undefined, 'decimal'),
              },
              tickAmount: 3,
            },
      markers: {
        size: 0,
      },
      xaxis: {
        axisBorder: xAxisBorder,
        type: 'numeric' as const,
        labels: {
          rotate: 0,
          //format: 'MMM dd', // note: cannot use monthdateformat here, because apex formats are not compatible with moment formats
          datetimeUTC: false,
          formatter: (value: number | string) => {
            const date = dateAdapter.date(value);
            if (date?.date() === 1) {
              return dateAdapter.date(value)?.format('MMM DD') || '';
            }
            return '';
          },
        },
        tickAmount: 'dataPoints' as const,
        axisTicks: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: [theme.palette.primary.light, alpha(theme.palette.error.main, 0.12)],
    }),
    [deviceType, theme, uoM, selection, isFetched, setCustomRangeFilter, changeChartSelection]
  );
};
export const useBrushChart = (
  manufacturerId: string | undefined,
  deviceType: TDeviceTypeSchema | undefined,
  defaultBrushRange: IRange,
  rangeFilter: EXTENDED_RANGE_FILTER | RANGE_FILTER
): IUseBrushChart => {
  const { brushSelection, clearBrushSelection } = useExtendedChartContext();

  const brushRangeFilter = useMemo(
    () => ({
      from: dateAdapter.date(brushSelection?.from)?.utc().toISOString() ?? '',
      to: dateAdapter.date(brushSelection?.to)?.utc().toISOString() ?? '',
    }),
    [brushSelection]
  );

  const brushChartQuery = useBrushChartRangeTelemetry({
    ...brushRangeFilter,
    manufacturerId: manufacturerId || '',
    enabled: !!manufacturerId && !!manufacturerId.length,
    keepPreviousData: true,
  });

  const data: IChartPoint[] = useMemo(() => {
    if (!brushChartQuery.data || !brushChartQuery.data.chartData) {
      return [];
    }

    return brushChartQuery.data?.chartData?.map((telemetryData) => {
      return {
        timestamp: dateAdapter.date(telemetryData.eventDateTime)?.toDate().getTime() ?? 0,
        value: parseDeviceReadings(telemetryData.value, deviceType).value,
      };
    });
  }, [deviceType, brushChartQuery.data]);

  useEffect(() => {
    if (rangeFilter !== EXTENDED_RANGE_FILTER.CUSTOM) {
      clearBrushSelection();
    }
  }, [rangeFilter, clearBrushSelection, defaultBrushRange]);

  return {
    recent: dateAdapter.date(brushChartQuery.data?.recent?.eventDateTime)?.toDate().getTime() ?? 0,
    data,
    refetch: brushChartQuery.refetch,
    isRefetching: brushChartQuery.isRefetching,
    isUpdating: brushChartQuery.isLoading,
    isFetched: brushChartQuery.isFetched,
  };
};
