import { ROLE, RoleSchema } from '@marlin/shared/utils-role';
import * as yup from 'yup';

import { content } from '../content';

// todo remove yup
export const roleSchema = yup
  .string()
  .oneOf([ROLE.ADMIN, ROLE.VIEWER], content.ROLE_CONTROL_VALIDATION)
  .label(content.ROLE_CONTROL_LABEL)
  .required(content.ROLE_CONTROL_VALIDATION);

export const roleZodSchema = RoleSchema;
