import { useRecipients } from '@marlin/account-data-access-recipient';
import {
  ALERT_STATUS_FILTER,
  IExtendedAlert,
  IPagedAlerts,
  useFilteredAlertsWithLoadMore,
} from '@marlin/alert/data-access/alert-action';
import { useCallback, useMemo } from 'react';

import { mapExtendedAlerts } from './utils/map-extended-alerts';
export interface ICriticality {
  low: boolean;
  high: boolean;
}

interface IParams {
  status: ALERT_STATUS_FILTER;
  pageSize: number;
  locationIds: string[];
  equipmentIds: string[];
  deviceIds: string[];
  deviceTypes: string[];
  criticality?: ICriticality;
}

const defaultParams: IParams = {
  status: ALERT_STATUS_FILTER.CURRENT,
  pageSize: 10,
  locationIds: [],
  equipmentIds: [],
  deviceIds: [],
  deviceTypes: [],
  criticality: {
    low: false,
    high: false,
  },
};

interface IUseAlerts {
  alerts: IExtendedAlert[];
  hasNextPage?: boolean;
  isLoading: boolean;
  error?: unknown;
  isError: boolean;
  fetchNextPage: () => void;
  refetchMobile: () => Promise<unknown>;
  dataUpdatedAt?: number;
  totalItems?: number;
  totalItemsDetails?: IPagedAlerts['totalItems'];
}

export const useMobileAlerts = (enabled: boolean, filterParams?: IParams): IUseAlerts => {
  const alertsQuery = useFilteredAlertsWithLoadMore({
    params: { ...defaultParams, ...filterParams },
    enabled: enabled,
    staleTime: 300,
  });

  const recipientQuery = useRecipients({ addDeleted: true });

  const extendedAlerts = useMemo(() => {
    if (!alertsQuery?.data) {
      return [];
    }
    return (
      alertsQuery?.data.pages
        .map((alerts) => {
          return alerts.data.map((alert) => mapExtendedAlerts(alert, recipientQuery.data));
        })
        .flatMap((i) => i) ?? []
    );
  }, [alertsQuery?.data, recipientQuery.data]);

  const alertsTotalItems = useMemo(() => {
    if (!alertsQuery?.data) {
      return undefined;
    }

    return alertsQuery.data.pages[0].totalItems;
  }, [alertsQuery.data]);

  const totalItems = useMemo(() => {
    const totalItems = alertsQuery.data?.pages[0]?.pagination?.totalItems;
    return totalItems || 0;
  }, [alertsQuery.data]);

  const refetchMobile = useCallback(() => {
    return alertsQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    alerts: extendedAlerts,
    hasNextPage: alertsQuery.hasNextPage,
    isLoading: alertsQuery.isLoading || alertsQuery.isFetching,
    isError: alertsQuery.isError,
    error: alertsQuery.error,
    fetchNextPage: alertsQuery.fetchNextPage,
    refetchMobile,
    dataUpdatedAt: alertsQuery.dataUpdatedAt,
    totalItems,
    totalItemsDetails: alertsTotalItems,
  };
};
