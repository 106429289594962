import { zodResolver } from '@hookform/resolvers/zod';
import { ParsedDailySchedule, TDayName, TParsedDailySchedule } from '@marlin/asset/data-access/equipment';
import { SettingsConfirmationModal } from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField } from '@marlin/shared/ui-form-common';
import { LeaveRouteGuard, useSearchParams } from '@marlin/shared/utils-router';
import { Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { TimePicker } from './time-picker.component';

interface IEditMode {
  name: TDayName;
  onCancelClick: () => void;
  defaultValues: TParsedDailySchedule;
  onSubmit: (data: TParsedDailySchedule, dayName: TDayName) => Promise<void>;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  editContainer: {
    width: '100%',
    padding: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  editHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  loader: {
    minHeight: theme.typography.pxToRem(478),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(4),
    },
  },
  timeLabel: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(8),
  },
  timeWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(8),
      gap: theme.typography.pxToRem(16),
    },
  },
  occupied: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },
  },
  form: {
    width: '100%',
  },
  divider: {
    marginTop: theme.typography.pxToRem(8),
  },
  button: {
    width: '50%',
  },
}));

export const EditMode = ({ name, onCancelClick, defaultValues, onSubmit }: IEditMode) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const settingGroupId = searchParams.get('settingGroupId');
  const settingId = searchParams.get('settingId');
  const form = useForm<TParsedDailySchedule>({
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver(ParsedDailySchedule),
  });

  const handleOnConfirm = (onConfirm: () => void) => {
    setSearchParams((currentParams) => {
      if (settingId) {
        currentParams.delete('settingId');
      } else if (settingGroupId) {
        currentParams.delete('settingGroupId');
      }

      return currentParams;
    });

    onConfirm();
  };

  const onSaveClick = async (values: TParsedDailySchedule, name: TDayName, onConfirm: () => void) => {
    try {
      await onSubmit(values, name);
      handleOnConfirm(onConfirm);
    } catch (error) {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form className={classes.form}>
        <div className={classes.editContainer} data-testid="schedule-edit">
          <div className={classes.editHeader}>
            <Typography variant="body1" className={classes.title} data-testid="schedule-edit-title">
              {name}
            </Typography>
          </div>
          <div>
            <EditOccupied name="occupied1" label="Occupied 1" />
            <Divider className={classes.divider} />
            <EditOccupied name="occupied2" label="Occupied 2" />
          </div>
          <div className={classes.buttonsWrapper}>
            <Button variant="outlined" className={classes.button} onClick={onCancelClick}>
              {content.CANCEL}
            </Button>
            <Button
              variant="contained"
              disabled={!form.formState.isDirty}
              onClick={form.handleSubmit((values) => onSaveClick(values, name, onCancelClick))}
              className={classes.button}
            >
              {content.SET}
            </Button>
          </div>
        </div>
        <LeaveRouteGuard
          when={form.formState.isDirty && settingGroupId === 'Schedule'}
          watchForSearchParamsChange
          watchForShouldIgnoreGuard
        >
          {({ onCancel, onConfirm }) => {
            return (
              <SettingsConfirmationModal
                onCancel={onCancel}
                onSave={form.handleSubmit((values) => onSaveClick(values, name, onConfirm))}
                onConfirm={() => handleOnConfirm(onConfirm)}
              />
            );
          }}
        </LeaveRouteGuard>
      </form>
    </FormProvider>
  );
};

interface IEditOccupied {
  label: string;
  name: 'occupied1' | 'occupied2';
}

const EditOccupied = ({ label, name }: IEditOccupied) => {
  const { classes } = useStyles();
  const {
    watch,
    setValue,
    formState: { defaultValues },
  } = useFormContext<TParsedDailySchedule>();
  const skipFrom = watch(`${name}.from.skip`);
  const skipTo = watch(`${name}.to.skip`);
  const defaultFromTime = defaultValues?.[name]?.from?.time;
  const defaultToTime = defaultValues?.[name]?.to?.time;

  useEffect(() => {
    if (skipFrom && defaultFromTime) {
      setValue(`${name}.from.time`, defaultFromTime);
    }
    if (skipTo && defaultToTime) {
      setValue(`${name}.to.time`, defaultToTime);
    }
  }, [defaultFromTime, defaultToTime, name, setValue, skipFrom, skipTo]);

  return (
    <div>
      <Typography className={classes.timeLabel}>{label}</Typography>
      <div className={classes.timeWrapper}>
        <div className={classes.occupied} data-testid={`${name}-from-time`}>
          <FormField<TParsedDailySchedule> fieldName={`${name}.from.time`}>
            {(props) => <TimePicker {...props} label="From" disabled={skipFrom} />}
          </FormField>
          <FormField<TParsedDailySchedule> fieldName={`${name}.from.skip`}>
            {(props) => (
              <FormControlLabel
                control={<Checkbox {...props} checked={skipFrom} name={`${name}.from.skip`} />}
                label="Skip"
              />
            )}
          </FormField>
        </div>
        <div className={classes.occupied} data-testid={`${name}-to-time`}>
          <FormField<TParsedDailySchedule> fieldName={`${name}.to.time`}>
            {(props) => <TimePicker {...props} label="To" disabled={skipTo} />}
          </FormField>
          <FormField<TParsedDailySchedule> fieldName={`${name}.to.skip`}>
            {(props) => (
              <FormControlLabel
                control={<Checkbox {...props} checked={skipTo} name={`${name}.to.skip`} />}
                label="Skip"
              />
            )}
          </FormField>
        </div>
      </div>
    </div>
  );
};
