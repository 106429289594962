import { getLogger } from '@marlin/shared/utils/logger';
import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { UAParser } from 'ua-parser-js';

import { getCurrentUser } from '../infrastructure/user';
import { queryKey } from './query-key.enum';
import { getTierName } from './use-get-cached-tiers';

type TQueryFnType = typeof getCurrentUser;

export const useCurrentUser = (enabled = true) => {
  const parser = new UAParser();
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.CURRENT_USER(),
    queryFn: getCurrentUser,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
    onSuccess: (data) => {
      getTierName(data?.currentOrganization?.features?.tierId).then((tierName) => {
        getLogger()?.registerProperties({
          organizationId: data.currentOrganization?.organizationId,
          organizationName: data.currentOrganization.organizationName,
          tier: tierName,
          userRole: data?.currentOrganization?.roles,
          deviceModel: parser.getDevice().model,
          deviceVendor: parser.getDevice().vendor,
          osVersion: parser.getOS().version,
          osName: parser.getOS().name,
        });
      });
    },
  });
};
