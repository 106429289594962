import {
  EQUIPMENT_UPSERT_ERROR,
  useEquipment,
  useUpdateEquipment as useEditEquipment,
} from '@marlin/asset/data-access/equipment';
import { IRequestError } from '@marlin/shared/utils/react-query';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { TEquipment } from '@marlin/shared/utils/zod';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TUpsertEquipmentForm } from '../form/types';
import { content } from './content';

type TLocationState = {
  prevLocation: string;
};

export interface IEditEquipment {
  isLoading: boolean;
  successfullyUpdatedEquipment: boolean;
  loadingUpdatedEquipment: boolean;
  nextRoute: string;
  equipment: TEquipment | undefined;
  onSubmit: (value: TUpsertEquipmentForm) => void;
  goToNextRoute: () => void;
  updateEquipmentErrorCode: EQUIPMENT_UPSERT_ERROR | undefined;
  isEquipmentError: boolean;
}

const getErrorMessage = (errorCode?: EQUIPMENT_UPSERT_ERROR) => {
  switch (errorCode) {
    case EQUIPMENT_UPSERT_ERROR.LINKED_DEVICES:
      return content.ASSIGNED_SENSORS_ERROR;
    default:
      return content.CONFIRMATION_MESSAGE_UPDATE_EQUIPMENT_ERROR;
  }
};

export const useUpdateEquipment = (): IEditEquipment => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location?.state satisfies TLocationState;
  const nextRoute = locationState?.prevLocation || '/equipment';
  const updateEquipmentMutation = useEditEquipment();

  const equipmentId = useIdFromPathname();

  const onError = useCallback(() => {
    enqueueSnackbar(content.EQUIPMENT_FETCHING_ERROR, {
      variant: 'error',
    });
  }, []);

  const equipmentQuery = useEquipment({ equipmentId, config: { onError } });
  const { enqueueSnackbar } = useSnackbar();

  const [updateEquipmentErrorCode, setUpdateEquipmentErrorCode] = useState<EQUIPMENT_UPSERT_ERROR | undefined>(
    undefined
  );

  const onSubmit = useCallback(
    (newData: TUpsertEquipmentForm) => {
      const formValues = { ...newData, locationId: newData.location.id };
      setUpdateEquipmentErrorCode(undefined);
      const updatedEquipment = {
        ...Object.fromEntries(
          Object.entries(formValues).map(([key, value]) => [key, value === undefined ? null : value])
        ),
      } as unknown as TEquipment;

      return updateEquipmentMutation
        .mutateAsync({ data: updatedEquipment, equipmentId })
        .then(() => {
          enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_EQUIPMENT_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch((e: AxiosError<IRequestError<EQUIPMENT_UPSERT_ERROR>>) => {
          if (e.response?.data?.errorCode === EQUIPMENT_UPSERT_ERROR.EQUIPMENT_NAME_MUST_BE_UNIQUE) {
            setUpdateEquipmentErrorCode(e.response.data.errorCode);
            return;
          }

          enqueueSnackbar(getErrorMessage(e.response?.data?.errorCode), {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [equipmentId, updateEquipmentMutation]
  );

  const goToNextRoute = useCallback(() => {
    navigate(nextRoute);
  }, [navigate, nextRoute]);

  const equipment = useMemo(() => equipmentQuery?.data, [equipmentQuery?.data]);

  const successfullyUpdatedEquipment = useMemo(
    () => updateEquipmentMutation.isSuccess,
    [updateEquipmentMutation.isSuccess]
  );

  const loadingUpdatedEquipment = useMemo(() => updateEquipmentMutation.isLoading, [updateEquipmentMutation.isLoading]);
  const isLoading = useMemo(() => equipmentQuery.isFetching, [equipmentQuery.isFetching]);

  return {
    isLoading,
    isEquipmentError: equipmentQuery.isError,
    successfullyUpdatedEquipment,
    loadingUpdatedEquipment,
    nextRoute,
    equipment,
    goToNextRoute,
    onSubmit,
    updateEquipmentErrorCode,
  };
};
