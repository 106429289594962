import { useSearchParams } from '@marlin/shared/utils-router';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';
import { TExtractFnReturnType, queryClient, useQuery } from '@marlin/shared/utils/react-query';
import { useCallback } from 'react';
import { z } from 'zod';

import { getSchedule } from '../infrastructure/equipment';
import { QUERY_KEY, queryKey } from './query-key.enum';

type TQueryFnType = typeof getSchedule;

type TUseEquipmentOptions = {
  equipmentId: string;
  onError?: () => void;
};

export const useEquipmentSchedule = ({ equipmentId, onError }: TUseEquipmentOptions) => {
  const [, setSearchParams] = useSearchParams();

  const onSuccess = useCallback(
    () => queryClient.invalidateQueries({ queryKey: queryKey.SETTINGS(SETTINGS_GROUP.TEMPERATURE, equipmentId) }),
    [equipmentId]
  );

  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: [QUERY_KEY.EQUIPMENT_SCHEDULE, equipmentId],
    queryFn: () => getSchedule(equipmentId),
    onError: (error: z.ZodError | unknown) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      setSearchParams((currentParams) => {
        if (currentParams.has('settingGroupId')) {
          currentParams.delete('settingGroupId');
        }
        return currentParams;
      });

      onError && onError();
    },
    onSuccess,
  });
};
