import { content } from '../content';

export const getTime = (occupiedTime: { skip: boolean; time: number }) => {
  if (occupiedTime.skip) {
    return content.SKIP;
  }

  return `${getHours(occupiedTime.time)}:${getMinutes(occupiedTime.time)} ${occupiedTime.time >= 720 ? 'PM' : 'AM'}`;
};

const getHours = (time: number) => {
  let hours = Math.floor(time / 60);

  if (hours > 12) {
    hours = hours - 12;
  }

  return hours.toString().padStart(2, '0');
};

const getMinutes = (time: number) => {
  return (time % 60).toString().padStart(2, '0');
};
