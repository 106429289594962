import { ALERT_STATUS_FILTER, IPagedAlerts } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { DataGrid, GridColumns, GridSortModel } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { getSettingValueLabel } from '../../../../utils/get-setting-value-from-metadata';

interface IEventLogGridProps {
  alerts: IPagedAlerts | undefined;
  page: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  isLoading: boolean;
  openDetails: (id: string) => void;
  metadata: TDeviceMetadataDatapointsResponse[];
  onSortModelChange: (sortModel: GridSortModel) => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    height: `calc(100vh - ${theme.typography.pxToRem(370)})`,
    minHeight: theme.typography.pxToRem(270),
  },
  title: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  cell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  dataGrid: {
    border: 'none',
  },
}));

const dataGridPageCounterOffset = 1;

export const EventLogGrid = ({
  page,
  alerts,
  onPageChange,
  onPageSizeChange,
  openDetails,
  isLoading,
  metadata,
  onSortModelChange,
}: IEventLogGridProps) => {
  const { classes } = useStyles();
  const dataGridPage = useMemo(() => page - dataGridPageCounterOffset, [page]);
  const changeDataGridPage = (newPage: number) => onPageChange(newPage + dataGridPageCounterOffset);

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'startTime',
        headerName: content.START_TIME,
        width: 180,
        renderCell: ({ value }) => {
          return formatDate(value, defaultDateTime);
        },
      },
      {
        field: 'status',
        headerName: content.STATUS,
        width: 124,
        renderCell: ({ value }) => {
          return alertStatusToDisplayMap.get(value) ?? value;
        },
      },
      {
        field: 'criticality',
        headerName: content.CRITICALITY,
        width: 132,
        renderCell: ({ value }) => {
          return CriticalityControl({ severity: value, isCriticalityButton: false });
        },
      },
      {
        field: 'ruleCategory',
        headerName: content.EVENT_TYPE,
        width: 158,
      },
      {
        field: 'title',
        headerName: content.TITLE,
        width: 248,
        renderCell: ({ value, row: { id } }) => {
          return (
            <span className={classes.title} onClick={() => openDetails(id)}>
              {value}
            </span>
          );
        },
      },
      {
        field: 'resolveTime',
        headerName: content.RESOLVED_TIME,
        width: 180,
        renderCell: ({ value }) => {
          return formatDate(value, defaultDateTime);
        },
      },
      {
        field: 'valueChanged',
        headerName: content.VALUE_CHANGED,
        width: 224,
        renderCell: ({ row = {} }) => {
          return (
            <span className={classes.cell}>
              {getSettingValueLabel(row?.additionalValues?.settingName, row?.additionalValues?.settingUom, metadata)}
            </span>
          );
        },
      },
      {
        field: 'newValue',
        headerName: content.NEW_VALUE_LABEL,
        width: 180,
        renderCell: ({ row }) => {
          return getSettingValueLabel(
            row?.additionalValues?.settingName,
            row?.additionalValues?.settingUom,
            metadata,
            row?.additionalValues?.newSettingValue
          );
        },
      },
      {
        field: 'originalValue',
        headerName: content.ORIGINAL_VALUE,
        width: 180,
        renderCell: ({ row }) => {
          return getSettingValueLabel(
            row?.additionalValues?.settingName,
            row?.additionalValues?.settingUom,
            metadata,
            row?.additionalValues?.prevSettingValue
          );
        },
      },
      {
        field: 'generatedBy',
        headerName: content.GENERATED_BY,
        width: 200,
        renderCell: ({ row }) => {
          const isAdmin = /^[-0]+$/.test(row?.additionalValues?.userId || '');

          return (
            <span className={classes.cell}>
              {isAdmin
                ? content.LOCAL_ADMIN
                : `${row?.additionalValues?.firstName || ''} ${row?.additionalValues?.lastName || ''}`}
            </span>
          );
        },
      },
    ];
  }, [classes.cell, classes.title, metadata, openDetails]);

  return (
    <div className={classes.container} data-testid="event-log-data-grid">
      <DataGrid
        columns={columns}
        className={classes.dataGrid}
        rows={alerts?.data || []}
        page={dataGridPage}
        pageSize={alerts?.pagination.pageSize || undefined}
        paginationMode="server"
        onPageChange={changeDataGridPage}
        onPageSizeChange={onPageSizeChange}
        rowCount={alerts?.pagination.totalItems ?? 0}
        rowsPerPageOptions={[5, 10, 50, 100]}
        disableSelectionOnClick
        loading={isLoading}
        disableColumnFilter
        disableColumnMenu
        sortingMode="server"
        onSortModelChange={onSortModelChange}
      />
    </div>
  );
};

const alertStatusToDisplayMap = new Map<ALERT_STATUS_FILTER, string>([
  [ALERT_STATUS_FILTER.CURRENT, content.NEW],
  [ALERT_STATUS_FILTER.RESOLVED, content.RESOLVED],
]);
