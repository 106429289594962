import { useCancelSnoozeAutomation, useSnoozeAutomation } from '@marlin/alert/data-access/automated-action';
import { CancelSnoozeModal, SnoozeModal } from '@marlin/shared/ui-domain-automation-snooze';
import { SnoozeAlertIcon } from '@marlin/shared/ui-domain-automation-snooze';
import { useModal } from '@marlin/shared/ui-modal';
import { TDateString, minutesToHours } from '@marlin/shared/utils-common-date';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useSnackbar } from 'notistack';

import { content } from '../content';

interface ISnoozeSectionProps {
  id: string;
  automationName: string;
  automationId: string;
  snoozeUntil: TDateString;
  deviceId: string;
  deviceName: string;
}

export const SnoozeSection = ({
  automationName,
  id,
  automationId,
  snoozeUntil,
  deviceId,
  deviceName,
}: ISnoozeSectionProps) => {
  const [snoozeAlertModal, openSnoozeAlertModal, closeSnoozeAlertModal] = useModal();
  const [cancelSnoozedAlertModal, openCancelSnoozedAlertModal, closeCancelSnoozedAlertModal] = useModal();
  const { mutateAsync: snooze } = useSnoozeAutomation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: cancelSnooze, status } = useCancelSnoozeAutomation();
  const allowSnoozeAlerts = usePermission(PERMISSIONS.AUTOMATION_SNOOZE_ALERTS);

  const handleSubmitCancelSnooze = async (isSystemAutomation: boolean, deviceName: string) => {
    try {
      await cancelSnooze({ automationId, deviceId });
      if (isSystemAutomation) {
        enqueueSnackbar(content.CANCEL_SNOOZE_MESSAGE_SYSTEM(automationName, deviceName), {
          variant: 'success',
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar(content.CANCEL_SNOOZE_SUCCESS(automationName), {
          variant: 'success',
          preventDuplicate: true,
        });
      }
      enqueueSnackbar(content.CANCEL_SNOOZE_SUCCESS(automationName), {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (e) {
      enqueueSnackbar(content.CANCEL_SNOOZE_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const handleSubmitSnoozeTime = async (minutes: number, isSystemAutomation: boolean) => {
    try {
      await snooze({ id: automationId, minutes, deviceId });
      if (isSystemAutomation) {
        enqueueSnackbar(
          content.SNOOZE_SYSTEM_SUCCESS(
            automationName,
            String(minutesToHours(minutes)),
            minutesToHours(minutes) > 1 ? content.HOURS : content.HOUR,
            deviceName
          ),
          {
            variant: 'success',
            preventDuplicate: true,
          }
        );
      } else {
        enqueueSnackbar(
          content.SNOOZE_SUCCESS(
            automationName,
            String(minutesToHours(minutes)),
            minutesToHours(minutes) > 1 ? content.HOURS : content.HOUR
          ),
          {
            variant: 'success',
            preventDuplicate: true,
          }
        );
      }
    } catch (e) {
      enqueueSnackbar(content.SNOOZE_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <>
      <SnoozeAlertIcon
        disabled={!allowSnoozeAlerts}
        data-testid="snooze-alert-icon"
        id={id}
        isSnoozed={!!snoozeUntil}
        isDeviceDrawer
        onClick={snoozeUntil ? openCancelSnoozedAlertModal : openSnoozeAlertModal}
      />
      {snoozeAlertModal && (
        <SnoozeModal
          automationName={automationName}
          onSubmit={handleSubmitSnoozeTime}
          onClose={closeSnoozeAlertModal}
          deviceName={deviceName}
        />
      )}
      {cancelSnoozedAlertModal && !!snoozeUntil && (
        <CancelSnoozeModal
          deviceName={deviceName}
          automationName={automationName}
          snoozeUntil={snoozeUntil}
          onSubmit={handleSubmitCancelSnooze}
          onClose={closeCancelSnoozedAlertModal}
          buttonDisabled={status !== 'idle'}
        />
      )}
    </>
  );
};
