import {
  startDatapointCommunication,
  stopDatapointCommunication,
  useDatapoints,
} from '@marlin/asset/data-access/equipment';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

import { content } from '../content';
import { useEquipmentsTelemetryContext } from '../context/equipments-telemetry.context';

export const useEquipmentTelemetry = (manufacturerId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleTelemetry } = useEquipmentsTelemetryContext();

  useEffect(() => {
    startDatapointCommunication(manufacturerId).subscribe({
      error: () => {
        enqueueSnackbar(content.PLANT_LIST.CONNECTION_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      },
    });

    return () => {
      stopDatapointCommunication(manufacturerId).subscribe({
        error: () => {
          return;
        },
      });
    };
  }, [enqueueSnackbar, manufacturerId]);

  useDatapoints(handleTelemetry);
};
