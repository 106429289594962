import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';

import { content } from '../../content';

export const useEquipmentSettingsSections = () => {
  return useMemo(
    () => [
      {
        id: SETTINGS_GROUP.TEMPERATURE,
        title: content.TEMPERATURE,
      },
      {
        id: SETTINGS_GROUP.SYSTEM,
        title: content.SYSTEM,
      },
      {
        id: SETTINGS_GROUP.ALERT,
        title: content.ALERT_SETTINGS,
      },
    ],
    []
  );
};
