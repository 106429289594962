import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { MODEL } from '@marlin/shared/utils/zod';
import { Handle, NodeProps, Position } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../const';
import { useCommonStyles } from '../common.styles';
import { NodeTooltip } from '../utils/node-tooltip.component';
import { SanitizationHeader } from './components/sanitization-header.component';
import { StartupHeader } from './components/startup-header.component';
import { RegisteredEquipmentContext } from './hooks/use-registered-equipment-context';
import { useRegisteredEquipment } from './hooks/use-registered-equipment.hook';
import { TNodeData, TStatus } from './types';
import {
  getBackgroundByEquipmentStatus,
  getBorderColorByEquipmentStatus,
  getColumnPosition,
  getEquipmentIcon,
} from './utils/utils';

interface IStylesProps {
  status?: TStatus;
}

const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { status }) => ({
  name: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '160%',
    '& > span': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
      marginLeft: theme.typography.pxToRem(8),
    },
    '& > svg': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
    },
  },
  header: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: theme.typography.pxToRem(8),
  },

  headerDefault: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
  },
  handleContainer: {
    position: 'relative',
  },
  leftHandle: {
    position: 'absolute',
    left: -8,
  },
  rightHandle: {
    position: 'absolute',
    right: -8,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const basicMargin = -12;
const groupMargin = -16;
const handleMargin = 8;

const getGroupMargin = (group: number) => groupMargin + group * handleMargin;

export const RegisteredEquipmentNodeDefault = ({ data, id }: NodeProps<TNodeData>) => {
  const registeredEquipment = useRegisteredEquipment(data);
  const { headerStatus, modeValue } = registeredEquipment;
  const { classes } = useStyles({ status: headerStatus });
  const { classes: commonClasses } = useCommonStyles();
  const sliceName = !!data?.name && data?.name?.length > maxLabelLength;

  return (
    <RegisteredEquipmentContext.Provider value={registeredEquipment}>
      {modeValue === 'startup' && (
        <StartupHeader lastReadingValues={data?.lastReadingValues || []} id={data.linkedId || ''} />
      )}
      {modeValue === 'sanitize' && (
        <SanitizationHeader
          lastReadingValues={data?.lastReadingValues || []}
          variant="default"
          id={data.linkedId || ''}
        />
      )}
      <div className={modeValue === 'startup' || modeValue === 'sanitize' ? classes.headerDefault : classes.header}>
        <Link
          to={routes.equipmentDashboard.details.url(data.linkedId || '')}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <NodeTooltip text={data.name || ''} when={sliceName}>
            <div className={classes.name}>
              {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data?.name}{' '}
              {getEquipmentIcon(data?.metadata?.tags?.model as MODEL)}
            </div>
          </NodeTooltip>
        </Link>
        <div className={classes.handleContainer}>
          {data.metadata?.metadataGroups?.map((group) => {
            const direction = getColumnPosition(group.column, data.metadata?.layout?.columns || 1);
            const handleIndex = group.row - 1;
            return (
              <div
                className={direction === 'left' ? classes.leftHandle : classes.rightHandle}
                style={{ top: group.merged === 'column' ? basicMargin : getGroupMargin(handleIndex) }}
              >
                <Handle
                  type="target"
                  id={group.handleId}
                  position={direction === 'left' ? Position.Left : Position.Right}
                  className={commonClasses.handle}
                />
                <Handle
                  type="source"
                  id={group.handleId}
                  position={direction === 'left' ? Position.Left : Position.Right}
                  className={commonClasses.handle}
                />
              </div>
            );
          })}
        </div>
      </div>
    </RegisteredEquipmentContext.Provider>
  );
};
