import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getHomeDashboard } from '../infrastructure/home-dashboard';
import { useDashboardHubNotification } from './get-home-dashboard-notification.hook';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getHomeDashboard;

export const useHomeDashboard = () => {
  useDashboardHubNotification();
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.HOME_DASHBOARD(),
    queryFn: () => getHomeDashboard(),
  });
};
