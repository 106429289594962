import { environment } from '@marlin/environment';
import { z } from 'zod';

export const safeParseData = <_T extends z.ZodTypeAny>(data: unknown, schema: _T): z.infer<typeof schema> => {
  const parsedResult = schema.safeParse(data);

  if (!parsedResult.success) {
    // eslint-disable-next-line no-console
    console.log(
      `[Zod Log of Page ${window.location.href}]\nissues ${JSON.stringify(parsedResult.error.issues, null, 2)}`
    );
    if (environment.module.automationHelper.extendedQaLogging) {
      // eslint-disable-next-line no-console
      console.log(
        `[Zod Log of Page ${window.location.href}]\nissues ${JSON.stringify(parsedResult.error.issues, null, 2)}`
      );
    }
    throw parsedResult.error;
  }

  return parsedResult.data;
};
