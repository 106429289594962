import { THomeLocationsParamsSchema } from '../infrastructure/schema/home-locations.schema';

enum QUERY_KEY {
  HOME_DASHBOARD = 'dashboard',
  HOME_LOCATIONS = 'home_locations',
  WATER_USAGE = 'water_usage',
}

export const queryKey = {
  HOME_DASHBOARD: () => [QUERY_KEY.HOME_DASHBOARD],
  HOME_LOCATIONS: ({ isMechanicalRoom, withAlerts }: THomeLocationsParamsSchema) => [
    QUERY_KEY.HOME_LOCATIONS,
    isMechanicalRoom,
    withAlerts,
  ],
  WATER_USAGE: () => [QUERY_KEY.WATER_USAGE],
};
