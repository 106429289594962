import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { useCallback, useRef, useState } from 'react';

import { IFullScreen } from '../types';
import { useWindowSize } from './use-window-size.hook';

const headerMarginBottom = 24;
const paperPaddings = 16;
const pageMargins = 64;

export const useFullScreenChart = () => {
  const { handleLockDesktopNavbar, handleUnlockDesktopNavbar, desktopNavbarLocked } = useDesktopSideNavbarCollapse();
  const [fullScreenChart, setFullScreenChart] = useState<IFullScreen>({ chartId: null, chartType: null, title: '' });
  const defaultSideBarState = useRef<boolean>(desktopNavbarLocked);
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowSize();

  const setFullScreen = useCallback(
    ({ chartId, chartType, title }: IFullScreen) => {
      setFullScreenChart({ chartId, chartType, title });
    },
    [setFullScreenChart]
  );

  const toggleFullScreen = useCallback(
    ({ chartId, chartType, title }: IFullScreen) => {
      if (fullScreenChart.chartId) {
        setFullScreen({ chartId: null, chartType: null, title: '' });

        if (defaultSideBarState.current) {
          handleLockDesktopNavbar();
        }

        return;
      }

      setFullScreen({ chartId, chartType, title });
      handleUnlockDesktopNavbar();
    },
    [fullScreenChart.chartId, handleLockDesktopNavbar, handleUnlockDesktopNavbar, setFullScreen]
  );

  return {
    fullScreenChart,
    toggleFullScreen,
    paperHeight: height - (pageHeaderRef.current?.offsetHeight || 0) - pageMargins - headerMarginBottom - paperPaddings,
    pageHeaderRef,
  };
};
