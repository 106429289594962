import { EquipmentFormTypes } from '@marlin/shared/ui-form';
import { EquipmentTypeEnum } from '@marlin/shared/utils/zod';
import { z } from 'zod';

export const UpsertEquipmentForm = z
  .object({
    commissionDate: z.string().optional(),
    description: z.string().optional(),
    locationId: z.string().optional(),
    locationName: z.string().optional(),
    brand: z.string().optional(),
    model: z.string().optional(),
    type: EquipmentTypeEnum.optional(),
    serialNumber: z.string().optional(),
    name: z.string(),
    formType: EquipmentFormTypes.optional(),
    registrationCode: z.string().optional(),
  })
  .transform((data) => ({
    ...data,
    location: { id: data.locationId, name: data.locationName },
  }));

export type TUpsertEquipmentForm = z.infer<typeof UpsertEquipmentForm>;
