import { DEVICE_TYPE, IDevice, IDeviceResponse, meterTypeSchema } from '../../device.model';

export const mapDeviceType = (deviceType: string): DEVICE_TYPE => {
  switch (deviceType) {
    case 'Temperature': {
      return DEVICE_TYPE.TEMPERATURE;
    }
    case 'Pressure': {
      return DEVICE_TYPE.PRESSURE;
    }
    case 'Leak': {
      return DEVICE_TYPE.LEAK;
    }
    case 'FlowMeter':
    case 'PulseMeter': {
      return DEVICE_TYPE.PULSE_METER;
    }
    default: {
      return DEVICE_TYPE.UNKNOWN;
    }
  }
};

export const createDeviceDto = (data: IDeviceResponse): IDevice => {
  return {
    ...data,
    deviceType: mapDeviceType(data.deviceType),
    equipmentId: data.equipmentId,
    locationId: data.locationId,
    name: data.name,
    id: data.id,
    manufacturerId: data.manufacturerId,
    createdByUserId: data.createdByUserId,
    createdDateTime: data.createdDateTime,
    modifiedByUserId: data.modifiedByUserId,
    modifiedDateTime: data.modifiedDateTime,
    manufacturer: data.manufacturer,
    description: data.description,
    model: data.model,
    pinned: data.pinned,
    lastReadingValue: data.lastReadingValue,
    gatewayId: data.gatewayId?.replace('monnit-', ''),
    commissionDate: data.commissionDate,
    pulseMeterTypeId: data.pulseMeterTypeId,
    meterType: meterTypeSchema.parse(data.meterType),
    isCompound: !!data.devices?.length,
    devices: data.devices?.map(createDeviceDto),
    pulseMeterTypeIds: data.devices?.map((device, index) => ({
      pulseMeterTypeId: device.pulseMeterTypeId ?? undefined,
      order: index,
    })),
  };
};
