import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getOrganizationList } from '../infrastructure/organization';
import { TOrganizationListParams } from '../infrastructure/organization.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getOrganizationList;

export const useOrganizationList = (params?: TOrganizationListParams) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.ORGANIZATION_LIST(params),
    queryFn: () => getOrganizationList(params),
  });
};
