export const content = {
  HEADER: {
    TITLE: 'Analytics',
    DOWNLOAD_CSV: 'Download CSV',
    PRINT: 'Print',
    RESET_VIEW: 'Reset View',
    HELP: 'Help',
    LEGENDS: 'Legends',
    TRIGGERS: 'Triggers',
    ALERT: 'Alert',
    DEADBAND: 'Deadband',
    SHOW: 'Show',
  },
  MODAL: {
    TITLE: 'Delete Chart?',
    BODY: (value: string) => (
      <span>
        Are you sure you want to delete <b>{value}</b> chart?
      </span>
    ),
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    TOAST_MESSAGE: (value: string) => (
      <span>
        Chart <b>{value}</b> deleted successfully.
      </span>
    ),
  },
  EDIT_MODAL: {
    TITLE: 'Edit Chart Name',
    CANCEL: 'Cancel',
    SAVE: 'Update',
    CHART_NAME_ERROR: 'This is a required field',
    CHART_NAME_UNIQUE: 'Chart name must be unique',
    TOAST_MESSAGE: 'Chart updated successfully',
  },
  TIMEZONE_VALUE: (value: string) => `Timezone: ${value}`,
  TEMPERATURE: 'Temperature',
  PRESSURE: 'Pressure',
  FLOW: 'Flow',
  PUMP_VALVE: 'Pump & Valve',
  LEAK: 'Leak',
  CUSTOM: 'Custom',
  ON_OFF_STATUS: 'On/Off Status',
  CUSTOM_PERIOD: 'Custom period',
  EDIT_SENSORS: 'Edit sensors',
  BACK_BUTTON: 'Back',
  LOCATION: 'Location',
  EQUIPMENT: 'Equipment',
  SENSOR_TYPE: 'Sensor type',
  NEXA_SENSORS: 'Nexa Sensors',
  TOTAL_VOLUME: 'Total volume',
  AVERAGE_FLOW: 'Average Flow Rate',
  ADD_CHART: {
    TITLE: 'Add Chart',
    SELECT_CHART_TYPE: 'Select chart type',
    SELECT_SENSORS: 'Select sensors',
    NAME_CHART: 'Name chart',
    CANCEL: 'Cancel',
    BACK: 'Back',
    NEXT: 'Next',
    SUBMIT: 'Add Chart',
    CHART_TYPE: 'Chart type',
  },
  NEW_CHART: 'New Chart',
  NEW_CHART_SUBTITLE: 'Click the button to add a new chart.',
  LINE_CHART: 'Line Chart',
  BAR_CHART: 'Bar Chart',
  SELECT_SENSORS: 'Select sensors',
  CHART_NAME: 'Chart name',
  VALIDATION_MESSAGE: 'You can display a maximum of 10 sensors per chart.',
  NO_DATA_MESSAGE: 'No data available',
  CLEAR_ALL: 'Clear all',
  STH_WENT_WRONG: 'Something went wrong',
  TRY_AGAIN: 'Please try again later.',
  ADD_CHART_SUCCESSFULLY: 'Chart added successfully',
  NO_SENSORS_TITLE: 'No sensors connected yet',
  NO_SENSORS_SUBTITLE: 'Add a sensor to display analytics data.',
  ADD_SENSOR: 'Add Sensor',
  FOOTER: '© 2024 Watts. All rights reserved.',
  NO_CHARTS_TITLE: 'You have no charts.',
  NO_CHARTS_SUBTITLE: 'Click the buttons bellow to set up a new chart or restore the default charts.',
  RESTORE_DEFAULT: 'Restore default charts',
  REFRESH: 'Refresh',
  FULL_SCREEN: 'Full screen',
  BUCKET_OPTIONS: {
    MINUTES: 'By Minute',
    HOURS: 'By Hour',
    DAYS: 'By Day',
    WEEKS: 'By Week',
    MONTHS: 'By Month',
  },
  EXPORT_CSV: 'Export CSV',
  EXPORT_CSV_MODAL: {
    TITLE: 'Export',
    BODY: 'Export your data in the format that suit your needs:',
    SUMMARIZED_DATA:
      'Summarized data : Export the summarized data used to create this chart (for example, sums, averages, and medians)',
    CANCEL: 'Cancel',
  },
  EXPORTING_DATA_BODY: 'You can still browse reports and the platform while your data is exporting.',
  EXPORTING_DATA_TITLE: 'Exporting data',
  DATA_EXPORTED: 'Your data has been exported',
  ERROR: 'Something went wrong. Please try again later.',
  EXPORT_DISABLED_TOOLTIP: 'No data available to export. Please change time range or selections',
};
