import { MarlinTheme } from '@marlin/shared/theme';
import { TEquipmentSetting } from '@marlin/shared/utils/datapoint-mappers';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { useSettingListItem } from '../../../hooks/use-setting-list-item.hook';
import { TEditedSetting } from '../../../types';
import { DatapointError } from '../shared/datapoint-error.component';

interface IChildrenProps {
  onCancelClick: () => void;
}

interface ISettingRow {
  settingItem: TEquipmentSetting;
  children: (props: IChildrenProps) => ReactNode;
  disabled?: boolean;
  setEditedSetting: (value: TEditedSetting | undefined) => void;
  setIsDirty: (value: boolean) => void;
  outdated?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      gridArea: 'button',
    },
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  },
}));

export const RowControl = ({
  settingItem,
  children,
  disabled = false,
  setEditedSetting,
  setIsDirty,
  outdated,
}: ISettingRow) => {
  const { name, displayedValue } = settingItem;
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { onEditClick, isEditing, onCancelClick } = useSettingListItem({ name, setEditedSetting, setIsDirty });

  if (settingItem.controlState === 'error') {
    return <DatapointError />;
  }

  if (isEditing) {
    return <>{children({ onCancelClick })}</>;
  }

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            className={classes.button}
            onClick={onEditClick}
            disabled={disabled}
            data-testid="setting-list-item-button"
          >
            <ChevronRightIcon />
          </IconButton>
        </>
      ) : (
        <Button
          className={classes.button}
          onClick={onEditClick}
          disabled={disabled}
          data-testid="setting-list-item-button"
        >
          <Typography variant="body2" data-testid="setting-list-item-value">
            {outdated ? `(${content.PENDING}) ` : ''}
            {displayedValue}
          </Typography>
          <ChevronRightIcon />
        </Button>
      )}
    </>
  );
};
