import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { UpsertDevice } from '../form/upsert-device.component';
import { content } from './content';
import { useEditDevice } from './use-edit-device.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
}));

const EditDevice = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoading, successfullyUpdatedDevice, loadingUpdatedDevice, nextRoute, goToNextRoute, onSubmit, device } =
    useEditDevice();

  if (isLoading || !device) {
    return <LoadingSpinner />;
  }

  if (successfullyUpdatedDevice) {
    return <Navigate to={nextRoute} />;
  }

  return (
    <PageContainer prefix="edit-device">
      <PageHeader
        icon={null}
        title={device?.name || ''}
        subtitle={content.SUBTITLE}
        prefix="device-update-header"
        topActions={
          isMobile ? (
            <Button
              className={classes.button}
              startIcon={<CloseRoundedIcon />}
              variant="outlined"
              onClick={goToNextRoute}
            >
              {content.BUTTON_CANCEL}
            </Button>
          ) : undefined
        }
      />
      <UpsertDevice
        defaultValues={device}
        disableSubmit={loadingUpdatedDevice}
        onSubmit={onSubmit}
        onCancel={goToNextRoute}
        isEdit
      />
    </PageContainer>
  );
};

export { EditDevice };
