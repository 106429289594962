import {
  TCreateSystemMapLinkParams,
  TCreateSystemMapNodeParams,
  useCreateLink,
} from '@marlin/system-map/data-access/system-map';
import { FlowLinkEditModal } from '@marlin/system-map/shared/flow-link-edit-modal';
import { SystemMapLinkForm } from '@marlin/system-map/shared/system-map-link-form';
import { RenameTeeModal } from '@marlin/system-map/shared/ui-rename-tee-modal';
import { SystemMap } from '@marlin/system-map/ui/system-map';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import 'reactflow/dist/style.css';

import { content } from './content';
import { useRenameTee } from './use-rename-tee';

export const FlowMapDiagram = () => {
  const { teeToRename, openRenameTeeModal, closeRenameTeeModal, renameTee } = useRenameTee();
  const createLinkMutation = useCreateLink();
  const { enqueueSnackbar } = useSnackbar();

  const [modal, setModal] = useState<TCreateSystemMapNodeParams | null>(null);
  const closeModal = () => setModal(null);

  const onAddNewNode = async (value: TCreateSystemMapLinkParams) => {
    try {
      await createLinkMutation.mutateAsync(value);
      closeModal();
      enqueueSnackbar(content.ADD_LINK_SUCCESS_MESSAGE, {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (e) {
      enqueueSnackbar(content.ADD_LINK_ERROR_MESSAGE, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <>
      <SystemMap openRenameTeeModal={openRenameTeeModal} />
      {modal && (
        <FlowLinkEditModal onClose={closeModal}>
          <SystemMapLinkForm closeModal={closeModal} defaultValues={modal} onSubmit={onAddNewNode} />
        </FlowLinkEditModal>
      )}
      {!!teeToRename && (
        <RenameTeeModal
          isOpen={!!teeToRename}
          onCancel={closeRenameTeeModal}
          defaultValues={{ name: teeToRename.name || '' }}
          onSubmit={renameTee}
        />
      )}
    </>
  );
};
