import { TDateString, formatDate } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, getFormattedValue } from '@marlin/shared/utils-format-reading';

import { RANGE_FILTER, getTimeFormat } from './get-time-range-with-format';

interface IApexGlobals {
  globals: {
    seriesX: TDateString[][];
    colors: string[];
    initialSeries: {
      name: string;
      color: string;
      type: string;
      group: string;
      data: { x: number; y: number | null }[];
    }[];
  };
}

interface IRenderPointProps {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: IApexGlobals;
  chartData: { name: string; uom: TUnitOfMeasure | null }[];
  timeRange: RANGE_FILTER;
  TooltipComponent: React.FunctionComponent<{
    seriesFromCurrenDataPointIndex: IParsedSeriesData[];
    formattedDate: string;
  }>;
}

interface IParsedSeriesData {
  color: string;
  displayedValue: string;
  name: string;
}

const renderTooltip = ({
  dataPointIndex,
  series,
  seriesIndex,
  w,
  chartData,
  timeRange,
  TooltipComponent,
}: IRenderPointProps) => {
  const dateInMilliseconds = w.globals.seriesX[seriesIndex][dataPointIndex];
  const date = dateInMilliseconds && new Date(dateInMilliseconds);
  const formattedDate = formatDate(date, getTimeFormat(timeRange));

  const parsedSeries = series.reduce<IParsedSeriesData[]>((acc, item, index) => {
    const value = item[dataPointIndex];
    const pointConfig = w.globals.initialSeries[index];
    const current = chartData.find((item) => item.name === pointConfig.name);

    return current && (value || value === 0)
      ? [
          ...acc,
          {
            color: w.globals.colors[index],
            name: current?.name || '',
            displayedValue: getFormattedValue(String(value), current?.uom || null),
          },
        ]
      : acc;
  }, []);

  return <TooltipComponent seriesFromCurrenDataPointIndex={parsedSeries} formattedDate={formattedDate} />;
};

export { renderTooltip };
