import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';

import { TOrganizationRowSchema } from '../infrastructure/organization.schema';
import { TFilteredOrganizationResponse } from '../infrastructure/organization.schema';
import { useFilteredOrganizationsWithLoadMore } from '../queries/get-filtered-organizations.query';
import {
  TFilteredOrganizationWrapperAdapter,
  filteredorganizationWrapperAdapterSchema,
} from './organization-wrapper.schema';

export const useOrganizationList = ({ enabled, pageSize = 10 }: { enabled: boolean; pageSize?: number }) => {
  const { term, debouncedTerm, search: handleOrgSearchValue } = useSearchFilter();

  const organizationListQuery = useFilteredOrganizationsWithLoadMore({
    params: { search: debouncedTerm, pageSize },
    enabled,
  });

  const data: TFilteredOrganizationWrapperAdapter = useMemo(() => {
    const unfilteredRows: (TOrganizationRowSchema | null)[] =
      organizationListQuery.data?.pages?.flatMap((i: TFilteredOrganizationResponse) => i.data) || [];
    return filteredorganizationWrapperAdapterSchema.parse(
      unfilteredRows.filter((i: TOrganizationRowSchema | null): i is TOrganizationRowSchema => i !== null) || []
    );
  }, [organizationListQuery.data?.pages]);

  return {
    term,
    handleOrgSearchValue,
    data,
    fetchNextPage: organizationListQuery.fetchNextPage,
    isLoading: organizationListQuery.isLoading,
    isFetchingNextPage: organizationListQuery.isFetchingNextPage,
    hasNextPage: organizationListQuery.hasNextPage,
  };
};
