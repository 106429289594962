import { TCalculatedValues } from '../schema/calculated-values.schema';
import { IFilterParams } from './filter-params.utils';

export enum QUERY_KEY {
  EQUIPMENTS = 'equipments',
  EQUIPMENT = 'equipment',
  EQUIPMENT_DETAILS = 'equipment-details',
  METADATA = 'metadata',
  DYNAMIC_EQUIPMENT_METADATA = 'dynamic-metadata',
  EQUIPMENT_DEVICES = 'equipment-devices',
  START_SEND_DATA = 'start-send-data',
  STOP_SEND_DATA = 'stop-send-data',
  EQUIPMENT_SETTING = 'equipment-setting-group',
  EQUIPMENT_SCHEDULE = 'equipment-schedule',
  CALCULATION_VALUES = 'calculation-values',
  GRAPH = 'graph',
}

export const queryKey = {
  EQUIPMENTS: (filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.EQUIPMENTS, filterParams] : [QUERY_KEY.EQUIPMENTS],
  START_SEND_DATA: (id: string) => [QUERY_KEY.START_SEND_DATA, id],
  STOP_SEND_DATA: (id: string) => [QUERY_KEY.START_SEND_DATA, id],
  METADATA: (id?: string) => [QUERY_KEY.METADATA, id],
  DYNAMIC_EQUIPMENT_METADATA: (id: string) => [QUERY_KEY.DYNAMIC_EQUIPMENT_METADATA, id],
  EQUIPMENT_DEVICES: (id: string, filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.EQUIPMENT_DEVICES, id, filterParams] : [QUERY_KEY.EQUIPMENT_DEVICES, id],
  EQUIPMENT: (id: string) => [QUERY_KEY.EQUIPMENT, id],
  SETTINGS: (settingGroupId: string | null, id?: string) => [QUERY_KEY.EQUIPMENT_SETTING, id, settingGroupId],
  EQUIPMENT_SCHEDULE: (id: string) => [QUERY_KEY.EQUIPMENT_SCHEDULE, id],
  CALCULATION_VALUES: (params: {
    equipmentId: string;
    calculatedValues: TCalculatedValues[];
    from: string;
    to: string;
  }) => [QUERY_KEY.CALCULATION_VALUES, params],
  METADATA_BY_MODELS: (models: string[]) => [QUERY_KEY.METADATA, models],
  GRAPH: () => [QUERY_KEY.GRAPH],
};
