import { DEVICE_STATE, getDeviceState } from '../utils';
import { DeviceOperational } from './device-operational.component';
import { LostCommunication } from './lost-communication.component';
import { LowBattery } from './low-battery.component';
import { ReplaceBattery } from './replace-battery.component';

interface IDeviceStateProps {
  batteryLevel: number | null;
  isActive: boolean;
}

const DeviceState = ({ batteryLevel, isActive }: IDeviceStateProps) => {
  const deviceStatus = getDeviceState({ batteryLevel, isActive });

  switch (deviceStatus) {
    case DEVICE_STATE.LOW_BATTERY: {
      return <LowBattery batteryLevel={batteryLevel} />;
    }

    case DEVICE_STATE.OPERATIONAL: {
      return <DeviceOperational />;
    }

    case DEVICE_STATE.REPLACE_BATTERY: {
      return <ReplaceBattery batteryLevel={batteryLevel} />;
    }

    case DEVICE_STATE.LOST_COMMUNICATION: {
      return <LostCommunication />;
    }

    default: {
      return null;
    }
  }
};

export { DeviceState };
