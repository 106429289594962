import { TOperation } from '@marlin/asset/data-access/gateway';
import { Button, CircularProgress } from '@mui/material';
import React from 'react';

import { content } from '../../content';
import { IConnectionInfo, TAddressErrors } from '../../context/test-flow.context';
import { useModalStyles } from '../../hooks/modals/use-modal-styles.hook';
import { useSaveAndTestConfiguration } from '../../hooks/use-save-and-test.hook';
import { useSaveConfiguration } from '../../hooks/use-save-configuration.hook';
import {
  TUpsertConnectionFormSchemaType,
  TUpsertConnectionFormSchemaTypeUpdate,
} from '../form/upsert-connection-form.schema';

interface IFooterModal {
  onClose: () => void;
  redirectToTestPage: () => void;
  setOperations: (operations: TOperation[]) => void;
  setConnections: (connections: IConnectionInfo[]) => void;
  deviceId: string;
  data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate;
  isTestOnly: boolean;
  refetch: () => void;
  configurationId: string;
  setAddressErrors: React.Dispatch<React.SetStateAction<TAddressErrors | null>>;
}

export const FooterModal = ({
  onClose,
  deviceId,
  configurationId,
  redirectToTestPage,
  setOperations,
  setConnections,
  data,
  refetch,
  setAddressErrors,
  isTestOnly,
}: IFooterModal) => {
  const { classes } = useModalStyles();

  const { handleSave, isLoading: isSaveLoading } = useSaveConfiguration({
    onClose,
    deviceId,
    data,
    refetch,
    setAddressErrors,
  });
  const { handleDeactivateAndLoadConfig, handleSaveAndTest, isLoading } = useSaveAndTestConfiguration({
    onClose,
    deviceId,
    redirectToTestPage,
    setOperations,
    setConnections,
    data,
    refetch,
    setAddressErrors,
  });

  return (
    <div data-testid="test-modal-footer">
      {(isLoading || isSaveLoading) && (
        <div className={classes.overlay}>
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        </div>
      )}
      <Button className={classes.cancelButton} variant="text" data-testid="test-modal-footer-cancel" onClick={onClose}>
        {content.TEST_MODAL.CANCEL}
      </Button>
      {isTestOnly && (
        <Button
          variant="contained"
          data-testid="test-modal-footer-confirm"
          onClick={() => handleDeactivateAndLoadConfig({ configurationId })}
        >
          {content.TEST_MODAL.TEST}
        </Button>
      )}
      {!isTestOnly && (
        <>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            data-testid="test-modal-footer-confirm"
            onClick={handleSave}
          >
            {content.TEST_MODAL.SAVE}
          </Button>
          <Button variant="contained" data-testid="test-modal-footer-confirm" onClick={handleSaveAndTest}>
            {content.TEST_MODAL.SAVE_AND_TEST}
          </Button>
        </>
      )}
    </div>
  );
};
