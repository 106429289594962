import { MarlinTheme } from '@marlin/shared/theme';
import { useAnnotationTooltip } from '@marlin/shared/ui/annotation-tooltip';
import {
  ChartTooltip,
  isEmptyChartData,
  magnifyingGlass,
  openInNewRounded,
  zoomIn,
  zoomOut,
} from '@marlin/shared/ui/chart';
import {
  IApexAxisChartThresholdSeries,
  IChartSeries,
  RANGE_FILTER,
  TBucketOption,
  TChartDisplayType,
  TEventOptions,
  TToolbarOptions,
  handleBeforeZoom,
  renderTooltip,
} from '@marlin/shared/utils-chart';
import { dateAdapter, getUserTimezoneAbbreviation } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, UnitOfMeasure, getFormattedValue, roundValue } from '@marlin/shared/utils-format-reading';
import { useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';

import { getBarOptions, getRangeBarOptions } from './chart-options';
import { content } from './content';
import { getAnnotationPoints } from './get-annotation-points';

const fullChartHeight = 1000;

interface IChartOptionsParams {
  chartData: IChartSeries[];
  chartDataWithoutMargins?: IChartSeries[];
  thresholdsData?: IApexAxisChartThresholdSeries[];
  rangeFilter: RANGE_FILTER;
  from?: number;
  to?: number;
  max: number;
  min: number;
  handleZoomChange: (zoom?: { min: number; max: number }) => void;
  isZoomed: boolean;
  chartDisplayType: TChartDisplayType;
  chartId: string;
  isFullScreen?: boolean;
  onFullScreenClick?: () => void;
  customIconClassName?: string;
  hideUomOnAxis?: boolean;
  additionalAxisUom?: TUnitOfMeasure;
  mainDatapoint?: string;
  bucketOption?: TBucketOption | '';
}

export const useChartOptions = ({
  max,
  min,
  chartData,
  chartDataWithoutMargins,
  thresholdsData = [],
  rangeFilter,
  from,
  to,
  isZoomed,
  handleZoomChange,
  chartDisplayType,
  chartId,
  isFullScreen,
  customIconClassName,
  onFullScreenClick,
  hideUomOnAxis,
  additionalAxisUom,
  mainDatapoint,
  bucketOption,
}: IChartOptionsParams) => {
  const theme = useTheme<MarlinTheme>();
  const { currentAnnotationTooltip, onAnnotationLabelEnter, onAnnotationLabelLeave } = useAnnotationTooltip();
  const [blocked, setBlocked] = useState(false);
  const isEmpty = isEmptyChartData({ chartData, from, to });

  useEffect(() => {
    return () => {
      setBlocked(false);
    };
  }, []);

  const additionalAxis = useMemo(() => {
    if (!additionalAxisUom) {
      return undefined;
    }

    return {
      renderAdditionalY: (name: string) => ({
        labels: {
          formatter: (value: number) => getFormattedValue(String(value), UnitOfMeasure.Enum[additionalAxisUom]),
        },
        min: 0,
        max: 100,
        tickAmount: 4,
        seriesName: name,
      }),
      axisUnit: UnitOfMeasure.Enum[additionalAxisUom],
    };
  }, [additionalAxisUom]);

  const xAxisMin = useMemo(() => {
    if (chartDisplayType === 'bar' && bucketOption && ['days', 'months', 'weeks'].includes(bucketOption)) {
      return dateAdapter
        .date(from)
        ?.startOf(bucketOption.slice(0, -1) as 'day' | 'month' | 'week')
        .valueOf();
    }

    return from;
  }, [chartDisplayType, bucketOption, from]);

  const xAxisMax = useMemo(() => {
    if (chartDisplayType === 'bar' && bucketOption && ['days', 'months', 'weeks'].includes(bucketOption)) {
      return dateAdapter
        .date(to)
        ?.endOf(bucketOption.slice(0, -1) as 'day' | 'month' | 'week')
        .valueOf();
    }

    return to;
  }, [chartDisplayType, bucketOption, to]);

  const xAxisOptions: ApexOptions['xaxis'] = useMemo(
    () => ({
      title: {
        style: {
          fontSize: `${theme.typography.fontSize}px`,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.secondary,
        },
        text: content.TIMEZONE_VALUE(getUserTimezoneAbbreviation()),
      },
      type: 'datetime',
      min: xAxisMin,
      max: xAxisMax,
      labels: {
        datetimeUTC: false,
      },
      tooltip: {
        enabled: false,
      },
    }),
    [theme, xAxisMin, xAxisMax]
  );

  // axis setting should have same order as in chartData
  const yAxisUom: ApexOptions['yaxis'] = useMemo(() => {
    if (isEmpty)
      return [
        {
          show: false,
        },
      ];

    const labelOptions = [...chartData, ...thresholdsData].reduce<(ApexYAxis & { id?: string })[]>((acc, item) => {
      if (item.uom === additionalAxis?.axisUnit) {
        const option = additionalAxis?.renderAdditionalY(item.name);

        if (option && !Array.isArray(option))
          return [
            ...acc,
            {
              ...option,
              id: 'additional',
              show: !acc.some((axis) => axis.id === 'additional'),
            },
          ];
      }

      const tickAmount = isFullScreen ? fullChartHeight / 60 : 5;

      const getRoundedMax = () => {
        const roundedMax = min + tickAmount * (Math.floor((max - min) / tickAmount) + 1);
        const exponent = Math.floor(Math.log10(max)) - 1;
        const placeValue = Math.pow(10, exponent);

        return Math.ceil(roundedMax / placeValue) * placeValue;
      };

      const getRoundedMin = () => {
        if (chartId === 'flow') return 0;

        if (!min || min < 0) return min;

        const exponent = Math.floor(Math.log10(min)) - 1;
        const placeValue = min > 100 ? Math.pow(10, exponent) : 10;

        return Math.floor(min / placeValue) * placeValue;
      };

      const shouldReplaceThousandWithK = max > 10000;

      return [
        ...acc,
        {
          labels: {
            formatter: (value) =>
              formatYaxis({
                value,
                uom: item.uom,
                hideUomOnAxis,
                replaceThousand: shouldReplaceThousandWithK,
                chartDisplayType,
              }),
          },
          min: getRoundedMin(),
          max: getRoundedMax(),
          opposite: true,
          tickAmount,
          seriesName: item.name,
          id: 'main',
          show: !acc.some((axis) => axis.id === 'main'),
          forceNiceScale: shouldReplaceThousandWithK,
        },
      ];
    }, []);

    //adding dummy Yaxis label to ensure enough space at the beginning of chart to render first label
    labelOptions.push({
      labels: {
        formatter: () => '',
      },
    });

    return labelOptions;
  }, [additionalAxis, chartData, chartDisplayType, hideUomOnAxis, isEmpty, isFullScreen, max, min, thresholdsData]);

  const chartOptions: ApexOptions = useMemo(() => {
    const tooltipOptions: ApexOptions['tooltip'] = {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return renderToString(
          renderTooltip({
            series,
            seriesIndex,
            dataPointIndex,
            w,
            chartData: chartDataWithoutMargins
              ? chartDataWithoutMargins.map(({ name, uom }) => ({ name, uom }))
              : chartData.map(({ name, uom }) => ({ name, uom })),
            timeRange: isZoomed ? RANGE_FILTER.CUSTOM : rangeFilter,
            TooltipComponent: ChartTooltip,
          })
        );
      },
      enabled: true,
      enabledOnSeries: [...chartData.map((_, index) => index)],
      intersect: true,
    };

    const toolbarOptions: TToolbarOptions = {
      show: true,
      offsetY: -20,
      offsetX: -20,
      tools: {
        download: false,
        selection: false,
        zoom: blocked ? `<img id="zoom" src=${magnifyingGlass} alt="zoom" width="24" class="disabled"/>` : true,
        zoomin: `<img id="zoom-in" src=${zoomIn} alt="zoom in" width="24" class="${blocked ? 'disabled' : ''}"/>`,
        zoomout: `<img id="zoom-out" src=${zoomOut} alt="zoom out" width="24"/>`,
        pan: true,
        reset: false,
        customIcons:
          onFullScreenClick && !isFullScreen
            ? [
                {
                  icon: `<img id="full-screen" src=${openInNewRounded} alt="full screen" width="24" />`,
                  index: 4,
                  class: customIconClassName,
                  click(): void {
                    onFullScreenClick();
                  },
                  title: content.FULL_SCREEN,
                },
              ]
            : [],
      },
      autoSelected: 'zoom',
    };

    const eventOptions: TEventOptions = {
      beforeZoom(_: unknown, options: { xaxis: { max: number; min: number } }) {
        handleBeforeZoom({ xaxis: options.xaxis, from, to, setBlocked, handleZoomChange });
      },
      beforeResetZoom() {
        handleZoomChange();
      },
      scrolled(event, options: { xaxis: { max: number; min: number } }) {
        event.core.el.style.cursor = 'grabbing';
        const currentDate = dateAdapter.date()?.valueOf();

        if (currentDate && options.xaxis.max < currentDate) {
          handleZoomChange({ min: options.xaxis.min, max: options.xaxis.max });
        }
      },
    };

    if (chartDisplayType === 'rangeBar') {
      return {
        ...getRangeBarOptions(chartId, rangeFilter, toolbarOptions, eventOptions, from),
        xaxis: {
          ...xAxisOptions,
        },
      };
    }

    if (chartDisplayType === 'bar') {
      const columnAmount = chartDataWithoutMargins?.length
        ? Math.max(...chartDataWithoutMargins.map((item) => item.data.length))
        : 0;

      return {
        ...getBarOptions(chartId, toolbarOptions, eventOptions, columnAmount),
        tooltip: tooltipOptions,
        yaxis: yAxisUom,
        xaxis: {
          ...xAxisOptions,
        },
      };
    }

    return {
      chart: {
        id: chartId,
        animations: {
          enabled: false,
        },
        toolbar: toolbarOptions,
        zoom: {
          type: 'x',
          enabled: true,
        },
        events: eventOptions,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: [
          ...chartData.map(() => 1),
          ...thresholdsData.filter((td) => td.type === 'rangeArea').map(() => 0.1),
          ...thresholdsData.filter((td) => td.type === 'line').map(() => 1),
        ],
      },
      stroke: {
        curve: 'straight',
        width: [
          ...chartData.map((item) => (item.name === mainDatapoint ? 4 : 2)),
          ...thresholdsData.filter((td) => td.type === 'rangeArea').map(() => 0),
          ...thresholdsData.filter((td) => td.type === 'line').map(() => 2),
        ],
        dashArray: [
          ...chartData.map(() => 0),
          ...thresholdsData.filter((td) => td.type === 'rangeArea').map(() => 0),
          ...thresholdsData.filter((td) => td.type === 'line').map(() => 2),
        ],
      },
      xaxis: xAxisOptions,
      yaxis: yAxisUom,
      annotations: {
        points: getAnnotationPoints({ thresholdsData, theme, onAnnotationLabelEnter, onAnnotationLabelLeave }),
      },
      tooltip: { ...tooltipOptions, intersect: false, shared: true },
    };
  }, [
    chartData,
    blocked,
    onFullScreenClick,
    isFullScreen,
    customIconClassName,
    chartDisplayType,
    chartId,
    thresholdsData,
    xAxisOptions,
    yAxisUom,
    theme,
    onAnnotationLabelEnter,
    onAnnotationLabelLeave,
    chartDataWithoutMargins,
    isZoomed,
    rangeFilter,
    to,
    from,
    handleZoomChange,
    mainDatapoint,
  ]);

  return useMemo(
    () => ({
      chartOptions,
      currentAnnotationTooltip,
      isEmpty,
    }),
    [chartOptions, currentAnnotationTooltip, isEmpty]
  );
};

const formatYaxis = ({
  value,
  uom,
  hideUomOnAxis,
  replaceThousand,
  chartDisplayType,
}: {
  value: number;
  uom: TUnitOfMeasure | null;
  hideUomOnAxis?: boolean;
  replaceThousand: boolean;
  chartDisplayType?: TChartDisplayType;
}) => {
  if (chartDisplayType === 'bar') {
    const formattedDecimalValue = getFormattedValue(String(value), uom, 'decimal');

    if (replaceThousand && value > 1000) {
      const [number, unitOfMeasure] = formattedDecimalValue.split(' ');
      return `${number.slice(0, -3)}K ${unitOfMeasure}`;
    }

    return formattedDecimalValue;
  }

  return hideUomOnAxis ? roundValue(value, 'decimal') : getFormattedValue(String(value), uom);
};
