import { TDeviceSchema, TGetDeviceSchema, useFilteredDevicesWithLoadMore } from '@marlin/asset/data-access/device';
import { IBaseFilters } from '@marlin/asset/shared/ui/mobile-filter-modal';
import { TDeviceType } from '@marlin/shared/ui-form';
import { useMemo } from 'react';

import { TDeviceSort } from './sorting';

const defaultParams = {
  pageSize: 10,
};

export interface IOption {
  id: string;
  name: string;
}

export interface IDeviceHubFilters extends IBaseFilters<TDeviceSort> {
  locations: IOption[];
  deviceTypes?: TDeviceType[];
}

interface IUseDevicesHub {
  rows: TDeviceSchema[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  totalItems?: number;
  loadedItems?: number;
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  error?: unknown;
}

export const useDevicesHub = (filters: IDeviceHubFilters): IUseDevicesHub => {
  const params = useMemo(
    () => ({
      params: { ...defaultParams, ...filters, locations: filters.locations.map(({ id }) => id) },
    }),
    [filters]
  );

  const devicesQuery = useFilteredDevicesWithLoadMore(params);

  const rows: TDeviceSchema[] = useMemo(() => {
    const unfilteredRows: (TDeviceSchema | null)[] =
      devicesQuery.data?.pages?.flatMap((i: TGetDeviceSchema) => i.data) || [];
    return unfilteredRows.filter((i: TDeviceSchema | null): i is TDeviceSchema => i !== null);
  }, [devicesQuery.data?.pages]);

  return {
    rows,
    totalItems: devicesQuery.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: devicesQuery.hasNextPage,
    fetchNextPage: devicesQuery.fetchNextPage,
    refetch: devicesQuery.refetch,
    isLoading: devicesQuery.isLoading,
    isFetching: devicesQuery.isFetching,
    isError: devicesQuery.isError,
    error: devicesQuery.error,
  };
};
