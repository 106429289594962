import { IEquipmentSharedProps } from '@marlin/asset/shared/ui/datapoint-display';
import { HierarchyBreadcrumbs } from '@marlin/asset/shared/ui/hierarchy-breadcrumbs';
import { DetailTabs, useTab } from '@marlin/asset/shared/ui/tabs';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { EQUIPMENT_TAB_VALUES, routes } from '@marlin/shared/utils-routes';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Navigate } from 'react-router-dom';

import { content } from '../../content';
import { DatapointsContextProvider } from '../../context/datapoints.context';
import { EditSettingContextProvider } from '../../context/edit-setting.context';
import { EquipmentAlertsContextProvider } from '../../context/equipment-alerts.context';
import { useEquipmentDetailsConfigContext } from '../../context/equipment-details-config-context';
import { EquipmentContextProvider } from '../../context/equipment.context';
import { useEquipmentDetails } from '../../hooks/use-equipment-details.hook';
import { useNavigation } from '../../hooks/use-navigation.hook';
import { ActionButtons } from '../components/dashboard/action-buttons';
import { Dashboard } from '../components/dashboard/dashboard.component';
import { MobilePageHeader } from './mobile/page-header.component';
import { useStyles } from './styles.component';

export const DetailsBuilder = ({ equipment, isEquipmentError, refetchEquipment }: IEquipmentSharedProps) => {
  const theme = useTheme();
  const {
    config: { headerBanner, tabs, dashboard },
  } = useEquipmentDetailsConfigContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigation = useNavigation();
  const { classes } = useStyles({ noHierarchyDisplayed: !equipment?.locationId });
  const { enqueueSnackbar } = useSnackbar();
  const tab = useTab();

  const {
    timestamp,
    datapointsWithUiMetadata,
    isEquipmentDeregistered,
    isStartDatapointCommunicationLoading,
    isError,
    refetchStartData,
    isLoading,
    isStartDataLoading,
    lastReadingTime,
  } = useEquipmentDetails({
    equipment,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isEquipmentDeregistered) {
    enqueueSnackbar(content.EQUIPMENT_DEREGISTERED_WARNING, { variant: 'warning', preventDuplicate: true });

    return <Navigate to={navigation.getEquipmentLink()} />;
  }

  return (
    <EquipmentContextProvider
      equipment={equipment}
      isEquipmentError={isEquipmentError || isError}
      refetchEquipment={() => {
        refetchEquipment();
        if (dashboard.enableStartStopData) {
          refetchStartData();
        }
      }}
      isLoading={isStartDataLoading}
      lastReadingTime={lastReadingTime}
    >
      <DatapointsContextProvider
        datapoints={datapointsWithUiMetadata}
        timestamp={timestamp}
        isLoading={isStartDatapointCommunicationLoading}
      >
        <EditSettingContextProvider>
          <EquipmentAlertsContextProvider>
            {headerBanner}
            <PageContainer className={classes.page}>
              <div className={classes.actionsWrapper}>
                {equipment?.locationId && (
                  <HierarchyBreadcrumbs locationId={equipment.locationId} selectedItemId={equipment.id} />
                )}
                {!isMobile && (
                  <ActionButtons detailEditLink={navigation.getEditEquipmentPageLink(equipment?.id || '')} />
                )}
              </div>
              <Box className={classes.headerWrapper}>
                {isMobile ? (
                  <MobilePageHeader
                    title={equipment?.name ?? ''}
                    detailEditLink={navigation.getEditEquipmentPageLink(equipment?.id || '')}
                  />
                ) : (
                  <PageHeader prefix="equipment-details-header" title={equipment?.name ?? ''} />
                )}
              </Box>
              <DetailTabs tabs={tabs} getRoute={routes.equipmentDashboard.details.url} />
              {tab === EQUIPMENT_TAB_VALUES.DASHBOARD ? <Dashboard config={dashboard} /> : tabs[tab]}
            </PageContainer>
          </EquipmentAlertsContextProvider>
        </EditSettingContextProvider>
      </DatapointsContextProvider>
    </EquipmentContextProvider>
  );
};
