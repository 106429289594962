import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { getCardTitle } from '@marlin/shared/utils/format-alert';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { CRITICALITY, IAlert } from '../types';
import { SnoozeSection } from './snooze-section.component';
import { TimeSection } from './time-section.component';
import { TitleSection } from './title-section.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  iconHigh: {
    color: theme.palette.error.main,
  },

  iconLow: {
    color: theme.palette.warning.light,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      marginTop: theme.typography.pxToRem(-10),
    },
  },
}));

interface IAlertContentHeaderMobileProps {
  alert: IAlert;
}

export function CurrentAlertContentHeaderMobile({ alert }: IAlertContentHeaderMobileProps) {
  const { classes } = useStyles();
  const title = useMemo(() => getCardTitle(alert), [alert]);

  const iconClasses: Record<CRITICALITY, string> = {
    [CRITICALITY.HIGH]: classes.iconHigh,
    [CRITICALITY.LOW]: classes.iconLow,
  };

  return (
    <>
      <div className={classes.row}>
        <CriticalityControl severity={alert.criticality} />
        <SnoozeSection
          id={alert.id}
          deviceId={alert.device.id}
          automationName={alert.automation.name}
          snoozeUntil={alert.snoozeUntil}
          automationId={alert.automation.id}
          deviceName={alert.device.name}
        />
      </div>
      <TitleSection title={title} icon={<WarningRoundedIcon className={iconClasses[alert.criticality]} />} />
      <TimeSection label={content.CARD.TIME_START} datetime={alert.startTime} />
    </>
  );
}
