import { zodResolver } from '@hookform/resolvers/zod';
import {
  EmailControl,
  FirstNameControl,
  JobTitleControl,
  LastNameControl,
  PhoneNumberControl,
} from '@marlin/shared/ui-form';
import { Button, Grid } from '@mui/material';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';

import { content } from '../translation-keys';
import { IUpdateUserProfile, IUser } from '../user.model';
import { UpsertProfileFormValidationSchema } from './user-profile-update-form.schema';
import { useStyles } from './user-profile-update-form.styles';

interface IUserProfileUpdateFormProps {
  user: IUser | undefined;
  onSubmit: (data: IUpdateUserProfile) => void;
  onCancel: (form: UseFormReturn<IUpdateUserProfile>) => void;
}

const defaultValues: Partial<IUpdateUserProfile> = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  title: '',
};

export function UserProfileUpdateForm({ user, onSubmit, onCancel }: IUserProfileUpdateFormProps) {
  const { classes } = useStyles();
  const form = useForm<IUpdateUserProfile>({
    defaultValues: { ...defaultValues, ...user },
    mode: 'onTouched',
    resolver: zodResolver(UpsertProfileFormValidationSchema),
  });

  const handleCancel = () => {
    onCancel(form);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FirstNameControl<IUpdateUserProfile> fieldName="firstName" />
          </Grid>

          <Grid item xs={12}>
            <LastNameControl<IUpdateUserProfile> fieldName="lastName" />
          </Grid>

          <Grid item xs={12}>
            <EmailControl<IUpdateUserProfile> fieldName="email" disabled={true} />
          </Grid>

          <Grid item xs={12}>
            <PhoneNumberControl<IUpdateUserProfile> fieldName="phone" />
          </Grid>

          <Grid item xs={12}>
            <JobTitleControl<IUpdateUserProfile> fieldName="title" />
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="outlined"
              data-testid="account-update-user-profile-cancel-button"
              onClick={handleCancel}
            >
              {content.BUTTONS.CANCEL}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              data-testid="account-update-user-profile-confirm-button"
              variant="contained"
              disabled={!form.formState.isDirty || !form.formState.isValid}
              onClick={form.handleSubmit(onSubmit)}
            >
              {content.BUTTONS.CONFIRM}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
