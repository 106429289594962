import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Accordion, AccordionDetails, AccordionSummary, alpha, useMediaQuery, useTheme } from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { TRow, useEquipmentDetailsSectionData } from '../../../../hooks/use-equipment-details-section-data';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(12),
    [theme.breakpoints.down('md')]: {
      gap: 0,
    },
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.15),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      textAlign: 'right',
    },
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
      padding: theme.typography.pxToRem(16),
      border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
      borderWidth: `0 0 ${theme.typography.pxToRem(1)}`,
    },
  },
  rowDescription: {
    order: 999,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.typography.pxToRem(8),
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    color: alpha(theme.palette.text.primary, 0.8),
  },
  description: {
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: '2',
    whiteSpace: 'unset',
    overflowWrap: 'anywhere',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      display: 'block',
    },
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      '&.Mui-expanded:hover': {
        cursor: 'default',
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mobilePaper: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

export const EquipmentDetails = () => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Accordion
      className={classes.accordion}
      data-testid="equipment-details-section"
      defaultExpanded
      expanded={!isMobile ? true : undefined}
      elevation={0}
    >
      <AccordionSummary className={classes.accordionSummary} expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
        {content.EQUIPMENT_DETAILS}
      </AccordionSummary>
      <AccordionDetails className={cx(classes.paper, classes.mobilePaper)}>
        <EquipmentDetailsSection />
      </AccordionDetails>
    </Accordion>
  );
};

export const EquipmentDetailsSection = () => {
  const { classes } = useStyles();
  const data = useEquipmentDetailsSectionData();

  return (
    <div className={classes.wrapper}>
      {data.map((row) => (
        <Row key={row.label} row={row} />
      ))}
    </div>
  );
};

const Row = ({ row }: { row: TRow }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isValueOverflow, setIsValueOverflow] = useState(false);
  const rowId = kebabCase(row.label);
  const isDescriptionRow = rowId === 'description';

  useEffect(() => {
    const value = document.getElementById(rowId);

    if (value && isDescriptionRow) {
      setIsValueOverflow(value.clientHeight < value.scrollHeight);
      return;
    }

    if (value) {
      setIsValueOverflow(value.offsetWidth < value.scrollWidth);
    }
  }, [rowId, isDescriptionRow]);

  if (isMobile && isDescriptionRow) {
    return (
      <div className={cx(classes.row, classes.rowDescription)}>
        <div className={cx(classes.label, classes.description)}>{row.label}</div>
        <div className={classes.description} id={rowId}>
          {row.value || content.EMPTY_DATAPOINT_VALUE}
        </div>
      </div>
    );
  }

  return (
    <div className={cx(classes.row, isDescriptionRow && classes.rowDescription)}>
      <div className={classes.label}>
        {row.label}
        {!!row?.tooltip && (
          <Tooltip text={row.tooltip} placement="bottom">
            <InfoRoundedIcon fontSize="small" color="primary" />
          </Tooltip>
        )}
      </div>
      <Tooltip
        placement="top-end"
        text={row.value || ''}
        disableHoverListener={isMobile ? true : !isValueOverflow}
        disableTouchListener
        data-testid="value-tooltip"
      >
        <div className={cx(classes.value, isDescriptionRow && classes.description)} id={rowId}>
          {row.value || content.EMPTY_DATAPOINT_VALUE}
        </div>
      </Tooltip>
    </div>
  );
};
