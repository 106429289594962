import { useObservableState } from 'observable-hooks';
import { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

import { navStorageService } from './nav-storage.service';

const desktopNavbarCollapsedState$ = new BehaviorSubject<boolean>(true);
const desktopNavbarLockedState$ = new BehaviorSubject<boolean>(false);

export const useDesktopSideNavbarCollapse = () => {
  const isLocked = navStorageService.get('localStorage');

  const [desktopNavbarCollapsed] = useObservableState<boolean>(() => desktopNavbarCollapsedState$, true);
  const [desktopNavbarLocked] = useObservableState<boolean>(() => desktopNavbarLockedState$, isLocked === 'locked');

  useEffect(() => {
    if (isLocked === 'locked') {
      desktopNavbarLockedState$.next(true);
    } else {
      desktopNavbarLockedState$.next(false);
    }
  }, [isLocked]);

  const handleLockDesktopNavbar = () => {
    navStorageService.set('locked');
    desktopNavbarLockedState$.next(true);
  };

  const handleUnlockDesktopNavbar = () => {
    navStorageService.set('');
    desktopNavbarLockedState$.next(false);
  };

  const handleCollapseDesktopNavbar = () => {
    desktopNavbarCollapsedState$.next(true);
  };

  const handleExpandDesktopNavbar = () => {
    desktopNavbarCollapsedState$.next(false);
  };

  return {
    desktopNavbarLocked,
    desktopNavbarCollapsed,
    handleCollapseDesktopNavbar,
    handleExpandDesktopNavbar,
    handleLockDesktopNavbar,
    handleUnlockDesktopNavbar,
  };
};
