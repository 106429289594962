import { maxBatteryLevel } from '../consts';
import { TPowerSource } from '../types';

export const getPowerSourceType = (batteryLevel: number): TPowerSource => {
  if (batteryLevel > maxBatteryLevel) {
    return 'LINE';
  }

  return 'BATTERY';
};
