import { MarlinTheme } from '@marlin/shared/theme';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { IProductDetails } from './product-details.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    gap: theme.typography.pxToRem(32),
    width: '100%',
    height: '100%',
    padding: theme.typography.pxToRem(24),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.typography.pxToRem(4),
    borderBottomRightRadius: theme.typography.pxToRem(4),
    borderTop: 'none',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      columnGap: theme.typography.pxToRem(16),
    },
  },
  detailItem: {
    flex: 1,
    width: '50%',
    maxWidth: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(16),
  },
  manufacturerLogo: {
    maxHeight: theme.typography.pxToRem(91),
  },
  equipmentRow: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    gap: theme.typography.pxToRem(4),
    textTransform: 'uppercase',
  },
  modelVariant: {
    display: 'block',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(32),
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  valueText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    maxHeight: theme.typography.pxToRem(356),
  },
  image: { maxWidth: '100%', maxHeight: '100%' },
}));

export function ProductDetailsUi({
  imageSrc,
  logoSrc,
  model,
  equipmentModel,
  serialNumber,
  role,
  children,
  statusPanel,
}: IProductDetails) {
  const { classes, cx } = useStyles();

  const getImage = () => {
    if (typeof imageSrc === 'function') {
      return imageSrc(model ?? '');
    }
    return imageSrc;
  };

  return (
    <div className={classes.wrapper} data-testid="equipment-product-details">
      {statusPanel}
      <Box className={classes.paper}>
        <Box className={cx(classes.detailItem, classes.details)}>
          <Box className={classes.manufacturerLogo} data-testid="equipment-manufacturer-logo">
            {logoSrc && <img src={logoSrc} alt={`Logo ${model}`} className={classes.manufacturerLogo} />}
          </Box>
          {equipmentModel && (
            <Box className={classes.equipmentRow}>
              <Typography variant="body1" data-testid="equipment-model-label">
                {content.MODEL}
              </Typography>
              <Typography variant="body1" data-testid="equipment-model" className={classes.valueText}>
                {equipmentModel}
              </Typography>
            </Box>
          )}
          {serialNumber && (
            <Box className={classes.equipmentRow}>
              <Typography variant="body1" data-testid="equipment-serial-number-label">
                {content.SERIAL_NUMBER_TITLE}
              </Typography>
              <Typography variant="body1" data-testid="equipment-serial-number" className={classes.valueText}>
                {serialNumber}
              </Typography>
            </Box>
          )}
          {!!role && (
            <Box className={classes.equipmentRow}>
              <Typography variant="body1" data-testid="equipment-role-label">
                {content.ROLE_TITLE}
              </Typography>
              <Typography variant="body1" data-testid="equipment-role" className={classes.valueText}>
                {role}
              </Typography>
            </Box>
          )}
          {children}
        </Box>
        <Box className={cx(classes.detailItem, classes.imageWrapper)} data-testid="equipment-product-image">
          {/* TODO: populate image from the API */}
          {imageSrc && <img className={classes.image} src={getImage()} alt={`Product ${model}`} />}
        </Box>
      </Box>
    </div>
  );
}
