import { RoleSchema } from '@marlin/shared/utils-role';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { paths } from './api';

const validationSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string().optional(),
  title: z.string().nullish(),
  role: RoleSchema,
});

type TValidationSchema = z.infer<typeof validationSchema>;

export const validateInvitation = async (invitationId: string): Promise<TValidationSchema> => {
  const response = await getHttpClient().get(paths.INVITATION, { params: { invitationId } });
  return validationSchema.parse(response);
};
