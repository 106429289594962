import { MarlinTheme } from '@marlin/shared/theme';
import { Icon } from '@mui/material';
import { HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  addIcon: {
    color: theme.palette.primary.main,
  },
  addAddressLabel: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.typography.pxToRem(8),
  },
}));

interface IAutocompleteOptionProps {
  props: HTMLAttributes<HTMLLIElement>;
  onClick: () => void;
}

export const AddAddressAutocompleteOption = ({ props, onClick }: IAutocompleteOptionProps) => {
  const { classes } = useStyles();

  return (
    <li {...props} onClick={onClick}>
      <Icon baseClassName="material-symbols-outlined" className={classes.addIcon} data-testid="add_circle-circle">
        add_circle
      </Icon>
      <p className={classes.addAddressLabel}>{content.ADDRESS_FORM.ADD_ADDRESS}</p>
    </li>
  );
};
