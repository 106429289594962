import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { validateInvitation } from '../infrastructure/validate-invitation';
import { IValidateInvitationError } from '../invitation.model';
import { queryKey } from './query-key.enum';

type TValidateInvitationOptions = {
  invitationId: string;
};

export const useValidateInvitation = ({ invitationId }: TValidateInvitationOptions) => {
  return useQuery<TExtractFnReturnType<typeof validateInvitation>, IValidateInvitationError>({
    queryKey: queryKey.VALIDATE_INVITATION(invitationId),
    queryFn: () => validateInvitation(invitationId),
    staleTime: Infinity,
  });
};
