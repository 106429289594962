import { AlertTab } from '@marlin/alert/ui/alert-tab';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useExtendedDeviceDrawer } from './components/container/context';
import { ReadingsTabMobile } from './components/readings-mobile';
import { content } from './content';
import { DeviceDetailsTab } from './device-details-tab.component';
import { DeviceReadingsTab } from './device-readings-tab.component';
import { useDeviceCurrentAlerts } from './hooks/use-device-current-alerts.hook';
import { useSensorId } from './hooks/use-sensor-id.hook';
import { TAB_INDEX, useTab } from './hooks/use-tab.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  containerExtended: {
    backgroundColor: theme.palette.background.alternative,
    overflowY: 'auto',
  },
  container: {
    flex: '1 1 0',
    overflowY: 'auto',
  },
  tabs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.primary,
  },
  tab: {
    minWidth: '33%',
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      flex: 1,
    },
  },
}));

const TabSwitcher = ({
  tabIndex,
  isExtended,
  isMobile,
}: {
  tabIndex: TAB_INDEX;
  isExtended?: boolean;
  isMobile?: boolean;
}): JSX.Element | null => {
  switch (tabIndex) {
    case TAB_INDEX.DEVICE_DETAILS: {
      return <DeviceDetailsTab />;
    }
    case TAB_INDEX.ALERTS: {
      return <AlertTab isExtended={isExtended} />;
    }
    case TAB_INDEX.READINGS: {
      if (isMobile) {
        return <ReadingsTabMobile />;
      }
      return <DeviceReadingsTab />;
    }

    default: {
      return null;
    }
  }
};

export const DeviceDrawerTabs = () => {
  const { classes, cx } = useStyles();
  const tab = useTab();
  const router = useRouter();
  const deviceId = useSensorId();
  const { totalCurrent } = useDeviceCurrentAlerts();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExtended = useExtendedDeviceDrawer();

  const handleChange = useCallback(
    (event: unknown, newTab: TAB_INDEX) => {
      if (tab !== newTab) {
        const deviceDrawerUrl = routes.deviceDrawer.open(router.getUrl(), deviceId, newTab);
        router.goTo(deviceDrawerUrl);
      }
    },
    [deviceId, router, tab]
  );

  return (
    <>
      {isMobile ? (
        <Tabs className={classes.tabs} value={tab} onChange={handleChange} data-testid="drawer-tabs">
          <Tab
            className={classes.tab}
            label={content.TAB_READINGS}
            value={TAB_INDEX.READINGS}
            data-testid={`drawer-tab-readings`}
          />
          <Tab
            className={classes.tab}
            label={content.TAB_ALERTS_MOBILE(totalCurrent)}
            value={TAB_INDEX.ALERTS}
            data-testid="modal-tab-alerts"
          />
          <Tab
            className={classes.tab}
            label={content.TAB_SENSOR_DETAILS_MOBILE}
            value={TAB_INDEX.DEVICE_DETAILS}
            data-testid="modal-tab-details"
          />
        </Tabs>
      ) : (
        <Tabs className={classes.tabs} value={tab} onChange={handleChange} data-testid="drawer-tabs">
          <Tab
            className={classes.tab}
            label={content.TAB_READINGS}
            value={TAB_INDEX.READINGS}
            data-testid={`drawer-tab-readings`}
          />
          <Tab
            className={classes.tab}
            label={content.TAB_ALERTS(totalCurrent)}
            value={TAB_INDEX.ALERTS}
            data-testid="modal-tab-alerts"
          />
          <Tab
            className={classes.tab}
            label={content.TAB_SENSOR_DETAILS_MOBILE}
            value={TAB_INDEX.DEVICE_DETAILS}
            data-testid="modal-tab-details"
          />
        </Tabs>
      )}
      <div
        className={cx(classes.container, { [classes.containerExtended]: isExtended })}
        data-testid="device-drawer-container"
      >
        <TabSwitcher tabIndex={tab} isExtended={isExtended} isMobile={isMobile} />
      </div>
    </>
  );
};
