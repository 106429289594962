import { MenuItem, Select } from '@mui/material';
import { useMemo, useState } from 'react';
import Plot from 'react-plotly.js';

interface ISankeyValues {
  label: string;
  gal: number[];
  percent: string[];
}

type TWeek = 'week1' | 'week2' | 'week3';

const valuesMap: Record<TWeek, ISankeyValues> = {
  week1: {
    label: 'Jul 14 - Jul 20',
    gal: [219006, 2160, 52302, 32880, 131664, 131664 / 2, 131664 / 2],
    percent: ['100%', '1%', '24%', '15%', '60%', '50%', '50%'],
  },
  week2: {
    label: 'Jul 21 - Jul 27',
    gal: [275316, 2388, 58968, 50772, 163188, 163188 / 2, 163188 / 2],
    percent: ['100%', '1%', '21%', '18%', '59%', '50%', '50%'],
  },
  week3: {
    label: 'Jul 28 - Aug 3',
    gal: [207648, 2418, 47358, 33126, 124746, 124746 / 2, 124746 / 2],
    percent: ['100%', '1%', '23%', '16%', '60%', '50%', '50%'],
  },
};

const data = {
  type: 'sankey' as const,
  orientation: 'h' as const,
  arrangement: 'snap' as const,
  node: {
    pad: 15,
    thickness: 30,
    line: {
      color: 'black',
      width: 0.5,
    },
    label: ['Cold Water Inlet', 'Softener', 'Softener discharge', 'Kitchen', 'Guest Rooms', 'DA Tank'],
  },
  link: {
    source: [0, 1, 1, 1, 1, 5, 5],
    target: [1, 2, 3, 4, 5, 1, 0],
  },
};

export const Sankey = (): JSX.Element => {
  const [week, setWeek] = useState<TWeek>('week1');
  const [unit] = useState<keyof ISankeyValues>('gal');

  const sankeyData = useMemo(
    () => [
      {
        ...data,
        link: {
          ...data.link,
          value: [...valuesMap[week][unit]],
        },
      },
    ],
    [unit, week]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <Select value={week} onChange={(event) => setWeek(event.target.value as TWeek)}>
          {Object.entries(valuesMap).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Plot
        style={{ alignSelf: 'center' }}
        data={sankeyData}
        layout={{ title: 'Sankey Flow PoC' }}
        config={{ displaylogo: false }}
      />
    </div>
  );
};
