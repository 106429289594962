import { Box, Modal, Typography } from '@mui/material';

import { useStyles } from './custom-sized-modal.styles';

interface IModalProps {
  title?: string | JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
  onClose: () => void;
  width?: number;
  disableCloseOnBackdropClick?: boolean;
}

export const CustomSizedModal = ({
  title,
  body,
  footer,
  onClose,
  width,
  disableCloseOnBackdropClick = false,
}: IModalProps) => {
  const { classes } = useStyles({ width });

  const onModalClose = (_: object, reason: string) => {
    if (disableCloseOnBackdropClick && reason === 'backdropClick') {
      return;
    }

    onClose();
  };

  return (
    <Modal
      open={true}
      onClose={onModalClose}
      data-testid="modal-root"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.container} data-testid="modal-body">
        <div className={classes.body}>
          {typeof title === 'string' ? (
            <Typography variant="h6" component="h2" data-testid="modal-title">
              {title}
            </Typography>
          ) : (
            title
          )}
          <div className={classes.contentWrapper} data-testid="modal-content">
            {body}
          </div>
          <div className={classes.footer} data-testid="modal-footer">
            {footer}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
