import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { paths } from './api';

const PowerBiSchema = z.object({
  reportUrl: z
    .string()
    .nullish()
    .transform((uri) => {
      return uri ? encodeURIComponent(uri) : uri;
    }),
});

const MonnitSchema = z.object({
  apiKeyHeader: z.string(),
  apiKeyValue: z.string(),
  isActive: z.boolean(),
});

export const OrganizationSettingsSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  settings: z.object({
    powerBI: PowerBiSchema,
    monnit: MonnitSchema,
  }),
});

export const UpdateOrganizationSettingsSchema = z.object({
  id: z.string(),
  settings: PowerBiSchema,
});

const AnalyticsSchema = z.object({
  reportUrl: z.string().url(),
});

export type TOrganizationSettings = z.infer<typeof OrganizationSettingsSchema>;
export type TUpdateOrganizationSettings = z.infer<typeof UpdateOrganizationSettingsSchema>;
export type TAnalyticSchema = z.infer<typeof AnalyticsSchema>;

export const getOrganizationSettings = async (): Promise<TOrganizationSettings> => {
  const res = await getHttpClient().get<unknown, TOrganizationSettings>(paths.ORGANIZATION_SETTINGS);
  return safeParseData(res, OrganizationSettingsSchema);
};

export const updateOrganizationSettings = async (data: TUpdateOrganizationSettings): Promise<TOrganizationSettings> => {
  const body = safeParseData(data, UpdateOrganizationSettingsSchema);
  const res = await getHttpClient().put(paths.ORGANIZATION_SETTINGS, body);
  return safeParseData(res, OrganizationSettingsSchema);
};
