import {
  TAddressRequest,
  TAddressResponse,
  TValidationAddressComponent,
  getAddressValidationService,
} from '@marlin/shared/utils/maps-api';
import { TOrgAddress, orgAddressSchema } from '@marlin/shared/utils/zod';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { mapAddressComponents } from './address-components.utils';

const mapValidationAddressComponent = (
  components: TValidationAddressComponent[]
): google.maps.GeocoderAddressComponent[] => {
  return components.map((component) => ({
    long_name: component.componentName.text,
    short_name: component.componentName.text,
    types: [component.componentType],
  }));
};

const mapComponentType = (componentType: string): string => {
  switch (componentType) {
    case 'locality':
      return 'city';
    case 'administrative_area_level_1':
      return 'state';
    default:
      return componentType.replace(/_/g, ' ');
  }
};

export const usePlaceValidation = (
  addressFallback: boolean,
  inputValue: string,
  fallbackValue: google.maps.places.AutocompletePrediction | null,
  value: google.maps.places.AutocompletePrediction | null,
  cancelSessionToken: () => void,
  setEditable: (editable: boolean) => void
) => {
  const [fallbackOptions, setFallbackOption] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [validationResponse, setValidationResponse] = useState<TAddressResponse | null>(null);
  const [address, setAddress] = useState<TOrgAddress | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const fetchAddress = useMemo(
    () =>
      debounce((request: TAddressRequest, callback: (results: TAddressResponse) => void) => {
        if (addressFallback) {
          getAddressValidationService()
            ?.validateAddress({
              ...request,
              // todo: in the future we will need to add the session token here
              // currently types of session tokens differ in Autocomplete and Address Validation API
              // see: https://developers.google.com/maps/documentation/places/web-service/place-session-tokens
            })
            .then(callback)
            .catch(() => {
              setErrorFields(['invalid_format']);
            });
        }
      }, 500),
    [addressFallback]
  );

  useEffect(() => {
    if (!getAddressValidationService()) {
      return;
    }
    setErrorFields([]);

    if (inputValue === '' || value || !addressFallback) {
      setFallbackOption([]);
      return;
    }

    fetchAddress(
      {
        address: {
          addressLines: [inputValue],
        },
      },
      (results: TAddressResponse) => {
        if (results.result.address) {
          setValidationResponse(results);
          setFallbackOption([
            {
              description: results.result.address.formattedAddress,
              structured_formatting: {
                main_text: inputValue,
                main_text_matched_substrings: [],
                secondary_text: results.result.address.formattedAddress,
              },
              place_id: 'fallback_address',
              types: [],
              terms: [],
              matched_substrings: [],
            },
          ]);
        }
      }
    );
  }, [addressFallback, fallbackValue, fetchAddress, inputValue, value]);

  useEffect(() => {
    if (!fallbackValue) {
      setAddress(null);
      return;
    }

    if (
      fallbackValue &&
      validationResponse &&
      fallbackValue.description === validationResponse.result.address.formattedAddress
    ) {
      if (
        validationResponse.result.verdict.addressComplete ||
        !validationResponse.result.address.missingComponentTypes
      ) {
        const addressComponents = mapValidationAddressComponent(validationResponse.result.address.addressComponents);
        const validatedAddress = orgAddressSchema.safeParse(mapAddressComponents(addressComponents || []));
        if (validatedAddress.success) {
          setErrorFields([]);
          setAddress(validatedAddress.data);
          setName(validationResponse.result.address.formattedAddress || null);
          cancelSessionToken();
          setEditable(false);
        } else {
          setErrorFields(['invalid_format']);
        }
      } else {
        setErrorFields(validationResponse.result.address.missingComponentTypes?.map(mapComponentType) || []);
      }
    }
  }, [cancelSessionToken, fallbackValue, setEditable, validationResponse]);

  const clearFallbackAddress = useCallback(() => {
    setAddress(null);
    setName(null);
  }, []);

  const setFallbackData = useCallback((data: { address?: TOrgAddress | null; name?: string | null }) => {
    setAddress(data.address || null);
    setName(data.name || null);
  }, []);

  return {
    fallbackOptions,
    fallbackAddress: address,
    fallbackName: name,
    errorFields,
    clearFallbackAddress,
    setFallbackData,
  };
};
