export const content = {
  LOCATIONS_FETCHING_ERROR: "Something went wrong. Locations haven't been fetched.",
  NEW_GATEWAY: 'New Gateway',
  CREATE_SUBTITLE: 'Use the form below to create a new gateway.',
  EDIT_SUBTITLE: 'Use the form below to edit your gateway.',
  GATEWAY_NAME: 'Gateway Name',
  GATEWAY_LABEL: 'Gateway Details',
  LOCATION_NAME: 'Location',
  REQUIRED: 'This is a required field',
  REGISTER_CODE: 'Register Code',
  REGISTER_LABEL: 'Register & Connect',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  UPDATE: 'Update',
  SUCCESS_MESSAGE: 'Your gateway has been created.',
  ADDITIONAL_INFORMATION_LABEL: 'Additional Information',
  DESCRIPTION: 'Description',
  COMMISSION_DATE: 'Commission Date',
  GATEWAY_ERROR_MESSAGE: `Something went wrong. Your sensor hasn't been created`,
  ALREADY_REGISTERED: 'This register code is already registered. Please try again.',
  NOT_EXISTS: 'This register code does not exist. Please try again.',
  REGISTRATION_CODE_LINK: 'How do I find my registration code?',
  UPDATE_SUCCESS_MESSAGE: 'Your gateway has been updated.',
  CREATE_ERROR: "Something went wrong. Your gateway hasn't been created.",

  INSTRUCTIONS_MODAL: {
    STEP: (index: number) => `Step ${index}: `,
    TITLE: 'How do I find my registration code?',
    STEP_1: 'Change your Wifi network to Nexa',
    STEP_1_KEYWORD: 'Wifi',
    STEP_2: 'Go to Admin page in https://app.nexaplatform.com/',
    STEP_2_KEYWORD: 'Admin',
    STEP_3: 'Click on Register.',
    STEP_3_KEYWORD: 'Register',
    STEP_4: 'Copy the code.',
    STEP_4_KEYWORD: 'the code',
  },

  MODAL: {
    UNSAVED_CHANGES_MODAL_TITLE: 'You have unsaved changes',
    UNSAVED_CHANGES_MODAL_BODY: 'Are you sure you want to leave?',
    CANCEL: 'Cancel',
    LEAVE: 'Leave',
  },
};
