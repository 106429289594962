import { emailZodSchema, roleZodSchema } from '@marlin/shared/ui-form';
import { orgAddressSchema } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { content } from '../content';

const userSchema = z.object({
  email: emailZodSchema,
  role: roleZodSchema,
});

export const invitationFormSchema = z.object({
  users: z.array(userSchema).superRefine((invitations, ctx) => {
    const uniqueEmails = new Set<string>();

    invitations.forEach((invitation, index) => {
      if (uniqueEmails.has(invitation.email)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.DUPLICATE_EMAIL,
          path: [`${index}.email`],
        });
        return;
      }
      uniqueEmails.add(invitation.email);
    });
  }),
});

export const setupOrganizationSchema = z
  .object({
    propertyName: z.string().min(3, content.ORG_NAME_MUST_BE_VALIDATION).max(80, content.MIN_MAX).nullish(),
    propertyAddress: z.object({
      name: z.string().min(2, content.MIN_MAX).max(80, content.MIN_MAX).nullish(),
      address: orgAddressSchema.nullish(),
    }),
  })
  .superRefine((value, ctx) => {
    if (value.propertyName === null || value.propertyName === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.PROPERTY_NAME_REQUIRED,
        path: ['propertyName'],
      });
    }

    if (value.propertyAddress.address === null || !orgAddressSchema.safeParse(value.propertyAddress?.address).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.ADDRESS_REQUIRED,
        path: ['propertyAddress'],
      });
    }
  });

const registrationFlowValues = ['Tekmar', 'AercoBoiler', 'AercoWaterHeater'] as const;

export enum EQUIPMENT_ENUM {
  INTELLISTATION = 'intellistation',
  AERCO_INNOVATION = 'aerco_innovation',
  AERCO_BENCHMARK = 'aerco_benchmark',
}

export enum EQUIPMENT_STATUS {
  NEW = 'NEW',
  CONNECTING = 'CONNECTING',
  OK = 'OK',
  ERROR = 'ERROR',
  REFETCH = 'REFETCH',
}

export const equipmentSchema = z.object({
  registrationFlow: z.enum(registrationFlowValues),
  equipmentName: z.string().min(4).nullish(),
  registrationCode: z.string().min(4).nullish(),
  serialNumber: z.string().min(4).nullish(),
  status: z.nativeEnum(EQUIPMENT_STATUS),
  equipmentType: z.nativeEnum(EQUIPMENT_ENUM),
});

export const registerEquipmentSchema = z.object({
  equipment: z.array(equipmentSchema),
});

export const selectOrganizationSchema = z.object({
  organizationId: z.string().nullish(),
});

export const selectOrgComponentSchema = z.object({
  organizationId: z.string().min(3),
});

export type TEquipment = z.infer<typeof equipmentSchema>;
export type TSetupOrganization = z.infer<typeof setupOrganizationSchema>;
export type TSelectOrganization = z.infer<typeof selectOrganizationSchema>;
export type TRegisterEquipment = z.infer<typeof registerEquipmentSchema>;
export type TInvitationFormValues = z.infer<typeof invitationFormSchema>;
