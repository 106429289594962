import { SettingsPayloadValidation, TSettingPayload, TSettingsPayload } from '@marlin/shared/utils/datapoint-mappers';
import { getHttpClient, queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { queryKey } from './query-key.enum';

type TUpdateEquipmentSettings = {
  data: TSettingsPayload;
  equipmentId: string;
  settingGroupId: string | null;
};

export const updateEquipmentSettings = async ({ data, equipmentId, settingGroupId }: TUpdateEquipmentSettings) => {
  const { datapoints } = SettingsPayloadValidation.parse({ ...data, settingGroupId });

  return await getHttpClient().patch(
    paths.EQUIPMENT_SETTINGS_GROUP,
    { datapoints },
    { params: { equipmentId, settingGroupId } }
  );
};

export const useUpdateEquipmentSettings = ({
  equipmentId,
  settingGroupId,
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
  equipmentId: string;
  settingGroupId: string | null;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.SETTINGS(settingGroupId, equipmentId) });

      onSuccess && onSuccess();
    },
    onError: (error: z.ZodError | unknown) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      onError && onError();
    },
    mutationFn: async (modifiedSetting: TSettingPayload) => {
      await updateEquipmentSettings({
        data: { datapoints: [modifiedSetting] },
        equipmentId,
        settingGroupId,
      });
    },
  });
};
