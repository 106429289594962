import { MarlinTheme } from '@marlin/shared/theme';
import { IChartRangeOption, chartRangeTypeOptions } from '@marlin/shared/ui-form';
import {
  RANGE_FILTER,
  useCustomPeriodModalContextStore,
  useSetCustomPeriodModalContextStore,
} from '@marlin/shared/utils-chart';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { DateCalendar, DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import React, { ReactNode, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    height: theme.typography.pxToRem(510),
    width: theme.typography.pxToRem(640),
  },
  dateWrapper: {
    display: 'flex',
    paddingBottom: theme.typography.pxToRem(16),
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: theme.palette.text.secondary,
    width: '100%',
    paddingLeft: theme.typography.pxToRem(16),
  },
  icon: {
    fontSize: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(5),
    color: theme.palette.text.secondary,
  },
  calendar: {
    backgroundColor: theme.palette.background.secondary,
    border: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button:first-of-type': {
      marginRight: theme.typography.pxToRem(16),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(8),
  },
  input: {
    width: theme.typography.pxToRem(300),
    '& > div': {
      height: theme.typography.pxToRem(40),
    },
    '& > label': {
      top: theme.typography.pxToRem(-6),
    },
    '& > .MuiInputLabel-shrink': {
      top: theme.typography.pxToRem(0),
    },
  },
}));

export const DurationModal = ({ setRangeFilter }: { setRangeFilter: (option: RANGE_FILTER) => void }) => {
  const { classes } = useStyles();
  const [fromDate, setModalStore] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDate] = useCustomPeriodModalContextStore((store) => store.toDate);
  const [currentDateFrom, setCurrentDateFrom] = useState<Moment | null>(fromDate);
  const [currentDateTo, setCurrentDateTo] = useState<Moment | null>(toDate);

  const handleSave = useCallback(() => {
    setModalStore({
      open: false,
      fromDate: currentDateFrom,
      toDate: currentDateTo,
    });
    setRangeFilter(RANGE_FILTER.CUSTOM);
  }, [currentDateFrom, currentDateTo, setModalStore, setRangeFilter]);

  const handleClose = useCallback(() => {
    setModalStore({
      open: false,
    });
  }, [setModalStore]);

  return (
    <div className={classes.container} data-testid="header-section-duration-modal-body-container">
      <div className={classes.title}>Select period</div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className={classes.dateWrapper} data-testid="header-section-duration-modal-body-inputs">
          <DateField readOnly className={classes.input} label={content.FROM} value={currentDateFrom} />
          <DateField readOnly className={classes.input} label={content.TO} value={currentDateTo} />
        </div>
        <div className={classes.dateWrapper} data-testid="header-section-duration-modal-body-calendards">
          <DateCalendar
            className={classes.calendar}
            maxDate={currentDateTo ?? undefined}
            disableFuture
            value={currentDateFrom}
            onChange={setCurrentDateFrom}
          />
          <DateCalendar
            className={classes.calendar}
            minDate={currentDateFrom ?? undefined}
            disableFuture
            value={currentDateTo}
            onChange={setCurrentDateTo}
          />
        </div>
      </LocalizationProvider>
      <div className={classes.footer}>
        <Button onClick={handleClose} data-testid="modal-footer-button-close">
          {content.CANCEL}
        </Button>
        <Button
          onClick={handleSave}
          disabled={!currentDateFrom || !currentDateTo}
          variant="contained"
          data-testid="modal-footer-button-save"
        >
          {content.SET_PERIOD}
        </Button>
      </div>
    </div>
  );
};

const CustomOption = (): ReactNode => {
  const { classes } = useStyles();
  const setCustomModalStore = useSetCustomPeriodModalContextStore();

  const handleOpen = useCallback(() => {
    setCustomModalStore({
      open: true,
    });
  }, [setCustomModalStore]);

  return (
    <Button className={classes.button} onClick={handleOpen}>
      <EditIcon className={classes.icon} /> {content.CUSTOM}
    </Button>
  );
};

export const durationOptions: IChartRangeOption[] = [
  ...chartRangeTypeOptions,
  {
    id: RANGE_FILTER.DAYS_30,
    name: content.DAYS_30,
  },
  {
    id: RANGE_FILTER.MONTHS_12,
    name: content.MONTH_12,
  },
  {
    id: RANGE_FILTER.CUSTOM,
    name: <CustomOption />,
  },
];
