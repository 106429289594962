import { VALIDATE_INVITATION_ERROR, useValidateInvitation } from '@marlin/account-data-access-invitation';
import { DataLoader } from '@marlin/shared/ui-loader';
import { Navigate, useParams } from 'react-router-dom';

import { InvalidUserEmail } from './invitation-not-accepted/invalid-user-email.component';
import { InvitationExpired } from './invitation-not-accepted/invitation-expired.component';
import { InvitationNotAccepted } from './invitation-not-accepted/invitation-not-accepted.component';
import { UpdateUserProfile } from './user-profile-update.component';

interface IValidateInvitationProps {
  redirectUrl: string;
}

export const ValidateInvitation = ({ redirectUrl }: IValidateInvitationProps): JSX.Element | null => {
  const { invitationId } = useParams();
  const { status, data, error } = useValidateInvitation({ invitationId: invitationId ?? '' });

  switch (status) {
    case 'loading': {
      return <DataLoader />;
    }

    case 'error': {
      switch (error?.response?.data?.errorCode) {
        case VALIDATE_INVITATION_ERROR.INVITATION_ALREADY_ACCEPTED: {
          return <Navigate to={redirectUrl} />;
        }

        case VALIDATE_INVITATION_ERROR.INVITATION_EXPIRED: {
          return <InvitationExpired organizationName={error?.response?.data?.OrganizationName} />;
        }

        case VALIDATE_INVITATION_ERROR.INVALID_EMAIL_ADDRESS: {
          return <InvalidUserEmail />;
        }

        default: {
          return <InvitationNotAccepted />;
        }
      }
    }

    case 'success': {
      return <UpdateUserProfile user={data} redirectUrl={redirectUrl} />;
    }

    default: {
      return null;
    }
  }
};
