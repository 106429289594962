import { useSnoozeAutomation } from '@marlin/alert/data-access/automated-action';
import { useSnackbar } from 'notistack';

import { content } from './content';
import { SnoozeModal } from './snooze-modal.component';

interface ISnoozeModalContainerProps {
  automation: {
    ruleId: string;
    ruleName: string;
  };
  deviceId: string;
  closeModal: () => void;
}

export const SnoozeModalContainer = ({ automation, closeModal, deviceId }: ISnoozeModalContainerProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: snooze } = useSnoozeAutomation();

  const handleSubmitSnoozeTime = async (minutes: number) => {
    closeModal();
    try {
      await snooze({ id: automation.ruleId, minutes, deviceId });
      enqueueSnackbar(content.SNOOZE_SUCCESS(automation.ruleName), {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (e) {
      enqueueSnackbar(content.SNOOZE_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return <SnoozeModal automationName={automation.ruleName} onSubmit={handleSubmitSnoozeTime} onClose={closeModal} />;
};
