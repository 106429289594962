import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ width?: number }>()((theme: MarlinTheme, { width }) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.typography.pxToRem(width || 530),
    backgroundColor: theme.palette.background.primary,
    // todo (THEME_COLORS_FIX) replace rgba colors with one from palette
    boxShadow: `0 ${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(
      -7
    )} rgba(0, 0, 0, 0.2), 0 ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(38)} ${theme.typography.pxToRem(
      3
    )} rgba(0, 0, 0, 0.14), 0 ${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(46)} ${theme.typography.pxToRem(
      8
    )} rgba(0, 0, 0, 0.12)`,
    borderRadius: theme.typography.pxToRem(4),
    paddingTop: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },

  body: {
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(16),
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.typography.pxToRem(32),
  },

  footer: {
    marginTop: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
}));
