import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { useCallback } from 'react';

import { TSelectedDatapoint } from '../types';

export const useSortDatapointsWithColor = () => {
  const theme = useTheme<MarlinTheme>();
  const chartColors = Array.from(Object.values(theme.palette.charting));

  return useCallback(
    (data: TSelectedDatapoint[]): TSelectedDatapoint[] => {
      const [sensorsInOrder, equipmentDatapointsInOrder] = partition(
        orderBy(data, 'label'),
        ({ parentType }) => parentType === 'sensor'
      );

      return [...sensorsInOrder, ...equipmentDatapointsInOrder].map((datapoint, index) => {
        const newColor = chartColors[index] ?? chartColors[chartColors.length - index];

        return { ...datapoint, color: newColor };
      });
    },
    [chartColors]
  );
};
