import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateHomeFlowMeter } from '../infrastructure/flow-meter';
import { queryKey } from './query-key.enum';

export const useUpdateHomeFlowMeter = () => {
  return useMutation({
    mutationFn: updateHomeFlowMeter,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.WATER_USAGE() }),
  });
};
