import { IUser } from './user.model';
import { userService } from './user.service';

interface IUserService {
  getUser(): IUser | null;
}

export class UserFacade {
  public constructor(private userService: IUserService) {}

  public getUser = (): IUser | null => this.userService.getUser();
}

export const userFacade = new UserFacade(userService);
