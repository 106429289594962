import { useChartOptions, useChartOptionsV2 } from '@marlin/asset/shared/ui/chart-options';
import { ChartSwitcherWrapper } from '@marlin/asset/shared/ui/chart-switcher-wrapper';
import { ChartTypeSwitcher } from '@marlin/asset/shared/ui/chart-type-switcher';
import { MarlinTheme } from '@marlin/shared/theme';
import { TChartDisplayType } from '@marlin/shared/utils-chart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Icon, IconButton, alpha } from '@mui/material';
import Button from '@mui/material/Button';
import kebabCase from 'lodash/kebabCase';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useMultiChartsStore } from '../../context/multi-charts.context';
import { useRemoveChart, useSelectedSensorsStore, useSetSelectedSensors } from '../../context/selected-sensors.context';
import { useSetSensorFiltering } from '../../context/sensor-filtering.context';
import { useBucketOptions } from '../../hooks/use-bucket-options.hook';
import { useChartData } from '../../hooks/use-chart-data.hook';
import { useDeleteChartModal } from '../../hooks/use-delete-chart-modal.hook';
import { useSensorFiltering } from '../../hooks/use-sensor-filtering.hook';
import { ChartType, IFullScreen, TChartType } from '../../types';
import { getChartIcon } from '../../utils/get-chart-icon-name';
import { BucketOptionSelect } from '../bucket-option-select.component';
import { Chips } from '../chips';
import { EditSensorsDrawer } from '../edit-sensors-drawer/edit-sensors-drawer.component';
import { ExportCsvButton } from '../export-csv-button.component';
import { ExportCsvModal } from '../export-csv-modal.component';
import { SectionHeader } from '../section-header.component';
import { TotalVolume } from '../total-volume.component';

interface IStyles {
  hide: boolean;
  isFullScreen: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { hide, isFullScreen }) => ({
  chartWrapper: {
    display: hide ? 'none' : 'block',
    borderBottom: isFullScreen ? 'none' : `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(8)} 0`,
    gap: theme.typography.pxToRem(16),
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    marginBottom: theme.typography.pxToRem(2),
  },
  fullScreenButton: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(13),
    marginTop: theme.typography.pxToRem(4),
    textTransform: 'uppercase',
    '& > svg': {
      height: theme.typography.pxToRem(18),
    },
    gap: theme.typography.pxToRem(4),
  },
  actions: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    alignItems: 'center',
  },
  button: {
    maxHeight: theme.typography.pxToRem(30),
    fontSize: theme.typography.pxToRem(13),
    maxWidth: theme.typography.pxToRem(130),
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(10)}`,
    letterSpacing: theme.typography.pxToRem(0.46),
    lineHeight: theme.typography.pxToRem(22),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.background.alternative, 0.7),
    zIndex: theme.zIndex.drawer,
  },
  skeleton: {
    height: '100%',
    width: '100%',
  },
  customIcon: {
    // this is to keep it consistent with default apex icons
    transform: 'scale(.7)',
  },
}));

interface IChartProps {
  chartId: string;
  fullScreenChartId: string | null;
  toggleFullScreen: ({ chartId, chartType }: IFullScreen) => void;
  paperHeight: number;
  setIsOffline: (isOffline: boolean) => void;
}

export const Chart = ({ chartId, fullScreenChartId, toggleFullScreen, paperHeight, setIsOffline }: IChartProps) => {
  const [rangeFilter] = useMultiChartsStore((store) => store.rangeFilter.range);
  const [chartDatapoints] = useSelectedSensorsStore((store) => store[chartId]?.chartDatapoints);
  const [chartType] = useSelectedSensorsStore((store) => store[chartId]?.chartType);
  const removeChart = useRemoveChart();
  const { bucketOption, setBucketOption } = useBucketOptions(chartId);
  const isOffline = !navigator.onLine;
  const { locationData } = useSensorFiltering();
  const [isExportCsvModalOpen, setIsExportCsvModalOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const setSelectedSensors = useSetSelectedSensors();
  const [selectedSensorsForChart] = useSelectedSensorsStore((store) => store[chartId]);

  const setSensorFiltering = useSetSensorFiltering();

  useEffect(() => {
    if (isOffline) {
      setIsOffline(true);
    }
  }, [isOffline, setIsOffline]);

  useEffect(() => {
    const activeManufacturerIdList = locationData
      ?.map((location) => location.devices.map((device) => device.manufacturerId))
      .flat();
    const chartDatapointsWithoutUnregistered = chartDatapoints.filter((chartDatapoint) =>
      activeManufacturerIdList?.includes(chartDatapoint.manufacturerId)
    );

    if (chartDatapointsWithoutUnregistered.length !== chartDatapoints.length) {
      setSelectedSensors({
        [chartId]: {
          ...selectedSensorsForChart,
          chartDatapoints: chartDatapointsWithoutUnregistered,
        },
      });
    }
  }, [chartDatapoints, chartId, locationData, selectedSensorsForChart, setSelectedSensors]);

  const headerRef = useRef<HTMLDivElement>(null);
  const [chartDisplayType, setChartDisplayType] = useState<TChartDisplayType>(
    getDefaultChartDisplayType(chartType, selectedSensorsForChart.chartDisplayType)
  );

  const onChartDisplayTypeChange = useCallback(
    (newChartDisplayType: TChartDisplayType) => {
      setChartDisplayType(newChartDisplayType);
      setSelectedSensors({
        [chartId]: {
          ...selectedSensorsForChart,
          chartDisplayType: newChartDisplayType,
        },
      });
    },
    [chartId, selectedSensorsForChart, setSelectedSensors]
  );

  const [isEditSensorDrawerOpen, setEditSensorDrawerOpen] = useState(false);

  const isFullScreen = useMemo(() => fullScreenChartId === chartId, [fullScreenChartId, chartId]);
  const { classes } = useStyles({ hide: !!fullScreenChartId && !isFullScreen, isFullScreen });

  const icon = getChartIcon(chartType);

  const onFullScreenClick = () => {
    toggleFullScreen({ chartId, chartType, title: name });
  };

  const {
    isLoading,
    isFetching,
    handleZoomChange,
    chartData,
    chartDataWithoutMargins,
    from,
    to,
    min,
    max,
    totalVolume,
    name,
    isZoomed,
    getTelemetryExport,
  } = useChartData({
    chartId,
    chartType,
    chartDisplayType,
  });

  const { chartOptions } = useChartOptions({
    chartData,
    chartDataWithoutMargins,
    from,
    to,
    max,
    min,
    handleZoomChange,
    isZoomed,
    chartDisplayType,
    chartId,
    isFullScreen,
    customIconClassName: classes.customIcon,
    onFullScreenClick,
    rangeFilter,
    hideUomOnAxis: ChartType.Enum.custom === chartType,
    bucketOption,
  });

  const { layout, config, handleRelayout, isEmpty, handleUnhover, handleHover, tooltip } = useChartOptionsV2({
    chartData,
    chartDataWithoutMargins,
    from,
    to,
    max,
    min,
    handleZoomChange,
    isZoomed,
    chartDisplayType,
    chartId,
    isFullScreen,
    customIconClassName: classes.customIcon,
    onFullScreenClick,
    rangeFilter,
    hideUomOnAxis: ChartType.Enum.custom === chartType,
    bucketOption,
  });

  const deleteChart = useCallback(() => {
    removeChart(chartId);
  }, [chartId, removeChart]);

  const { openChartDeleteModal } = useDeleteChartModal(deleteChart, name);

  const toggleDrawer = useCallback(
    (newIsOpen: boolean) => {
      setEditSensorDrawerOpen(newIsOpen);

      if (newIsOpen) {
        setSensorFiltering({ selectedChartType: chartType });
      }
    },
    [chartType, setSensorFiltering]
  );

  const rangeBarDatapoints = useMemo<string[] | undefined>(() => {
    if (chartDisplayType === 'rangeBar') {
      return chartDatapoints?.map((item) => `${item.label} - ${item.equipmentName}`);
    }

    return undefined;
  }, [chartDisplayType, chartDatapoints]);

  const submitExport = async () => {
    const exportingSnackbarId = enqueueSnackbar({
      variant: 'loadingSnackbar',
      preventDuplicate: true,
      message: content.EXPORTING_DATA_BODY,
      title: content.EXPORTING_DATA_TITLE,
      persist: true,
    });

    try {
      const { url } = await getTelemetryExport();
      window.open(url, '_blank');
      enqueueSnackbar(content.DATA_EXPORTED, {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    } finally {
      closeSnackbar(exportingSnackbarId);
    }
  };

  const onExportCsvModalClose = useCallback(() => {
    setIsExportCsvModalOpen(false);
  }, []);

  const onExportCsvClick = useCallback(() => {
    setIsExportCsvModalOpen(true);
  }, []);

  return (
    <>
      <div className={classes.chartWrapper}>
        <div ref={headerRef}>
          <div className={classes.header}>
            {isFullScreen ? (
              <Button
                onClick={() => toggleFullScreen({ chartId, chartType, title: name })}
                className={classes.fullScreenButton}
                data-testid="cancel-button"
              >
                <ArrowBackIcon />
                {content.BACK_BUTTON}
              </Button>
            ) : (
              <SectionHeader chartId={chartId} title={name} icon={icon} />
            )}
            <div className={classes.actions}>
              <Button
                variant="contained"
                onClick={() => toggleDrawer(true)}
                className={classes.button}
                data-testid={`open-edit-sensors-drawer-button-${kebabCase(name)}`}
              >
                {content.EDIT_SENSORS}
              </Button>
              {chartType === 'flow' && (
                <ChartTypeSwitcher chartDisplayType={chartDisplayType} setChartDisplayType={onChartDisplayTypeChange} />
              )}
              <ExportCsvButton
                onExportCsvClick={onExportCsvClick}
                name={name}
                buttonClassName={classes.button}
                disabled={isEmpty}
              />
              {chartDisplayType === 'bar' && (
                <BucketOptionSelect
                  bucketOption={bucketOption ?? ''}
                  setBucketOption={setBucketOption}
                  to={to}
                  from={from}
                  isZoomed={isZoomed}
                />
              )}
              {!isFullScreen && (
                <IconButton onClick={openChartDeleteModal} className={classes.iconButton}>
                  <Icon baseClassName="material-symbols-rounded" className={classes.icon}>
                    Delete
                  </Icon>
                </IconButton>
              )}
            </div>
          </div>
          <Chips chartId={chartId} />
          {totalVolume && <TotalVolume value={totalVolume} chartType={chartDisplayType} />}
        </div>
        <ChartSwitcherWrapper
          from={from}
          to={to}
          isFetching={isFetching}
          isLoading={isLoading}
          chartDisplayType={chartDisplayType}
          chartData={chartData}
          chartOptions={chartDisplayType === 'rangeBar' ? chartOptions : undefined}
          currentAnnotationTooltip={null}
          headerHeight={headerRef.current?.clientHeight || 0}
          chartId={chartId}
          paperHeight={paperHeight}
          isFullScreen={isFullScreen}
          rangeBarDatapoints={rangeBarDatapoints}
          layout={layout}
          config={config}
          handleRelayout={handleRelayout}
          isEmpty={isEmpty}
          handleHover={handleHover}
          handleUnhover={handleUnhover}
          tooltip={tooltip}
        />
      </div>
      <EditSensorsDrawer
        isEditSensorDrawerOpen={isEditSensorDrawerOpen}
        toggleDrawer={toggleDrawer}
        chartType={chartType}
        chartId={chartId}
      />
      {isExportCsvModalOpen && <ExportCsvModal submitExport={submitExport} onClose={onExportCsvModalClose} />}
    </>
  );
};

const getDefaultChartDisplayType = (chartType?: TChartType, storedDisplayType?: string): TChartDisplayType => {
  if (storedDisplayType && chartType === 'flow' && ['bar', 'rangeArea'].includes(storedDisplayType)) {
    return storedDisplayType as TChartDisplayType;
  }

  switch (chartType) {
    case 'flow':
      return 'bar';
    case 'leak':
    case 'onOff':
      return 'rangeBar';
    default:
      return 'line';
  }
};
