import {
  globalConfigAerco,
  globalConfigIntellistation,
  globalConfigPartition,
  globalConfigPvi,
  globalConfigSentinel,
} from '@marlin/asset/shared/equipment-config';
import {
  statusIdToKeyAdapterAerco,
  statusIdToKeyAdapterIntellistation,
  statusIdToKeyAdapterSentinel,
} from '@marlin/shared/utils/datapoint-mappers';
import { ModelEnum, TModel } from '@marlin/shared/utils/zod';

export const getConnectionTimeout = (model: TModel) => {
  switch (model) {
    case ModelEnum.enum.INTELLISTATION:
      return globalConfigIntellistation.dashboard.liveDataExpirationSeconds;
    case ModelEnum.enum.AERCO_BOILER_DUAL:
    case ModelEnum.enum.AERCO_BOILER_LN:
    case ModelEnum.enum.AERCO_WATER_HEATER_N:
    case ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION:
      return globalConfigAerco.dashboard.liveDataExpirationSeconds;
    case ModelEnum.enum.PVI_BRIGADE:
    case ModelEnum.enum.CONQUEST_100_20L100A_GCL:
    case ModelEnum.enum.CONQUEST_100_25L100A_GCL:
    case ModelEnum.enum.CONQUEST_100_30L100A_GCL:
    case ModelEnum.enum.CONQUEST_130_40L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_50L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_60L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_70L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_80L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_90L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_100L130A_GCML:
    case ModelEnum.enum.CONQUEST_1100_CQT1100:
    case ModelEnum.enum.CONQUEST_1200_CQT1200:
    case ModelEnum.enum.CONQUEST_130_40L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_50L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_60L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_70L130AGCMLW:
    case ModelEnum.enum.CONQUEST_130_80L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_1100_CQTO1100:
    case ModelEnum.enum.CONQUEST_1200_CQTO1200:
      return globalConfigPvi.dashboard.liveDataExpirationSeconds;
    case ModelEnum.enum.PARTITION:
      return globalConfigPartition.dashboard.liveDataExpirationSeconds;
    // TODO: [SENTINEL] add sentinel timeout
    case ModelEnum.enum.SENTINEL:
      return globalConfigSentinel.dashboard.liveDataExpirationSeconds;
    default:
      return Number.MIN_SAFE_INTEGER;
  }
};

export const getModeDatapoint = (model: TModel) => {
  switch (model) {
    case ModelEnum.enum.INTELLISTATION:
      return 'overridemode';
    case ModelEnum.enum.AERCO_BOILER_DUAL:
    case ModelEnum.enum.AERCO_BOILER_LN:
    case ModelEnum.enum.AERCO_WATER_HEATER_N:
    case ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION:
      return 'unitstatusidx';
    case ModelEnum.enum.SENTINEL:
      return 'scene';
    default:
      return '';
  }
};

export const getStatusAdapter = (model: TModel) => {
  switch (model) {
    case ModelEnum.enum.INTELLISTATION:
      return statusIdToKeyAdapterIntellistation;
    case ModelEnum.enum.AERCO_BOILER_DUAL:
    case ModelEnum.enum.AERCO_BOILER_LN:
    case ModelEnum.enum.AERCO_WATER_HEATER_N:
    case ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION:
      return statusIdToKeyAdapterAerco;
    case ModelEnum.enum.SENTINEL:
      return statusIdToKeyAdapterSentinel;
    default:
      return null;
  }
};
