import { SLIDE, TEASER_MODAL_ACTION_TYPE, TeaserButton, useTeaserModal } from '@marlin/account/feature/teaser';
import { CollapsableLogo } from '@marlin/shared/theme';
import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { Drawer } from '@mui/material';
import { FC, PropsWithChildren, useEffect } from 'react';

import { Logout } from './components/logout.component';
import { NavLinks } from './components/nav-links.component';
import { content } from './content';
import { useStyles } from './side-nav.component.styles';
import { TSideNavElement } from './types';

interface ISideNavProps {
  isCollapsed: boolean;
  topNavLinks: TSideNavElement[];
  bottomNavLinks: TSideNavElement[];
  app?: 'marlin' | 'portfolio' | 'support';
}

export const SideNav: FC<PropsWithChildren<ISideNavProps>> = ({
  children,
  isCollapsed,
  topNavLinks,
  bottomNavLinks,
  app,
}) => {
  const { modalDispatch } = useTeaserModal();
  const allowSystemMap = usePermission(PERMISSIONS.SYSTEM_MAP);
  const { handleExpandDesktopNavbar, handleCollapseDesktopNavbar, desktopNavbarLocked } =
    useDesktopSideNavbarCollapse();
  const { classes } = useStyles({ isCollapsed, desktopNavbarLocked });

  useEffect(() => {
    handleCollapseDesktopNavbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpgradeNexa = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL,
      payload: {
        slide: SLIDE.SYSTEM_MAP,
      },
    });
  };

  return (
    <Drawer
      onMouseLeave={handleCollapseDesktopNavbar}
      onMouseEnter={handleExpandDesktopNavbar}
      className={classes.wrapper}
      variant="permanent"
      anchor="left"
      data-testid="side-nav-menu"
    >
      {children}
      <div className={classes.optionsWrapper}>
        <div>
          <CollapsableLogo isCollapsed={isCollapsed} locked={desktopNavbarLocked} />
          <NavLinks isCollapsed={isCollapsed} desktopNavbarLocked={desktopNavbarLocked} navLinks={topNavLinks} />
        </div>

        <div className={classes.bottomWrapper}>
          {!allowSystemMap && app === 'marlin' && (
            <div className={classes.upgradeNexaBtn}>
              <TeaserButton
                onClick={handleUpgradeNexa}
                variant={isCollapsed && !desktopNavbarLocked ? 'collapsed' : 'main'}
                text={isCollapsed && !desktopNavbarLocked ? '' : content.UPGRADE_NEXA}
              />
            </div>
          )}
          <NavLinks isCollapsed={isCollapsed} desktopNavbarLocked={desktopNavbarLocked} navLinks={bottomNavLinks} />
          <Logout isCollapsed={isCollapsed} desktopNavbarLocked={desktopNavbarLocked} />
        </div>
      </div>
    </Drawer>
  );
};
