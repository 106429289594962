import { MarlinTheme } from '@marlin/shared/theme';
import { Select } from '@marlin/shared/ui-form-common';
import { SelectChangeEvent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { TChartType } from '../../types';
import { chartTypesIcons } from '../../utils/chart-types-icons';

const useStyles = makeStyles<{ fullWidth: boolean }>()((theme: MarlinTheme, { fullWidth }) => ({
  selectWidth: {
    width: fullWidth ? '100%' : '50%',
  },
}));

interface ISensorTypeSelectProps {
  disabled?: boolean;
  label?: string;
  fullWidth?: boolean;
  selectedChartType: TChartType | '';
  onChartTypeChange: (event: SelectChangeEvent<TChartType | ''>) => void;
}

const options = [
  { id: 'temperature', name: content.TEMPERATURE, Icon: chartTypesIcons['temperature'] },
  { id: 'pressure', name: content.PRESSURE, Icon: chartTypesIcons['pressure'] },
  { id: 'leak', name: content.LEAK, Icon: chartTypesIcons['leak'] },
  { id: 'flow', name: content.FLOW, Icon: chartTypesIcons['flow'] },
  { id: 'onOff', name: content.ON_OFF_STATUS, Icon: chartTypesIcons['onOff'] },
  { id: 'custom', name: content.CUSTOM, Icon: chartTypesIcons['custom'] },
];

export const SensorTypeSelect = ({
  disabled = false,
  label = content.SENSOR_TYPE,
  fullWidth = false,
  selectedChartType,
  onChartTypeChange,
}: ISensorTypeSelectProps) => {
  const { classes } = useStyles({ fullWidth });

  return (
    <Select
      required
      className={classes.selectWidth}
      data={options}
      label={label}
      prefix="sensorType"
      disabled={disabled}
      value={selectedChartType}
      onChange={onChartTypeChange}
    />
  );
};
