import { BehaviorSubject } from 'rxjs';

import { IUser } from './user.model';

const emptyUser: IUser = {
  id: '',
  email: '',
  organizationId: '',
  currentOrganizationId: '',
};

export class UserService {
  private user: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  public getUser = (): IUser | null => this.user.value;

  public setUser = (user: IUser): void => {
    this.user.next(user);
  };

  public setUserOrganization = (organizationId: string) => {
    const currentUser = this.user.value;
    this.user.next({
      ...(currentUser ? currentUser : emptyUser),
      organizationId,
    });
  };
}

export const userService = new UserService();
