import { TDeviceUserMeasuresPreferences, TUnitOfMeasure } from '../zod/uom.schema';

const convertFromFahrenheitToCelsius = (value: number) => {
  return ((value - 32) * 5) / 9;
};

//Pressure
const convertFromPsiToKpa = (value: number) => {
  return value * 6.89475729;
};

//Flow
const convertFormGpmToLpm = (value: number) => {
  return value * 3.785411784;
};

const convertFormGpmToM3h = (value: number) => {
  return value * 0.22712470704;
};

//Energy
const convertFromMbtuToKwh = (value: number) => {
  return value * 0.293071083;
};
const convertFromMbtuToGj = (value: number) => {
  return value * 0.0010550559;
};
const convertFromMbtuToTh = (value: number) => {
  return value * 0.0099957;
};

export const uomConversions: Record<string, Record<string, (v: number) => number>> = {
  f: { c: convertFromFahrenheitToCelsius },
  psi: { kpa: convertFromPsiToKpa },
  gpm: { lpm: convertFormGpmToLpm, m3ph: convertFormGpmToM3h },
  mbtu: { kwh: convertFromMbtuToKwh, gj: convertFromMbtuToGj, thm: convertFromMbtuToTh },
};

type TConvertedValue = { value: string; uom: TUnitOfMeasure | null };

export const getConvertedValue = (
  value: string,
  unitOfMeasure: TUnitOfMeasure | null,
  userMeasuresPreferences?: TDeviceUserMeasuresPreferences
): TConvertedValue => {
  const initialValue = { value, uom: unitOfMeasure };

  if (!unitOfMeasure || !userMeasuresPreferences || !(unitOfMeasure in uomConversions)) {
    return initialValue;
  }

  const userMeasuresPreferencesValues = Object.values(userMeasuresPreferences);

  if (userMeasuresPreferencesValues.includes(unitOfMeasure)) {
    return initialValue;
  }

  const conversionGroup = uomConversions[unitOfMeasure];

  const convertedValueWithUom = userMeasuresPreferencesValues.reduce<TConvertedValue | undefined>(
    (convertedValue, unit) => {
      if (unit && unit in conversionGroup) {
        if (uomConversions[unitOfMeasure][unit])
          return { value: uomConversions[unitOfMeasure][unit](Number(value)).toString(), uom: unit };

        return convertedValue;
      }

      return convertedValue;
    },
    undefined
  );

  if (!convertedValueWithUom) {
    // eslint-disable-next-line no-console
    console.warn('No conversion found for', value, unitOfMeasure);

    return initialValue;
  }

  return convertedValueWithUom;
};
