export const content = {
  DAYS_30: '30 days',
  MONTH_12: '12 months',
  CUSTOM: 'Custom period',
  CANCEL: 'Cancel',
  SET_PERIOD: 'Set period',
  BACK: 'Back',
  FROM: 'From',
  TO: 'To',
};
