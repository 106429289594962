import { PERMISSIONS, TPermission } from '@marlin/shared/utils-permission';

import { useCurrentUser } from './get-my-user.query';

const featuresToPermissions = {
  // TIER 2
  SensorDevices: PERMISSIONS.SENSOR_DEVICES,
  SystemMap: PERMISSIONS.SYSTEM_MAP,
  SustainabilityData: PERMISSIONS.SUSTAINABILITY_DATA,
  CustomAutomations: PERMISSIONS.CUSTOM_AUTOMATIONS,
  MultiDataCharts: PERMISSIONS.MULTI_DATA_CHARTS,
  EnergyCalculations: PERMISSIONS.ENERGY_CALCULATIONS,
  DataExport: PERMISSIONS.DATA_EXPORT,
  PerformanceScores: PERMISSIONS.PERFORMANCE_SCORES,
  AutogeneratedReports: PERMISSIONS.AUTOGENERATED_REPORTS,
  // TIER 3
  EnergyCalculations2: PERMISSIONS.ENERGY_CALCULATIONS_2,
  MultiPointAutomations: PERMISSIONS.MULTI_POINT_CALCULATIONS,
  PredictiveInsights: PERMISSIONS.PREDICTIVE_INSIGHTS,
  EquationBuilder: PERMISSIONS.EQUATION_BUILDER,
  MultiDataCharts2: PERMISSIONS.MULTI_DATA_CHARTS_2,
  CustomReportFormats: PERMISSIONS.CUSTOM_REPORT_FORMATS,
};

type TFeaturesToPermissionsKeys = keyof typeof featuresToPermissions;

export const useMarlinTiers = () => {
  const { isLoading, data } = useCurrentUser();
  const currentOrganization = data?.currentOrganization;
  const tierData = currentOrganization ? currentOrganization.features.features : [];

  const permissions: TPermission[] = [];
  tierData.forEach((feature: string) => {
    permissions.push(featuresToPermissions[feature as TFeaturesToPermissionsKeys]);
  });

  return {
    isLoading,
    data: {
      permissions,
    },
  };
};
