import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { Handle, NodeProps, Position, useStore } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../const';
import { useCommonStyles } from '../common.styles';
import { NodeTooltip } from '../utils/node-tooltip.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  equipment: {
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(8),
  },
  equipmentLabel: {
    marginRight: theme.typography.pxToRem(8),
    whiteSpace: 'nowrap',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

type TNodeData = TGraphAsset['data'] & { label: string };

export const UnregisteredEquipmentNode = ({ data, id }: NodeProps<TNodeData>) => {
  const { classes, cx } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const draggable = useStore((store) => store.nodesDraggable);

  const sliceName = !!(data.name && data.name?.length > maxLabelLength);

  return (
    <Link
      to={routes.equipments.details.url(data.assetId || '')}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      disabled={draggable}
    >
      <NodeTooltip text={data.name || ''} when={sliceName}>
        <div className={cx(commonClasses.node, classes.equipment)} data-testid={`equipment-node-${id}`}>
          <div className={commonClasses.nodeContent}>
            <span className={classes.equipmentLabel} data-testid={`equipment-node-${id}-label`}>
              {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data.name}
            </span>
            <DeviceHubRoundedIcon />
          </div>
          <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
          <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
          <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
          <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
        </div>
      </NodeTooltip>
    </Link>
  );
};
