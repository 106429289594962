import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { content } from '../content';
import { GatewayUpsertForm } from '../gateway-upsert-form.component';
import { useUpdateGateway } from '../hooks/use-update-gateway.hook';

export const GatewayEdit = () => {
  const { gateway, isLoading, onSubmit, gatewayId, registrationErrorCode } = useUpdateGateway();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <GatewayUpsertForm
      defaultValues={gateway}
      gatewayId={gatewayId}
      onSubmit={onSubmit}
      registrationErrorCode={registrationErrorCode}
      title={gateway?.name || ''}
      subtitle={content.EDIT_SUBTITLE}
    />
  );
};
