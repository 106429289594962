import { useUpdateSettings } from '@marlin/account-data-access-organization';
import { useCurrentUser } from '@marlin/account-data-access-organization';
import { CommonChip } from '@marlin/shared/ui/chips';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import { Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { content } from '../content';
import { useStyles } from './appearance-card.styles';
import { ReactComponent as DasboardLayout } from './dashboard-layout.svg';
import { ReactComponent as LocationsLayout } from './locations-layout.svg';

interface IAppearanceCardProps {
  variant: 'DASHBOARD' | 'LOCATIONS';
  isActive: boolean;
}

export const AppearanceCard = ({ variant, isActive }: IAppearanceCardProps) => {
  const { classes } = useStyles();
  const update = useUpdateSettings();
  const { data } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const updateDefaultLayout = (defaultHomePage: 'DASHBOARD' | 'LOCATIONS') => {
    update
      .mutateAsync({
        ...data?.settings,
        defaultHomePage,
      })
      .then(() => {
        enqueueSnackbar(content.DEFAULT_HOME_VIEW_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
      })
      .catch(() => {
        enqueueSnackbar(content.SOMETHING_WENT_WRONG, {
          variant: 'error',
          preventDuplicate: true,
        });
      });
  };

  const handleLocationsLayoutClick = () => {
    if (isActive) {
      return;
    }
    updateDefaultLayout('LOCATIONS');
  };

  const handleDashboardLayoutClick = () => {
    if (isActive) {
      return;
    }
    updateDefaultLayout('DASHBOARD');
  };

  return (
    <div className={isActive ? classes.containerActive : classes.container}>
      {variant === 'DASHBOARD' ? (
        <div onClick={handleDashboardLayoutClick}>
          {isActive ? (
            <div className={classes.titleContainerActive}>
              <div className={classes.titleContainer}>
                <DashboardRoundedIcon />
                <Typography className={classes.title}>{content.DASHBOARD_LAYOUT}</Typography>
              </div>
              <div>
                <CommonChip active={true} variant={'filled'} label={content.ACTIVE} />
              </div>
            </div>
          ) : (
            <div className={classes.titleContainerNoActive}>
              <DashboardRoundedIcon />
              <Typography className={classes.title}>{content.DASHBOARD_LAYOUT}</Typography>
            </div>
          )}
          <Typography className={classes.subtitle}>{content.DASHBOARD_LAYOUT_SUBTITLE}</Typography>
          <Divider />
          <DasboardLayout />
        </div>
      ) : (
        <div onClick={handleLocationsLayoutClick}>
          {isActive ? (
            <div className={classes.titleContainerActive}>
              <div className={classes.titleContainer}>
                <ViewWeekRoundedIcon />
                <Typography className={classes.title}>{content.LOCATIONS_LAYOUT}</Typography>
              </div>
              <div>
                <CommonChip active={true} variant={'filled'} label={content.ACTIVE} />
              </div>
            </div>
          ) : (
            <div className={classes.titleContainerNoActive}>
              <ViewWeekRoundedIcon />
              <Typography className={classes.title}>{content.LOCATIONS_LAYOUT}</Typography>
            </div>
          )}
          <Typography className={classes.subtitle}>{content.LOCATIONS_LAYOUT_SUBTITLE}</Typography>
          <Divider />
          <LocationsLayout />
        </div>
      )}
    </div>
  );
};
