import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, Select } from '@marlin/shared/ui-form-common';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { TNode } from '@marlin/system-map/data-access/system-map';
import { Grid, SelectChangeEvent } from '@mui/material';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { assetTypeOptions, equipmentModelOptions } from './constants';
import { content } from './content';
import { NodeAutocomplete } from './node-autocomplete.component';
import { ASSET_TYPE, IAssetFilter, IAssetType, NODE_TYPE, TTag } from './types';
import { useEquipmentHandle } from './use-equipment-handle';

// TODO: Add support for rest of equipment types
const mapTagModelToEquipmentType = (modelName: string): EQUIPMENT_TYPE | undefined => {
  switch (modelName) {
    case 'Intellistation':
      return EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE;

    default:
      return undefined;
  }
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'baseline',
  },
  assetSelect: {
    width: '100%',
  },
  equipmentSelected: {
    width: `calc(50% - ${theme.typography.pxToRem(8)})`,
  },
  equipmentModelField: {
    marginLeft: theme.typography.pxToRem(16),
  },
}));

type TNodeToken = 'inlet' | 'outlet';

interface INodeProps {
  defaultValue?: TNode;
  title: ReactNode;
  nodeType: NODE_TYPE;
  assets: TNode[];
  onAssetFilterChange: (node: NODE_TYPE, assetFilter: IAssetFilter) => void;
  editMode?: boolean;
}

const handleIdTag = 'HandleId';
const modelTag = 'Model';

export const Node = ({
  title,
  nodeType,
  onAssetFilterChange,
  assets,
  defaultValue = undefined,
  editMode = false,
}: INodeProps) => {
  const { classes, cx } = useStyles();
  const { boiler, waterHeater, sentinel } = useFeatureFlagsContext();

  const { setValue: setFieldValue, watch, trigger } = useFormContext();
  const { isDirty, isSubmitted } = useFormState();
  const [assetType, setAssetType] = useState<ASSET_TYPE | undefined>();
  const [equipmentModelType, setEquipmentModelType] = useState<EQUIPMENT_TYPE | undefined>();
  const [assetSearch, setAssetSearch] = useState<string | undefined>();
  const [equipmentHandle, setEquipmentHandle] = useState<string | undefined>();
  const { equipmentHandlers } = useEquipmentHandle({ assets, assetSearch });

  const handleAssetTypeChange = useCallback(
    (event: SelectChangeEvent<IAssetType[]>) => {
      const {
        target: { value },
      } = event;
      const newAssetType = value.length ? (value as ASSET_TYPE) : undefined;
      setAssetType(newAssetType);

      setFieldValue(nodeType, null);
      setAssetSearch(undefined);
      setEquipmentModelType(undefined);
      setEquipmentHandle(undefined);
      onAssetFilterChange(nodeType, { filter: newAssetType, search: undefined });
      trigger();
    },

    [trigger, setFieldValue, nodeType, onAssetFilterChange]
  );

  const tagsKey = `${nodeType}.tags`;

  const selectedAssetTags = watch(tagsKey);

  const handleEquipmentModelChange = useCallback(
    (event: SelectChangeEvent<IAssetType[]>) => {
      const {
        target: { value },
      } = event;
      const newEquipmentModel = value.length ? (value as EQUIPMENT_TYPE) : undefined;
      setAssetSearch('');
      onAssetFilterChange(nodeType, { filter: assetType, search: '', equipmentType: newEquipmentModel });
      setFieldValue(`${nodeType}.equipmentModel`, newEquipmentModel);
      setEquipmentModelType(newEquipmentModel);
    },
    [setFieldValue, onAssetFilterChange, nodeType, assetType]
  );

  const handleAssetSearchInputChange = useCallback(
    (value: string | undefined) => {
      setAssetSearch(value);
      onAssetFilterChange(nodeType, { filter: assetType, search: value, equipmentType: equipmentModelType });
    },
    [assetType, nodeType, onAssetFilterChange, equipmentModelType]
  );

  const handleEquipmentHandleChange = useCallback(
    (event: SelectChangeEvent) => {
      const {
        target: { value },
      } = event;

      const index = selectedAssetTags.findIndex((tag: TTag) => tag.name === handleIdTag);

      if (index === -1) {
        setFieldValue(
          tagsKey,
          [
            ...selectedAssetTags,
            {
              name: handleIdTag,
              value: value,
            },
          ],
          { shouldDirty: true }
        );
      } else {
        const ret = selectedAssetTags.slice(0);
        ret[index] = {
          name: handleIdTag,
          value: value,
        };
        setFieldValue(tagsKey, ret, { shouldDirty: true });
      }
      setFieldValue(`${nodeType}.equipmentHandle`, value, { shouldDirty: true });
      trigger();
      setEquipmentHandle(value);
    },
    [trigger, nodeType, tagsKey, selectedAssetTags, setFieldValue]
  );

  useEffect(() => {
    if (editMode) {
      return;
    }
    const isFormReset = !isDirty && !isSubmitted;
    if (isFormReset) {
      setAssetType(undefined);
      setAssetSearch('');
      onAssetFilterChange(nodeType, { filter: undefined, search: '' });
    }
  }, [isDirty, isSubmitted, nodeType, onAssetFilterChange, editMode]);

  useEffect(() => {
    if (defaultValue && editMode) {
      const assetType = defaultValue.assetType?.length ? (defaultValue.assetType as ASSET_TYPE) : undefined;
      setAssetType(assetType);
      handleAssetSearchInputChange(defaultValue.name || '');

      const modelName = defaultValue.tags?.find((tag) => tag.name === modelTag)?.value;
      let eqHandle;

      if (nodeType === 'inlet') {
        eqHandle = defaultValue.linkTags?.find((tag) => tag.name === 'InletHandleId')?.value;
      }
      if (nodeType === 'outlet') {
        eqHandle = defaultValue.linkTags?.find((tag) => tag.name === 'OutletHandleId')?.value;
      }

      if (modelName) {
        setFieldValue(`${nodeType}.equipmentModel`, mapTagModelToEquipmentType(modelName));
        setEquipmentModelType(mapTagModelToEquipmentType(modelName));
      }
      if (eqHandle) {
        setFieldValue(`${nodeType}.equipmentHandle`, eqHandle);
        setEquipmentHandle(eqHandle);
        setFieldValue(tagsKey, [
          modelName && {
            name: modelTag,
            value: mapTagModelToEquipmentType(modelName),
          },
          {
            name: handleIdTag,
            value: eqHandle,
          },
        ]);
      }

      onAssetFilterChange(nodeType, {
        filter: eqHandle ? ASSET_TYPE.EQUIPMENT : assetType,
        search: defaultValue.name || '',
      });
      return;
    }
    // NOTE: Cannot react on handleAssetSearchInputChange because of clearing asset on asset type change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, nodeType, onAssetFilterChange, editMode]);

  const isEquipmentSelected = assetType === ASSET_TYPE.EQUIPMENT;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.header}>{title}</div>
      </Grid>
      <Grid item xs={12} justifyContent={'space-between'} direction={'row'}>
        <Select
          className={isEquipmentSelected ? classes.equipmentSelected : classes.assetSelect}
          fullWidth
          prefix="asset-type"
          emptyOption={content.ASSET_TYPE_PLACEHOLDER}
          label={content.ASSET_TYPE_LABEL}
          value={assetType ?? ''}
          onChange={handleAssetTypeChange}
          data={assetTypeOptions}
        />
        {isEquipmentSelected && (
          <FormField<Record<TNodeToken, TNode>> fieldName={`${nodeType}.equipmentModel`}>
            {(props) => (
              <Select
                className={cx(classes.equipmentModelField, classes.equipmentSelected)}
                fullWidth
                prefix="equipment-model"
                label={content.EQUIPMENT_MODEL_LABGEL}
                {...props}
                value={equipmentModelType ?? ''}
                onChange={handleEquipmentModelChange}
                data={equipmentModelOptions(boiler, waterHeater, sentinel)}
              />
            )}
          </FormField>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormField<Record<TNodeToken, TNode>> fieldName={nodeType}>
          {(props) => {
            return (
              <NodeAutocomplete
                id={nodeType}
                inputValue={assetSearch ?? ''}
                onInputChange={handleAssetSearchInputChange}
                showGroup={!assetType}
                options={assets}
                {...props}
                onChange={(value: TNode | null | string) => {
                  setEquipmentHandle(undefined);
                  setFieldValue(`${nodeType}.equipmentHandle`, undefined);
                  if (typeof value === 'string' || value === null) {
                    return props?.onChange && props.onChange(value);
                  }

                  return (
                    props?.onChange &&
                    props.onChange({
                      ...value,
                      equipmentModel: equipmentModelType,
                      tags: [
                        {
                          name: modelTag,
                          value: value.metadata?.tags?.model,
                        },
                      ],
                    })
                  );
                }}
              />
            );
          }}
        </FormField>
      </Grid>
      {equipmentHandlers && equipmentHandlers.length > 0 && (
        <Grid item xs={12}>
          <FormField<Record<TNodeToken, TNode>> fieldName={`${nodeType}.equipmentHandle`}>
            {(props) => {
              return (
                <Select
                  className={classes.assetSelect}
                  fullWidth
                  prefix="equipment-handle"
                  label={content.EQUIPMENT_HANDLE}
                  {...props}
                  onChange={handleEquipmentHandleChange}
                  data={equipmentHandlers}
                  value={equipmentHandle ?? ''}
                />
              );
            }}
          </FormField>
        </Grid>
      )}
    </Grid>
  );
};
