import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { useGetOrganizationDetails, useUpdateOrganizationDetails } from '@marlin/account-data-access-organization';
import { FormField, Input } from '@marlin/shared/ui-form-common';
import { Paper } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ReadOnlySection } from '../components/read-only-section.component';
import { ChangeOrganizationNameFormFieldsSchema, TChangeOrganizationNameForm } from './change-org-name.schema';
import { useStyles } from './change-org-name.styles';
import { Body, Footer, Title } from './confirmation-modal';
import { content } from './content';

export const ChangeOrgNameControl = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);
  const { data } = useGetOrganizationDetails();
  const { mutateAsync: updateOrganizationDetails } = useUpdateOrganizationDetails();
  const { modalDispatch } = useContext(ModalContext);

  const form = useForm<TChangeOrganizationNameForm>({
    mode: 'onChange',
    resolver: zodResolver(ChangeOrganizationNameFormFieldsSchema),
    defaultValues: {
      organizationName: '',
    },
    values: {
      organizationName: data?.name || '',
    },
  });

  const submit = useCallback(
    async (orgName: string) =>
      updateOrganizationDetails({ name: orgName })
        .then(() => {
          enqueueSnackbar(content.UPDATE_SUCCESS, { variant: 'success' });
        })
        .catch(() => {
          form.reset();
          enqueueSnackbar(content.UPDATE_ERROR, { variant: 'error' });
        }),
    [form, enqueueSnackbar, updateOrganizationDetails]
  );

  const onSubmit = (data: TChangeOrganizationNameForm) => {
    submit(data.organizationName);
    modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
    toggleEditMode();
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const cancelEditMode = () => {
    toggleEditMode();
    modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
    form.reset();
  };

  const openModal = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <Title />,
        body: <Body currentOrgName={data?.name || ''} newOrgName={form.getValues().organizationName} />,
        footer: <Footer onCancel={cancelEditMode} onSubmit={form.handleSubmit(onSubmit)} />,
      },
    });
  };

  return (
    <Paper data-testid="card-change-org-control" className={classes.container}>
      <Typography className={classes.orgNameText}>{content.ORGANIZATION_NAME}</Typography>
      {editMode ? (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={classes.inputsWrapper}>
            <FormField<TChangeOrganizationNameForm> fieldName="organizationName">
              {(props) => <Input label={content.ORGANIZATION_NAME} {...props} required disabled={!editMode} />}
            </FormField>
            <div className={classes.editModeButtons}>
              <Button variant="outlined" onClick={cancelEditMode} data-testid="change-org-control-cancel">
                {content.CANCEL}
              </Button>
              <Button
                variant="contained"
                onClick={openModal}
                disabled={!form.formState.isDirty || !form.formState.isValid}
                data-testid="change-org-control-submit"
              >
                {content.UPDATE}
              </Button>
            </div>
          </form>
        </FormProvider>
      ) : (
        <ReadOnlySection onEditClick={toggleEditMode} testid="organization-name">
          <div data-testid="organization-settings-readonly-organization-name">{data?.name}</div>
        </ReadOnlySection>
      )}
    </Paper>
  );
};
