import { TCreateSystemMapNodeParams } from '@marlin/system-map/data-access/system-map';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { getConnectedEdges, useReactFlow } from 'reactflow';

import { content } from './content';

const maxAllowedEdges = 4;

interface IAddNodeToTeeContextMenuProps {
  onAddNodeToTee: (tee: TCreateSystemMapNodeParams) => void;
}

export const useAddNodeToTeeContextMenu = ({ onAddNodeToTee }: IAddNodeToTeeContextMenuProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const reactFlow = useReactFlow();

  const addNodeToTee = useCallback(
    async (teeId: string) => {
      const tee = reactFlow.getNode(teeId);
      if (!tee) {
        return;
      }
      const connectedEdgesCount = getConnectedEdges([tee], reactFlow.getEdges()).length;
      if (connectedEdgesCount >= maxAllowedEdges) {
        enqueueSnackbar(content.TOO_MANY_LINKS_ON_SELECTED_TEE, {
          variant: 'error',
          preventDuplicate: true,
        });
        return;
      }

      const systemMapNode = {
        nodeType: tee.data.nodeType,
        assetId: tee.data.assetId,
        assetType: tee.data.assetType,
        positionX: tee.position.x,
        positionY: tee.position.y,
        name: tee.data.name,
        nodeId: tee.id,
      };

      onAddNodeToTee(systemMapNode);
    },
    [reactFlow, onAddNodeToTee, enqueueSnackbar]
  );
  return {
    addNodeToTee,
  };
};
