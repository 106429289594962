import { MarlinTheme } from '@marlin/shared/theme';
import { TGraphLink } from '@marlin/system-map/data-access/system-map';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { BaseEdge, EdgeProps, getSmoothStepPath, useReactFlow, useStore } from 'reactflow';

import {
  hasHighPrioAlerts,
  hasLostCommunicationAlert,
  hasLowPrioAlerts,
  isTelemetryError,
} from '../nodes/utils/node-errors.utils';
import { getEdgeParams } from './floating-edges-utils';
import { MARKER_TYPE } from './use-marker-definitions.hook';

const createMarkerEnd = (type: string = MARKER_TYPE.ARROW_DEFAULT) => `url(#${type})`;

export function SystemMapEdge({
  id,
  style,
  source,
  target,
  data,
  sourceHandleId,
  targetHandleId,
}: EdgeProps<TGraphLink['data']>) {
  const { getNode } = useReactFlow();
  const theme = useTheme<MarlinTheme>();
  const sourceNode = getNode(source);
  const targetNode = getNode(target);

  const edgeProps = useMemo(() => {
    if (sourceNode && targetNode) {
      const hasHighAlerts = [sourceNode, targetNode].some(
        (node) => hasHighPrioAlerts(node.data) || hasLostCommunicationAlert(node.data) || isTelemetryError(node.data)
      );
      if (hasHighAlerts) {
        return {
          color: theme.palette.error.main,
          type: data?.isAttachment ? MARKER_TYPE.CIRCLE_ERROR : MARKER_TYPE.ARROW_ERROR,
        };
      }
      const hasLowAlerts = [sourceNode, targetNode].some((node) => hasLowPrioAlerts(node.data));
      if (hasLowAlerts) {
        return {
          color: theme.palette.warning.main,
          type: data?.isAttachment ? MARKER_TYPE.CIRCLE_WARNING : MARKER_TYPE.ARROW_WARNING,
        };
      }
    }
    return {
      color: theme.palette.systemMap.main,
      type: data?.isAttachment ? MARKER_TYPE.CIRCLE_DEFAULT : MARKER_TYPE.ARROW_DEFAULT,
    };
  }, [
    data?.isAttachment,
    sourceNode,
    targetNode,
    theme.palette.error.main,
    theme.palette.systemMap.main,
    theme.palette.warning.main,
  ]);

  const ancestors = useStore((store) => {
    const nodes = Array.from(store.nodeInternals.values());
    const getAncestorsList = (nodeId: string): string[] => {
      const node = nodes.find((n) => n.id === nodeId);
      if (node && node.parentNode) {
        return [node.parentNode, ...getAncestorsList(node.parentNode)];
      }
      return [nodeId];
    };

    return {
      sourceAncestors: getAncestorsList(source),
      targetAncestors: getAncestorsList(target),
    };
  });

  if (!sourceNode || !targetNode) {
    return null;
  }

  if (ancestors.sourceAncestors.includes(target) || ancestors.targetAncestors.includes(source)) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
    sourceHandleId,
    targetHandleId
  );

  const [edgePath] = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
    borderRadius: 0,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          ...style,
          stroke: edgeProps.color,
        }}
        markerEnd={createMarkerEnd(edgeProps.type)}
      />
    </>
  );
}
