export const minutesIn8Hours = 8 * 60;
export const minutesIn24Hours = 24 * 60;
export const minutesIn7Days = 7 * minutesIn24Hours;
export const minutesIn90Days = 90 * minutesIn24Hours;
export const minutesIn365Days = 365 * minutesIn24Hours;

export const maxDatapointsCount = 10;
export const sensorsGroupId = 'nexaSensors';

export const DateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const analyticsSelectedSensorsUserDataAreaKey = 'analytics-selected-sensors';
export const analyticsAveragingFunctionFilterUserDataAreaKey = 'analytics-averaging-function-filter';
