import {
  DeleteDeviceModalBody,
  DeleteDeviceModalFooter,
  DeleteDeviceModalTitle,
} from '@marlin/asset/ui/delete-device-modal';
import {
  DeleteEquipmentModalBody,
  DeleteEquipmentModalFooter,
  DeleteEquipmentModalTitle,
} from '@marlin/asset/ui/delete-equipment-modal';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useContext, useMemo } from 'react';

import { IAsset } from './types';

interface IUseLocationsDetailsContextMenu {
  allowDelete: boolean;
  allowEdit: boolean;
  deleteAsset: (asset: IAsset) => void;
  editAsset: (asset: IAsset) => void;
}

export const useLocationsDetailsContextMenu = (): IUseLocationsDetailsContextMenu => {
  const { goTo } = useRouter();
  const { modalDispatch } = useContext(ModalContext);

  const allowDelete = usePermission(PERMISSIONS.DELETE_ASSET);
  const allowEdit = usePermission(PERMISSIONS.EDIT_ASSET);

  const deleteAsset = useCallback(
    (asset: IAsset) => {
      if (asset.type === 'EQUIPMENT') {
        modalDispatch({
          type: MODAL_ACTION_TYPE.SHOW,
          payload: {
            title: <DeleteEquipmentModalTitle deviceCount={asset.deviceCount} />,
            body: (
              <DeleteEquipmentModalBody
                deleteAssetName={asset.name}
                deviceCount={asset.deviceCount}
                assetId={asset.id}
              />
            ),
            footer: <DeleteEquipmentModalFooter deviceCount={asset.deviceCount} assetId={asset.id} />,
          },
        });
      } else if (asset.type === 'DEVICE') {
        modalDispatch({
          type: MODAL_ACTION_TYPE.SHOW,
          payload: {
            title: <DeleteDeviceModalTitle />,
            body: <DeleteDeviceModalBody deviceName={asset.name} deviceId={asset.id} />,
            footer: <DeleteDeviceModalFooter deviceId={asset.id} />,
          },
        });
      }
    },
    [modalDispatch]
  );
  const editAsset = useCallback(
    (asset: IAsset) => {
      const editAssetUrl =
        asset.type === 'EQUIPMENT' ? routes.equipments.update.url(asset.id) : routes.sensors.update.url(asset.id);
      goTo(editAssetUrl);
    },
    [goTo]
  );

  return useMemo(
    () => ({
      allowDelete,
      allowEdit,
      deleteAsset,
      editAsset,
    }),
    [allowDelete, allowEdit, deleteAsset, editAsset]
  );
};
